import React, { useEffect } from 'react';
import classNames from 'classnames';
import { Icon56CheckCircleOutline, Icon56ErrorOutline } from '@vkontakte/icons';
import { useStoreMap, useUnit } from 'effector-react';
import { ModalCard } from '@vkontakte/vkui';
import { useAppearance } from '@vkontakte/vk-bridge-react';
import { callAnalyticShowDirect } from '@analytics';
import Typograf from '@common/components/common/Typograf';
import styles from './styles.module.scss';
import { vkUIModalManager } from '@common/services/vk-ui-modal-manager';
export const ModalManagerCard = (props) => {
    const { id } = props;
    const modalContent = useStoreMap(vkUIModalManager.modalCard.$modalsPayload, (state) => state[id]);
    const closeModal = useUnit(vkUIModalManager.closeModal);
    const defaultTheme = useAppearance();
    const { analyticShowAttribute, theme = defaultTheme, header, subheader, actions, icon, children, mode, onClose, } = modalContent ?? {};
    const onCloseModalCard = () => {
        onClose && onClose();
        closeModal();
    };
    const getClasses = () => {
        const classes = ['vkui--vkBase--', 'vkui--vkCom--', 'vkui--vkIOS--'];
        return classes.map((item) => `${item}${theme}`);
    };
    useEffect(() => {
        if (analyticShowAttribute) {
            callAnalyticShowDirect(analyticShowAttribute);
        }
    }, [analyticShowAttribute]);
    let iconToRender = null;
    if (icon) {
        iconToRender = icon;
    }
    else {
        switch (mode) {
            case 'success': {
                iconToRender = <Icon56CheckCircleOutline className={styles.icon_success}/>;
                break;
            }
            case 'info': {
                iconToRender = <Icon56ErrorOutline className={styles.icon_warning}/>;
                break;
            }
            case 'error': {
                iconToRender = <Icon56ErrorOutline className={styles.icon_error}/>;
                break;
            }
            default:
                null;
                break;
        }
    }
    return (<ModalCard id={id} icon={iconToRender} header={header && <Typograf>{header}</Typograf>} subheader={subheader && <Typograf>{subheader}</Typograf>} actions={actions} onClose={onCloseModalCard} subheaderComponent='h5' headerComponent='h2' className={classNames(getClasses())}>
      {children}
    </ModalCard>);
};
