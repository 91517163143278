import React from 'react';
import { modalManagerTools } from '@common/services/modal-manager';
import ModalButton from '@common/components/modals/ModalButton';
import { redirectToVKID } from '../../../model';
export const showUnauthorizedModal = () => {
    modalManagerTools.modalTemplates.showMessageModal({
        analyticShowAttribute: 'modal-unauthorized',
        mode: 'warning',
        header: 'Кажется, вы не авторизованы',
        subheader: 'Чтобы продолжить необходимо пройти авторизацию',
        actions: (<ModalButton size='l' mode='primary' onClick={() => {
                redirectToVKID();
            }}>
        Войти
      </ModalButton>),
    });
};
