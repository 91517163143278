import React from 'react';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import ModalButton from '@common/components/modals/ModalButton';
import { useGetSubscriptionDetailsContext } from '../../../../lib';
const ManagementButton = () => {
    const { subscription } = useGetSubscriptionDetailsContext();
    const { management } = subscription;
    return (<ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionManagementButtonRedirectClick)} target='_blank' href={management?.url} stretched={true}>
      {management?.title}
    </ModalButton>);
};
export default ManagementButton;
