import { useUnit } from 'effector-react';
import { AuthStateModel } from '@common/entities/auth-state';
import { useEffect } from 'react';
export const useAuthorizationChecker = (params, deps) => {
    const authState = useUnit(AuthStateModel.$authState);
    const { authorizedCallback, unauthorizedCallback, idleCallback } = params ?? {};
    useEffect(() => {
        if (authState === 'authorized') {
            authorizedCallback && authorizedCallback();
        }
        if (authState === 'unauthorized') {
            unauthorizedCallback && unauthorizedCallback();
        }
        if (authState === 'idle') {
            idleCallback && idleCallback();
        }
    }, [authState, ...deps]);
};
