import React from 'react';
import { Div, Group, Header as HeaderVkui, Text } from '@vkontakte/vkui';
import { useUnit } from 'effector-react';
import { useIntl } from 'react-intl';
import useDateFormat from '@common/hooks/useDateFormat';
import { MembersList } from '@common/features/group-subscription';
import { AbTestLkUntieCardModel } from '@common/features/ab-test-lk-untie-card';
import { AbTestLkUntieCardV2Model } from '@common/features/ab-test-lk-untie-card-v2/model';
import { useGetIsShowNewCancelButtonType } from '../../lib';
import { SubscriptionDetailsBenefits } from '../subscription-details-benefits';
import { Button } from '../button';
import { getStatusText } from './lib/get-status-text';
import messages from './messages';
import styles from './styles.module.scss';
const SubscriptionContent = (props) => {
    const { subscription } = props;
    const { cancelButtonSubscriptionType } = useUnit(AbTestLkUntieCardModel.$chosenVariant);
    const intl = useIntl();
    const isGroupSubscriptionManagementAvailable = subscription?.features?.group?.is_group_subscription && subscription.status !== 'inactive';
    const { status, subscription_type, cost, description, subscription_start_time, last_recurrent_payment_time } = subscription;
    const statusText = getStatusText(status);
    const subscriptionStartTime = subscription_start_time ? useDateFormat(subscription_start_time) : null;
    const lastRecurrentPaymentTime = last_recurrent_payment_time ? useDateFormat(last_recurrent_payment_time) : null;
    const isShowContent = subscription_type || statusText || subscription_start_time || cost?.display || last_recurrent_payment_time;
    const abTestNeedCustomizeSubscriptionDetail = AbTestLkUntieCardV2Model.useNeedCustomizeSubscriptionDetail();
    const useGetIsShowNewCancelButtonTypeResult = useGetIsShowNewCancelButtonType(cancelButtonSubscriptionType, subscription);
    return (<>
      <div className={useGetIsShowNewCancelButtonTypeResult ? styles.subscriptionContent : undefined}>
        {isShowContent && (<Group mode='plain' header={!useGetIsShowNewCancelButtonTypeResult && (<HeaderVkui style={{ marginTop: '-16px' }}>{intl.formatMessage(messages.header)}</HeaderVkui>)}>
            <Div className={styles.table}>
              {subscription_type && (<div className={styles.tableItem}>
                  <Text className={styles.tableLabel}>{intl.formatMessage(messages.type)}:</Text>
                  <Text className={styles.tableValue}>{subscription_type}</Text>
                </div>)}
              {statusText && (<div className={styles.tableItem}>
                  <Text className={styles.tableLabel}>{intl.formatMessage(messages.status)}:</Text>
                  <Text className={styles.tableValue}>{statusText}</Text>
                </div>)}
              {subscriptionStartTime && (<div className={styles.tableItem}>
                  <Text className={styles.tableLabel}>{intl.formatMessage(messages.subscriptionStartTime)}:</Text>
                  <Text className={styles.tableValue}>{subscriptionStartTime}</Text>
                </div>)}
              {cost?.display && (<div className={styles.tableItem}>
                  <Text className={styles.tableLabel}>{intl.formatMessage(messages.cost)}:</Text>
                  <Text className={styles.tableValue}>{cost.display}</Text>
                </div>)}
              {lastRecurrentPaymentTime && (<div className={styles.tableItem}>
                  <Text className={styles.tableLabel}>{intl.formatMessage(messages.lastRecurrentPaymentTime)}:</Text>
                  <Text className={styles.tableValue}>{lastRecurrentPaymentTime}</Text>
                </div>)}
              {(useGetIsShowNewCancelButtonTypeResult || abTestNeedCustomizeSubscriptionDetail) && (<div className={styles.tableItem}>
                  <Button />
                </div>)}
            </Div>
          </Group>)}
        {isGroupSubscriptionManagementAvailable && <MembersList />}
        {description.length > 0 && <SubscriptionDetailsBenefits benefits={[...description]}/>}
      </div>
    </>);
};
export default SubscriptionContent;
