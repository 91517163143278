import { showSubscriptionCancelReasonModal } from '@common/entities/subscriptions';
import { showCancelGroupSubscriptionModal } from '../lib/modals';
import { suspendFlow } from './suspend-flow';
export const cancelGroupSubscriptionFlow = async (params) => {
    const { subscriptionId, isPauseAvailable } = params;
    const cancelSubscriptionHandler = () => {
        if (isPauseAvailable) {
            suspendFlow({
                subscriptionId,
                cancelSubscription: () => {
                    showSubscriptionCancelReasonModal({ subscriptionId });
                },
            });
            return;
        }
        showSubscriptionCancelReasonModal({ subscriptionId });
    };
    showCancelGroupSubscriptionModal({
        cancelSubscriptionHandler,
    });
};
