import { defineMessages } from 'react-intl';
export default defineMessages({
    text: {
        id: 'textWithSubscription',
        defaultMessage: 'Подписка будет действовать до {subscriptionEndTime} и не продлится автоматически. Хотите удалить карту?',
    },
    descriptionSuspend: {
        id: 'descriptionSuspendWithSubscription',
        defaultMessage: 'Подписка будет остановлена и не продлится автоматически, так как не выбран способ оплаты. Вы хотите удалить карту?',
    },
    success: {
        id: 'successTextWithSubscription',
        defaultMessage: 'Подписка будет действовать до {subscriptionEndTime}. Выберите другой способ оплаты, чтобы она обновлялась автоматически.',
    },
    descriptionSuccessSuspend: {
        id: 'descriptionSuccessSuspendWithSubscription',
        defaultMessage: 'Подписка будет остановлена. Выберите другой способ оплаты, чтобы она обновлялась автоматически.',
    },
});
