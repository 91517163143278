import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    title: {
        id: 'title',
        defaultMessage: 'Заявка отклонена',
    },
    temporaryRejected: {
        id: 'temporaryRejected',
        defaultMessage: 'Новую заявку на подписку для студентов можно отправить {daysCount, plural, =0 {завтра} one {через {daysCount} день} few {через {daysCount} дня} other {через {daysCount} дней}}{descriptions, select, null {} other {. {descriptions}}}',
    },
    permanentRejected: {
        id: 'permanentRejected',
        defaultMessage: 'Специальная цена на подписку доступна только студентам. {descriptions, select, null {} other {{descriptions}}}',
    },
    nowHasActiveSubscriptionRejected: {
        id: 'nowHasActiveSubscriptionRejected',
        defaultMessage: 'Специальная цена на подписку доступна только для студентов. Сейчас у вас активна подписка на {subscriptionType} за {subscriptionPrice}',
    },
    nowHasActiveManagementSubscriptionRejected: {
        id: 'nowHasActiveManagementSubscriptionRejected',
        defaultMessage: 'Специальная цена на подписку доступна только для студентов. Сейчас у вас активна подписка {subscriptionType} от {distributorName, select, null {Партнера} other {{distributorName}}}',
    },
    ok: {
        id: 'ok',
        defaultMessage: 'Понятно',
    },
});
