import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { api } from '../../api';
import { dateFormatter } from '../../lib';
import { $paymentsHistoryState, changePaymentsHistoryState } from './payments-history-state';
const $paymentsHistory = createStore([]);
const PaymentHistoryGate = createGate();
const getPaymentsHistory = createEvent();
const getPaymentsHistoryFx = createEffect(async () => {
    changePaymentsHistoryState('process');
    try {
        const data = await api.getPaymentsHistory();
        const mappedPayments = data.map((payment) => {
            return {
                ...payment,
                operation: {
                    ...payment.operation,
                    date: dateFormatter(payment.operation.date),
                },
            };
        });
        changePaymentsHistoryState('idle');
        return mappedPayments;
    }
    catch (error) {
        changePaymentsHistoryState('error');
        return [];
    }
});
sample({
    clock: [PaymentHistoryGate.open, getPaymentsHistory],
    target: getPaymentsHistoryFx,
});
$paymentsHistory.on(getPaymentsHistoryFx.doneData, (_state, payload) => payload);
export const paymentHistoryModel = {
    PaymentHistoryGate,
    $paymentsHistory,
    getPaymentsHistory,
    $paymentsHistoryState,
};
