import { createEffect } from 'effector';
import apiCommon from '@common/utils/ApiCommon';
const createApi = () => {
    const getOffersFx = createEffect(async (params) => {
        const offers = await apiCommon.getOffers(params);
        if (!offers.data.success) {
            return Promise.reject(offers.data.errors);
        }
        return offers.data;
    });
    return {
        getOffersFx,
    };
};
export { createApi };
