import { createEvent, sample } from 'effector';
import { AuthStateModel } from '@common/entities/auth-state';
import { unauthorizedWayFx } from './unauthorized-way';
import { finishStartEmailAuthorizedWay, startEmailAuthorizedWay } from './start-email-authorized-way';
import { createCallbackAfterFlow } from '../callback-after-flow';
export const startEmailStudentFlow = createEvent();
createCallbackAfterFlow(startEmailStudentFlow, finishStartEmailAuthorizedWay);
sample({
    clock: startEmailStudentFlow,
    source: { authState: AuthStateModel.$authState },
    filter: ({ authState }) => authState === 'authorized',
    fn: (_, clockData) => clockData,
    target: startEmailAuthorizedWay,
});
sample({
    clock: startEmailStudentFlow,
    source: { authState: AuthStateModel.$authState },
    filter: ({ authState }) => authState === 'unauthorized',
    target: unauthorizedWayFx,
});
