import React, { useEffect, useMemo, useState } from 'react';
import { useUnit } from 'effector-react';
import { Avatar, Button, Group, Header, RichCell } from '@vkontakte/vkui';
import { Icon20PaymentCardOutline, Icon24MoneyCircle } from '@vkontakte/icons';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import { paymentMethodModel } from '@common/data-layer/payment-method/model';
import Icon28LogoVisaColor from '@common/components/icons/internal/Icon28LogoVisaColor';
import Icon28LogoMastercardColor from '@common/components/icons/internal/Icon28LogoMastercardColor';
import { paymentMethodWidgetModel } from '../../model';
import { IconSbpbankColor } from '../../lib/fc-icons';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import messages from './messages';
const CurrentPaymentMethod = (props) => {
    const intl = useIntl();
    const { unlinkPaymentMethodHandler } = props;
    const $paymentMethod = useUnit(paymentMethodModel.$paymentMethod);
    const $unlinkPaymentMethodState = useUnit(paymentMethodWidgetModel.$unlinkPaymentMethodState);
    const [isRemovingInProgress, setIsRemovingInProgress] = useState(false);
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    useEffect(() => {
        if ($unlinkPaymentMethodState === 'process') {
            setIsRemovingInProgress(true);
            return;
        }
        setIsRemovingInProgress(false);
    }, [$unlinkPaymentMethodState]);
    const onClickHandler = async () => {
        try {
            setIsLoadingButton(true);
            await unlinkPaymentMethodHandler();
        }
        finally {
            setIsLoadingButton(false);
        }
    };
    const getContent = () => {
        const getVkpayContent = () => {
            return {
                icon: <Icon24MoneyCircle className={styles.icon}/>,
                text: 'VK Pay',
                caption: null,
            };
        };
        const getSbpContent = (params) => {
            return {
                icon: <IconSbpbankColor className={styles.icon}/>,
                text: params.bankName,
                caption: null,
            };
        };
        const getCardContent = (params) => {
            const { typeCard, lastNumberCard, endDateCard } = params;
            const iconCardMapping = {
                visa: <Icon28LogoVisaColor />,
                mastercard: <Icon28LogoMastercardColor />,
                default: <Icon24MoneyCircle className={styles.icon}/>,
            };
            return {
                icon: iconCardMapping[typeCard] || iconCardMapping['default'],
                text: `•••• ${lastNumberCard}`,
                caption: endDateCard ?? `Срок действия ${endDateCard}`,
            };
        };
        switch ($paymentMethod?.status) {
            case 'bind': {
                if ($paymentMethod.type === 'sbp') {
                    return getSbpContent({ bankName: $paymentMethod.attributes.bank_name });
                }
                if ($paymentMethod.type === 'vkpay_checkout') {
                    return getVkpayContent();
                }
                if ($paymentMethod.type === 'card') {
                    return getCardContent({
                        typeCard: $paymentMethod.attributes?.pay_system || 'default',
                        lastNumberCard: $paymentMethod.attributes?.last_card_digits || '',
                        endDateCard: $paymentMethod.attributes?.expire_date,
                    });
                }
                return {
                    icon: <Icon20PaymentCardOutline className={styles.icon}/>,
                    text: intl.formatMessage(messages.paymentMethodNoBindTxt),
                    caption: null,
                };
            }
            case 'process': {
                return {
                    icon: <Icon20PaymentCardOutline className={styles.icon}/>,
                    text: intl.formatMessage(messages.paymentMethodInProcessTxt),
                    caption: null,
                };
            }
            default: {
                return {
                    icon: <Icon20PaymentCardOutline className={styles.icon}/>,
                    text: intl.formatMessage(messages.paymentMethodDefaultTxt),
                    caption: null,
                };
            }
        }
    };
    const { icon, text, caption } = useMemo(() => getContent(), []);
    return (<Group separator='hide' header={<Header>{intl.formatMessage(messages.currentPaymentMethodTitle)}</Header>}>
      <RichCell before={<Avatar size={48} gradientColor={'custom'}>
            {icon}
          </Avatar>} caption={caption} after={$paymentMethod?.status === 'bind' && (<Button {...createAnalyticClickAttribute(analyticsDictionary.modalPaymethodManageButtonUnlinkClick)} mode='tertiary' loading={isRemovingInProgress || isLoadingButton} disabled={isRemovingInProgress || isLoadingButton} onClick={onClickHandler} size='s'>
              {intl.formatMessage(messages.unlinkPaymentMethodBtnTxt)}
            </Button>)} hasHover={false} hasActive={false}>
        {text}
      </RichCell>
    </Group>);
};
export default CurrentPaymentMethod;
