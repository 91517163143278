import vkBridge from '@vkontakte/vk-bridge';
import { Platform } from '@vkontakte/vkui';
import { validateData } from '@common/shared/lib/validate-data/validateData';
import { DEFAULT_TIMEOUT, VK_BRIDGE_TIMEOUT_ERROR } from './consts';
import { IS_MINIAPP, MINIAPP_ID, WITH_DEBUG } from '@common/consts';
import VKWebAppInitResponseSchema from './schemas/VKWebAppInitResponseSchema.json';
import VKWebAppGetSilentTokenParamsSchema from './schemas/VKWebAppGetSilentTokenParamsSchema.json';
import VKWebAppGetSilentTokenResponseSchema from './schemas/VKWebAppGetSilentTokenResponseSchema.json';
class VkBridgeTimeoutError extends Error {
    constructor({ method, timeout }) {
        super(`${VK_BRIDGE_TIMEOUT_ERROR} - method: ${method}, timeout: ${timeout}`);
        this.name = `${VK_BRIDGE_TIMEOUT_ERROR}.${method}`;
        this.method = method;
        this.timeout = timeout;
    }
}
class ApiVkBridge {
    constructor() {
        this.isAppInited = false;
        this.access_token = null;
        this.canShowAltPayMethodsForAndroid = null;
        this.setDefaultData = (withPromise) => {
            let resolve = () => {
            };
            if (!withPromise) {
                return {
                    promise: null,
                    resolve,
                    data: null,
                };
            }
            const promise = new Promise((promiseResolve) => {
                resolve = promiseResolve;
            });
            return {
                promise,
                resolve,
                data: null,
            };
        };
        this.validateData = validateData;
        this.callVkBridgeWithTimeout = async (methodName, params) => {
            if (!IS_MINIAPP) {
                console.error(`Call VkBridge outside miniapp, method: ${methodName}`);
            }
            if (methodName !== 'VKWebAppInit') {
                await this.init.promise;
            }
            const { requestParams, requestScheme, responseScheme, timeout = DEFAULT_TIMEOUT } = params;
            return new Promise((resolve, reject) => {
                const timeoutId = setTimeout(() => {
                    if (WITH_DEBUG) {
                        console.error(`vk bridge does not response: ${methodName}`);
                    }
                    const error = new VkBridgeTimeoutError({
                        method: methodName,
                        timeout,
                    });
                    reject(error);
                }, timeout);
                if (requestScheme) {
                    this.validateData(requestScheme, requestParams, reject, `vkBridge - ${methodName} - params`);
                }
                vkBridge
                    .send(methodName, requestParams)
                    .then((data) => {
                    clearTimeout(timeoutId);
                    if (responseScheme) {
                        this.validateData(responseScheme, data, reject, `vkBridge - ${methodName} - response`);
                    }
                    return resolve(data);
                })
                    .catch((error) => {
                    clearTimeout(timeoutId);
                    return reject(error);
                });
            });
        };
        this.getAuthToken = async (params) => {
            if (!this.access_token) {
                this.access_token = (await this.callVkBridgeWithTimeout('VKWebAppGetAuthToken', {
                    requestParams: {
                        app_id: MINIAPP_ID,
                        scope: '',
                    },
                    ...params,
                })).access_token;
            }
            return this.access_token;
        };
        this.getSilentToken = (params) => this.callVkBridgeWithTimeout('VKWebAppGetSilentToken', {
            requestParams: {
                app_id: MINIAPP_ID,
            },
            requestScheme: VKWebAppGetSilentTokenParamsSchema,
            responseScheme: VKWebAppGetSilentTokenResponseSchema,
            ...params,
        });
        this.buildMemoMethod = (key, methodName) => async (params) => {
            if (!this[key].promise) {
                this[key] = this.setDefaultData(true);
                this.callVkBridgeWithTimeout(methodName, {
                    requestParams: {},
                    ...params,
                }).then((result) => {
                    this[key].data = result;
                    this[key].resolve();
                });
            }
            await this[key].promise;
            return this[key].data;
        };
        this.getConfig = this.buildMemoMethod('appConfig', 'VKWebAppGetConfig');
        this.getClientVersion = this.buildMemoMethod('clientVersion', 'VKWebAppGetClientVersion');
        this.getUserInfo = this.buildMemoMethod('userInfo', 'VKWebAppGetUserInfo');
        this.getCanShowAltPayMethodsForAndroid = async (additionalParams) => {
            if (this.canShowAltPayMethodsForAndroid === null) {
                const result = await this.callApiMethod('store.canShowAltPayMethods', {}, additionalParams);
                this.canShowAltPayMethodsForAndroid = result.response === 1;
            }
            return this.canShowAltPayMethodsForAndroid;
        };
        this.getIsClientAndroidAndCanPayByCard = async () => {
            const { platform, is_google_services_available = false } = await this.getClientVersion();
            if (platform !== Platform.ANDROID) {
                return false;
            }
            if (!is_google_services_available) {
                return true;
            }
            const canShowAltPayMethodsForAndroid = await this.getCanShowAltPayMethodsForAndroid();
            return canShowAltPayMethodsForAndroid;
        };
        this.sendVKPayCheckout = async (payload, additionalParams) => {
            return await this.callVkBridgeWithTimeout('VKWebAppVKPayCheckout', {
                requestParams: {
                    payload,
                },
                ...additionalParams,
            });
        };
        this.initMiniApp = (params) => new Promise((resolve, reject) => {
            const MAX_TIME_TILL_ERROR = 30000;
            const TIME_FROM_START = Math.floor(performance.now());
            const TIME_TILL_ERROR = MAX_TIME_TILL_ERROR - TIME_FROM_START;
            const bridgeEventsHandler = (event) => {
                try {
                    const { type, data } = event.detail;
                    const unsubscribe = () => vkBridge.unsubscribe(bridgeEventsHandler);
                    switch (type) {
                        case 'VKWebAppInitResult': {
                            delete data.request_id;
                            unsubscribe();
                            resolve(data);
                            this.init.resolve();
                            break;
                        }
                        case 'VKWebAppInitFailed': {
                            unsubscribe();
                            reject(data);
                            this.init.resolve();
                            break;
                        }
                    }
                }
                catch { }
            };
            vkBridge.subscribe(bridgeEventsHandler);
            this.callVkBridgeWithTimeout('VKWebAppInit', {
                requestParams: {},
                responseScheme: VKWebAppInitResponseSchema,
                timeout: TIME_TILL_ERROR,
                ...params,
            })
                .then((result) => {
                resolve(result);
                this.init.resolve();
            })
                .catch((error) => {
                reject(error);
                this.init.resolve();
            });
        });
        this.closeMiniApp = (additionalParams) => this.callVkBridgeWithTimeout('VKWebAppClose', {
            requestParams: {
                status: 'success',
                payload: { name: 'closeApp' },
            },
            ...additionalParams,
        }).catch(() => this.callVkBridgeWithTimeout('VKWebAppClose', {
            requestParams: undefined,
            ...additionalParams,
        }));
        this.callApiMethod = async (methodName, params, additionalParams) => {
            await this.getAuthToken();
            return this.callVkBridgeWithTimeout('VKWebAppCallAPIMethod', {
                requestParams: {
                    method: methodName,
                    params: {
                        v: '5.131',
                        access_token: this.access_token,
                        ...params,
                    },
                },
                ...additionalParams,
            });
        };
        this.openApp = (params, additionalParams) => {
            return this.callVkBridgeWithTimeout('VKWebAppOpenApp', { requestParams: params, ...additionalParams });
        };
        this.supports = vkBridge.supports;
        this.subscribe = vkBridge.subscribe;
        this.isEmbedded = vkBridge.isEmbedded;
        this.isIframe = vkBridge.isIframe;
        this.isWebView = vkBridge.isWebView;
        this.isStandalone = vkBridge.isStandalone;
        this.init = this.setDefaultData(true);
        this.clientVersion = this.setDefaultData();
        this.appConfig = this.setDefaultData();
        this.userInfo = this.setDefaultData();
    }
}
export default ApiVkBridge;
