import { createEvent, createStore } from 'effector';
import { getDataPulling } from '@common/components/Root/actions';
import { modalManagerModel } from '@common/services/modal-manager';
import { api } from '../../api';
import { CANCEL_INVALID, DEFAULT_ERROR_INVALID, VALID } from './content';
const $checkboxRulesField = createStore(false);
const toggleCheckboxRulesField = createEvent();
$checkboxRulesField.on(toggleCheckboxRulesField, (_state, payload) => payload);
export const acceptInvitation = async (communityId) => {
    try {
        const result = await api.invitationConfirm(communityId);
        if (!result.data.successful) {
            modalManagerModel.showModal(DEFAULT_ERROR_INVALID((result.data.errors && result.data.errors[0]) || {}));
            return;
        }
        await getDataPulling({ all: true }).catch();
        modalManagerModel.showModal(VALID);
    }
    catch {
        modalManagerModel.showModal(DEFAULT_ERROR_INVALID());
    }
};
export const cancelInvitation = async (communityId) => {
    try {
        const result = await api.invitationDecline(communityId);
        modalManagerModel.closeModal();
        if (!result.data.successful) {
            throw new Error();
        }
    }
    catch (_e) {
        modalManagerModel.showModal(CANCEL_INVALID);
    }
};
export const showAcceptInvitationModalModel = {
    acceptInvitation,
    cancelInvitation,
    $checkboxRulesField,
    toggleCheckboxRulesField,
};
