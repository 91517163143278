import { defineMessages } from 'react-intl';
const messages = defineMessages({
    currentPaymentMethodTitle: {
        id: 'currentPaymentMethodTitle',
        defaultMessage: 'Мой способ оплаты',
    },
    unlinkPaymentMethodBtnTxt: {
        id: 'unlinkPaymentMethodBtnTxt',
        defaultMessage: 'Отвязать',
    },
    paymentMethodNoBindTxt: {
        id: 'paymentMethodNoBindTxt',
        defaultMessage: 'Не выбран',
    },
    paymentMethodInProcessTxt: {
        id: 'paymentMethodNoBindTxt',
        defaultMessage: 'В процессе привязки',
    },
    paymentMethodDefaultTxt: {
        id: 'paymentMethodDefaultTxt',
        defaultMessage: 'Не выбран',
    },
});
export default messages;
