import apiCommon from '@common/utils/ApiCommon';
import UnlinkPaymentMethodParamsSchema from './schemas/UnlinkPaymentMethodParamsSchema.json';
const UnlinkPaymentMethodResponseSchema = () => import('./schemas/UnlinkPaymentMethodResponseSchema.json');
const LinkPaymentMethodResponseSchema = () => import('./schemas/LinkPaymentMethodResponseSchema.json');
const GetPaymentsHistoryResponseSchema = () => import('./schemas/GetPaymentsHistoryResponseSchema.json');
const getPaymentsHistory = () => {
    return apiCommon
        .makeGetRequest('/api/front/user/payments/list', undefined, undefined, GetPaymentsHistoryResponseSchema)
        .then((response) => {
        if (!response.data.successful) {
            throw new Error('');
        }
        return response.data.data;
    });
};
const linkPaymentMethod = () => apiCommon.makePostRequest('api/front/user/payments/card/link', undefined, undefined, LinkPaymentMethodResponseSchema);
const unlinkPaymentsMethod = (params) => apiCommon.makePostRequest('api/front/user/payments/unlink-pay-method', params, UnlinkPaymentMethodParamsSchema, UnlinkPaymentMethodResponseSchema);
export const api = {
    getPaymentsHistory,
    linkPaymentMethod,
    unlinkPaymentsMethod,
};
