import React from 'react';
import { ModalPageHeader } from '@vkontakte/vkui';
import { modalManagerModel, modalManagerTools } from '@common/services/modal-manager';
import { linkPaymentMethod } from '@common/components/Root/actions';
import { CurrentPaymentMethod, PaymentsHistory } from '../../../ui';
import { Footer } from './ui';
import { analyticsDictionary } from '@analytics';
const changePaymentMethod = () => linkPaymentMethod('paymethod');
const showAlertModalHandler = (params) => {
    modalManagerTools.modalTemplates.showMessageModal({
        analyticShowAttribute: params.modalElementKey ?? '',
        mode: params.mode,
        header: params.title,
        subheader: params.description,
    });
};
const payloadToManagerModal = () => {
    return {
        analyticShowAttribute: analyticsDictionary.modalPaymethodManageShow,
        header: <ModalPageHeader>Способ оплаты</ModalPageHeader>,
        footer: (<Footer changePaymentMethod={changePaymentMethod} showAlertModal={showAlertModalHandler}/>),
        children: (<div>
        <CurrentPaymentMethod />
        <PaymentsHistory />
      </div>),
    };
};
export const showPaymentManagementModal = () => modalManagerModel.showModalPageWithFixedFooter(payloadToManagerModal());
