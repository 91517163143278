import React from 'react';
import { showStudentFormModal, studentAnalytics } from '@common/entities/student';
import { intl } from '@common/shared/lib/intl';
import { modalManagerModel } from '@common/services/modal-manager';
import { StudentHead, StudentId } from '../../icons';
import { messages } from './messages';
const closeModal = () => {
    modalManagerModel.closeModal();
};
export const createSteps = (setStep) => {
    const STEPS = {
        one: {
            icon: <StudentHead />,
            subheader: intl.formatMessage(messages.stepOneSubhead),
            title: intl.formatMessage(messages.stepOneTitle),
            description: intl.formatMessage(messages.stepOneDescription),
            showKey: (status) => studentAnalytics.form.areYouSureYouAreStudent.show(status),
            buttons: [
                {
                    elementKey: () => null,
                    name: intl.formatMessage(messages.stepOneBtnCancelText),
                    mode: 'secondary',
                    action: closeModal,
                },
                {
                    elementKey: (status) => studentAnalytics.form.areYouSureYouAreStudent.click(status),
                    name: intl.formatMessage(messages.stepOneBtnSureText),
                    mode: 'primary',
                    action: () => setStep('two'),
                },
            ],
        },
        two: {
            icon: <StudentId width={180}/>,
            subheader: intl.formatMessage(messages.stepTwoSubhead),
            title: intl.formatMessage(messages.stepTwoTitle),
            description: intl.formatMessage(messages.stepTwoDescription),
            showKey: (status) => studentAnalytics.form.doYouStudyIUniversity.show(status),
            buttons: [
                {
                    elementKey: () => null,
                    name: intl.formatMessage(messages.stepTwoBtnCancelText),
                    mode: 'secondary',
                    action: closeModal,
                },
                {
                    elementKey: (status) => studentAnalytics.form.doYouStudyIUniversity.click(status),
                    name: intl.formatMessage(messages.stepTwoBtnStartText),
                    mode: 'primary',
                    action: () => {
                        showStudentFormModal();
                    },
                },
            ],
        },
    };
    return STEPS;
};
