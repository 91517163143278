import { createEvent, createStore } from 'effector';
const DEFAULT_VALUE = {
    lastName: '',
    firstName: '',
    middleName: '',
    cityId: null,
    cityName: '',
    establishmentId: null,
    establishmentName: '',
    establishmentType: null,
    flow: 'studentId',
    email: undefined,
};
const $fields = createStore(DEFAULT_VALUE);
const setFields = createEvent();
const clear = createEvent();
$fields.on(setFields, (prevState, newState) => ({ ...prevState, ...newState }));
$fields.reset(clear);
export const fieldsModel = { $fields, setFields, clear };
