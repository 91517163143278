import React from 'react';
import { Icon56CheckCircleOutline, Icon56ErrorOutline } from '@vkontakte/icons';
import { modalManagerModel } from '@common/services/modal-manager';
import ModalButton from '@common/components/modals/ModalButton';
import ModalImage from './images/modal-image';
import { analyticsDictionary } from '../analytics-dictionary';
export const CONTENT = (name) => {
    return {
        ACTIVE_SUBSCRIPTION: {
            analyticShowAttribute: analyticsDictionary.modalAcceptInvitationShow,
            icon: <ModalImage />,
            header: `${name} приглашает тебя подключиться к совместной подписке`,
            subheader: 'Активная подписка будет остановлена. Текущая подписка возобновится после отмены к совместной подписки.',
            buttons: {
                action: {
                    name: 'Подключить подписку',
                },
                cancel: {
                    name: 'Оставить текущую подписку',
                },
            },
        },
        EMPTY_SUBSCRIPTION: {
            analyticShowAttribute: analyticsDictionary.modalAcceptInvitationShow,
            icon: <ModalImage />,
            header: 'Присоединиться к совместной подписке?',
            subheader: `${name} приглашает вас присоединиться к совместной подписке`,
            buttons: {
                action: {
                    name: 'Подключить подписку',
                },
                cancel: {
                    name: 'Отказаться',
                },
            },
        },
    };
};
export const INVALID = {
    analyticShowAttribute: analyticsDictionary.modalAcceptInvitationShowError,
    icon: <Icon56ErrorOutline />,
    header: 'Приглашение больше неактуально',
    subheader: 'Приглашение было отменено или истекло. Вы можете запросить повторное приглашение у владельца подписки.',
    actions: (<ModalButton size={'l'} stretched={true} mode={'secondary'} onClick={() => modalManagerModel.closeModal()}>
      Закрыть
    </ModalButton>),
};
export const DEFAULT_ERROR_INVALID = (message = {}) => {
    return {
        analyticShowAttribute: analyticsDictionary.modalAcceptInvitationShowError,
        icon: <Icon56ErrorOutline />,
        header: message?.title || 'Не удалось принять приглашение',
        subheader: message?.detail || 'Попробуйте перейти по ссылке, которую вам прислали, ещё раз или обратитесь в поддержку',
        actions: (<ModalButton size={'l'} stretched={true} mode={'secondary'} onClick={() => modalManagerModel.closeModal()}>
        Понятно
      </ModalButton>),
    };
};
export const VALID = {
    analyticShowAttribute: analyticsDictionary.modalAcceptInvitationShowSuccess,
    icon: <Icon56CheckCircleOutline />,
    header: 'Приглашение принято',
    subheader: 'Вы присоединились к совместной подписке. Подписка будет продлеваться автоматически.',
    actions: (<ModalButton size={'l'} stretched={true} mode={'secondary'} onClick={() => modalManagerModel.closeModal()}>
      Хорошо
    </ModalButton>),
};
export const CANCEL_INVALID = {
    analyticShowAttribute: analyticsDictionary.modalAcceptInvitationShowCanselError,
    icon: <Icon56ErrorOutline />,
    header: 'Не удалось отклонить приглашение в группу',
    subheader: 'Попробуйте перейти по ссылке, которую вам прислали, ещё раз или обратитесь в поддержку',
    actions: (<ModalButton size={'l'} stretched={true} mode={'secondary'} onClick={() => modalManagerModel.closeModal()}>
      Понятно
    </ModalButton>),
};
export const makeInvalidModalPayload = (payload) => {
    return {
        analyticShowAttribute: analyticsDictionary.modalAcceptInvitationShowError,
        icon: <Icon56ErrorOutline />,
        header: 'Приглашение больше неактуально',
        subheader: 'Приглашение было отменено или истекло. Вы можете запросить повторное приглашение у владельца подписки.',
        actions: (<ModalButton size={'l'} stretched={true} mode={'secondary'} onClick={() => modalManagerModel.closeModal()}>
        Закрыть
      </ModalButton>),
        ...payload,
    };
};
