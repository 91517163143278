import { createEffect, createEvent, createStore, sample } from 'effector';
import apiCommon from '@common/utils/ApiCommon';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { getVariant } from '@common/features/ab-test-lk-untie-card/get-variants';
const $variantLkUntieCardForABTests = createStore(null);
const $chosenVariant = $variantLkUntieCardForABTests.map((state) => getVariant(state));
const $isPauseRecurrent = createStore(false);
const updateIsPauseRecurrent = createEvent();
const getVariantsLkUntieCardForABTestsFx = createEffect(async () => {
    const { data: result } = await apiCommon.getVariantsLkUntieCardForABTests();
    if (!result.successful) {
        return 'none';
    }
    return result.data.variant;
});
const getPauseSubscriptionRecurrentStatusFx = createEffect(async () => {
    const { data: result } = await apiCommon.getPauseSubscriptionRecurrentStatus();
    if (!result.successful) {
        return false;
    }
    return result.data.paused_recurrents;
});
sample({
    clock: SubscriptionsModel.$activeSubscription,
    source: {
        userHasSubscriptionWithPermissionGoToLk: SubscriptionsModel.$userHasSubscriptionWithPermissionGoToLk,
        variantLkUntieCardForABTests: $variantLkUntieCardForABTests,
    },
    filter: ({ userHasSubscriptionWithPermissionGoToLk, variantLkUntieCardForABTests }, activeSubscription) => {
        return Boolean(!variantLkUntieCardForABTests &&
            activeSubscription &&
            activeSubscription.status !== 'suspended' &&
            activeSubscription.own &&
            userHasSubscriptionWithPermissionGoToLk &&
            !activeSubscription.management);
    },
    target: [getVariantsLkUntieCardForABTestsFx, getPauseSubscriptionRecurrentStatusFx],
});
sample({ clock: [updateIsPauseRecurrent, getPauseSubscriptionRecurrentStatusFx.doneData], target: $isPauseRecurrent });
sample({ clock: getVariantsLkUntieCardForABTestsFx.doneData, target: $variantLkUntieCardForABTests });
export const AbTestLkUntieCardModel = {
    $chosenVariant,
    $pending: getVariantsLkUntieCardForABTestsFx.pending,
    $isPauseRecurrent,
    updateIsPauseRecurrent,
};
