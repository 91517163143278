import { Connect } from '@vkontakte/superappkit';
import { validateData } from '@common/shared/lib/validate-data/validateData';
export const silentAuth = async () => {
    const schema = {
        type: 'object',
        properties: {
            auth: { enum: [1] },
        },
        required: ['token', 'uuid'],
    };
    const { payload } = await Connect.silentAuth().catch(() => {
        throw new Error();
    });
    validateData(schema, payload, () => {
        throw new Error();
    }, 'silentAuth');
    return {
        token: payload.token,
        uuid: payload.uuid,
    };
};
