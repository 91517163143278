import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    namePartnerText: {
        id: 'namePartnerText',
        defaultMessage: 'Партнёра',
    },
    subheaderText: {
        id: 'subheaderText',
        defaultMessage: 'Вы подключили подписку на VK Музыку от {namePartner}. Чтобы управлять подпиской, перейдите в личный кабинет {namePartner}.',
    },
    buttonText: {
        id: 'buttonText',
        defaultMessage: 'Перейти',
    },
});
