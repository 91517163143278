import React from 'react';
import { analyticsDictionary } from '@analytics';
import tryToGetErrorsMessages from '@common/helpers/tryToGetErrorsMessages';
import { modalManagerModel, modalManagerTools } from '@common/services/modal-manager';
import ModalButton from '@common/components/modals/ModalButton';
import { intl } from '@common/shared/lib/intl';
import { messages } from './messages';
export const unlinkErrorHandler = (error) => {
    const { title, description } = tryToGetErrorsMessages(error?.errors, {
        title: intl.formatMessage(messages.unlinkErrorTitle),
        description: intl.formatMessage(messages.unlinkErrorDescription),
    });
    return modalManagerTools.modalTemplates.showMessageModal({
        analyticShowAttribute: analyticsDictionary.modalPaymethodUnlinkErrorShow,
        mode: 'error',
        header: title,
        subheader: description,
        actions: (<ModalButton size='l' mode='primary' onClick={() => modalManagerModel.closeModal()}>
        {intl.formatMessage(messages.unlinkErrorCloseModalBtnText)}
      </ModalButton>),
    });
};
