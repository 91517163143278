import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    header: {
        id: 'header',
        defaultMessage: 'Вы отменили подписку',
    },
    subheader: {
        id: 'subheader',
        defaultMessage: 'Бесплатный период отключён. Чтобы снова слушать музыку без ограничений, оформите новую подписку в личном кабинете.',
    },
    confirmButton: {
        id: 'confirmButton',
        defaultMessage: 'Понятно',
    },
});
