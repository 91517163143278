import React from 'react';
import { useIntl } from 'react-intl';
import { Icon20PaymentCardOutline, Icon28MoneyCircleOutline, Icon28StorefrontOutline } from '@vkontakte/icons';
import { Avatar, Group, Header, PanelSpinner, RichCell } from '@vkontakte/vkui';
import { useUnit } from 'effector-react';
import { DATE_FORMAT_LONG } from '@common/consts';
import Typograf from '@common/components/common/Typograf';
import Error from '@common/components/common/Error';
import { IconSbpbankBlue } from '../../lib/fc-icons';
import { paymentHistoryModel } from '../../model';
import styles from './styles.module.scss';
import messages from './messages';
const mappingCardPaySystem = {
    master: 'Mastercard',
    visa: 'Visa',
    mir: 'MIR',
    world: 'MIR',
    maestro: 'Maestro',
};
const getCardPaySystem = (paySystem) => {
    if (!paySystem) {
        return '';
    }
    return mappingCardPaySystem[paySystem] || paySystem;
};
export const PaymentsHistory = () => {
    const $paymentsHistory = useUnit(paymentHistoryModel.$paymentsHistory);
    const $paymentsHistoryState = useUnit(paymentHistoryModel.$paymentsHistoryState);
    const intl = useIntl();
    const getIcon = (payment) => {
        switch (payment.payment_method.type) {
            case 'sbp': {
                return <IconSbpbankBlue className={styles.icon}/>;
            }
            case 'app_store':
            case 'google_play': {
                return <Icon28StorefrontOutline className={styles.icon}/>;
            }
            case 'card': {
                return <Icon20PaymentCardOutline className={styles.icon}/>;
            }
            default: {
                return <Icon28MoneyCircleOutline className={styles.icon}/>;
            }
        }
    };
    const getContent = () => {
        switch ($paymentsHistoryState) {
            case 'process': {
                return <PanelSpinner />;
            }
            case 'idle': {
                const afterCaption = (payment) => {
                    const { payment_method } = payment;
                    switch (payment_method.type) {
                        case 'sbp': {
                            return payment_method.attributes.bank_name;
                        }
                        case 'app_store':
                        case 'google_play': {
                            return payment_method.attributes.store_name;
                        }
                        case 'card': {
                            return `${getCardPaySystem(payment_method.attributes?.pay_system)} •••• ${payment_method.attributes?.last_card_digits || ''}`;
                        }
                        default: {
                            return null;
                        }
                    }
                };
                return (<div className={styles.historyList}>
            {$paymentsHistory.map((payment, index) => (<RichCell key={index} before={<Avatar size={48}>{getIcon(payment)}</Avatar>} text={intl.formatDate(payment.operation.date, DATE_FORMAT_LONG)} caption={afterCaption(payment)} after={<Typograf>
                    {payment.operation.type === 'payment' ? '-' : '+'} {payment.operation.amount}&nbsp;₽
                  </Typograf>} hasHover={false} hasActive={false}>
                {payment.operation.title}
              </RichCell>))}
          </div>);
            }
            case 'error': {
                return (<div className={styles.error}>
            <Error title={<>{intl.formatMessage(messages.errorTitle, { br: <br /> })}</>} buttonText={intl.formatMessage(messages.errorButtonText)} buttonOnClick={paymentHistoryModel.getPaymentsHistory}/>
          </div>);
            }
        }
    };
    if ($paymentsHistory.length === 0) {
        return null;
    }
    return (<Group header={<Header>{intl.formatMessage(messages.paymentHistoryHeader)}</Header>} className={styles.history}>
      {getContent()}
    </Group>);
};
