import { createEffect, createEvent, createStore, sample } from 'effector';
const $subscribers = createStore(new Map());
const subscribe = createEvent();
const unsubscribe = createEvent();
const success = createEvent();
const callSubscribersFx = createEffect((subscribers) => {
    subscribers.forEach((callback) => callback());
});
$subscribers.on(subscribe, (state, payload) => {
    state.set(payload, payload);
    return new Map(state);
});
$subscribers.on(unsubscribe, (state, payload) => {
    state.delete(payload);
    return new Map(state);
});
sample({ clock: success, source: $subscribers, fn: (source) => source, target: callSubscribersFx });
export const sharedCheckoutModel = {
    success,
    subscribe,
    unsubscribe,
};
