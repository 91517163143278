import React from 'react';
import { calcHeight } from '@common/entities/student/lib';
const DEFAULT_WIDTH = 191;
const DEFAULT_HEIGHT = 109;
const RecordBook = ({ width = DEFAULT_WIDTH }) => {
    const height = calcHeight({ width, defaultWidth: DEFAULT_WIDTH, defaultHeight: DEFAULT_HEIGHT });
    return (<svg width={width} height={height} viewBox='0 0 191 109' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask id='mask0_13395_164078' maskUnits='userSpaceOnUse' x='19' y='0' width='153' height='108'>
        <path d='M70.6314 107.641H120.898C145.003 107.641 157.059 107.641 164.544 100.085C172.03 92.529 171.966 80.3633 171.966 56.0931V51.5479C171.966 27.2811 171.966 15.112 164.544 7.55602C157.123 3.23719e-06 145.003 0 120.898 0H70.6314C46.5235 0 34.4712 3.23719e-06 26.9856 7.55602C19.5 15.112 19.5 27.2709 19.5 51.5479V56.0931C19.5 80.3633 19.5 92.529 26.9856 100.085C34.4712 107.641 46.5235 107.641 70.6314 107.641Z' fill='#F5F5F5'/>
      </mask>
      <g mask='url(#mask0_13395_164078)'>
        <path d='M70.6314 107.641H120.898C145.003 107.641 157.059 107.641 164.544 100.085C172.03 92.529 171.966 80.3633 171.966 56.0931V51.5479C171.966 27.2811 171.966 15.112 164.544 7.55602C157.123 3.23719e-06 145.003 0 120.898 0H70.6314C46.5235 0 34.4712 3.23719e-06 26.9856 7.55602C19.5 15.112 19.5 27.2709 19.5 51.5479V56.0931C19.5 80.3633 19.5 92.529 26.9856 100.085C34.4712 107.641 46.5235 107.641 70.6314 107.641Z' fill='#F0F2F5'/>
        <path d='M194 9H-1V98H194V9Z' fill='#F6F5FF'/>
        <path d='M32.2188 40.8274C39.3869 29.1944 58.0593 27.7027 70.9915 29.8139C83.3144 31.6249 94.7901 35.976 106.847 38.435C123.92 42.6384 145.681 35.8235 158.418 45.9982C173.621 59.6995 155.258 70.9894 140.626 74.8019C129.826 77.6184 117.989 77.79 108.308 77.2896C85.185 75.8599 45.3223 67.0005 33.58 55.053C30.3244 51.7789 29.4296 45.4311 32.2188 40.8274Z' fill='#00EAFF'/>
        <path d='M100.002 71.7093C98.2547 70.9754 97.4694 71.2328 96.2795 71.9095L93 34.9469C94.7468 35.6427 95.6416 35.6141 96.922 34.5752L100.002 71.7093Z' fill='#0077FF'/>
        <path d='M29.3624 40.5388L91.9732 35.3077C92.4311 35.2694 92.8854 35.4149 93.2363 35.712C93.5871 36.0091 93.8056 36.4336 93.8438 36.8921L96.5748 69.6614C96.613 70.1199 96.4678 70.5748 96.171 70.926C95.8742 71.2773 95.4503 71.4961 94.9924 71.5343L32.3816 76.7654C31.9249 76.8036 31.4719 76.6585 31.122 76.3622C30.7722 76.0659 30.5542 75.6426 30.5161 75.1853L27.7847 42.4113C27.7465 41.9528 27.8917 41.4979 28.1885 41.1467C28.4853 40.7954 28.9093 40.5766 29.3672 40.5384L29.3624 40.5388Z' fill='white'/>
        <path d='M95.034 72.376L32.4103 77.6183C31.7551 77.6737 31.1049 77.4664 30.6023 77.0419C30.0998 76.6174 29.7863 76.0105 29.7305 75.3546L27.008 42.6716C26.9553 42.0152 27.1647 41.3646 27.5903 40.8626C28.0159 40.3605 28.623 40.0479 29.2784 39.9933L91.9021 34.751C92.5572 34.6956 93.2075 34.9029 93.71 35.3274C94.2125 35.7519 94.5261 36.3588 94.5818 37.0147L97.3044 69.6882C97.3318 70.014 97.2948 70.342 97.1954 70.6534C97.0961 70.9649 96.9365 71.2537 96.7256 71.5033C96.5148 71.7529 96.2568 71.9585 95.9666 72.1083C95.6763 72.258 95.3594 72.349 95.034 72.376ZM29.4259 41.4944C29.2978 41.5021 29.1725 41.5357 29.0576 41.5931C28.9428 41.6505 28.8407 41.7306 28.7575 41.8286C28.6744 41.9265 28.6119 42.0403 28.5738 42.1631C28.5357 42.2858 28.5228 42.415 28.5359 42.5429L31.2346 75.2354C31.2447 75.3621 31.28 75.4854 31.3384 75.5982C31.3969 75.711 31.4772 75.811 31.5748 75.8923C31.6723 75.9735 31.7851 76.0344 31.9066 76.0714C32.028 76.1084 32.1555 76.1207 32.2818 76.1075L94.9055 70.8653C95.0336 70.8576 95.1589 70.8241 95.2738 70.7666C95.3886 70.7092 95.4907 70.6291 95.5739 70.5312C95.657 70.4332 95.7195 70.3195 95.7576 70.1967C95.7957 70.0739 95.8086 69.9447 95.7956 69.8169L93.073 37.1386C93.0647 37.0103 93.0306 36.8849 92.9727 36.7701C92.9148 36.6553 92.8343 36.5534 92.736 36.4706C92.6377 36.3878 92.5237 36.3258 92.4008 36.2882C92.278 36.2507 92.1488 36.2384 92.0211 36.2522L29.4259 41.4944Z' fill='#0077FF'/>
        <path d='M97.9874 34.8063L160.598 29.5752C161.056 29.537 161.51 29.6824 161.861 29.9796C162.212 30.2767 162.431 30.7012 162.469 31.1597L165.2 63.929C165.238 64.3875 165.093 64.8424 164.796 65.1936C164.499 65.5448 164.075 65.7637 163.617 65.8019L101.007 71.033C100.55 71.0712 100.097 70.9261 99.747 70.6298C99.3972 70.3335 99.1792 69.9101 99.1411 69.4529L96.4097 36.6789C96.3902 36.4522 96.4155 36.2238 96.4843 36.0069C96.5531 35.79 96.6639 35.5888 96.8105 35.4148C96.957 35.2409 97.1364 35.0976 97.3384 34.9932C97.5404 34.8888 97.7609 34.8253 97.9874 34.8063Z' fill='white'/>
        <path d='M163.722 66.6287L101.098 71.8709C100.443 71.9251 99.7937 71.7173 99.2915 71.293C98.7893 70.8688 98.4754 70.2626 98.4184 69.6072L95.6959 36.9337C95.6419 36.2766 95.8507 35.6248 96.2764 35.1217C96.7021 34.6186 97.3099 34.3053 97.9663 34.2507L160.59 29.0084C161.246 28.9543 161.897 29.1626 162.4 29.5877C162.903 30.0128 163.218 30.6202 163.274 31.2769L165.992 63.9504C166.045 64.6068 165.836 65.2573 165.41 65.7593C164.984 66.2614 164.377 66.574 163.722 66.6287ZM98.09 35.7519C97.9619 35.7595 97.8366 35.7931 97.7217 35.8505C97.6069 35.908 97.5048 35.988 97.4216 36.086C97.3385 36.1839 97.276 36.2977 97.2379 36.4205C97.1998 36.5432 97.1869 36.6724 97.1999 36.8003L99.9225 69.4738C99.9455 69.731 100.069 69.9688 100.266 70.1356C100.463 70.3024 100.717 70.3848 100.974 70.365L163.598 65.1227C163.727 65.1151 163.852 65.0814 163.968 65.0237C164.083 64.9659 164.185 64.8854 164.268 64.7869C164.352 64.6885 164.414 64.5741 164.452 64.4508C164.49 64.3275 164.502 64.1978 164.488 64.0695L161.747 31.3913C161.738 31.2629 161.704 31.1376 161.646 31.0228C161.588 30.908 161.508 30.8061 161.41 30.7233C161.311 30.6405 161.197 30.5784 161.074 30.5409C160.952 30.5033 160.822 30.4911 160.695 30.5049L98.09 35.7519Z' fill='#0077FF'/>
        <path d='M85.9811 41.4231L55.1571 44.0013C55.0738 44.0104 54.9895 44.0027 54.9092 43.9786C54.8289 43.9546 54.7542 43.9147 54.6895 43.8613C54.6249 43.8079 54.5715 43.7421 54.5326 43.6677C54.4937 43.5934 54.4701 43.512 54.4631 43.4284C54.4561 43.3447 54.4659 43.2605 54.4919 43.1808C54.5179 43.101 54.5596 43.0272 54.6145 42.9638C54.6695 42.9004 54.7365 42.8486 54.8117 42.8116C54.8869 42.7745 54.9687 42.7528 55.0524 42.7479L85.8764 40.1697C85.9597 40.1606 86.044 40.1683 86.1243 40.1924C86.2046 40.2164 86.2793 40.2563 86.344 40.3097C86.4087 40.3631 86.462 40.4289 86.5009 40.5033C86.5398 40.5776 86.5634 40.659 86.5704 40.7426C86.5774 40.8263 86.5676 40.9105 86.5416 40.9903C86.5156 41.07 86.4739 41.1438 86.419 41.2072C86.3641 41.2706 86.297 41.3224 86.2218 41.3595C86.1466 41.3965 86.0648 41.4182 85.9811 41.4231Z' fill='#66ADFF'/>
        <path d='M72.8385 47.013L55.5321 48.4427C55.4488 48.4518 55.3645 48.4441 55.2842 48.42C55.2039 48.396 55.1292 48.3561 55.0645 48.3027C54.9999 48.2493 54.9465 48.1835 54.9076 48.1091C54.8687 48.0348 54.8451 47.9534 54.8381 47.8698C54.8311 47.7861 54.8409 47.702 54.8669 47.6222C54.8929 47.5424 54.9346 47.4686 54.9895 47.4052C55.0445 47.3418 55.1115 47.29 55.1867 47.253C55.2619 47.2159 55.3437 47.1943 55.4274 47.1893L72.7338 45.7596C72.8161 45.7518 72.8991 45.7604 72.9781 45.785C73.0571 45.8096 73.1303 45.8497 73.1937 45.9029C73.257 45.9561 73.3092 46.0214 73.3471 46.095C73.3849 46.1686 73.4079 46.249 73.4144 46.3315C73.4216 46.4142 73.4124 46.4975 73.3872 46.5765C73.3621 46.6556 73.3216 46.7289 73.2681 46.7923C73.2146 46.8556 73.1491 46.9077 73.0753 46.9456C73.0016 46.9835 72.9211 47.0064 72.8385 47.013Z' fill='#66ADFF'/>
        <path d='M73.2135 51.469L55.9071 52.8987C55.8238 52.9077 55.7395 52.9 55.6592 52.876C55.5789 52.8519 55.5042 52.812 55.4395 52.7587C55.3749 52.7053 55.3215 52.6394 55.2826 52.5651C55.2437 52.4908 55.2201 52.4094 55.2131 52.3257C55.2061 52.2421 55.2159 52.1579 55.2419 52.0781C55.2679 51.9983 55.3096 51.9246 55.3645 51.8612C55.4195 51.7978 55.4865 51.746 55.5617 51.7089C55.6369 51.6719 55.7187 51.6502 55.8024 51.6453L73.1088 50.2156C73.1921 50.2065 73.2764 50.2142 73.3567 50.2383C73.437 50.2623 73.5117 50.3022 73.5764 50.3556C73.6411 50.409 73.6944 50.4748 73.7333 50.5492C73.7722 50.6235 73.7958 50.7049 73.8028 50.7885C73.8098 50.8722 73.8 50.9564 73.774 51.0362C73.748 51.1159 73.7063 51.1897 73.6514 51.2531C73.5964 51.3165 73.5294 51.3683 73.4542 51.4054C73.379 51.4424 73.2972 51.4641 73.2135 51.469Z' fill='#66ADFF'/>
        <path d='M86.7225 50.3395L79.2354 50.9638C79.0718 50.9735 78.9108 50.9187 78.787 50.8111C78.6631 50.7036 78.5862 50.5519 78.5725 50.3883C78.5588 50.2247 78.6096 50.0623 78.7138 49.9357C78.8181 49.809 78.9677 49.7282 79.1307 49.7105L86.6225 49.0862C86.7063 49.0765 86.7912 49.0838 86.8721 49.1077C86.953 49.1316 87.0283 49.1715 87.0935 49.2252C87.1587 49.2788 87.2124 49.3451 87.2514 49.42C87.2905 49.4948 87.3141 49.5768 87.3208 49.661C87.3275 49.7452 87.3172 49.8299 87.2905 49.9101C87.2638 49.9902 87.2213 50.0641 87.1655 50.1275C87.1096 50.1908 87.0416 50.2422 86.9655 50.2786C86.8894 50.3151 86.8067 50.3358 86.7225 50.3395Z' fill='#66ADFF'/>
        <path d='M73.5891 55.9299L56.6201 57.3596C56.5384 57.3687 56.4558 57.361 56.377 57.3369C56.2983 57.3129 56.225 57.273 56.1616 57.2196C56.0982 57.1662 56.0459 57.1004 56.0078 57.026C55.9697 56.9517 55.9465 56.8703 55.9396 56.7867C55.9328 56.703 55.9424 56.6188 55.9679 56.5391C55.9934 56.4593 56.0343 56.3855 56.0881 56.3221C56.142 56.2587 56.2077 56.2069 56.2814 56.1699C56.3552 56.1328 56.4354 56.1112 56.5174 56.1062L73.4864 54.6765C73.5681 54.6675 73.6508 54.6752 73.7295 54.6992C73.8083 54.7233 73.8815 54.7632 73.9449 54.8166C74.0083 54.87 74.0606 54.9358 74.0988 55.0101C74.1369 55.0845 74.16 55.1658 74.1669 55.2495C74.1737 55.3331 74.1641 55.4173 74.1386 55.4971C74.1131 55.5769 74.0723 55.6506 74.0184 55.714C73.9646 55.7775 73.8989 55.8292 73.8251 55.8663C73.7514 55.9034 73.6711 55.925 73.5891 55.9299Z' fill='#66ADFF'/>
        <path d='M87.4664 59.2565L56.6424 61.8348C56.5577 61.8452 56.4718 61.8382 56.3898 61.8143C56.3079 61.7904 56.2317 61.75 56.1658 61.6955C56.1 61.6411 56.0459 61.5739 56.0069 61.4978C55.9679 61.4218 55.9448 61.3386 55.939 61.2534C55.9331 61.1681 55.9447 61.0825 55.973 61.0019C56.0013 60.9212 56.0457 60.8472 56.1035 60.7843C56.1614 60.7215 56.2314 60.671 56.3093 60.6361C56.3872 60.6013 56.4714 60.5826 56.5568 60.5814L87.3855 58.0032C87.4693 57.9935 87.5542 58.0008 87.6352 58.0247C87.7161 58.0486 87.7914 58.0885 87.8565 58.1422C87.9217 58.1958 87.9754 58.2621 88.0144 58.337C88.0535 58.4118 88.0771 58.4938 88.0838 58.578C88.0905 58.6622 88.0802 58.7469 88.0535 58.8271C88.0268 58.9072 87.9843 58.9811 87.9285 59.0444C87.8726 59.1078 87.8046 59.1592 87.7285 59.1956C87.6524 59.2321 87.5698 59.2528 87.4855 59.2565H87.4664Z' fill='#66ADFF'/>
        <path d='M67.3369 65.427L57.0321 66.2896C56.9488 66.2986 56.8645 66.2909 56.7842 66.2669C56.7039 66.2428 56.6292 66.2029 56.5645 66.1495C56.4999 66.0962 56.4465 66.0303 56.4076 65.956C56.3687 65.8816 56.3451 65.8003 56.3381 65.7166C56.3311 65.633 56.3409 65.5488 56.3669 65.469C56.3929 65.3892 56.4346 65.3155 56.4895 65.2521C56.5445 65.1887 56.6115 65.1369 56.6867 65.0998C56.7619 65.0627 56.8437 65.0411 56.9274 65.0362L67.2513 64.1736C67.3346 64.1645 67.4189 64.1723 67.4992 64.1963C67.5795 64.2203 67.6542 64.2602 67.7189 64.3136C67.7835 64.367 67.8369 64.4328 67.8758 64.5072C67.9146 64.5815 67.9383 64.6629 67.9453 64.7466C67.9523 64.8302 67.9425 64.9144 67.9165 64.9942C67.8904 65.0739 67.8488 65.1477 67.7938 65.2111C67.7389 65.2745 67.6719 65.3263 67.5967 65.3634C67.5215 65.4004 67.4397 65.4221 67.356 65.427H67.3369Z' fill='#66ADFF'/>
        <path d='M67.7075 69.8889L57.3837 70.7515C57.3004 70.7606 57.2161 70.7528 57.1358 70.7288C57.0554 70.7047 56.9808 70.6648 56.9161 70.6115C56.8514 70.5581 56.7981 70.4923 56.7592 70.4179C56.7203 70.3436 56.6967 70.2622 56.6897 70.1785C56.6827 70.0949 56.6925 70.0107 56.7185 69.9309C56.7445 69.8511 56.7862 69.7774 56.8411 69.714C56.896 69.6506 56.9631 69.5988 57.0383 69.5617C57.1135 69.5247 57.1953 69.503 57.279 69.4981L67.6028 68.6355C67.6862 68.6265 67.7705 68.6342 67.8508 68.6582C67.9311 68.6823 68.0058 68.7222 68.0704 68.7755C68.1351 68.8289 68.1884 68.8947 68.2273 68.9691C68.2662 69.0434 68.2898 69.1248 68.2968 69.2085C68.3038 69.2921 68.294 69.3763 68.268 69.4561C68.242 69.5359 68.2003 69.6096 68.1454 69.673C68.0905 69.7364 68.0235 69.7882 67.9483 69.8253C67.8731 69.8623 67.7912 69.884 67.7075 69.8889Z' fill='#66ADFF'/>
        <path d='M45.231 70.0832L34.9071 70.9458C34.8238 70.9549 34.7395 70.9472 34.6592 70.9231C34.5789 70.8991 34.5042 70.8592 34.4395 70.8058C34.3749 70.7524 34.3215 70.6866 34.2826 70.6122C34.2437 70.5379 34.2201 70.4565 34.2131 70.3729C34.2061 70.2892 34.2159 70.2051 34.2419 70.1253C34.2679 70.0455 34.3096 69.9717 34.3645 69.9083C34.4195 69.8449 34.4865 69.7931 34.5617 69.7561C34.6369 69.719 34.7187 69.6974 34.8024 69.6924L45.131 68.8299C45.2144 68.8208 45.2987 68.8285 45.379 68.8525C45.4593 68.8766 45.534 68.9165 45.5986 68.9699C45.6633 69.0233 45.7166 69.0891 45.7555 69.1634C45.7944 69.2378 45.818 69.3192 45.825 69.4028C45.832 69.4864 45.8222 69.5706 45.7962 69.6504C45.7702 69.7302 45.7285 69.804 45.6736 69.8674C45.6187 69.9308 45.5517 69.9825 45.4765 70.0196C45.4013 70.0567 45.3194 70.0783 45.2357 70.0832H45.231Z' fill='#66ADFF'/>
        <path d='M118.815 38.678L104.579 39.8694C104.416 39.879 104.255 39.8242 104.131 39.7167C104.007 39.6091 103.93 39.4574 103.916 39.2938C103.903 39.1303 103.953 38.9678 104.058 38.8412C104.162 38.7146 104.311 38.6337 104.474 38.616L118.711 37.4246C118.794 37.4155 118.878 37.4232 118.959 37.4473C119.039 37.4713 119.114 37.5112 119.178 37.5646C119.243 37.618 119.296 37.6838 119.335 37.7582C119.374 37.8325 119.398 37.9139 119.405 37.9975C119.412 38.0812 119.402 38.1654 119.376 38.2451C119.35 38.3249 119.308 38.3987 119.253 38.4621C119.198 38.5255 119.131 38.5773 119.056 38.6143C118.981 38.6514 118.899 38.673 118.815 38.678Z' fill='#66ADFF'/>
        <path d='M132.834 59.1089L126.399 59.6475C126.316 59.6565 126.232 59.6488 126.151 59.6248C126.071 59.6007 125.996 59.5608 125.932 59.5074C125.867 59.4541 125.814 59.3882 125.775 59.3139C125.736 59.2395 125.712 59.1582 125.705 59.0745C125.698 58.9909 125.708 58.9067 125.734 58.8269C125.76 58.7471 125.802 58.6734 125.857 58.61C125.912 58.5466 125.979 58.4948 126.054 58.4577C126.129 58.4206 126.211 58.399 126.295 58.3941L132.711 57.8556C132.874 57.846 133.035 57.9008 133.159 58.0083C133.283 58.1158 133.36 58.2676 133.374 58.4311C133.387 58.5947 133.337 58.7571 133.232 58.8838C133.128 59.0104 132.978 59.0912 132.815 59.1089H132.834Z' fill='#66ADFF'/>
        <path d='M133.342 65.2278L126.907 65.7663C126.744 65.7759 126.583 65.7211 126.459 65.6136C126.335 65.506 126.258 65.3543 126.244 65.1907C126.231 65.0272 126.281 64.8647 126.386 64.7381C126.49 64.6115 126.64 64.5306 126.803 64.5129L133.238 63.9744C133.321 63.9653 133.405 63.973 133.486 63.9971C133.566 64.0211 133.641 64.061 133.705 64.1144C133.77 64.1678 133.823 64.2336 133.862 64.308C133.901 64.3823 133.925 64.4637 133.932 64.5473C133.939 64.631 133.929 64.7152 133.903 64.7949C133.877 64.8747 133.835 64.9485 133.78 65.0119C133.725 65.0753 133.658 65.1271 133.583 65.1641C133.508 65.2012 133.426 65.2228 133.342 65.2278Z' fill='#66ADFF'/>
        <path d='M155.144 35.6373L124.501 38.2012C124.337 38.2108 124.176 38.156 124.053 38.0485C123.929 37.9409 123.852 37.7892 123.838 37.6257C123.824 37.4621 123.875 37.2997 123.979 37.173C124.084 37.0464 124.233 36.9656 124.396 36.9478L155.039 34.3839C155.203 34.3743 155.364 34.4291 155.488 34.5366C155.612 34.6441 155.689 34.7959 155.702 34.9594C155.716 35.123 155.665 35.2854 155.561 35.4121C155.457 35.5387 155.307 35.6195 155.144 35.6373Z' fill='#66ADFF'/>
        <path d='M119.295 44.811L105.102 45.9976C104.935 46.0103 104.769 45.9559 104.642 45.8464C104.515 45.7369 104.436 45.5813 104.424 45.4138C104.411 45.2464 104.465 45.0807 104.575 44.9534C104.684 44.826 104.839 44.7474 105.007 44.7347L119.191 43.5481C119.357 43.5342 119.523 43.5871 119.65 43.6953C119.778 43.8034 119.857 43.9579 119.871 44.1247C119.885 44.2916 119.832 44.4571 119.724 44.5849C119.616 44.7127 119.462 44.7923 119.295 44.8062V44.811Z' fill='#66ADFF'/>
        <path d='M155.655 41.7711L125.017 44.3302C124.933 44.3393 124.849 44.3316 124.769 44.3075C124.688 44.2835 124.614 44.2436 124.549 44.1902C124.484 44.1368 124.431 44.071 124.392 43.9966C124.353 43.9223 124.329 43.8409 124.322 43.7573C124.316 43.6736 124.325 43.5895 124.351 43.5097C124.377 43.4299 124.419 43.3561 124.474 43.2927C124.529 43.2293 124.596 43.1775 124.671 43.1405C124.746 43.1034 124.828 43.0818 124.912 43.0769L155.56 40.5177C155.723 40.5081 155.884 40.5629 156.008 40.6704C156.132 40.7779 156.209 40.9297 156.223 41.0932C156.236 41.2568 156.186 41.4192 156.081 41.5459C155.977 41.6725 155.827 41.7533 155.664 41.7711H155.655Z' fill='#66ADFF'/>
        <path d='M119.858 50.9304L105.579 52.1266C105.496 52.1356 105.411 52.1279 105.331 52.1039C105.251 52.0798 105.176 52.0399 105.111 51.9866C105.047 51.9332 104.993 51.8674 104.955 51.793C104.916 51.7187 104.892 51.6373 104.885 51.5536C104.878 51.47 104.888 51.3858 104.914 51.306C104.94 51.2262 104.982 51.1525 105.036 51.0891C105.091 51.0257 105.158 50.9739 105.234 50.9368C105.309 50.8998 105.391 50.8781 105.474 50.8732L119.711 49.6818C119.873 49.6685 120.034 49.7187 120.16 49.8219C120.286 49.925 120.367 50.0731 120.386 50.2348C120.405 50.3966 120.361 50.5595 120.262 50.6892C120.164 50.819 120.019 50.9054 119.858 50.9304Z' fill='#66ADFF'/>
        <path d='M156.163 47.8995L125.524 50.4539C125.441 50.463 125.357 50.4553 125.276 50.4312C125.196 50.4072 125.121 50.3673 125.057 50.3139C124.992 50.2605 124.939 50.1947 124.9 50.1203C124.861 50.046 124.837 49.9646 124.83 49.881C124.823 49.7973 124.833 49.7132 124.859 49.6334C124.885 49.5536 124.927 49.4798 124.982 49.4164C125.037 49.353 125.104 49.3012 125.179 49.2642C125.254 49.2271 125.336 49.2055 125.42 49.2005L156.058 46.6414C156.141 46.6323 156.226 46.64 156.306 46.6641C156.386 46.6881 156.461 46.728 156.526 46.7814C156.59 46.8348 156.644 46.9006 156.682 46.975C156.721 47.0493 156.745 47.1307 156.752 47.2143C156.759 47.298 156.749 47.3821 156.723 47.4619C156.697 47.5417 156.655 47.6155 156.601 47.6789C156.546 47.7423 156.479 47.7941 156.403 47.8311C156.328 47.8682 156.246 47.8898 156.163 47.8948V47.8995Z' fill='#66ADFF'/>
        <path d='M120.339 57.0689L106.102 58.2603C106.019 58.2694 105.935 58.2617 105.855 58.2376C105.774 58.2136 105.7 58.1737 105.635 58.1203C105.57 58.0669 105.517 58.0011 105.478 57.9268C105.439 57.8524 105.415 57.771 105.408 57.6874C105.401 57.6037 105.411 57.5196 105.437 57.4398C105.463 57.36 105.505 57.2862 105.56 57.2228C105.615 57.1594 105.682 57.1076 105.757 57.0706C105.832 57.0335 105.914 57.0119 105.998 57.007L120.234 55.8155C120.398 55.8059 120.559 55.8607 120.682 55.9683C120.806 56.0758 120.883 56.2275 120.897 56.3911C120.911 56.5546 120.86 56.7171 120.756 56.8437C120.651 56.9704 120.502 57.0512 120.339 57.0689Z' fill='#66ADFF'/>
        <path d='M156.672 54.0285L126.048 56.5925C125.884 56.6021 125.723 56.5473 125.599 56.4398C125.476 56.3322 125.399 56.1805 125.385 56.0169C125.371 55.8534 125.422 55.6909 125.526 55.5643C125.631 55.4377 125.78 55.3568 125.943 55.3391L156.582 52.7752C156.665 52.7661 156.749 52.7738 156.829 52.7979C156.91 52.8219 156.984 52.8618 157.049 52.9152C157.114 52.9686 157.167 53.0344 157.206 53.1087C157.245 53.1831 157.269 53.2645 157.276 53.3481C157.283 53.4317 157.273 53.5159 157.247 53.5957C157.221 53.6755 157.179 53.7493 157.124 53.8127C157.069 53.8761 157.002 53.9278 156.927 53.9649C156.852 54.002 156.77 54.0236 156.686 54.0285H156.672Z' fill='#66ADFF'/>
        <path d='M120.855 63.1975L106.618 64.3889C106.455 64.3985 106.294 64.3437 106.17 64.2362C106.046 64.1287 105.969 63.9769 105.955 63.8134C105.942 63.6498 105.992 63.4874 106.097 63.3607C106.201 63.2341 106.351 63.1533 106.514 63.1355L120.75 61.9441C120.833 61.935 120.917 61.9428 120.998 61.9668C121.078 61.9909 121.153 62.0308 121.217 62.0841C121.282 62.1375 121.335 62.2033 121.374 62.2777C121.413 62.352 121.437 62.4334 121.444 62.5171C121.451 62.6007 121.441 62.6849 121.415 62.7647C121.389 62.8445 121.347 62.9182 121.292 62.9816C121.238 63.045 121.17 63.0968 121.095 63.1339C121.02 63.1709 120.938 63.1926 120.855 63.1975Z' fill='#66ADFF'/>
        <path d='M157.187 60.1617L126.524 62.7208C126.361 62.7305 126.2 62.6757 126.076 62.5681C125.952 62.4606 125.875 62.3089 125.862 62.1453C125.848 61.9817 125.899 61.8193 126.003 61.6927C126.107 61.566 126.257 61.4852 126.42 61.4675L157.063 58.9083C157.227 58.8987 157.387 58.9535 157.511 59.061C157.635 59.1686 157.712 59.3203 157.726 59.4839C157.739 59.6474 157.689 59.8099 157.584 59.9365C157.48 60.0631 157.331 60.1439 157.168 60.1617H157.187Z' fill='#66ADFF'/>
        <path d='M95.2815 35.3804L94.7266 35.4268L97.7164 71.302L98.2714 71.2556L95.2815 35.3804Z' fill='#252833'/>
        <path d='M46.0855 62.8448C42.4586 65.0322 40.2977 65.2276 36.7422 63.6264L37.4514 60.7098C37.4853 60.5678 37.5489 60.4347 37.6382 60.3192C37.7274 60.2038 37.8401 60.1086 37.9688 60.0401C38.0976 59.9716 38.2394 59.9313 38.3849 59.9219C38.5304 59.9124 38.6762 59.9341 38.8127 59.9854C40.3152 60.4807 41.9509 60.3748 43.3772 59.6899C43.6191 59.5805 43.8939 59.569 44.1441 59.6578C44.3943 59.7467 44.6005 59.9289 44.7195 60.1665L46.0855 62.8448Z' fill='#CC61FA'/>
        <path d='M37.1328 61.9825C37.1328 61.9825 40.7692 63.5075 45.3005 61.3105C44.7579 61.8729 44.1153 62.4829 41.0167 62.807C39.5984 62.9595 37.2423 62.421 37.1328 61.9825Z' fill='#811AC7'/>
        <path d='M39.5419 56.3016C39.3229 55.968 39.5419 55.7583 39.8418 55.7011C40.8794 55.5391 41.7456 55.4104 42.0312 55.5867C42.5786 55.9441 41.6647 56.7877 40.8365 56.9354C40.584 56.9542 40.331 56.9054 40.1035 56.794C39.876 56.6826 39.6822 56.5127 39.5419 56.3016Z' fill='white'/>
        <path d='M38.6466 53.2179C38.9036 53.175 39.1511 53.4133 39.1987 53.7516C39.208 53.8165 39.2039 53.8826 39.1865 53.9457C39.1692 54.0089 39.1391 54.0678 39.098 54.1188C39.0569 54.1698 39.0057 54.2117 38.9477 54.242C38.8897 54.2723 38.8261 54.2903 38.7608 54.2949C38.5038 54.3378 38.3181 54.2425 38.2848 53.9041C38.2479 53.7652 38.2646 53.6174 38.3317 53.4902C38.3987 53.363 38.5111 53.2658 38.6466 53.2179Z' fill='#212121'/>
        <path d='M42.3353 52.918C42.0736 52.918 41.8594 53.1848 41.8594 53.528C41.8594 53.8711 42.026 54.0045 42.3639 54.0045C42.6257 54.0045 42.797 53.8902 42.797 53.528C42.8101 53.3869 42.7699 53.2461 42.6845 53.1332C42.599 53.0203 42.4745 52.9436 42.3353 52.918Z' fill='#212121'/>
        <path d='M75.527 68.0395C75.4657 68.0394 75.4053 68.0247 75.3508 67.9966C75.2886 67.9599 75.2388 67.9052 75.2082 67.8397C75.1776 67.7742 75.1676 67.7009 75.1795 67.6296L75.5032 65.7615C75.527 65.6779 75.5809 65.606 75.6545 65.5598C75.728 65.5136 75.8161 65.4961 75.9016 65.5109C75.9872 65.5257 76.0643 65.5716 76.1182 65.6398C76.172 65.7081 76.1988 65.7938 76.1933 65.8806L76.0029 66.9767C76.2647 66.8337 76.5789 66.6717 76.9263 66.5001C78.0877 65.8949 80.5152 64.632 80.5199 64.0172C80.5199 63.9219 80.3581 63.8266 80.2867 63.7885C78.9587 63.0831 75.4175 63.9219 72.7901 64.856C72.7049 64.8775 72.6148 64.8665 72.5374 64.8249C72.4599 64.7833 72.4008 64.7143 72.3716 64.6313C72.3424 64.5484 72.3452 64.4575 72.3795 64.3765C72.4138 64.2955 72.477 64.2302 72.5569 64.1936C73.9848 63.717 78.6969 62.1491 80.6151 63.1737C80.7863 63.2418 80.9342 63.3582 81.0406 63.5088C81.1471 63.6594 81.2076 63.8376 81.2148 64.022C81.2148 64.9751 79.4918 65.9283 77.2452 67.1197C76.7292 67.3678 76.2299 67.6496 75.7507 67.9632C75.6881 68.0155 75.6084 68.0427 75.527 68.0395Z' fill='#66ADFF'/>
        <path d='M71.2554 69.0402C71.1818 69.0409 71.1099 69.0182 71.05 68.9754C70.9901 68.9326 70.9454 68.8718 70.9222 68.8019C70.8949 68.7134 70.9029 68.6177 70.9446 68.5351C70.9864 68.4524 71.0585 68.3892 71.1459 68.3587C73.7447 67.5247 80.8082 66.7336 81.8601 66.6192C82.1885 66.2904 83.4022 65.0656 83.7639 64.3079C83.7983 64.2325 83.8584 64.1719 83.9335 64.1369C84.0085 64.102 84.0936 64.095 84.1733 64.1173C84.2528 64.1393 84.3221 64.1885 84.3691 64.2565C84.4161 64.3244 84.4377 64.4067 84.4303 64.489L84.2447 66.3953L85.3156 65.5327C85.3643 65.4951 85.4218 65.4705 85.4826 65.4614C85.5434 65.4522 85.6055 65.4587 85.6631 65.4802C85.7203 65.5041 85.7703 65.5426 85.8079 65.592C85.8455 65.6414 85.8694 65.6998 85.8773 65.7614L85.9915 66.6192L86.972 66.1427C87.0096 66.126 87.0499 66.1163 87.091 66.1141C88.2476 66.0045 91.2177 65.8281 91.2462 65.8234C91.3336 65.8267 91.4165 65.8624 91.479 65.9235C91.5415 65.9847 91.5791 66.0669 91.5845 66.1542C91.5898 66.2416 91.5625 66.3278 91.5079 66.3961C91.4533 66.4644 91.3753 66.51 91.2891 66.5239C91.2605 66.5239 88.3999 66.6955 87.2147 66.8051L85.8535 67.4437C85.8048 67.4684 85.751 67.4813 85.6964 67.4813C85.6418 67.4813 85.588 67.4684 85.5393 67.4437C85.4921 67.4162 85.4517 67.3783 85.4212 67.3329C85.3907 67.2875 85.3709 67.2358 85.3632 67.1816L85.268 66.462L84.0305 67.4532C83.9778 67.4966 83.9132 67.523 83.8453 67.529C83.7773 67.5349 83.7091 67.5202 83.6497 67.4866C83.5893 67.4547 83.5398 67.4054 83.5076 67.3451C83.4754 67.2848 83.4619 67.2163 83.4688 67.1482L83.6021 65.7948C82.9976 66.5239 82.317 67.1816 82.2741 67.2245C82.2175 67.2768 82.1459 67.3101 82.0695 67.3198C81.9886 67.3198 74.0589 68.1824 71.3744 69.045C71.3347 69.0494 71.2946 69.0478 71.2554 69.0402Z' fill='#66ADFF'/>
        <path d='M127.593 52.1265C130.971 52.1265 133.709 49.3826 133.709 45.9978C133.709 42.613 130.971 39.8691 127.593 39.8691C124.215 39.8691 121.477 42.613 121.477 45.9978C121.477 49.3826 124.215 52.1265 127.593 52.1265Z' fill='#66ADFF'/>
        <path d='M127.594 54.5428C126.031 54.5438 124.498 54.1151 123.162 53.3035C121.826 52.4918 120.738 51.3284 120.017 49.9401C119.296 48.5518 118.97 46.992 119.074 45.4306C119.177 43.8693 119.707 42.3665 120.606 41.0861C121.504 39.8057 122.736 38.7969 124.168 38.1696C125.6 37.5424 127.176 37.3209 128.725 37.5292C130.274 37.7375 131.736 38.3676 132.952 39.3509C134.167 40.3342 135.09 41.6328 135.619 43.1052C136.081 44.3948 136.227 45.7768 136.045 47.1347C135.862 48.4926 135.356 49.7867 134.569 50.9078C133.783 52.029 132.738 52.9445 131.525 53.5771C130.311 54.2097 128.963 54.5409 127.594 54.5428ZM127.594 38.1537C125.929 38.1504 124.306 38.6785 122.961 39.6614C121.615 40.6442 120.618 42.0307 120.113 43.6196C119.608 45.2086 119.622 46.9172 120.153 48.4977C120.683 50.0782 121.703 51.4482 123.064 52.409C124.425 53.3698 126.057 53.8714 127.722 53.841C129.387 53.8105 130.999 53.2496 132.324 52.2397C133.649 51.2298 134.618 49.8234 135.091 48.2245C135.564 46.6257 135.515 44.9177 134.953 43.3483C134.408 41.8303 133.409 40.5172 132.093 39.5878C130.776 38.6583 129.205 38.1576 127.594 38.1537Z' fill='#66ADFF'/>
        <path d='M124.745 47.2618C124.704 47.2614 124.664 47.2496 124.629 47.2277C124.595 47.2058 124.567 47.1747 124.549 47.1379C124.527 47.0873 124.525 47.0298 124.545 46.9781C124.564 46.9263 124.604 46.8844 124.654 46.8615L130.332 44.2594C130.383 44.2368 130.44 44.2352 130.492 44.2548C130.544 44.2745 130.586 44.3138 130.609 44.3643C130.621 44.3889 130.627 44.4157 130.629 44.4431C130.63 44.4705 130.626 44.4978 130.617 44.5236C130.608 44.5494 130.593 44.573 130.575 44.5931C130.556 44.6132 130.534 44.6294 130.509 44.6407L124.835 47.2332C124.807 47.2479 124.776 47.2576 124.745 47.2618Z' fill='white'/>
        <path d='M124.857 44.8208C124.817 44.8196 124.778 44.8074 124.745 44.7855C124.711 44.7637 124.684 44.733 124.667 44.6969C124.644 44.6463 124.642 44.5889 124.662 44.5371C124.682 44.4853 124.721 44.4434 124.771 44.4205L128.422 42.7525C128.472 42.7347 128.526 42.7363 128.575 42.7569C128.623 42.7776 128.662 42.8158 128.684 42.8639C128.705 42.912 128.708 42.9665 128.691 43.0165C128.675 43.0665 128.64 43.1084 128.593 43.1338L124.947 44.8018C124.919 44.8153 124.888 44.8218 124.857 44.8208Z' fill='white'/>
        <path d='M125.576 49.1292C125.536 49.1279 125.497 49.1157 125.463 49.0938C125.43 49.072 125.403 49.0414 125.385 49.0052C125.363 48.9547 125.361 48.8972 125.381 48.8454C125.4 48.7936 125.44 48.7517 125.49 48.7288L131.164 46.1363C131.213 46.1185 131.268 46.1201 131.316 46.1407C131.365 46.1614 131.404 46.1996 131.425 46.2477C131.447 46.2958 131.45 46.3503 131.433 46.4003C131.416 46.4503 131.381 46.4922 131.335 46.5176L125.661 49.1101C125.635 49.1236 125.605 49.1301 125.576 49.1292Z' fill='white'/>
        <path d='M149.413 56.7261C152.791 56.7261 155.529 53.9822 155.529 50.5974C155.529 47.2126 152.791 44.4688 149.413 44.4688C146.035 44.4688 143.297 47.2126 143.297 50.5974C143.297 53.9822 146.035 56.7261 149.413 56.7261Z' fill='#66ADFF'/>
        <path d='M149.426 59.1421C148.912 59.1408 148.4 59.0946 147.894 59.0039C145.751 58.6116 143.843 57.4076 142.564 55.6422C141.286 53.8768 140.737 51.686 141.031 49.5254C141.324 47.3648 142.439 45.4008 144.142 44.0419C145.845 42.6831 148.006 42.0339 150.175 42.2295C152.344 42.4251 154.354 43.4504 155.788 45.0921C157.221 46.7338 157.967 48.8656 157.87 51.0439C157.774 53.2223 156.843 55.2796 155.27 56.7879C153.697 58.2962 151.604 59.1394 149.426 59.1421ZM149.403 42.7577C147.374 42.7518 145.421 43.5297 143.951 44.9294C142.481 46.329 141.607 48.2425 141.511 50.2712C141.415 52.3 142.105 54.2877 143.436 55.8203C144.767 57.353 146.637 58.3124 148.657 58.4988C150.677 58.6851 152.691 58.0841 154.279 56.8208C155.868 55.5576 156.908 53.7295 157.184 51.7173C157.46 49.7051 156.95 47.6639 155.76 46.0189C154.57 44.3738 152.793 43.2518 150.797 42.8863C150.337 42.8012 149.87 42.7582 149.403 42.7577Z' fill='#66ADFF'/>
        <path d='M152.617 50.8877L146.429 50.2872C146.402 50.2848 146.375 50.277 146.351 50.2641C146.327 50.2512 146.305 50.2336 146.288 50.2122C146.271 50.1909 146.258 50.1663 146.25 50.1399C146.243 50.1134 146.24 50.0857 146.244 50.0584C146.246 50.031 146.254 50.0044 146.267 49.9801C146.28 49.9558 146.297 49.9343 146.319 49.917C146.34 49.8996 146.364 49.8868 146.391 49.8791C146.417 49.8715 146.445 49.8693 146.472 49.8726L152.66 50.4683C152.715 50.4714 152.767 50.4966 152.804 50.5381C152.841 50.5797 152.86 50.6343 152.857 50.6899C152.854 50.7455 152.829 50.7976 152.788 50.8347C152.746 50.8718 152.692 50.8908 152.636 50.8877H152.617Z' fill='white'/>
        <path d='M151.719 48.6194L147.73 48.2334C147.703 48.231 147.676 48.2232 147.652 48.2104C147.627 48.1976 147.605 48.18 147.588 48.1588C147.57 48.1375 147.557 48.1129 147.548 48.0865C147.54 48.06 147.537 48.0322 147.54 48.0047C147.546 47.9495 147.573 47.8987 147.616 47.8632C147.658 47.8276 147.713 47.81 147.768 47.814L151.757 48.2001C151.813 48.2001 151.866 48.2222 151.905 48.2615C151.944 48.3008 151.967 48.3541 151.967 48.4098C151.967 48.4654 151.944 48.5187 151.905 48.558C151.866 48.5973 151.813 48.6194 151.757 48.6194H151.719Z' fill='white'/>
        <path d='M152.425 52.9321L146.238 52.3316C146.183 52.3256 146.132 52.2984 146.097 52.2557C146.061 52.2131 146.043 52.1583 146.047 52.1029C146.053 52.048 146.081 51.9978 146.124 51.963C146.166 51.9282 146.221 51.9117 146.276 51.917L152.464 52.5127C152.491 52.5151 152.518 52.5229 152.542 52.5357C152.567 52.5486 152.589 52.5661 152.606 52.5874C152.624 52.6086 152.637 52.6332 152.646 52.6597C152.654 52.6861 152.657 52.7139 152.654 52.7415C152.652 52.769 152.644 52.7959 152.631 52.8204C152.618 52.845 152.601 52.8667 152.579 52.8844C152.558 52.9022 152.534 52.9155 152.507 52.9237C152.481 52.9318 152.453 52.9347 152.425 52.9321Z' fill='white'/>
        <path d='M49.581 62.2877C49.4944 62.6612 49.3324 63.0131 49.1051 63.3218C48.9305 63.5563 48.7143 63.7566 48.4673 63.9128C47.5534 64.5228 46.1207 64.6419 43.7504 64.8659L39.3714 65.2281C36.3728 65.4759 34.8735 65.5998 33.8549 64.6133C33.6788 64.4455 33.528 64.2528 33.4075 64.0414C33.2683 63.8089 33.1564 63.5611 33.0743 63.3027C32.7173 62.2495 32.5983 60.7293 32.4079 58.4704L32.189 55.8492L31.8082 51.2265C31.5226 47.7952 31.3798 46.0748 32.2223 44.9311C33.0648 43.7873 34.5641 43.6634 37.5627 43.4156L41.9464 43.0486C44.9451 42.8008 46.4444 42.6769 47.463 43.6682C48.4815 44.6594 48.6243 46.3703 48.8909 49.8064L49.3145 54.9056L49.4906 57.0502C49.7334 59.5665 49.8666 61.163 49.581 62.2877Z' fill='#00EAFF'/>
        <path d='M49.5761 62.2878C49.4894 62.6613 49.3275 63.0132 49.1002 63.3219C48.9256 63.5564 48.7094 63.7567 48.4624 63.9129C46.885 64.1301 45.2948 64.2399 43.7026 64.2417C41.675 64.2417 39.5283 64.2036 37.6054 64.1368C35.9871 64.0797 34.5259 64.0272 33.4216 64.0177C33.2824 63.7852 33.1706 63.5373 33.0884 63.279C32.7315 62.2258 32.6125 60.7056 32.4221 58.4466L32.2031 55.8255C32.3748 55.5779 32.559 55.3393 32.7553 55.1106C35.3731 52.3847 32.3888 51.8366 34.5497 49.8684C35.1752 49.4759 35.6807 48.9186 36.0109 48.2576C36.525 46.642 37.991 46.1178 39.9472 46.4276C40.1978 46.4686 40.4534 46.4686 40.704 46.4276C43.1172 45.6365 43.8407 47.2521 44.25 47.8573C44.8259 48.7151 45.5066 48.7151 46.1158 49.0868C48.5528 50.5165 47.8341 52.9613 47.8007 53.4093C47.7991 53.5529 47.8362 53.6943 47.9083 53.8185C47.9804 53.9426 48.0846 54.045 48.2101 54.1146C48.6218 54.308 49.0033 54.56 49.3429 54.8628L49.519 57.0074C49.7284 59.5666 49.8617 61.1631 49.5761 62.2878Z' fill='#212121'/>
        <path d='M49.577 62.287C49.4904 62.6606 49.3284 63.0125 49.1011 63.3212C48.9265 63.5557 48.7103 63.756 48.4633 63.9121C47.5494 64.5221 46.1167 64.6413 43.7464 64.8653L39.3674 65.2275C36.3688 65.4753 34.8695 65.5992 33.8509 64.6127C33.6748 64.4449 33.5241 64.2522 33.4035 64.0408C33.2643 63.8083 33.1525 63.5604 33.0703 63.3021C34.2364 62.8732 35.7024 62.3824 36.9876 62.0392C37.3874 61.9344 37.7682 61.8391 38.1156 61.7676C38.4631 61.6961 38.6868 61.658 38.9248 61.6294C39.2199 61.7628 40.2004 61.7104 41.2618 61.6294H41.3237H41.3856C42.447 61.5627 43.2894 61.4673 43.575 61.291H44.0986C44.5542 61.3088 45.0086 61.3502 45.4599 61.4149C46.8509 61.6094 48.2266 61.9008 49.577 62.287Z' fill='#CC61FA'/>
        <path d='M43.7925 61.0101C43.4612 59.9266 43.2661 58.8059 43.2118 57.6741C42.1171 57.6741 41.108 58.1221 40.0085 58.0935L38.6235 57.6455C38.7758 58.8877 38.7389 60.1459 38.514 61.377C37.662 61.744 37.4431 64.0744 41.7078 63.7599C45.9725 63.4453 44.278 61.1245 43.7925 61.0101Z' fill='#FFA9A9'/>
        <path d='M38.7151 59.5085C40.3857 60.2996 43.2749 58.7173 42.0754 58.8127C40.876 58.908 38.6484 58.1836 38.6484 58.1836C38.7019 58.6231 38.7241 59.0658 38.7151 59.5085Z' fill='#FF8481'/>
        <path d='M45.6061 52.5795C45.4625 52.4697 45.2869 52.4103 45.1063 52.4103C44.9257 52.4103 44.7501 52.4697 44.6065 52.5795C44.3114 51.2022 43.7688 50.006 43.231 49.5199C42.5932 48.9099 41.4746 48.0712 40.3704 48.1474C39.2661 48.2237 37.9905 49.3007 37.4336 49.9774C36.991 50.5446 36.6388 51.8218 36.5578 53.2372C36.4811 53.1788 36.3934 53.1366 36.2999 53.1131C36.2064 53.0896 36.1092 53.0853 36.014 53.1005C35.9188 53.1156 35.8277 53.1499 35.7461 53.2012C35.6645 53.2526 35.5942 53.3199 35.5393 53.3992C35.2156 53.8043 35.6154 54.9814 36.653 55.3055C36.7266 55.7957 36.8576 56.2756 37.0433 56.7352C37.6002 58.0886 38.8949 59.1752 41.0748 59.0561C43.2262 58.8845 44.3543 57.6025 44.6922 56.1728C44.8026 55.6785 44.8569 55.1734 44.854 54.6669C45.7822 54.1283 46.0154 52.8702 45.6061 52.5795Z' fill='#FFA9A9'/>
        <path d='M39.2143 51.9218C39.4999 52.1458 39.281 52.2268 38.3814 52.3269C38.0958 52.3603 37.8768 52.4079 37.8054 52.3269C37.5722 52.1315 38.6717 51.6406 39.2143 51.9218Z' fill='#212121'/>
        <path d='M41.7021 51.7404C41.4498 51.9977 41.7021 52.0501 42.5874 52.0215C42.8777 52.0215 43.1014 52.0215 43.1681 51.9691C43.368 51.7165 42.2066 51.4068 41.7021 51.7404Z' fill='#212121'/>
        <path d='M40.7176 54.8858C40.3987 54.8572 40.2083 54.8191 40.1226 54.6952C40.0369 54.5713 40.1226 54.4045 40.1654 54.2663C40.2511 54.1224 40.294 53.9571 40.2892 53.7897C40.2865 53.7483 40.2998 53.7073 40.3263 53.6754C40.3529 53.6435 40.3907 53.623 40.432 53.6182C40.4733 53.6187 40.5131 53.6339 40.5445 53.6609C40.5758 53.6879 40.5966 53.7251 40.6033 53.7659C40.5925 53.9814 40.5374 54.1923 40.4415 54.3854C40.414 54.4139 40.3972 54.4509 40.3939 54.4903C40.4952 54.5365 40.6066 54.5562 40.7176 54.5475C40.7393 54.5461 40.761 54.5498 40.781 54.5584C40.8009 54.567 40.8186 54.5802 40.8325 54.5969C40.8464 54.6136 40.8562 54.6334 40.861 54.6546C40.8659 54.6759 40.8656 54.6979 40.8604 54.719C40.8623 54.7394 40.8601 54.7601 40.8539 54.7796C40.8478 54.7992 40.8377 54.8173 40.8244 54.8328C40.811 54.8484 40.7947 54.8611 40.7764 54.8702C40.758 54.8793 40.738 54.8846 40.7176 54.8858Z' fill='#FF8481'/>
        <path d='M41.3227 49.8677C41.3632 50.1878 41.5241 50.4804 41.7726 50.6858C42.0211 50.8912 42.3385 50.9941 42.6601 50.9733C42.9807 50.9365 43.2822 50.802 43.524 50.5881C43.7657 50.3742 43.936 50.0911 44.0119 49.7771C44.077 49.4597 44.0679 49.1315 43.9854 48.8182C43.9028 48.5048 43.749 48.2149 43.5359 47.9709C43.1129 47.4787 42.5473 47.1306 41.9176 46.9749C40.9664 46.7355 39.9626 46.8207 39.0651 47.2169C38.1676 47.6131 37.4278 48.2977 36.9628 49.1624C36.4106 50.2632 36.5344 51.3641 37.9147 51.345C38.5397 51.3477 39.1597 51.2329 39.7424 51.0067C40.0137 50.8875 41.3227 50.2632 41.3227 49.8677Z' fill='#212121'/>
        <path d='M46.2863 62.945C42.5118 65.1944 40.2652 65.3707 36.5859 63.7075L37.338 60.686C37.3747 60.5384 37.4426 60.4003 37.5371 60.281C37.6315 60.1618 37.7503 60.0641 37.8856 59.9946C38.0208 59.9251 38.1693 59.8854 38.3211 59.878C38.473 59.8707 38.6246 59.896 38.7659 59.9521C40.3214 60.4756 42.019 60.3748 43.5018 59.671C43.7502 59.5556 44.0334 59.5408 44.2924 59.6296C44.5514 59.7184 44.7661 59.904 44.8917 60.1475L46.2863 62.945Z' fill='#CC61FA'/>
        <path d='M37 62.0152C37 62.0152 40.7697 63.6117 45.4866 61.3623C44.9202 61.939 44.2491 62.568 41.041 62.8921C39.556 63.0398 37.1142 62.4727 37 62.0152Z' fill='#811AC7'/>
        <path d='M39.5279 56.1395C39.3042 55.7916 39.5279 55.5724 39.8468 55.5152C40.9225 55.3531 41.8459 55.2245 42.1124 55.4056C42.6836 55.7773 41.7317 56.6494 40.8702 56.8019C40.6082 56.8179 40.3466 56.7652 40.1112 56.649C39.8758 56.5329 39.6748 56.3572 39.5279 56.1395Z' fill='white'/>
        <path d='M38.6178 52.9315C38.8844 52.8886 39.1414 53.1412 39.189 53.4891C39.2017 53.5585 39.1989 53.6298 39.1807 53.6979C39.1625 53.766 39.1295 53.8293 39.0839 53.883C39.0384 53.9368 38.9815 53.9798 38.9174 54.009C38.8532 54.0381 38.7835 54.0526 38.713 54.0514C38.4465 54.0943 38.2371 53.9943 38.2037 53.6464C38.1682 53.4968 38.1914 53.3393 38.2684 53.2063C38.3455 53.0733 38.4705 52.9749 38.6178 52.9315Z' fill='#212121'/>
        <path d='M42.4447 52.6416C42.1734 52.6416 41.9688 52.918 41.9688 53.2707C41.9688 53.6233 42.1449 53.7472 42.4923 53.7472C42.7636 53.7472 42.9683 53.6281 42.9683 53.2707C42.9791 53.1184 42.9306 52.9679 42.833 52.8506C42.7354 52.7334 42.5962 52.6585 42.4447 52.6416Z' fill='#212121'/>
        <path d='M52.4912 69.771C55.8692 69.771 58.6075 67.0271 58.6075 63.6423C58.6075 60.2576 55.8692 57.5137 52.4912 57.5137C49.1133 57.5137 46.375 60.2576 46.375 63.6423C46.375 67.0271 49.1133 69.771 52.4912 69.771Z' fill='#66ADFF'/>
        <path d='M52.4917 71.7345C50.4906 71.7407 48.5581 71.0043 47.0676 69.6674C45.577 68.3305 44.6342 66.488 44.421 64.4957C44.2079 62.5035 44.7397 60.5028 45.9136 58.8802C47.0876 57.2575 48.8204 56.128 50.7775 55.7098C52.7345 55.2915 54.7769 55.6142 56.5103 56.6155C58.2436 57.6168 59.5449 59.2257 60.1627 61.1315C60.7806 63.0372 60.6713 65.1046 59.8558 66.9343C59.0403 68.7641 57.5766 70.2263 55.7473 71.0387C54.722 71.4941 53.6133 71.731 52.4917 71.7345ZM52.4917 56.2508C50.8988 56.247 49.3474 56.7588 48.0688 57.7099C46.7901 58.661 45.8528 60.0004 45.3965 61.5284C44.9402 63.0565 44.9894 64.6912 45.5368 66.189C46.0842 67.6867 47.1005 68.9671 48.434 69.8394C49.7676 70.7116 51.347 71.1289 52.9367 71.029C54.5265 70.9291 56.0414 70.3173 57.2555 69.285C58.4697 68.2527 59.3181 66.8551 59.6742 65.3006C60.0303 63.7461 59.875 62.118 59.2315 60.6591C58.8435 59.7699 58.2832 58.9667 57.583 58.2959C56.8828 57.6251 56.0567 57.1001 55.1524 56.7512C54.3022 56.4217 53.3986 56.2521 52.4869 56.2508H52.4917Z' fill='#66ADFF'/>
        <path d='M54.9814 60.5631C54.8719 60.4916 53.8581 61.9499 53.8676 62.3121C53.8676 63.1127 53.4821 63.799 53.0442 63.2652C52.9934 63.1113 52.9662 62.9507 52.9633 62.7887H53.0537C53.1241 62.769 53.1873 62.7292 53.2354 62.6742C53.2836 62.6192 53.3147 62.5513 53.325 62.4789C53.3288 62.3906 53.3039 62.3034 53.2542 62.2304C53.2044 62.1574 53.1324 62.1025 53.049 62.0738C53.1061 61.8927 53.0918 61.6211 52.9776 61.5972C52.8633 61.5734 52.2684 61.8355 52.2874 61.7211C52.3207 61.5305 52.2874 61.3399 52.1636 61.3065C52.0399 61.2732 51.9209 61.3685 51.7876 61.4114C51.6544 61.4543 51.5163 61.4114 51.4068 61.4733C51.2974 61.5353 51.3117 61.7736 51.4306 61.9261C51.5496 62.0786 50.869 62.0786 50.7928 62.1834C50.7167 62.2883 50.8404 62.5218 50.9785 62.66C50.9195 62.7237 50.8841 62.8057 50.878 62.8924C50.872 62.9791 50.8956 63.0652 50.9452 63.1365C50.9887 63.1947 51.049 63.2382 51.1179 63.2612C51.1867 63.2842 51.261 63.2856 51.3307 63.2652C51.3586 63.2586 51.3857 63.249 51.4116 63.2366C51.4969 63.3843 51.5565 63.5455 51.5877 63.7132C51.464 64.3804 50.7738 63.9658 50.3883 63.2366C50.2169 62.9221 48.5463 62.4026 48.4844 62.517C48.5407 63.3967 48.8834 64.2337 49.4601 64.8999C49.9932 65.548 51.0975 66.1675 52.0732 65.3764C52.2922 65.853 51.7543 66.0198 51.84 66.2342C52.1779 67.0778 53.2013 66.8014 53.2013 66.8014C53.2013 66.8014 54.2294 66.5249 54.1056 65.629C54.0723 65.4002 53.4726 65.5289 53.4726 64.9999C53.7182 65.0375 53.9689 65.0233 54.2088 64.9583C54.4486 64.8932 54.6722 64.7787 54.8653 64.6222C55.0584 64.4656 55.2168 64.2704 55.3302 64.0491C55.4436 63.8277 55.5096 63.5851 55.524 63.3367C55.6811 61.2779 55.1052 60.6584 54.9814 60.5631Z' fill='white'/>
      </g>
    </svg>);
};
export default RecordBook;
