import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
const TYPES_CHECK_MAP = {
    mobile: /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/,
    table: /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i,
};
export const useCheckUserAgentPlatform = () => {
    const [match, setMatch] = useState('desktop');
    useEffect(() => {
        const checker = debounce(() => {
            const { userAgent } = navigator;
            let result = 'desktop';
            if (TYPES_CHECK_MAP.mobile.test(userAgent)) {
                result = 'mobile';
            }
            if (TYPES_CHECK_MAP.table.test(userAgent)) {
                result = 'tablet';
            }
            setMatch(result);
        });
        window.addEventListener('resize', checker);
        checker();
        return () => {
            window.removeEventListener('resize', checker);
        };
    }, []);
    return match;
};
