import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { ACTIVE_SUBSCRIPTION_STATUS_LIST, showManagementModal } from '@common/entities/subscriptions/lib';
import { checkOfferButtonType } from '@common/entities/offers/lib/utils';
import { showConfirmedModal } from '../showConfirmedModal';
import { showStudentConfirmStatusPlatformNotSupportedModal } from '../show-student-confirm-status-platform-not-supported-modal';
import { applyOffer } from '@common/components/Root/actions';
const DEFAULT_BUTTON = {
    name: '',
    params: [],
    theme: '',
    type: 'purchase',
};
export class ConfirmedStrategy {
    async do(payload) {
        const { studentOffer, callback, activeSubscription } = payload;
        if (activeSubscription &&
            ACTIVE_SUBSCRIPTION_STATUS_LIST.includes(activeSubscription?.status) &&
            activeSubscription?.management &&
            activeSubscription.management.distributor?.external) {
            const { info, title, url, distributor } = activeSubscription.management;
            showManagementModal({
                title: info,
                buttonText: title,
                distributorName: distributor?.name ?? null,
                url,
            });
            return;
        }
        if (!studentOffer) {
            return;
        }
        let button = DEFAULT_BUTTON;
        const { correlationId, widgets } = studentOffer;
        const { offer } = widgets;
        if (offer && offer.buttons[0]) {
            button = offer.buttons[0];
        }
        if (checkOfferButtonType(button, 'platform_not_supported')) {
            showStudentConfirmStatusPlatformNotSupportedModal();
            return;
        }
        const showUseOfferConfirmModalParams = {
            applyOfferParams: {
                correlationId: correlationId,
                button: {
                    type: 'purchase',
                },
            },
            offer: studentOffer,
        };
        if (callback) {
            await callback();
        }
        if (SubscriptionsModel.$hasActiveSubscriptionStatus.getState()) {
            await applyOffer({
                correlationId: correlationId,
                button: {
                    type: 'purchase',
                },
            });
            return;
        }
        showConfirmedModal(showUseOfferConfirmModalParams);
    }
}
