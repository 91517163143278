import { createEffect, sample } from 'effector';
import { createGate } from 'effector-react';
import { api } from '../../api';
import { mappings } from '../../lib/mappings';
import { $members } from './members';
export const GroupSubscriptionMembersGate = createGate({});
export const getGroupSubscriptionMembersFx = createEffect(async () => await api.getGroupSubscriptionMembers());
$members.on(getGroupSubscriptionMembersFx.doneData, (state, payload) => {
    if (!payload.successful) {
        return state;
    }
    const { id, capacity, members, owner_user_id } = payload.data;
    const mapMembers = members.map((member) => mappings.apiMemberGroupSubscription(member));
    return {
        groupId: id,
        capacity,
        ownerVkId: owner_user_id,
        items: mapMembers,
    };
});
sample({
    clock: GroupSubscriptionMembersGate.open,
    source: { $members },
    target: getGroupSubscriptionMembersFx,
    filter: ({ $members }) => !$members.items.length,
});
