import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { Actions, Children } from './ui';
export const showStudentStatusReadyModal = () => {
    modalManagerModel.showModal({
        analyticShowAttribute: '',
        actions: <Actions />,
        children: <Children />,
    });
};
