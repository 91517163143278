import { createEffect, createEvent, createStore, sample } from 'effector';
import { getCheckoutData } from '@common/entities/checkout-subscription';
const $checkoutData = createStore(null);
const $status = createStore('initial');
const getCheckoutDataFx = createEffect(async (paymentId) => {
    const { payment } = await getCheckoutData({ orderUuid: paymentId });
    return payment;
});
const setCheckoutData = createEvent();
sample({ clock: getCheckoutDataFx, fn: () => null, target: $checkoutData });
sample({ clock: getCheckoutDataFx.doneData, target: $checkoutData });
sample({ clock: getCheckoutDataFx.pending, fn: () => 'loading', target: $status });
sample({ clock: getCheckoutDataFx.doneData, fn: () => 'success', target: $status });
sample({ clock: setCheckoutData, target: $checkoutData });
sample({ clock: setCheckoutData, fn: () => 'success', target: $status });
export const checkoutModel = {
    $checkoutData,
    $status,
    getCheckoutDataFx,
    setCheckoutData,
};
