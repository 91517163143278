export const GENERAL_META_TAGS = [
    {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0',
    },
    {
        name: 'description',
        content: 'Слушайте музыку офлайн и без рекламы',
    },
    {
        name: 'keywords',
        content: 'Музыка, Music, VK, VK музыка, подписка, музыка, музыка без рекламы, слушать без интернета',
    },
];
