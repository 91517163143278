import Router from 'next/router';
export const modifyNextSearchParams = ({ paramsToAdd, paramsToRemove }, mode = 'replace') => {
    const search = new URLSearchParams(location.search);
    if (typeof paramsToRemove === 'string') {
        search.delete(paramsToRemove);
    }
    else if (paramsToRemove) {
        paramsToRemove.forEach((paramToRemove) => {
            search.delete(paramToRemove);
        });
    }
    if (paramsToAdd) {
        if (Array.isArray(paramsToAdd)) {
            paramsToAdd.forEach((paramToAdd) => {
                search.append(paramToAdd.param, paramToAdd.value.toString());
            });
        }
        else {
            search.append(paramsToAdd.param, paramsToAdd.value.toString());
        }
    }
    const { push, replace } = Router;
    const handler = mode === 'push' ? push : replace;
    handler({
        search: search.toString(),
    }, undefined, {
        shallow: true,
    });
};
