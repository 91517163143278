import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    header: {
        id: 'header',
        defaultMessage: 'Заявка на рассмотрении',
    },
    subheader: {
        id: 'subheader',
        defaultMessage: 'Нужно немного подождать — анкету проверяют. Обычно это занимает несколько минут.',
    },
    btnText: {
        id: 'btnText',
        defaultMessage: 'Хорошо',
    },
});
