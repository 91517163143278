import { createEffect } from 'effector';
import { subscriptionsApi, SubscriptionsModel } from '@common/entities/subscriptions';
const renewManualPausedRecurrentFx = createEffect(async (subscription) => {
    if (!subscription) {
        return Promise.reject();
    }
    const subscriptionId = subscription.own ? undefined : subscription.id;
    const { data } = await subscriptionsApi.enableSubscriptionRecurrent(subscriptionId ? { vk_subscription_id: subscriptionId } : undefined);
    if (!data.successful) {
        return Promise.reject(data.errors);
    }
    await SubscriptionsModel.getSubscriptionsAndSubscriptionsStatusFx({ state: 'updating' });
    return Promise.resolve();
});
export const ManualRecurrentRenewApi = {
    renewManualPausedRecurrentFx,
};
