import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Div, FormItem, Input } from '@vkontakte/vkui';
import { MAX_NAME_LENGTH } from '@common/entities/student/lib/show-student-form-modal/ui/Form/lib/consts';
import WarningBanner from '@common/components/common/WarningBanner';
import ModalButton from '@common/components/modals/ModalButton';
import { messages } from './messages';
import { studentAnalytics, StudentApi } from '@common/entities/student';
import { showSuccessSendEmailModal } from '@common/features/start-student-flow/lib';
import { createAnalyticClickAttribute } from '@analytics';
const EmailForm = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [state, setState] = useState('idle');
    const intl = useIntl();
    const isLoading = state === 'loading';
    const isDisabled = state === 'loading';
    const inputChangeHandler = (event) => {
        const value = event.target.value.slice(0, MAX_NAME_LENGTH);
        setEmail(value);
        setError(null);
    };
    const submit = async () => {
        setState('loading');
        try {
            if (!email) {
                setError('Укажите почту');
                return;
            }
            const { data } = await StudentApi.sendEmailStudentForm({ email });
            if (!data.successful) {
                setError(data?.errors?.[0]?.detail ?? 'Что то случилось с валидацией почты');
                return;
            }
            showSuccessSendEmailModal();
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setState('idle');
        }
    };
    return (<>
      <Div>
        <WarningBanner type={'attention'} title={intl.formatMessage(messages.emailWarningBannerTitle)} description={intl.formatMessage(messages.emailWarningBannerDescription)}/>
      </Div>
      <form>
        <FormItem top={intl.formatMessage(messages.email)} bottom={error} status={error ? 'error' : 'default'} topComponent='h5'>
          <Input name='email' value={email} onChange={inputChangeHandler}/>
        </FormItem>
      </form>
      <Div>
        <ModalButton size='l' mode='primary' loading={isLoading} disabled={isDisabled} onClick={submit} {...createAnalyticClickAttribute(studentAnalytics.emailForm.emailFormClick)}>
          Отправить
        </ModalButton>
      </Div>
    </>);
};
export default EmailForm;
