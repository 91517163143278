import { createEvent, createStore, sample } from 'effector';
import { reset } from 'patronum';
import { createGate } from 'effector-react';
const gate = createGate({});
const $status = createStore('initial');
const setStatus = createEvent();
const $title = createStore(null);
const setTitle = createEvent();
sample({ clock: setStatus, target: $status });
sample({ clock: setTitle, target: $title });
reset({ clock: gate.close, target: [$status, $title] });
export const showCheckoutModalModelV2 = {
    $status,
    $title,
    gate,
    setStatus,
    setTitle,
};
