import { modalManagerModel } from '@common/services/modal-manager';
import { showSubscriptionCancelReasonModal } from '@common/entities/subscriptions';
import { showBenefitsCancelSubscription } from '../lib/modals';
import { suspendFlow } from './suspend-flow';
import { winbackFlow } from './winback-flow';
export const cancelBaseFlow = async (params) => {
    const { subscriptionId, isPauseAvailable } = params;
    const cancelSubscription = async () => {
        showBenefitsCancelSubscription({
            cancelSubscriptionHandler: async () => {
                await winbackFlow({
                    closeMainModal: modalManagerModel.closeModal,
                    subscriptionId: subscriptionId,
                });
            },
        });
    };
    if (isPauseAvailable) {
        suspendFlow({ subscriptionId, cancelSubscription });
        return;
    }
    showBenefitsCancelSubscription({
        cancelSubscriptionHandler: () => {
            showSubscriptionCancelReasonModal({ subscriptionId });
        },
    });
};
