import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';
const ContentLayout = ({ children, className, offPadding, ...props }) => {
    const offPaddingClassName = offPadding && style.offPadding;
    return (<div id={props.id ?? ''} className={cn(style.contentLayout, offPaddingClassName, className)} ref={props.customref} {...props}>
      {children}
    </div>);
};
export default ContentLayout;
