import React from 'react';
const BenefitsVariantOne = () => {
    return (<svg xmlns='http://www.w3.org/2000/svg' width='92' height='82' fill='none' viewBox='0 0 92 82'>
      <path fill='#2787F5' d='M29.261 78.212c-13.472 0-20.15 0-24.468-4.188C.475 69.835.604 63.099.604 49.555V35.652c0-13.487 0-20.15 4.189-24.469C8.98 6.865 15.717 7.01 29.26 7.01h26.714c13.486 0 20.15 0 24.468 4.174 4.318 4.174 4.174 10.925 4.174 24.469v14.033c0 13.472 0 20.15-4.174 24.468-4.174 4.318-10.924 4.188-24.468 4.188l-26.714-.129z'></path>
      <path fill='#2929B2' d='M13.76 39.869c0-5.354 0-8.032 1.439-9.701 1.44-1.67 3.713-1.67 8.305-1.67h38.27c4.592 0 6.88 0 8.306 1.67 1.424 1.67 1.439 4.318 1.439 9.7v15.315c0 5.354 0 8.046-1.44 9.701-1.439 1.655-3.713 1.67-8.304 1.67H23.489c-4.591 0-6.88 0-8.305-1.67-1.425-1.67-1.439-4.318-1.439-9.7l.014-15.315z'></path>
      <path fill='#fff' d='M13.76 35.205c0-5.354 0-8.03 1.439-9.7 1.44-1.67 3.713-1.67 8.305-1.67h38.27c4.592 0 6.88 0 8.306 1.67 1.424 1.67 1.439 4.317 1.439 9.7v15.343c0 5.355 0 8.032-1.44 9.701-1.439 1.67-3.713 1.67-8.304 1.67H23.489c-4.591 0-6.88 0-8.305-1.67-1.425-1.67-1.439-4.318-1.439-9.7l.014-15.344z'></path>
      <path fill='#212121' d='M39.394 42.143a.72.72 0 000 1.238l.172.1a5.096 5.096 0 011.44 1.267 5.44 5.44 0 01.921 1.8 7.038 7.038 0 01-.245 4.922 6.016 6.016 0 01-1.64 2.13 7.442 7.442 0 01-2.577 1.353 10.778 10.778 0 01-3.382.49 10.55 10.55 0 01-4.404-.879A7.512 7.512 0 0127 52.534a.72.72 0 010-.935l1.224-1.44a.705.705 0 011.08 0c.435.506.956.93 1.54 1.253.975.493 2.06.73 3.151.69a5.24 5.24 0 003.34-.92 3.323 3.323 0 001.166-2.764 3.642 3.642 0 00-1.31-2.993 5.93 5.93 0 00-3.886-1.094h-.159a.72.72 0 01-.72-.72v-1.382a.706.706 0 01.72-.705h.26a4.88 4.88 0 003.382-1.05 3.296 3.296 0 001.252-2.563 2.648 2.648 0 00-1.05-2.331 4.606 4.606 0 00-2.721-.734 5.93 5.93 0 00-2.95.72 5.253 5.253 0 00-1.57 1.352.704.704 0 01-1.079.072l-1.281-1.44a.72.72 0 010-.92c.268-.343.572-.657.907-.936a9.25 9.25 0 011.799-1.108c.69-.326 1.414-.572 2.159-.734a12.092 12.092 0 012.418-.245 9.385 9.385 0 012.878.432c.831.255 1.611.654 2.303 1.18a5.137 5.137 0 011.44 1.843c.35.752.527 1.573.518 2.403a5.757 5.757 0 01-1.123 3.498 4.88 4.88 0 01-1.295 1.194v-.014zM56.852 51.023v2.102a.576.576 0 01-.575.575h-8.535a.576.576 0 01-.576-.575v-2.102a.558.558 0 01.357-.522.56.56 0 01.219-.039h2.202a.575.575 0 00.576-.576V37.02a.576.576 0 00-.907-.475c-.459.296-.94.556-1.44.777-.484.231-.984.428-1.496.59a5.899 5.899 0 01-.778.173.562.562 0 01-.662-.561v-2.174a.562.562 0 01.46-.575 4.22 4.22 0 001.066-.36 13.637 13.637 0 001.684-.907c.5-.323.98-.673 1.44-1.05.205-.173.397-.361.575-.562a.534.534 0 01.432-.201h2.878a.576.576 0 01.576.575v17.617a.576.576 0 00.576.576h1.44a.561.561 0 01.489.561z'></path>
      <path fill='#2929B2' d='M84.585 52.842a21.489 21.489 0 10-32.125 25.46h3.526c13.486 0 20.15 0 24.468-4.188 3.8-3.785 4.117-9.83 4.131-21.272z'></path>
      <path fill='#212121' d='M69.907 73.405A12.853 12.853 0 0157.184 62.58a1.226 1.226 0 112.418-.403 10.464 10.464 0 0019.675 3.08 1.227 1.227 0 012.188 1.109 12.955 12.955 0 01-10.651 7.038h-.907zM81.609 60.94a1.223 1.223 0 01-1.224-1.137 10.478 10.478 0 00-20.524-2.202 1.223 1.223 0 01-1.44.85 1.238 1.238 0 01-.834-1.526A12.954 12.954 0 0169.1 47.612 12.953 12.953 0 0182.875 59.63a1.225 1.225 0 01-1.137 1.31h-.13z'></path>
      <path fill='#212121' d='M56.752 66.208a1.108 1.108 0 01-.504-.115 1.223 1.223 0 01-.604-1.612l1.44-3.181a1.24 1.24 0 011.784-.518l3.022 1.986a1.223 1.223 0 01-1.338 2.044l-1.843-1.195-.849 1.871a1.208 1.208 0 01-1.108.72zM81.71 62.005c-.29.001-.57-.1-.792-.288l-2.75-2.346a1.226 1.226 0 011.584-1.87l1.684 1.439 1.238-1.915a1.226 1.226 0 012.034-.035 1.223 1.223 0 01.024 1.36l-2 3.094a1.236 1.236 0 01-.85.547l-.172.014z'></path>
      <path fill='#2929B2' d='M57.26 7.009l.346 5.757s-7.672-.475-7.585-5.757H57.26zM23.648 12.766l-.346-5.757c-2.734 0-5.123.086-7.196.259.158 5.023 7.542 5.498 7.542 5.498z'></path>
      <path fill='#FFD91D' d='M24.109 0h-.015a3.469 3.469 0 00-3.469 3.469v5.958a3.469 3.469 0 003.47 3.47h.014a3.469 3.469 0 003.468-3.47V3.47A3.469 3.469 0 0024.11 0zM58.35 0h-.015a3.469 3.469 0 00-3.469 3.469v5.958a3.469 3.469 0 003.469 3.47h.014a3.469 3.469 0 003.47-3.47V3.47A3.469 3.469 0 0058.348 0z'></path>
      <path fill='#FF3985' d='M91.352 60.587a21.49 21.49 0 11-21.414-21.564 21.412 21.412 0 0121.414 21.564z'></path>
      <path fill='#fff' stroke='#fff' d='M79.284 52.886h0l.006-.007a1.357 1.357 0 00-1.897-1.897h0l-.005.003-7.152 5.358a.74.74 0 01-.84 0l-6.988-5.278h0l-.005-.004a1.367 1.367 0 00-1.612-.005 1.357 1.357 0 00-.567.898l.493.08-.493-.08c-.03.182-.022.367.022.546l.485-.12-.485.12a1.355 1.355 0 00.236.493l5.257 7.023a.73.73 0 010 .838l-5.394 7.19v-.001l-.003.005a1.357 1.357 0 001.896 1.897h0l.008-.006 7.14-5.43a.71.71 0 01.83 0l6.995 5.319a1.372 1.372 0 001.95-1.93l-5.276-7.067a.72.72 0 01-.002-.834l5.401-7.111z'></path>
    </svg>);
};
export default BenefitsVariantOne;
