import React from 'react';
import { ButtonGroup } from '@vkontakte/vkui';
import { createAnalyticClickAttribute } from '@analytics';
import { modalManagerModel } from '@common/services/modal-manager';
import { showSubscriptionCancelReasonModal } from '@common/entities/subscriptions';
import Typograf from '@common/components/common/Typograf';
import ModalButton from '@common/components/modals/ModalButton';
import { analyticsDictionary } from '../../analytics-dictionary';
import { WinbackIcon } from './fc-icons';
const Actions = (props) => {
    const { subscriptionId } = props;
    return (<ButtonGroup mode='vertical' gap='m' stretched={true}>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalCancelSubscriptionAudiobooksButtonConfirmClick)} size={'l'} stretched={true} onClick={() => {
            modalManagerModel.closeModal();
        }}>
        Остаться в подписке
      </ModalButton>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalCancelSubscriptionAudiobooksButtonCancelClick)} size={'l'} stretched={true} mode={'secondary'} onClick={() => {
            showSubscriptionCancelReasonModal({ subscriptionId });
        }}>
        Всё равно отменить
      </ModalButton>
    </ButtonGroup>);
};
const getPayloadToManagerModal = (subscriptionId) => {
    return {
        analyticShowAttribute: analyticsDictionary.modalCancelSubscriptionAudiobooksShow,
        icon: <WinbackIcon />,
        header: <Typograf>Аудиокниги — тоже в подписке</Typograf>,
        subheader: (<Typograf>
        Слушайте бестселлеры, эксклюзивы, классику и новинки без ограничений: стоимость подписки для вас не изменится.
        Ищите аудиокниги в разделе «Книги и шоу»!
      </Typograf>),
        actions: <Actions subscriptionId={subscriptionId}/>,
    };
};
export const showAudiobooksCancelSubscription = (subscriptionId) => modalManagerModel.showModal(getPayloadToManagerModal(subscriptionId));
