import { paymentMethodWidgetModel } from '@common/widgets/payment-method-widget/model';
import { modalManagerModel } from '@common/services/modal-manager';
import { showUnlinkConfirmModal, unlinkErrorHandler, unlinkSuccessHandler } from '../lib';
import { risingPriceWarningModel, showRisingPriceWarningModal } from '@common/entities/rising-price-warning';
import { SubscriptionsModel } from '@common/entities/subscriptions';
import { abTestDeactivateTrial } from '@common/features/ab-test-deactivate-trial';
import { showAbTestUnlinkDeactivationWarningModal } from '@common/features/ab-test-deactivate-trial/lib';
const unlinkPaymentMethod = () => {
    const subscriptionStatus = SubscriptionsModel.$subscriptionOnw.getState()?.status ?? null;
    if (subscriptionStatus === 'inactive') {
        paymentMethodWidgetModel.unlinkPaymentMethod({
            successHandler: unlinkSuccessHandler,
            errorHandler: unlinkErrorHandler,
        });
        return;
    }
    showUnlinkConfirmModal();
};
export const unlinkPaymentMethodHandler = async () => {
    const mainUnlinkPaymentMethodFlow = async () => {
        const response = await risingPriceWarningModel.getRisingPricesData({ type: 'unlink' });
        if (!response.available) {
            unlinkPaymentMethod();
            return;
        }
        showRisingPriceWarningModal({
            analyticShowAttribute: 'modal_paymethod_unlink_save_price',
            title: response.title,
            detail: response.detail,
            firstButton: {
                analyticClick: 'modal_paymethod_unlink_save_price-button_cancel',
                children: 'Продлевать автоматически',
                handler: () => {
                    modalManagerModel.closeModal();
                },
            },
            secondButton: {
                analyticClick: 'modal_paymethod_unlink_save_price-button_confirm',
                children: 'Удалить способ оплаты',
                handler: () => {
                    unlinkPaymentMethod();
                },
            },
        });
    };
    try {
        const abTest = await abTestDeactivateTrial.cardUnbind.getOnCardUnbindTestVariantFx();
        if (abTest === 'deactivate') {
            showAbTestUnlinkDeactivationWarningModal();
            return;
        }
        await mainUnlinkPaymentMethodFlow();
    }
    catch {
        await mainUnlinkPaymentMethodFlow();
    }
};
