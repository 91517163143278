import { createMainStudentElementAnalyticString } from '@common/entities/student/lib/analytics/constants';
const createElement = (name) => `${createMainStudentElementAnalyticString()('CONFIRMED')}_status_${name}`;
const show = (offerId) => createElement(`${offerId}`);
const click = (offerId) => `${show(offerId)}-button`;
const iOSNotSupportedShow = createElement('ios');
const externalSubscriptionActiveShow = createElement('external_subscription');
const externalSubscriptionActiveClick = `${externalSubscriptionActiveShow}-button`;
export const confirmedAnalytics = {
    show,
    click,
    iOSNotSupportedShow,
    externalSubscriptionActiveShow,
    externalSubscriptionActiveClick,
};
