import React from 'react';
const Icon28LogoVisaColor = ({ size = 28 }) => (<svg width={size} height={size} viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0)'>
      <path d='M13.478 11.877c-.016 1.254 1.115 1.954 1.967 2.37.875.426 1.17.7 1.166 1.082-.007.584-.698.841-1.346.852-1.129.017-1.786-.306-2.308-.55l-.407 1.907c.524.242 1.494.453 2.5.462 2.36 0 3.905-1.168 3.913-2.978.01-2.297-3.171-2.424-3.15-3.451.008-.311.304-.644.954-.728.322-.043 1.21-.075 2.216.389l.395-1.845A6.014 6.014 0 0017.275 9c-2.222 0-3.785 1.183-3.797 2.877zm9.697-2.718c-.431 0-.794.252-.956.639l-3.373 8.066h2.36l.469-1.3h2.883l.272 1.3h2.079L25.095 9.16h-1.92zm.33 2.352l.68 3.269h-1.864l1.184-3.27zM10.618 9.159l-1.86 8.705h2.248l1.859-8.705h-2.248zm-3.326 0l-2.34 5.925-.946-5.038c-.111-.562-.55-.887-1.037-.887H-.856l-.053.253c.785.17 1.677.446 2.218.74.33.18.425.338.533.765l1.793 6.947h2.376L9.653 9.16h-2.36z' fill='#1A1F71'/>
    </g>
    <defs>
      <clipPath id='clip0'>
        <path fill='#fff' d='M0 0h28v28H0z'/>
      </clipPath>
    </defs>
  </svg>);
export default Icon28LogoVisaColor;
