import { useRouter } from 'next/router';
import { Icon28ArrowLeftOutline } from '@vkontakte/icons';
import { ROUTES } from '@website/shared/lib/routes';
import { ContentLayout } from '@website/shared/ui/layouts/content-layout';
import styles from './styles.module.scss';
const BackToProfile = () => {
    const router = useRouter();
    const backToProfileClick = () => {
        void router.push(ROUTES.profile);
    };
    return (<ContentLayout>
      <button type={'button'} className={styles.backToProfile} onClick={backToProfileClick} onTouchCancel={backToProfileClick}>
        <Icon28ArrowLeftOutline />К управлению подпиской
      </button>
    </ContentLayout>);
};
export default BackToProfile;
