import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { ButtonGroup } from '@vkontakte/vkui';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import ModalButton from '@common/components/modals/ModalButton';
import Typograf from '@common/components/common/Typograf/component';
import { redirectToVKID } from '@common/entities/auth-vkid';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
const Actions = () => {
    return (<ButtonGroup mode='vertical' gap='m' stretched={true}>
      <ModalButton size={'l'} stretched={true} onClick={() => redirectToVKID()} {...createAnalyticClickAttribute(analyticsDictionary.modalAuthErrorButton)}>
        Попробовать снова
      </ModalButton>
    </ButtonGroup>);
};
const payloadToManagerModal = {
    analyticShowAttribute: analyticsDictionary.modalAuthError,
    icon: <Icon56ErrorOutline />,
    header: <Typograf>Не получилось авторизоваться с VK ID</Typograf>,
    subheader: (<Typograf>
      Попробуйте ещё раз или повторите попытку позже. Если не вышло, напишите{' '}
      <a target='_blank' rel='noreferrer' href='https://vk.com/support?act=new&from=combo'>
        в поддержку.
      </a>
    </Typograf>),
    actions: <Actions />,
};
export const showAuthErrorModal = () => modalManagerModel.showModal(payloadToManagerModal);
