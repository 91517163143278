import { createEvent, sample } from 'effector';
import { AuthStateModel } from '@common/entities/auth-state';
import { unauthorizedWayFx } from './unauthorized-way';
import { confirmingAuthorizedWay, finishConfirmingAuthorizedWay } from './confirming-authorized-way';
import { createCallbackAfterFlow } from '../callback-after-flow';
export const startConfirmingEmailFlow = createEvent();
createCallbackAfterFlow(startConfirmingEmailFlow.map((payload) => ({ callbackAfterFinishedFlow: payload.callbackAfterFinishedFlow })), finishConfirmingAuthorizedWay);
sample({
    clock: startConfirmingEmailFlow,
    source: { authState: AuthStateModel.$authState },
    filter: ({ authState }) => authState === 'authorized',
    fn: (_, clockData) => clockData,
    target: confirmingAuthorizedWay,
});
sample({
    clock: startConfirmingEmailFlow,
    source: { authState: AuthStateModel.$authState },
    filter: ({ authState }) => authState === 'unauthorized',
    target: unauthorizedWayFx,
});
