import { createEvent, createStore, sample } from 'effector';
import { spread } from 'patronum';
import { StudentApi } from '@common/entities/student/api';
const { getStudentsStatusFx } = StudentApi;
const updateStudentStatus = createEvent();
const updateEnableInfoBanner = createEvent();
const $status = createStore(null);
const $sourceData = createStore(null);
const $enableInfoBanner = createStore(false);
$enableInfoBanner.on(updateEnableInfoBanner, (_, payload) => {
    return payload;
});
sample({
    clock: getStudentsStatusFx.doneData,
    fn: (clockData) => {
        return {
            status: clockData.status,
            sourceData: clockData,
            enableInfoBanner: clockData.enable_notification,
        };
    },
    target: spread({
        targets: {
            status: $status,
            sourceData: $sourceData,
            enableInfoBanner: $enableInfoBanner,
        },
    }),
});
sample({
    clock: updateStudentStatus,
    fn: (clockData) => {
        if (!clockData.successful) {
            return {
                status: null,
                sourceData: null,
                enableInfoBanner: false,
            };
        }
        return {
            status: clockData.data.status,
            sourceData: clockData.data,
            enableInfoBanner: clockData.data.enable_notification,
        };
    },
    target: spread({
        targets: {
            status: $status,
            sourceData: $sourceData,
            enableInfoBanner: $enableInfoBanner,
        },
    }),
});
export const StudentModel = {
    $status,
    $sourceData,
    $enableInfoBanner,
    updateStudentStatus,
    updateEnableInfoBanner,
};
