export const MAP_STATUS_REJECT_INFO_TO_STUDENT_FORM_METHODS = {
    FORM: 'addForm',
    SELFIE: 'addUploadSelfie',
    STUDENT_ID: 'addUploadStudentId',
    RECORD_BOOK: 'addUploadRecordBook',
    SELFIE_WITH_RECORD_BOOK: 'addUploadSelfieWithRecordBook',
};
export const DEFAULT_ERROR_VALUE = {
    cityIdStatus: null,
    firstNameStatus: null,
    lastNameStatus: null,
    establishmentIdStatus: null,
    emailStatus: null,
};
