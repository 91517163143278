import icon90ForFour3XWebp from './icon-90-for-four@3x.webp';
import icon90ForFour3XPng from './icon-90-for-four@3x.png';
import icon90ForFour2XWebp from './icon-90-for-four@2x.webp';
import icon90ForFour2XPng from './icon-90-for-four@2x.png';
import icon90ForFour1XWebp from './icon-90-for-four@1x.webp';
import icon90ForFour1XPng from './icon-90-for-four@1x.png';
export const icon90ForFourImage = {
    src: icon90ForFour1XPng.src,
    width: 90,
    height: 90,
    sources: [
        { width: 90, height: 90, srcSet: icon90ForFour3XWebp.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 90, height: 90, srcSet: icon90ForFour3XPng.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 90, height: 90, srcSet: icon90ForFour2XWebp.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 90, height: 90, srcSet: icon90ForFour2XPng.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 90, height: 90, srcSet: icon90ForFour1XWebp.src },
        { width: 90, height: 90, srcSet: icon90ForFour1XPng.src },
    ],
};
