import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import useIsIosOrAndroid from '@common/hooks/useIsIosOrAndroid';
import useIsSmallScreen from '@common/hooks/useIsSmallScreen';
import { ModalPage, ModalPageHeader, PanelHeaderBack, Separator, usePlatform } from '@vkontakte/vkui';
import styles from './styles.module.scss';
const checkIfElementHasScrollTop = (target, finalElement) => {
    if (target === finalElement) {
        return target.scrollTop > 0;
    }
    if (target.scrollTop > 0) {
        return true;
    }
    const parent = target.parentElement;
    if (parent) {
        return checkIfElementHasScrollTop(parent, finalElement);
    }
    return false;
};
const ModalPageWithFixedFooter = (props) => {
    const { children, className, footer, footerClassName, footerWithSeparator = true, hideFooterOnMobile = false, ...restProps } = props;
    const platform = usePlatform();
    const wrapRef = useRef(null);
    const isMobile = platform !== 'vkcom';
    const hideFooter = isMobile && hideFooterOnMobile;
    const showFooter = Boolean(footer) && !hideFooter;
    useEffect(() => {
        const wrapElement = wrapRef.current;
        const handleTouchEvent = (event) => {
            const { target, currentTarget } = event;
            if (currentTarget instanceof HTMLElement && target instanceof HTMLElement) {
                const hasScrollTop = checkIfElementHasScrollTop(target, currentTarget);
                if (hasScrollTop && event.cancelable) {
                    event.stopPropagation();
                }
            }
        };
        if (wrapElement) {
            wrapElement.addEventListener('touchstart', handleTouchEvent, { passive: false });
            wrapElement.addEventListener('touchmove', handleTouchEvent, { passive: false });
        }
        return () => {
            if (wrapElement) {
                wrapElement.removeEventListener('touchstart', handleTouchEvent);
                wrapElement.removeEventListener('touchmove', handleTouchEvent);
            }
        };
    }, [wrapRef.current]);
    return (<ModalPage {...restProps} className={classNames(styles.container, className)} settlingHeight={100}>
      <div ref={wrapRef} className={styles.wrap}>
        {children}
      </div>
      {showFooter && (<footer className={footerClassName}>
          {footerWithSeparator && <Separator wide={true}/>}
          {footer}
        </footer>)}
    </ModalPage>);
};
const Header = (props) => {
    const isIosOrAndroid = useIsIosOrAndroid();
    const isSmallScreen = useIsSmallScreen();
    const { handleClick, ...restProps } = props;
    return (<ModalPageHeader {...restProps} before={isIosOrAndroid && isSmallScreen && <PanelHeaderBack onClick={handleClick}/>}>
      {props.children}
    </ModalPageHeader>);
};
export default ModalPageWithFixedFooter;
export { Header };
