import { modalManagerModel } from '@common/services/modal-manager';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import dayjs from 'dayjs';
import { intl } from '@common/shared/lib/intl';
import { messages } from './messages';
import ModalButton from '@common/components/modals/ModalButton';
import React from 'react';
const getErrorMsg = (timestamp) => {
    if (!timestamp) {
        return intl.formatMessage(messages.textErrorWithoutTimestampFor429);
    }
    const afterTxt = dayjs.unix(+timestamp).fromNow();
    return intl.formatMessage(messages.textErrorFor429, { afterTxt });
};
export const showDDOSModal = (error) => {
    if (error?.response?.status && error.response.status === 429) {
        const rateLimit = error.response.headers['x-ratelimit-reset'];
        modalManagerModel.showModal({
            analyticShowAttribute: analyticsDictionary.modalRequestErrorShow,
            header: intl.formatMessage(messages.headerFor429),
            subheader: getErrorMsg(rateLimit),
            actions: (<ModalButton mode='secondary' {...createAnalyticClickAttribute(analyticsDictionary.modalRequestErrorButtonConfirmClick)} onClick={() => modalManagerModel.closeModal()}>
          {intl.formatMessage(messages.btnTextFor429)}
        </ModalButton>),
        });
    }
    return Promise.reject(error);
};
