import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useUnit } from 'effector-react';
import { unlinkCardModel } from '@common/widgets/payment-method-widget/ui/current-payment-method/lib/modals/showUnlinkCardReasonModal/model';
import { paymentMethodWidgetModel } from '@common/widgets/payment-method-widget/model';
import { unlinkErrorHandler, unlinkSuccessHandler, } from '@common/widgets/payment-method-widget/ui/current-payment-method/lib';
import styles from './styles.module.scss';
import ModalButton from '@common/components/modals/ModalButton';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import Typograf from '@common/components/common/Typograf';
import messages from '@common/widgets/payment-method-widget/ui/current-payment-method/lib/modals/showUnlinkCardReasonModal/messages';
export const Footer = (props) => {
    const { successHandler } = props;
    const intl = useIntl();
    const form = useUnit(unlinkCardModel.$form);
    const [isInProgress, setIsInProgress] = useState(false);
    const submit = () => {
        setIsInProgress(true);
        const reasons = [];
        const list = [
            ['no_advantage', form.noAdvantageChecked],
            ['bad_price', form.badPriceChecked],
            ['tech_problems', form.techProblemsChecked],
            ['another_subscription', form.anotherSubscriptionChecked, form.anotherSubscriptionText],
            ['other', form.otherChecked, form.otherText],
        ];
        list.forEach(([reason, checked, comment]) => {
            if (checked) {
                const reasonItem = { reason };
                if (comment) {
                    reasonItem.comment = comment;
                }
                reasons.push(reasonItem);
            }
        });
        paymentMethodWidgetModel.unlinkPaymentMethod({
            successHandler: successHandler || unlinkSuccessHandler,
            errorHandler: unlinkErrorHandler,
            reasons,
        });
    };
    return (<div className={styles.footer}>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalPaymethodCancelReasonButtonConfirm)} stretched={true} mode='secondary' onClick={submit} disabled={isInProgress} loading={isInProgress}>
        <Typograf>{intl.formatMessage(messages.submit)}</Typograf>
      </ModalButton>
    </div>);
};
