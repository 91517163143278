import React from 'react';
import { analyticsDictionary } from '@analytics';
import { modalManagerModel, modalManagerTools } from '@common/services/modal-manager';
import ModalButton from '@common/components/modals/ModalButton';
import { intl } from '@common/shared/lib/intl';
import { messages } from './messages';
export const unlinkSuccessHandler = () => {
    return modalManagerTools.modalTemplates.showMessageModal({
        analyticShowAttribute: analyticsDictionary.modalPaymethodUnlinkSuccessShow,
        mode: 'success',
        header: intl.formatMessage(messages.unlinkSuccessTitle),
        subheader: intl.formatMessage(messages.unlinkSuccessDescription),
        actions: (<ModalButton size='l' mode='primary' onClick={() => modalManagerModel.closeModal()}>
        {intl.formatMessage(messages.unlinkSuccessCloseModalBtnTxt)}
      </ModalButton>),
    });
};
