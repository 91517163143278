import { attach, createEffect, createStore, sample } from 'effector';
import { AuthStateModel } from '@common/entities/auth-state';
import { checkoutApi } from '@common/entities/checkout';
const $result = createStore(null);
const getCheckoutABTestFx = attach({
    effect: createEffect(async ({ abTestResult, isAuthorized }) => {
        if (abTestResult) {
            return abTestResult;
        }
        if (!isAuthorized) {
            return 'none';
        }
        const { data } = await checkoutApi.getCheckoutABTest();
        return data.variant ?? 'none';
    }),
    source: {
        abTestResult: $result,
        isAuthorized: AuthStateModel.$isAuthorized,
    },
});
const $loading = getCheckoutABTestFx.pending;
sample({ clock: getCheckoutABTestFx.doneData, target: $result });
export const checkoutAbTestModel = {
    $result,
    $loading,
    getCheckoutABTestFx,
};
