import api from '@common/utils/ApiCommon';
const getPaymentMethodPaymentId = () => api
    .linkPaymentsMethod()
    .then(({ data: linkPaymentsMethodResult }) => {
    if (linkPaymentsMethodResult.successful) {
        return Promise.resolve(linkPaymentsMethodResult);
    }
    return Promise.reject(linkPaymentsMethodResult);
})
    .catch((error) => {
    return Promise.reject(error);
});
export default getPaymentMethodPaymentId;
