import React from 'react';
import classNames from 'classnames';
import { Group } from '@vkontakte/vkui';
import useIsSmallScreen from '@common/hooks/useIsSmallScreen';
import styles from './styles.module.scss';
const CustomGroup = (props) => {
    const isSmallScreen = useIsSmallScreen();
    const { separator = 'hide', ...restProps } = props;
    return (<Group {...restProps} separator={separator} mode={props.mode ?? 'card'} className={classNames(props.className, {
            [styles.container_mobile]: isSmallScreen,
        })}>
      {props.children}
    </Group>);
};
export default CustomGroup;
