import { createEffect, createStore, sample } from 'effector';
import { showPrivacyPolicyModal } from './lib/modals';
import { UserApi } from '@website/entities/user/api';
import { writePrivacyPolicyInLocalStorage } from './lib/modals/showPrivacyPolicyModal';
import { PRIVACY_VERSION } from '@common/consts';
const $profile = createStore(null);
const $profileLoading = createStore(true);
const $avatar = $profile.map((state) => state && state.avatar['50']);
const $privacyAccepted = createStore('idle');
const writePrivacyPolicyInLocalStorageFx = createEffect(() => {
    writePrivacyPolicyInLocalStorage(PRIVACY_VERSION);
});
const showPrivacyPolicyModalFx = createEffect(() => {
    showPrivacyPolicyModal();
});
$profile.on(UserApi.getUserDataProfileFx.doneData, (_, payload) => payload);
$profileLoading.on(UserApi.getUserDataProfileFx.finally, () => false);
$privacyAccepted.on(UserApi.getPrivacyAcceptedDataFx.doneData, (_, payload) => payload.data.accepted);
sample({
    clock: $privacyAccepted,
    filter: (clockData) => clockData === false,
    target: showPrivacyPolicyModalFx,
});
sample({
    clock: $privacyAccepted,
    filter: (clockData) => clockData === true,
    target: writePrivacyPolicyInLocalStorageFx,
});
export const UserModel = { $profile, $profileLoading, $avatar, $privacyAccepted };
