import { createEffect, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import apiCommon from '@common/utils/ApiCommon';
import { SubscriptionsModel } from '@common/entities/subscriptions';
import { loadOffersFx } from './offer';
export const $inactiveSubscriptionRenewStatus = createStore('initial');
export const subscriptionRenewStatusGate = createGate();
export const getInactiveSubscriptionRenewStatusFx = createEffect(async () => {
    const firstSubscription = SubscriptionsModel.$subscriptions.getState()?.[0];
    if (!firstSubscription) {
        return 'not_available';
    }
    try {
        const result = await apiCommon.inactiveSubscriptionCheckRenewStatus(firstSubscription.own ? undefined : { vk_subscription_id: firstSubscription.id });
        if (!result.data.successful) {
            return 'not_available';
        }
        if (result.data.data.restorable) {
            return 'restorable';
        }
        return 'not_restorable';
    }
    catch {
        return 'not_available';
    }
});
sample({
    clock: subscriptionRenewStatusGate.open,
    target: [getInactiveSubscriptionRenewStatusFx, loadOffersFx],
});
$inactiveSubscriptionRenewStatus.on(getInactiveSubscriptionRenewStatusFx.doneData, (_state, payload) => payload);
