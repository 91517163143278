import React from 'react';
import { useUnit } from 'effector-react';
import { SubscriptionContent } from './subscription-content';
import { getSubscriptionById } from '@common/entities/subscriptions';
import { SubscriptionDetailsContext } from '../lib';
export const Children = (props) => {
    const { subscriptionId, contextProps } = props;
    const subscription = useUnit(getSubscriptionById(subscriptionId));
    return (<>
      {subscription && (<SubscriptionDetailsContext.Provider value={{ ...contextProps, subscription: subscription }}>
          <SubscriptionContent {...contextProps} subscription={subscription}/>
        </SubscriptionDetailsContext.Provider>)}
    </>);
};
