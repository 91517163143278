import { defineMessages } from '@formatjs/intl';
export const messages = defineMessages({
    title: {
        id: 'title',
        defaultMessage: 'Ссылка-приглашение отправлена',
    },
    description: {
        id: 'description',
        defaultMessage: 'Вы можете напомнить другу принять приглашение',
    },
    button_confirm: {
        id: 'button_confirm',
        defaultMessage: 'Отменить приглашение',
    },
    remove_member_success_message: {
        id: 'remove_member_success_message',
        defaultMessage: 'Приглашение отменено',
    },
    remove_member_error_message: {
        id: 'remove_member_error_message',
        defaultMessage: 'Не удалось отозвать приглашение',
    },
});
