const OFFER_PAY_SUCCESS = 'offerPaySuccess';
const SUBSCRIPTION_ACTIVATED = 'subscriptionActivated';
class AppEvents {
    constructor() {
        this.listeners = {
            offerPaySuccess: [],
            subscriptionActivated: [],
        };
        this.callEvent = (event) => {
            console.log('AppEvents, callEvent:', event);
            this.listeners[event].forEach((listener) => listener());
        };
        this.addEventListener = (event, listener) => {
            this.listeners[event].push(listener);
            return () => this.removeEventListener(event, listener);
        };
        this.removeEventListener = (event, listener) => {
            this.listeners[event] = this.listeners[event].filter((fn) => fn !== listener);
        };
    }
}
const appEvents = new AppEvents();
export { appEvents };
