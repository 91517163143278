import { useMemo, useEffect } from 'react';
const dictionary = {};
const useCallUnmountCallbacks = () => {
    const key = useMemo(() => String(Date.now()), []);
    const addUnmountCallback = (fn) => {
        const list = dictionary[key];
        if (list) {
            list.push(fn);
        }
        else {
            dictionary[key] = [fn];
        }
    };
    useEffect(() => {
        return () => {
            const list = dictionary[key];
            list?.forEach((cb) => void cb?.());
            delete dictionary[key];
        };
    }, []);
    return addUnmountCallback;
};
export default useCallUnmountCallbacks;
