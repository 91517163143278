import { ReadyStrategy } from '@common/entities/student/lib/strategy/ready-strategy';
import { WaitStrategy } from '@common/entities/student/lib/strategy/wait-strategy';
import { ConfirmedStrategy } from '@common/entities/student/lib/strategy/confirmed-strategy';
import { RejectedStrategy } from '@common/entities/student/lib/strategy/rejected-strategy';
import { RetryStrategy } from '@common/entities/student/lib/strategy/retry-strategy';
export const strategyMap = {
    READY: new ReadyStrategy(),
    WAIT: new WaitStrategy(),
    CONFIRMED: new ConfirmedStrategy(),
    RETRY: new RetryStrategy(),
    REJECTED: new RejectedStrategy(),
};
export class StrategyFactory {
    constructor() {
        this.strategyMapTest = strategyMap;
    }
    getStrategy(status) {
        if (status === null || !this.strategyMapTest[status]) {
            throw new Error('Нет такой стратегии');
        }
        return this.strategyMapTest[status];
    }
}
