import { useMemo } from 'react';
import classNames from 'classnames';
import { usePlatform } from '@vkontakte/vkui';
import useIsSmallScreen from './useIsSmallScreen';
const useCommonClassNames = ({ wideScreen: wideScreenClassName, smallScreen: smallScreenClassName, vkcom: vkcomClassName, ios: iosClassName, android: androidClassName, mobilePlatform: mobilePlatformClassName, }, ...other) => {
    const isSmallScreen = useIsSmallScreen();
    const platform = usePlatform();
    const className = useMemo(() => {
        const commonClassNames = [];
        [
            [wideScreenClassName, Boolean(wideScreenClassName) && !isSmallScreen],
            [smallScreenClassName, Boolean(smallScreenClassName) && isSmallScreen],
            [vkcomClassName, Boolean(vkcomClassName) && platform === 'vkcom'],
            [iosClassName, Boolean(iosClassName) && platform === 'ios'],
            [androidClassName, Boolean(androidClassName) && platform === 'android'],
            [mobilePlatformClassName, (Boolean(mobilePlatformClassName) && platform === 'ios') || platform === 'ios'],
        ].forEach(([className, condition]) => {
            if (condition && typeof className === 'string') {
                commonClassNames.push(className);
            }
        });
        return classNames(commonClassNames, ...other);
    }, [
        isSmallScreen,
        platform,
        wideScreenClassName,
        smallScreenClassName,
        vkcomClassName,
        iosClassName,
        androidClassName,
        mobilePlatformClassName,
        JSON.stringify(other),
    ]);
    return className;
};
export default useCommonClassNames;
