import React from 'react';
import styles from './style.module.scss';
import classNames from 'classnames';
const AnimatedLogo = ({ className }) => {
    return (<svg className={classNames(className)} viewBox='45 67 510 100' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M208.143 142L226.5 91.6428H217.428L202.714 133.143H201.286L187.071 91.6428H177.857L195.643 142H208.143Z' fill='white'/>
      <path d='M250.298 115.929L271.655 91.6428H261.084L243.226 112.143H241.155V91.6428H232.583V142H241.155V120.429H243.298L262.155 142H273.441L250.298 115.929Z' fill='white'/>
      <path d='M302.185 142V103.071H303.328L317.399 142H325.399L339.756 103.071H340.899V142H349.256V91.6428H336.114L322.114 129.714H320.971L307.256 91.6428H293.828V142H302.185Z' fill='white'/>
      <path d='M376.169 131.643H375.027L364.098 104.857H355.312L371.812 144L371.384 145.143C370.312 148.143 369.027 149.143 366.312 149.143H359.384L360.098 156.643H365.741C372.598 156.643 376.312 153.571 378.812 146.643L394.027 104.857H385.669L376.169 131.643Z' fill='white'/>
      <path d='M419.865 123.071C423.65 122.071 426.436 119 426.436 114.786C426.436 108.571 420.508 104.143 412.293 104.143C404.936 104.143 398.722 107.571 396.436 114.643L404.008 115.929C405.508 113 408.436 111.143 412.008 111.143C415.436 111.143 418.222 112.857 418.222 115.643C418.222 118.143 415.936 119.786 411.508 119.786H406.865V126.929H411.508C417.079 126.929 418.793 128.786 418.793 131.143C418.793 134 416.222 135.714 411.508 135.714C407.222 135.714 404.722 134.286 403.079 131L395.508 132.286C397.293 138.5 403.365 142.714 411.579 142.714C421.008 142.714 427.008 137.857 427.008 131.786C427.008 127.143 423.65 124.214 419.865 123.071Z' fill='white'/>
      <path d='M448.855 142C457.712 142 463.998 136.5 463.998 128.714C463.998 120.929 457.783 115.429 448.855 115.429H443.069V104.857H434.855V142H448.855ZM476.64 142V104.857H468.426V142H476.64ZM443.069 122.571H448.855C452.926 122.571 455.783 125.071 455.783 128.714C455.783 132.357 452.926 134.857 448.855 134.857H443.069V122.571Z' fill='white'/>
      <path d='M519.772 142L502.701 122.714L518.344 104.857H508.558L496.129 119.286H494.129V104.857H485.915V142H494.129V127.214H496.129L508.986 142H519.772Z' fill='white'/>
      <path d='M538.167 104.143C530.953 104.143 524.596 107.857 522.739 114.286L530.31 115.571C531.453 113.286 534.024 111.143 537.739 111.143C542.239 111.143 544.882 114.357 545.239 119.214H537.239C526.31 119.214 520.882 124 520.882 130.857C520.882 137.786 526.239 142.714 533.81 142.714C539.882 142.714 543.953 139.214 545.739 136.143L547.453 142H553.524V121.143C553.524 110.357 547.667 104.143 538.167 104.143ZM535.739 135.786C531.739 135.786 529.167 133.714 529.167 130.5C529.167 127.643 531.239 125.643 537.524 125.643H545.31C545.31 131.714 541.453 135.786 535.739 135.786Z' fill='white'/>
      <path d='M52.0297 74.0294C45.2106 80.8486 45.0066 91.6995 45.0005 112.999C64.0885 112.999 76.0061 104.805 86.4598 97.6162C94.6264 92.0007 101.9 86.9994 111 86.9994C124.546 86.9994 136.373 96.5768 142.644 101.655C143.528 102.371 144.302 102.998 144.955 103.496C144.757 88.3094 143.692 79.7505 137.971 74.0294C130.941 67 119.628 67 97.0003 67L93.0003 67C70.3729 67 59.0592 67 52.0297 74.0294Z' fill='#0077FF'/>
      <path d='M90.0603 166.999C91.0185 167 91.9983 167 93.0003 167H97.0003C98.0022 167 98.9819 167 99.9401 166.999H90.0603Z' fill='#0077FF'/>
      <path d='M45.0002 112.999C64.0882 112.999 76.0058 104.805 86.4595 97.6162C94.6261 92.0007 101.899 86.9994 111 86.9994C124.545 86.9994 136.373 96.5768 142.644 101.655C143.528 102.371 144.302 102.998 144.955 103.496C145 106.969 145 110.788 145 115V119C145 141.627 145 152.941 137.971 159.971C131.252 166.689 120.621 166.986 99.939 166.999H90.0608C69.3793 166.986 58.7477 166.689 52.0294 159.971C45 152.941 45 141.627 45 119L45 115C45 114.323 45 113.656 45.0002 112.999Z' fill='#F45FFF'/>

      <path className={styles.staticBar} d='M122.5 117.975C122.5 115.504 124.529 113.5 127 113.5V113.5C129.472 113.5 131.5 115.504 131.5 117.975V136.025C131.5 138.497 129.472 140.5 127 140.5V140.5C124.529 140.5 122.5 138.497 122.5 136.025V117.975Z' fill='white'/>
      <path className={styles.staticBar} d='M106.501 106.475C106.501 104.004 108.53 102 111.001 102V102C113.472 102 115.501 104.004 115.501 106.475V147.525C115.501 149.997 113.472 152 111.001 152V152C108.53 152 106.501 149.997 106.501 147.525V106.475Z' fill='white'/>
      <path className={styles.staticBar} d='M90.501 117.975C90.501 115.504 92.5295 113.5 95.001 113.5V113.5C97.4725 113.5 99.501 115.504 99.501 117.975V136.025C99.501 138.496 97.4725 140.5 95.001 140.5V140.5C92.5295 140.5 90.501 138.496 90.501 136.025V117.975Z' fill='white'/>
      <path className={styles.staticBar} d='M74.499 124.475C74.499 122.003 76.5275 120 78.999 120V120C81.4705 120 83.499 122.003 83.499 124.475V129.525C83.499 131.996 81.4705 134 78.999 134V134C76.5275 134 74.499 131.996 74.499 129.525V124.475Z' fill='white'/>
      <path className={styles.staticBar} d='M58.499 124.475C58.499 122.003 60.5275 120 62.999 120V120C65.4705 120 67.499 122.003 67.499 124.475V129.525C67.499 131.996 65.4705 134 62.999 134V134C60.5275 134 58.499 131.996 58.499 129.525V124.475Z' fill='white'/>

      <rect className={classNames(styles.bar, styles.bar1)} rx='4.5' x='0' y='0'/>
      <rect className={classNames(styles.bar, styles.bar2)} rx='4.5' x='0' y='0'/>
      <rect className={classNames(styles.bar, styles.bar3)} rx='4.5' x='0' y='0'/>
      <rect className={classNames(styles.bar, styles.bar4)} rx='4.5' x='0' y='0'/>
      <rect className={classNames(styles.bar, styles.bar5)} rx='4.5' x='0' y='0'/>
    </svg>);
};
export default AnimatedLogo;
