import React from 'react';
import { createIntl, createIntlCache } from '@formatjs/intl';
import { DATE_FORMAT_LONG, DATE_FORMAT_SHORT } from '@common/consts';
import { getMessagesFromCurrentLocale } from '@common/shared/messages/get-messages';
const cache = createIntlCache();
export const intl = createIntl({
    locale: 'ru',
    messages: getMessagesFromCurrentLocale('ru'),
    defaultLocale: 'ru',
    defaultRichTextElements: {
        p: (...content) => content,
        a: (...content) => {
            const href = content[0][0];
            const text = content[0][1];
            return <a href={String(href)}>{text}</a>;
        },
        span: (...content) => <span>{content}</span>,
        div: (...content) => <div>{content}</div>,
        strong: (...content) => <strong>{content}</strong>,
        b: (...content) => <b>{content}</b>,
        ul: (...content) => <ul>{content}</ul>,
        ol: (...content) => <ol>{content}</ol>,
        li: (...content) => <li>{content}</li>,
        q: (...content) => <q>{content}</q>,
        i: (...content) => <i>{content}</i>,
        s: (...content) => <s>{content}</s>,
        u: (...content) => <u>{content}</u>,
        em: (...content) => <em>{content}</em>,
        wbr: () => <wbr />,
        br: <br />,
        hr: <hr />,
        __: () => '&nbsp',
    },
}, cache);
export const formatDate = (stringOrDate) => {
    const specificDate = new Date(stringOrDate);
    const currentDate = new Date();
    let date = null;
    if (!stringOrDate) {
        return null;
    }
    if (specificDate.getFullYear() !== currentDate.getFullYear()) {
        date = intl.formatDate(stringOrDate, DATE_FORMAT_LONG);
        if (intl.locale === 'ru') {
            date = date.replace(/ г\.$/, '');
        }
    }
    else {
        date = intl.formatDate(stringOrDate, DATE_FORMAT_SHORT);
    }
    return date;
};
