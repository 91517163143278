import icon90Wink3XWebp from './icon-90-wink@3x.webp';
import icon90Wink3XPng from './icon-90-wink@3x.png';
import icon90Wink2XWebp from './icon-90-wink@2x.webp';
import icon90Wink2XPng from './icon-90-wink@2x.png';
import icon90Wink1XWebp from './icon-90-wink@1x.webp';
import icon90Wink1XPng from './icon-90-wink@1x.png';
export const icon90WinkImage = {
    src: icon90Wink1XPng.src,
    width: 90,
    height: 90,
    sources: [
        { width: 90, height: 90, srcSet: icon90Wink3XWebp.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 90, height: 90, srcSet: icon90Wink3XPng.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 90, height: 90, srcSet: icon90Wink2XWebp.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 90, height: 90, srcSet: icon90Wink2XPng.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 90, height: 90, srcSet: icon90Wink1XWebp.src },
        { width: 90, height: 90, srcSet: icon90Wink1XPng.src },
    ],
};
