import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    checkoutTitleVersionPay: {
        id: 'checkoutTitleVersionPay',
        defaultMessage: 'Оплата',
    },
    checkoutTitleVersionPaymentMethod: {
        id: 'checkoutTitleVersionPaymentMethod',
        defaultMessage: 'Способ оплаты',
    },
    successfulPaymentModalTitle: {
        id: 'successfulPaymentModalTitle',
        defaultMessage: 'Оплата прошла',
    },
    successfulPaymentModalDescription: {
        id: 'successfulPaymentModalDescription',
        defaultMessage: 'Подписка оплачена и будет обновляться автоматически',
    },
    noPhoneValidTitle: {
        id: 'noPhoneValidTitle',
        defaultMessage: 'Требуется подтверждение телефона',
    },
    paymethodBindedTitle: {
        id: 'paymethodBindedTitle',
        defaultMessage: 'Вы выбрали новый способ оплаты',
    },
    paymethodBindedDescription: {
        id: 'paymethodBindedDescription',
        defaultMessage: 'Теперь ваша подписка будет обновляться автоматически',
    },
    tariffNotBeAppliedTitle: {
        id: 'tariffNotBeAppliedTitle',
        defaultMessage: 'Не удалось применить выбранный тариф',
    },
    tariffNotBeAppliedDescription: {
        id: 'tariffNotBeAppliedDescription',
        defaultMessage: 'Попробуйте еще раз позже',
    },
    failedToLinkPaymentMethodTitle: {
        id: 'failedToLinkPaymentMethodTitle',
        defaultMessage: 'Не удалось привязать способ оплаты',
    },
    failedToLinkPaymentMethodDescription: {
        id: 'failedToLinkPaymentMethodDescription',
        defaultMessage: 'Во время привязки способа оплаты произошла ошибка',
    },
    closeCheckoutModalBtnTitle: {
        id: 'closeCheckoutModalBtnTitle',
        defaultMessage: 'Отмена',
    },
    defaultErrorTitle: {
        id: 'defaultErrorTitle',
        defaultMessage: 'Упс',
    },
    defaultErrorDescription: {
        id: 'defaultErrorDescription',
        defaultMessage: 'Произошла непредвиденная ошибка',
    },
});
