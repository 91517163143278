export const messages = {
    mobile: {
        title: 'Сделайте фото студенческого билета',
        subtitle: 'Сфотографируйте документ так, чтобы ваши ф. и. о. и название учебного заведения были хорошо видны. Также должны быть видны все края документа.',
        uploadButtonTitle: 'Сделать фото студенческого',
    },
    desktop: {
        title: 'Прикрепите фото студенческого билета',
        subtitle: 'Сфотографируйте документ так, чтобы ваши ф. и. о. и название учебного заведения были хорошо видны. Также должны быть видны все края документа.',
        uploadButtonTitle: 'Прикрепить фото',
    },
};
