import React from 'react';
import { LeftRightPaddingWrapper } from '../left-right-padding-wrapper';
import { PhotoFormatText } from '../photo-format-text';
import styles from './styles.module.scss';
const DesktopLayout = (props) => {
    const { icon, title, subtitle, banner, preview, button, documentFilePreview } = props;
    return (<LeftRightPaddingWrapper>
      <div className={styles.desktopLayout}>
        <div className={styles.main}>
          <div className={styles.makePhotoBlock}>
            {icon}
            <span className={styles.title}>{title}</span>
            <span className={styles.subtitle}>{subtitle}</span>
          </div>
          {banner}
          {preview}
          <div>{!documentFilePreview && button}</div>
        </div>
        <div className={styles.footer}>
          {!documentFilePreview && (<div className={styles.description}>
              <PhotoFormatText />
            </div>)}
        </div>
      </div>
    </LeftRightPaddingWrapper>);
};
export default DesktopLayout;
