import { createEffect, createEvent, createStore, sample } from 'effector';
import { api } from '../api';
const $comboWidgetsState = createStore('idle');
const changeComboWidgetsState = createEvent();
const $comboWidgets = createStore([]);
const getComboWidgets = createEvent();
const getComboWidgetsFx = createEffect(async () => {
    try {
        return (await api.getComboWidgets()).data.data.widgets;
    }
    catch {
        return [];
    }
});
$comboWidgets.on(getComboWidgetsFx.doneData, (_state, payload) => {
    if (!payload) {
        return [];
    }
    return payload;
});
$comboWidgetsState.on(changeComboWidgetsState, (_state, payload) => payload);
$comboWidgetsState.on(getComboWidgetsFx.pending, () => 'process');
$comboWidgetsState.on(getComboWidgetsFx.done, () => 'idle');
$comboWidgetsState.on(getComboWidgetsFx.fail, () => 'error');
sample({ clock: getComboWidgets, target: getComboWidgetsFx });
export const comboWidgetsModel = {
    $comboWidgets,
    getComboWidgets,
    getComboWidgetsFx,
    $comboWidgetsState,
};
