import { defineMessages } from 'react-intl';
export default defineMessages({
    title: {
        id: 'title',
        defaultMessage: 'Отмена подписки',
    },
    subtitle: {
        id: 'subtitle',
        defaultMessage: 'Почему вы решили отменить подписку?',
    },
    noAdvantageText: {
        id: 'noAdvantageText',
        defaultMessage: 'Не понимаю, зачем мне подписка',
    },
    noAdvantageDescription: {
        id: 'noAdvantageDescription',
        defaultMessage: 'С подпиской нет рекламы между треками, можно скачивать треки и слушать их без интернета. А музыка будет играть даже при заблокированном экране.',
    },
    badPriceText: {
        id: 'badPriceText',
        defaultMessage: 'Меня не устраивает цена',
    },
    badPriceDescription: {
        id: 'badPriceDescription',
        defaultMessage: 'С подпиской вы можете слушать треки и плейлисты без рекламы, а также скачивать их. Музыка не прервётся, даже если вы свернёте приложение или заблокируете устройство. Также вам доступна вся коллекция аудиокниг в разделе «Книги и шоу». За каждый месяц подписки вы получите эксклюзивные стикеры от VK Музыки.',
    },
    techProblemsText: {
        id: 'techProblemsText',
        defaultMessage: 'У меня возникли технические трудности с подпиской',
    },
    techProblemsDescription: {
        id: 'techProblemsDescription',
        defaultMessage: 'Расскажите о вашей проблеме службе поддержки, и они помогут вам с любым вопросом: support@music.vk.com',
    },
    anotherSubscriptionText: {
        id: 'anotherSubscriptionText',
        defaultMessage: 'У меня есть другая подписка',
    },
    anotherSubscriptionPlaceholder: {
        id: 'anotherSubscriptionPlaceholder',
        defaultMessage: 'Расскажите, какой подпиской вы пользуетесь и почему',
    },
    otherSubscriptionText: {
        id: 'otherSubscriptionText',
        defaultMessage: 'Другая причина',
    },
    otherSubscriptionPlaceholder: {
        id: 'otherSubscriptionPlaceholder',
        defaultMessage: 'Ваше мнение поможет сделать VK Музыку ещё лучше и удобнее',
    },
    submit: {
        id: 'submit',
        defaultMessage: 'Отменить подписку',
    },
    successTitle: {
        id: 'successTitle',
        defaultMessage: 'Подписка отменена',
    },
    successDefaultDescription: {
        id: 'successDefaultDescription',
        defaultMessage: 'Вы можете слушать музыку без ограничений до {subscriptionEndTime} включительно. Деньги за подписку больше не списываются',
    },
    successSuspendedDescription: {
        id: 'successSuspendedDescription',
        defaultMessage: 'Деньги за подписку больше не списываются',
    },
    errorTitle: {
        id: 'errorTitle',
        defaultMessage: 'Не удалось отменить подписку',
    },
    errorDescription: {
        id: 'errorDescription',
        defaultMessage: 'Произошла ошибка. Попробуйте еще раз позже',
    },
});
