import React from 'react';
const DEFAULT_WIDTH = 156;
const DEFAULT_HEIGHT = 63;
const StudentId = ({ width = DEFAULT_WIDTH }) => {
    const height = (width / DEFAULT_WIDTH) * DEFAULT_HEIGHT;
    return (<svg width={width} height={height} viewBox='0 0 180 63' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6.75258 15.3232C16.0351 0.251793 40.2152 -1.68076 56.9619 1.05445C72.9196 3.40068 87.7802 9.03779 103.393 12.2237C125.502 17.6694 153.682 8.84022 170.176 22.0223C189.863 39.7734 166.084 54.4002 147.136 59.3396C133.151 62.9886 117.822 63.2109 105.285 62.5626C75.3419 60.7103 23.7212 49.2324 8.51539 33.7534C4.29943 29.5117 3.14066 21.2876 6.75258 15.3232Z' fill='#00EAFF'/>
      <path d='M94.5355 55.3326C92.2734 54.3818 91.2564 54.7152 89.7155 55.592L85.4688 7.70425C87.7308 8.60569 88.8896 8.56865 90.5476 7.22266L94.5355 55.3326Z' fill='#0077FF'/>
      <path d='M3.0617 14.9489L84.1405 8.17241C84.7335 8.12285 85.3218 8.31129 85.7761 8.69626C86.2304 9.08123 86.5134 9.63121 86.5629 10.2252L90.0999 52.6801C90.1494 53.2741 89.9613 53.8635 89.577 54.3186C89.1927 54.7736 88.6436 55.0571 88.0506 55.1067L6.97185 61.8832C6.38051 61.9326 5.79378 61.7447 5.34074 61.3608C4.8877 60.9768 4.60546 60.4284 4.55611 59.836L1.01862 17.3749C0.969133 16.7809 1.15723 16.1916 1.54154 15.7365C1.92584 15.2814 2.47487 14.9979 3.06784 14.9484L3.0617 14.9489Z' fill='white'/>
      <path d='M88.1016 56.1968L7.00611 62.9885C6.15775 63.0604 5.31563 62.7918 4.66491 62.2418C4.01418 61.6918 3.60811 60.9055 3.53596 60.0557L0.0103403 17.7125C-0.0578614 16.8621 0.213323 16.0193 0.764447 15.3689C1.31557 14.7184 2.10166 14.3134 2.95041 14.2426L84.0459 7.45089C84.8942 7.37907 85.7364 7.64766 86.3871 8.19762C87.0378 8.74758 87.4439 9.53389 87.516 10.3837L91.0416 52.7145C91.0771 53.1366 91.0292 53.5616 90.9006 53.9651C90.772 54.3686 90.5652 54.7427 90.2922 55.0662C90.0191 55.3896 89.6851 55.6559 89.3092 55.8499C88.9333 56.0439 88.523 56.1618 88.1016 56.1968ZM3.14149 16.1875C2.97553 16.1975 2.8133 16.2409 2.66455 16.3153C2.51581 16.3897 2.38362 16.4935 2.27594 16.6204C2.16827 16.7473 2.08733 16.8947 2.038 17.0537C1.98866 17.2128 1.97196 17.3802 1.98888 17.5458L5.48368 59.9014C5.49677 60.0655 5.54249 60.2253 5.61813 60.3714C5.69378 60.5176 5.79783 60.6471 5.92417 60.7524C6.05051 60.8576 6.19659 60.9365 6.35381 60.9844C6.51104 61.0323 6.67623 61.0483 6.83969 61.0313L87.9352 54.2396C88.1011 54.2296 88.2633 54.1861 88.4121 54.1117C88.5608 54.0373 88.693 53.9336 88.8007 53.8067C88.9084 53.6798 88.9893 53.5324 89.0386 53.3733C89.088 53.2143 89.1047 53.0469 89.0878 52.8812L85.5621 10.5442C85.5515 10.3779 85.5073 10.2155 85.4323 10.0668C85.3573 9.91805 85.253 9.78606 85.1257 9.67877C84.9985 9.57147 84.8508 9.49108 84.6917 9.44245C84.5326 9.39382 84.3654 9.37795 84.2 9.39579L3.14149 16.1875Z' fill='#0077FF'/>
      <path d='M91.9289 7.52261L173.008 0.745348C173.601 0.695782 174.189 0.884209 174.643 1.26918C175.098 1.65415 175.381 2.20412 175.43 2.79811L178.967 45.2531C179.016 45.8471 178.828 46.4364 178.444 46.8915C178.059 47.3466 177.51 47.6301 176.917 47.6796L95.8386 54.4569C95.2473 54.5063 94.6605 54.3184 94.2075 53.9345C93.7545 53.5506 93.4722 53.0021 93.4229 52.4098L89.8858 9.94864C89.8605 9.65488 89.8933 9.35901 89.9824 9.07798C90.0715 8.79696 90.215 8.53629 90.4048 8.31093C90.5946 8.08556 90.8269 7.89993 91.0884 7.76465C91.35 7.62938 91.6356 7.54713 91.9289 7.52261Z' fill='white'/>
      <path d='M177.051 48.7507L95.9558 55.5424C95.1078 55.6125 94.2666 55.3433 93.6163 54.7937C92.9659 54.2441 92.5594 53.4588 92.4857 52.6096L88.9601 10.2787C88.8901 9.42732 89.1605 8.58291 89.7118 7.9311C90.2631 7.27929 91.0502 6.8734 91.9001 6.80263L172.996 0.0109201C173.845 -0.0592269 174.688 0.210622 175.34 0.761415C175.991 1.31221 176.398 2.09909 176.472 2.94988L179.991 45.2807C180.06 46.1311 179.788 46.974 179.237 47.6244C178.686 48.2748 177.9 48.6799 177.051 48.7507ZM92.0604 8.74753C91.8944 8.75749 91.7322 8.80097 91.5835 8.87537C91.4347 8.94976 91.3025 9.05352 91.1948 9.18042C91.0872 9.30731 91.0062 9.45472 90.9569 9.61376C90.9076 9.7728 90.8909 9.94019 90.9078 10.1059L94.4334 52.4367C94.4632 52.77 94.6229 53.078 94.8779 53.2941C95.1329 53.5102 95.4626 53.617 95.7956 53.5913L176.891 46.7996C177.058 46.7897 177.22 46.746 177.37 46.6713C177.519 46.5965 177.651 46.4921 177.759 46.3646C177.867 46.237 177.948 46.0888 177.996 45.9291C178.045 45.7693 178.061 45.6013 178.044 45.4351L174.493 3.09806C174.483 2.93177 174.438 2.76938 174.363 2.62064C174.288 2.47191 174.184 2.33992 174.057 2.23262C173.93 2.12533 173.782 2.04494 173.623 1.99631C173.464 1.94768 173.297 1.9318 173.131 1.94964L92.0604 8.74753Z' fill='#0077FF'/>
      <path d='M76.38 16.0949L36.464 19.4352C36.3561 19.447 36.247 19.437 36.143 19.4058C36.039 19.3746 35.9423 19.323 35.8585 19.2538C35.7748 19.1846 35.7057 19.0994 35.6553 19.003C35.605 18.9067 35.5744 18.8013 35.5653 18.6929C35.5563 18.5846 35.569 18.4755 35.6026 18.3721C35.6363 18.2688 35.6903 18.1732 35.7614 18.091C35.8325 18.0089 35.9193 17.9418 36.0167 17.8938C36.1141 17.8458 36.2201 17.8177 36.3284 17.8114L76.2444 14.4711C76.3523 14.4593 76.4615 14.4693 76.5655 14.5005C76.6695 14.5316 76.7662 14.5833 76.8499 14.6525C76.9337 14.7217 77.0028 14.8069 77.0531 14.9033C77.1035 14.9996 77.134 15.105 77.1431 15.2134C77.1521 15.3217 77.1395 15.4308 77.1058 15.5342C77.0721 15.6375 77.0181 15.7331 76.947 15.8153C76.8759 15.8974 76.7891 15.9645 76.6917 16.0125C76.5943 16.0605 76.4883 16.0885 76.38 16.0949Z' fill='#66ADFF'/>
      <path d='M59.3556 23.3374L36.9445 25.1897C36.8366 25.2015 36.7274 25.1915 36.6234 25.1603C36.5195 25.1292 36.4227 25.0775 36.339 25.0083C36.2552 24.9391 36.1862 24.8539 36.1358 24.7576C36.0855 24.6612 36.0548 24.5558 36.0458 24.4474C36.0368 24.3391 36.0494 24.23 36.0831 24.1266C36.1168 24.0233 36.1708 23.9277 36.2419 23.8456C36.313 23.7634 36.3998 23.6963 36.4972 23.6483C36.5946 23.6003 36.7006 23.5723 36.8089 23.5659L59.22 21.7136C59.3266 21.7034 59.4342 21.7146 59.5364 21.7465C59.6387 21.7783 59.7336 21.8303 59.8156 21.8992C59.8976 21.9682 59.9651 22.0528 60.0142 22.1481C60.0633 22.2434 60.0929 22.3476 60.1014 22.4545C60.1107 22.5616 60.0988 22.6695 60.0663 22.772C60.0337 22.8744 59.9813 22.9694 59.912 23.0515C59.8427 23.1335 59.7578 23.201 59.6623 23.2501C59.5669 23.2992 59.4626 23.3289 59.3556 23.3374Z' fill='#66ADFF'/>
      <path d='M59.8478 29.1101L37.4367 30.9623C37.3288 30.9741 37.2196 30.9641 37.1156 30.9329C37.0116 30.9018 36.9149 30.8501 36.8312 30.7809C36.7474 30.7118 36.6783 30.6265 36.628 30.5302C36.5776 30.4338 36.547 30.3284 36.538 30.22C36.5289 30.1117 36.5416 30.0026 36.5753 29.8993C36.609 29.7959 36.663 29.7003 36.7341 29.6182C36.8052 29.536 36.892 29.469 36.9894 29.4209C37.0867 29.3729 37.1927 29.3449 37.3011 29.3385L59.7122 27.4862C59.8201 27.4745 59.9293 27.4845 60.0333 27.5156C60.1373 27.5468 60.234 27.5985 60.3178 27.6676C60.4015 27.7368 60.4706 27.8221 60.5209 27.9184C60.5713 28.0147 60.6019 28.1202 60.6109 28.2285C60.62 28.3369 60.6073 28.4459 60.5736 28.5493C60.5399 28.6527 60.486 28.7482 60.4149 28.8304C60.3437 28.9125 60.2569 28.9796 60.1596 29.0276C60.0622 29.0757 59.9562 29.1037 59.8478 29.1101Z' fill='#66ADFF'/>
      <path d='M77.338 27.6468L67.6425 28.4556C67.4306 28.4681 67.2222 28.3971 67.0618 28.2578C66.9015 28.1184 66.8018 27.9218 66.7841 27.7099C66.7664 27.498 66.8321 27.2876 66.9671 27.1235C67.1022 26.9595 67.2959 26.8548 67.5069 26.8318L77.2085 26.0229C77.3171 26.0104 77.427 26.0199 77.5318 26.0508C77.6366 26.0818 77.7341 26.1336 77.8185 26.2031C77.9029 26.2726 77.9724 26.3584 78.023 26.4554C78.0735 26.5524 78.1041 26.6586 78.1128 26.7677C78.1215 26.8768 78.1081 26.9865 78.0736 27.0904C78.039 27.1942 77.984 27.29 77.9117 27.372C77.8394 27.454 77.7513 27.5206 77.6527 27.5679C77.5542 27.6151 77.4471 27.6419 77.338 27.6468Z' fill='#66ADFF'/>
      <path d='M60.3308 34.8893L38.3566 36.7416C38.2508 36.7534 38.1438 36.7434 38.0418 36.7122C37.9399 36.6811 37.845 36.6294 37.7629 36.5602C37.6808 36.491 37.613 36.4058 37.5637 36.3095C37.5143 36.2131 37.4843 36.1077 37.4754 35.9993C37.4666 35.891 37.479 35.7819 37.512 35.6785C37.545 35.5752 37.598 35.4796 37.6677 35.3975C37.7374 35.3153 37.8225 35.2483 37.918 35.2002C38.0135 35.1522 38.1174 35.1242 38.2237 35.1178L60.1979 33.2655C60.3037 33.2538 60.4107 33.2638 60.5127 33.2949C60.6146 33.3261 60.7095 33.3778 60.7916 33.4469C60.8737 33.5161 60.9415 33.6014 60.9908 33.6977C61.0402 33.794 61.0702 33.8995 61.0791 34.0078C61.0879 34.1162 61.0755 34.2252 61.0425 34.3286C61.0095 34.432 60.9565 34.5275 60.8868 34.6097C60.8171 34.6918 60.732 34.7589 60.6365 34.8069C60.541 34.855 60.4371 34.883 60.3308 34.8893Z' fill='#66ADFF'/>
      <path d='M78.3015 39.199L38.3855 42.5393C38.2758 42.5528 38.1645 42.5438 38.0584 42.5128C37.9523 42.4818 37.8536 42.4294 37.7683 42.3589C37.6831 42.2884 37.6131 42.2013 37.5626 42.1028C37.512 42.0043 37.4821 41.8965 37.4746 41.786C37.467 41.6756 37.482 41.5647 37.5187 41.4602C37.5553 41.3558 37.6128 41.2599 37.6877 41.1784C37.7625 41.0969 37.8532 41.0316 37.9541 40.9864C38.055 40.9412 38.1641 40.9171 38.2746 40.9155L78.1967 37.5752C78.3052 37.5627 78.4152 37.5721 78.52 37.6031C78.6248 37.634 78.7223 37.6858 78.8066 37.7553C78.891 37.8248 78.9606 37.9106 79.0111 38.0076C79.0617 38.1046 79.0922 38.2109 79.1009 38.32C79.1096 38.429 79.0963 38.5388 79.0618 38.6426C79.0272 38.7464 78.9721 38.8422 78.8998 38.9242C78.8275 39.0063 78.7395 39.0729 78.6409 39.1201C78.5423 39.1673 78.4353 39.1942 78.3261 39.199H78.3015Z' fill='#66ADFF'/>
      <path d='M52.2342 47.195L38.8898 48.3126C38.7819 48.3243 38.6727 48.3143 38.5688 48.2832C38.4648 48.252 38.368 48.2003 38.2843 48.1311C38.2005 48.062 38.1315 47.9767 38.0811 47.8804C38.0308 47.7841 38.0002 47.6786 37.9911 47.5703C37.9821 47.4619 37.9948 47.3528 38.0284 47.2495C38.0621 47.1461 38.1161 47.0505 38.1872 46.9684C38.2583 46.8862 38.3451 46.8192 38.4425 46.7712C38.5399 46.7231 38.6459 46.6951 38.7542 46.6887L52.1232 45.5712C52.2311 45.5594 52.3403 45.5694 52.4443 45.6006C52.5483 45.6317 52.645 45.6834 52.7288 45.7526C52.8125 45.8218 52.8816 45.907 52.9319 46.0034C52.9823 46.0997 53.0129 46.2051 53.0219 46.3135C53.031 46.4218 53.0183 46.5309 52.9846 46.6343C52.951 46.7376 52.897 46.8332 52.8259 46.9154C52.7547 46.9975 52.6679 47.0646 52.5706 47.1126C52.4732 47.1606 52.3672 47.1886 52.2588 47.195H52.2342Z' fill='#66ADFF'/>
      <path d='M52.7159 52.9738L39.3469 54.0914C39.2389 54.1031 39.1298 54.0931 39.0258 54.062C38.9218 54.0308 38.8251 53.9791 38.7413 53.9099C38.6576 53.8408 38.5885 53.7555 38.5381 53.6592C38.4878 53.5629 38.4572 53.4574 38.4481 53.3491C38.4391 53.2407 38.4518 53.1316 38.4855 53.0283C38.5191 52.9249 38.5731 52.8293 38.6442 52.7472C38.7153 52.665 38.8021 52.598 38.8995 52.55C38.9969 52.5019 39.1029 52.4739 39.2113 52.4675L52.5803 51.35C52.6882 51.3382 52.7974 51.3482 52.9013 51.3794C53.0053 51.4105 53.1021 51.4622 53.1858 51.5314C53.2696 51.6006 53.3386 51.6858 53.389 51.7822C53.4393 51.8785 53.4699 51.9839 53.479 52.0923C53.488 52.2006 53.4753 52.3097 53.4417 52.4131C53.408 52.5164 53.354 52.612 53.2829 52.6942C53.2118 52.7763 53.125 52.8434 53.0276 52.8914C52.9302 52.9394 52.8242 52.9674 52.7159 52.9738Z' fill='#66ADFF'/>
      <path d='M23.6104 53.2272L10.2414 54.3448C10.1335 54.3565 10.0243 54.3465 9.92032 54.3154C9.81633 54.2842 9.71961 54.2325 9.63586 54.1634C9.55211 54.0942 9.48303 54.0089 9.43268 53.9126C9.38233 53.8163 9.35172 53.7108 9.34268 53.6025C9.33363 53.4941 9.34631 53.3851 9.37999 53.2817C9.41367 53.1783 9.46765 53.0828 9.53876 53.0006C9.60987 52.9185 9.69668 52.8514 9.79406 52.8034C9.89144 52.7553 9.99743 52.7273 10.1058 52.721L23.481 51.6034C23.5889 51.5917 23.698 51.6017 23.802 51.6328C23.906 51.664 24.0027 51.7157 24.0865 51.7848C24.1702 51.854 24.2393 51.9393 24.2897 52.0356C24.34 52.1319 24.3706 52.2373 24.3797 52.3457C24.3887 52.4541 24.376 52.5631 24.3424 52.6665C24.3087 52.7699 24.2547 52.8654 24.1836 52.9476C24.1125 53.0297 24.0257 53.0968 23.9283 53.1448C23.8309 53.1928 23.7249 53.2209 23.6166 53.2272H23.6104Z' fill='#66ADFF'/>
      <path d='M118.894 12.5388L100.459 14.0823C100.247 14.0948 100.039 14.0238 99.8782 13.8845C99.7179 13.7452 99.6182 13.5486 99.6005 13.3367C99.5828 13.1248 99.6485 12.9143 99.7835 12.7503C99.9186 12.5862 100.112 12.4815 100.323 12.4585L118.759 10.9149C118.867 10.9032 118.976 10.9132 119.08 10.9443C119.184 10.9755 119.281 11.0272 119.364 11.0963C119.448 11.1655 119.517 11.2508 119.568 11.3471C119.618 11.4434 119.649 11.5489 119.658 11.6572C119.667 11.7656 119.654 11.8746 119.62 11.978C119.587 12.0814 119.533 12.177 119.462 12.2591C119.39 12.3412 119.304 12.4083 119.206 12.4563C119.109 12.5044 119.003 12.5324 118.894 12.5388Z' fill='#66ADFF'/>
      <path d='M137.055 39.0076L128.722 39.7053C128.614 39.717 128.505 39.707 128.401 39.6759C128.297 39.6447 128.2 39.593 128.116 39.5238C128.033 39.4547 127.963 39.3694 127.913 39.2731C127.863 39.1768 127.832 39.0713 127.823 38.963C127.814 38.8546 127.827 38.7455 127.86 38.6422C127.894 38.5388 127.948 38.4432 128.019 38.3611C128.09 38.2789 128.177 38.2119 128.275 38.1638C128.372 38.1158 128.478 38.0878 128.586 38.0814L136.895 37.3837C137.107 37.3713 137.315 37.4423 137.476 37.5816C137.636 37.7209 137.736 37.9175 137.753 38.1294C137.771 38.3413 137.705 38.5517 137.57 38.7158C137.435 38.8799 137.242 38.9846 137.03 39.0076H137.055Z' fill='#66ADFF'/>
      <path d='M137.714 46.9352L129.381 47.6329C129.169 47.6454 128.96 47.5744 128.8 47.4351C128.64 47.2958 128.54 47.0992 128.522 46.8873C128.505 46.6754 128.57 46.4649 128.705 46.3009C128.84 46.1368 129.034 46.0321 129.245 46.0091L137.578 45.3114C137.686 45.2997 137.796 45.3097 137.9 45.3408C138.004 45.372 138.1 45.4237 138.184 45.4928C138.268 45.562 138.337 45.6473 138.387 45.7436C138.438 45.8399 138.468 45.9454 138.477 46.0537C138.486 46.1621 138.474 46.2711 138.44 46.3745C138.406 46.4779 138.352 46.5734 138.281 46.6556C138.21 46.7377 138.123 46.8048 138.026 46.8528C137.928 46.9009 137.822 46.9289 137.714 46.9352Z' fill='#66ADFF'/>
      <path d='M165.941 8.59936L126.26 11.9211C126.048 11.9336 125.839 11.8626 125.679 11.7233C125.519 11.5839 125.419 11.3874 125.401 11.1755C125.384 10.9635 125.449 10.7531 125.584 10.589C125.719 10.425 125.913 10.3203 126.124 10.2973L165.806 6.97552C166.018 6.96306 166.226 7.03407 166.387 7.17338C166.547 7.3127 166.647 7.50928 166.664 7.72119C166.682 7.9331 166.616 8.14354 166.481 8.30761C166.346 8.47167 166.152 8.57637 165.941 8.59936Z' fill='#66ADFF'/>
      <path d='M119.522 20.4847L101.142 22.0221C100.926 22.0385 100.712 21.968 100.547 21.8262C100.382 21.6843 100.28 21.4827 100.264 21.2658C100.248 21.0488 100.318 20.8342 100.46 20.6692C100.601 20.5042 100.802 20.4023 101.019 20.3859L119.387 18.8485C119.603 18.8305 119.817 18.8991 119.982 19.0392C120.147 19.1793 120.25 19.3795 120.268 19.5956C120.286 19.8118 120.218 20.0262 120.078 20.1918C119.938 20.3574 119.738 20.4605 119.522 20.4785V20.4847Z' fill='#66ADFF'/>
      <path d='M166.601 16.5456L126.925 19.8612C126.817 19.873 126.708 19.863 126.604 19.8318C126.5 19.8007 126.403 19.749 126.319 19.6798C126.236 19.6106 126.167 19.5254 126.116 19.4291C126.066 19.3327 126.035 19.2273 126.026 19.1189C126.017 19.0106 126.03 18.9015 126.064 18.7982C126.097 18.6948 126.151 18.5992 126.222 18.5171C126.293 18.4349 126.38 18.3679 126.478 18.3198C126.575 18.2718 126.681 18.2438 126.789 18.2374L166.477 14.9218C166.689 14.9094 166.898 14.9804 167.058 15.1197C167.218 15.259 167.318 15.4556 167.336 15.6675C167.353 15.8794 167.288 16.0898 167.153 16.2539C167.018 16.418 166.824 16.5227 166.613 16.5456H166.601Z' fill='#66ADFF'/>
      <path d='M120.252 28.4124L101.761 29.9621C101.653 29.9739 101.544 29.9639 101.44 29.9327C101.336 29.9016 101.239 29.8499 101.155 29.7807C101.072 29.7115 101.003 29.6263 100.952 29.5299C100.902 29.4336 100.871 29.3282 100.862 29.2198C100.853 29.1115 100.866 29.0024 100.9 28.899C100.933 28.7957 100.987 28.7001 101.058 28.6179C101.129 28.5358 101.216 28.4687 101.314 28.4207C101.411 28.3727 101.517 28.3446 101.625 28.3383L120.061 26.7947C120.271 26.7775 120.479 26.8426 120.642 26.9762C120.805 27.1098 120.91 27.3016 120.935 27.5112C120.96 27.7208 120.903 27.9319 120.775 28.0999C120.648 28.268 120.46 28.38 120.252 28.4124Z' fill='#66ADFF'/>
      <path d='M167.261 24.4859L127.585 27.7953C127.477 27.807 127.368 27.797 127.264 27.7659C127.16 27.7347 127.063 27.683 126.98 27.6139C126.896 27.5447 126.827 27.4594 126.776 27.3631C126.726 27.2668 126.695 27.1613 126.686 27.053C126.677 26.9446 126.69 26.8356 126.724 26.7322C126.757 26.6288 126.811 26.5332 126.883 26.4511C126.954 26.369 127.04 26.3019 127.138 26.2539C127.235 26.2058 127.341 26.1778 127.45 26.1714L167.125 22.8558C167.233 22.8441 167.342 22.8541 167.446 22.8852C167.55 22.9164 167.647 22.9681 167.731 23.0373C167.814 23.1064 167.883 23.1917 167.934 23.288C167.984 23.3843 168.015 23.4898 168.024 23.5981C168.033 23.7065 168.02 23.8156 167.987 23.9189C167.953 24.0223 167.899 24.1179 167.828 24.2C167.757 24.2822 167.67 24.3492 167.572 24.3973C167.475 24.4453 167.369 24.4733 167.261 24.4797V24.4859Z' fill='#66ADFF'/>
      <path d='M120.868 36.365L102.433 37.9086C102.325 37.9203 102.216 37.9103 102.112 37.8792C102.008 37.848 101.911 37.7963 101.827 37.7271C101.744 37.658 101.674 37.5727 101.624 37.4764C101.574 37.3801 101.543 37.2746 101.534 37.1663C101.525 37.0579 101.538 36.9488 101.571 36.8455C101.605 36.7421 101.659 36.6465 101.73 36.5644C101.801 36.4822 101.888 36.4152 101.985 36.3671C102.083 36.3191 102.189 36.2911 102.297 36.2847L120.733 34.7411C120.945 34.7287 121.153 34.7997 121.313 34.939C121.474 35.0783 121.573 35.2749 121.591 35.4868C121.609 35.6987 121.543 35.9092 121.408 36.0732C121.273 36.2373 121.079 36.342 120.868 36.365Z' fill='#66ADFF'/>
      <path d='M167.921 32.426L128.264 35.7477C128.052 35.7602 127.843 35.6892 127.683 35.5499C127.523 35.4106 127.423 35.214 127.405 35.0021C127.387 34.7902 127.453 34.5797 127.588 34.4156C127.723 34.2516 127.917 34.1469 128.128 34.1239L167.804 30.8021C167.911 30.7904 168.021 30.8004 168.125 30.8315C168.229 30.8627 168.325 30.9144 168.409 30.9835C168.493 31.0527 168.562 31.138 168.612 31.2343C168.663 31.3306 168.693 31.4361 168.702 31.5444C168.711 31.6528 168.699 31.7619 168.665 31.8652C168.631 31.9686 168.577 32.0642 168.506 32.1463C168.435 32.2285 168.348 32.2955 168.251 32.3435C168.154 32.3916 168.048 32.4196 167.939 32.426H167.921Z' fill='#66ADFF'/>
      <path d='M121.539 44.3054L103.103 45.8489C102.892 45.8614 102.683 45.7904 102.523 45.6511C102.362 45.5118 102.263 45.3152 102.245 45.1033C102.227 44.8914 102.293 44.6809 102.428 44.5169C102.563 44.3528 102.757 44.2481 102.968 44.2251L121.403 42.6815C121.511 42.6698 121.62 42.6798 121.724 42.7109C121.828 42.7421 121.925 42.7938 122.009 42.8629C122.093 42.9321 122.162 43.0174 122.212 43.1137C122.262 43.21 122.293 43.3155 122.302 43.4238C122.311 43.5322 122.298 43.6413 122.265 43.7446C122.231 43.848 122.177 43.9436 122.106 44.0257C122.035 44.1079 121.948 44.1749 121.851 44.2229C121.753 44.271 121.647 44.299 121.539 44.3054Z' fill='#66ADFF'/>
      <path d='M168.587 40.3723L128.881 43.6879C128.669 43.7004 128.46 43.6293 128.3 43.49C128.14 43.3507 128.04 43.1541 128.022 42.9422C128.005 42.7303 128.07 42.5199 128.205 42.3558C128.34 42.1917 128.534 42.087 128.745 42.0641L168.427 38.7485C168.639 38.736 168.847 38.807 169.008 38.9463C169.168 39.0856 169.268 39.2822 169.285 39.4941C169.303 39.706 169.237 39.9165 169.102 40.0806C168.967 40.2446 168.774 40.3493 168.563 40.3723H168.587Z' fill='#66ADFF'/>
      <path d='M88.4219 8.26512L87.7031 8.3252L91.5749 54.8042L92.2936 54.7441L88.4219 8.26512Z' fill='#252833'/>
      <path d='M24.7126 43.8482C20.0159 46.6822 17.2175 46.9354 12.6133 44.8608L13.5317 41.0821C13.5756 40.8982 13.658 40.7257 13.7735 40.5761C13.8891 40.4266 14.035 40.3033 14.2017 40.2146C14.3684 40.1258 14.5521 40.0736 14.7405 40.0613C14.9289 40.0491 15.1177 40.0772 15.2945 40.1437C17.2402 40.7854 19.3584 40.6482 21.2054 39.7608C21.5187 39.6191 21.8746 39.6042 22.1985 39.7193C22.5225 39.8344 22.7895 40.0705 22.9436 40.3783L24.7126 43.8482Z' fill='#CC61FA'/>
      <path d='M13.125 42.7309C13.125 42.7309 17.834 44.7067 23.7019 41.8604C22.9992 42.5889 22.1671 43.3792 18.1546 43.7991C16.3178 43.9967 13.2668 43.299 13.125 42.7309Z' fill='#811AC7'/>
      <path d='M16.24 35.3709C15.9564 34.9387 16.24 34.667 16.6283 34.593C17.9722 34.383 19.0941 34.2163 19.4639 34.4448C20.1728 34.9078 18.9893 36.0007 17.9167 36.1921C17.5896 36.2164 17.2619 36.1532 16.9673 36.0089C16.6727 35.8646 16.4216 35.6444 16.24 35.3709Z' fill='white'/>
      <path d='M15.0871 31.3766C15.42 31.321 15.7405 31.6298 15.8022 32.0683C15.8142 32.1524 15.8089 32.238 15.7865 32.3199C15.764 32.4018 15.725 32.4781 15.6717 32.5442C15.6185 32.6103 15.5523 32.6647 15.4771 32.7039C15.402 32.7432 15.3196 32.7665 15.2351 32.7725C14.9022 32.8281 14.6618 32.7045 14.6186 32.266C14.5708 32.0859 14.5925 31.8943 14.6793 31.7295C14.7661 31.5647 14.9117 31.4387 15.0871 31.3766Z' fill='#252040'/>
      <path d='M19.8586 30.9873C19.5196 30.9873 19.2422 31.3332 19.2422 31.7779C19.2422 32.2226 19.4579 32.3955 19.8956 32.3955C20.2347 32.3955 20.4566 32.2473 20.4566 31.7779C20.4734 31.5951 20.4214 31.4126 20.3108 31.2663C20.2001 31.12 20.0388 31.0205 19.8586 30.9873Z' fill='#252040'/>
      <path d='M62.8347 50.5783C62.7553 50.5782 62.6772 50.5592 62.6066 50.5228C62.526 50.4753 62.4616 50.4045 62.422 50.3196C62.3823 50.2347 62.3694 50.1398 62.3847 50.0473L62.8039 47.627C62.8348 47.5187 62.9046 47.4257 62.9998 47.3658C63.0951 47.3058 63.209 47.2833 63.3199 47.3024C63.4307 47.3216 63.5306 47.3811 63.6003 47.4695C63.67 47.5579 63.7047 47.669 63.6976 47.7814L63.4511 49.2015C63.7901 49.0162 64.1968 48.8063 64.6468 48.584C66.1507 47.7999 69.2942 46.1637 69.3004 45.3672C69.3004 45.2438 69.0908 45.1203 68.9984 45.0709C67.2787 44.1571 62.6929 45.2438 59.2906 46.4539C59.1803 46.4818 59.0635 46.4675 58.9633 46.4136C58.863 46.3598 58.7864 46.2703 58.7486 46.1629C58.7108 46.0554 58.7144 45.9376 58.7588 45.8327C58.8032 45.7277 58.8851 45.6432 58.9885 45.5957C60.8376 44.9783 66.9397 42.9469 69.4236 44.2744C69.6454 44.3626 69.8368 44.5135 69.9747 44.7085C70.1125 44.9036 70.1909 45.1346 70.2003 45.3734C70.2003 46.6083 67.969 47.8431 65.0598 49.3867C64.3915 49.7082 63.745 50.0733 63.1244 50.4795C63.0434 50.5473 62.9401 50.5826 62.8347 50.5783Z' fill='#66ADFF'/>
      <path d='M57.3076 51.8751C57.2123 51.876 57.1192 51.8466 57.0416 51.7911C56.9641 51.7356 56.9061 51.6569 56.8761 51.5663C56.8407 51.4517 56.8511 51.3277 56.9052 51.2206C56.9592 51.1135 57.0527 51.0316 57.1658 50.9921C60.5312 49.9116 69.6781 48.8867 71.0403 48.7385C71.4655 48.3125 73.0373 46.7257 73.5057 45.744C73.5502 45.6463 73.6281 45.5678 73.7253 45.5225C73.8224 45.4772 73.9326 45.4682 74.0358 45.497C74.1388 45.5255 74.2286 45.5893 74.2894 45.6774C74.3502 45.7654 74.3783 45.872 74.3686 45.9786L74.1283 48.4483L75.5151 47.3308C75.5781 47.2821 75.6525 47.2503 75.7313 47.2384C75.81 47.2265 75.8904 47.2349 75.965 47.2629C76.0392 47.2937 76.1039 47.3437 76.1526 47.4077C76.2013 47.4716 76.2323 47.5473 76.2424 47.6271L76.3903 48.7385L77.66 48.1211C77.7087 48.0994 77.7609 48.0869 77.8141 48.084C79.3119 47.942 83.158 47.7136 83.195 47.7074C83.3081 47.7117 83.4156 47.7579 83.4965 47.8372C83.5774 47.9164 83.6261 48.0229 83.633 48.1361C83.6399 48.2492 83.6046 48.3609 83.5339 48.4494C83.4632 48.5379 83.3622 48.597 83.2505 48.615C83.2135 48.615 79.5091 48.8373 77.9744 48.9793L76.2116 49.8067C76.1485 49.8387 76.0788 49.8553 76.0082 49.8553C75.9375 49.8553 75.8678 49.8387 75.8048 49.8067C75.7436 49.771 75.6913 49.7219 75.6518 49.6631C75.6123 49.6043 75.5867 49.5373 75.5767 49.4671L75.4534 48.5348L73.8509 49.819C73.7827 49.8752 73.699 49.9095 73.611 49.9172C73.523 49.9249 73.4347 49.9057 73.3578 49.8622C73.2796 49.8209 73.2155 49.757 73.1738 49.6789C73.132 49.6008 73.1146 49.512 73.1236 49.4239L73.2962 47.6704C72.5134 48.615 71.632 49.4671 71.5765 49.5226C71.5031 49.5904 71.4105 49.6335 71.3115 49.6461C71.2067 49.6461 60.938 50.7637 57.4617 51.8812C57.4103 51.887 57.3583 51.8849 57.3076 51.8751Z' fill='#66ADFF'/>
      <path d='M130.268 29.9623C134.642 29.9623 138.188 26.4074 138.188 22.0222C138.188 17.6369 134.642 14.082 130.268 14.082C125.894 14.082 122.348 17.6369 122.348 22.0222C122.348 26.4074 125.894 29.9623 130.268 29.9623Z' fill='#66ADFF'/>
      <path d='M130.262 33.0926C128.238 33.0939 126.252 32.5385 124.522 31.4869C122.792 30.4354 121.383 28.9281 120.45 27.1295C119.516 25.3308 119.093 23.3099 119.228 21.2871C119.362 19.2643 120.048 17.3173 121.212 15.6585C122.375 13.9996 123.971 12.6926 125.825 11.88C127.679 11.0673 129.72 10.7803 131.726 11.0502C133.732 11.3201 135.625 12.1365 137.199 13.4104C138.773 14.6843 139.968 16.3668 140.654 18.2744C141.252 19.9452 141.441 21.7356 141.204 23.4949C140.968 25.2541 140.313 26.9307 139.294 28.3832C138.275 29.8358 136.923 31.0218 135.351 31.8414C133.779 32.661 132.034 33.0901 130.262 33.0926ZM130.262 11.8593C128.105 11.855 126.003 12.5392 124.261 13.8126C122.519 15.086 121.227 16.8823 120.574 18.9408C119.92 20.9994 119.938 23.2131 120.625 25.2607C121.312 27.3083 122.633 29.0833 124.395 30.3281C126.158 31.5729 128.271 32.2227 130.427 32.1833C132.583 32.1438 134.67 31.4172 136.386 30.1088C138.102 28.8003 139.358 26.9782 139.97 24.9068C140.582 22.8354 140.519 20.6225 139.791 18.5892C139.085 16.6226 137.792 14.9214 136.087 13.7172C134.382 12.513 132.348 11.8643 130.262 11.8593Z' fill='#66ADFF'/>
      <path d='M126.577 23.6585C126.524 23.658 126.472 23.6427 126.428 23.6143C126.383 23.586 126.347 23.5457 126.324 23.498C126.295 23.4324 126.293 23.358 126.318 23.2909C126.344 23.2238 126.395 23.1695 126.46 23.1399L133.813 19.7687C133.879 19.7395 133.953 19.7373 134.02 19.7628C134.087 19.7882 134.141 19.8392 134.171 19.9045C134.186 19.9365 134.195 19.9712 134.197 20.0067C134.199 20.0421 134.194 20.0776 134.182 20.111C134.169 20.1444 134.151 20.175 134.127 20.2011C134.103 20.2271 134.074 20.248 134.041 20.2626L126.694 23.6214C126.658 23.6405 126.618 23.653 126.577 23.6585Z' fill='white'/>
      <path d='M126.715 20.4973C126.664 20.4957 126.613 20.4799 126.57 20.4516C126.526 20.4233 126.491 20.3836 126.469 20.3368C126.44 20.2713 126.438 20.1968 126.463 20.1297C126.488 20.0626 126.539 20.0083 126.604 19.9787L131.333 17.8177C131.397 17.7946 131.467 17.7967 131.53 17.8234C131.593 17.8501 131.643 17.8996 131.671 17.9619C131.699 18.0243 131.703 18.0949 131.681 18.1597C131.66 18.2245 131.614 18.2788 131.554 18.3116L126.833 20.4726C126.796 20.4901 126.756 20.4986 126.715 20.4973Z' fill='white'/>
      <path d='M127.649 26.0789C127.597 26.0773 127.547 26.0615 127.503 26.0332C127.46 26.0049 127.425 25.9652 127.402 25.9184C127.373 25.8529 127.371 25.7784 127.397 25.7113C127.422 25.6442 127.473 25.5899 127.538 25.5603L134.885 22.2015C134.949 22.1784 135.02 22.1805 135.083 22.2072C135.146 22.2339 135.196 22.2834 135.224 22.3457C135.252 22.4081 135.255 22.4787 135.234 22.5435C135.212 22.6083 135.167 22.6625 135.107 22.6954L127.76 26.0542C127.726 26.0717 127.687 26.0802 127.649 26.0789Z' fill='white'/>
      <path d='M158.514 35.9203C162.888 35.9203 166.434 32.3654 166.434 27.9802C166.434 23.595 162.888 20.04 158.514 20.04C154.14 20.04 150.594 23.595 150.594 27.9802C150.594 32.3654 154.14 35.9203 158.514 35.9203Z' fill='#66ADFF'/>
      <path d='M158.539 39.051C157.873 39.0493 157.209 38.9894 156.554 38.8719C153.78 38.3638 151.308 36.8038 149.653 34.5167C147.998 32.2295 147.286 29.3911 147.667 26.5919C148.047 23.7927 149.49 21.2482 151.696 19.4877C153.901 17.7271 156.7 16.8861 159.508 17.1395C162.317 17.3929 164.92 18.7212 166.776 20.8482C168.633 22.9751 169.599 25.737 169.474 28.5592C169.349 31.3815 168.143 34.0469 166.106 36.001C164.069 37.9551 161.359 39.0475 158.539 39.051ZM158.508 17.8238C155.881 17.8162 153.353 18.824 151.449 20.6374C149.546 22.4507 148.414 24.9297 148.289 27.5582C148.165 30.1866 149.057 32.7618 150.781 34.7474C152.505 36.7331 154.927 37.976 157.543 38.2175C160.158 38.459 162.766 37.6803 164.823 36.0437C166.88 34.407 168.228 32.0386 168.585 29.4316C168.942 26.8247 168.281 24.1802 166.741 22.0489C165.2 19.9177 162.898 18.4639 160.314 17.9905C159.718 17.8802 159.114 17.8244 158.508 17.8238Z' fill='#66ADFF'/>
      <path d='M162.669 28.3575L154.656 27.5793C154.621 27.5763 154.586 27.5661 154.555 27.5494C154.524 27.5327 154.496 27.5098 154.473 27.4822C154.451 27.4545 154.434 27.4226 154.424 27.3884C154.415 27.3541 154.412 27.3182 154.416 27.2828C154.419 27.2473 154.429 27.2128 154.446 27.1813C154.462 27.1498 154.485 27.122 154.513 27.0995C154.541 27.077 154.572 27.0603 154.607 27.0505C154.641 27.0406 154.677 27.0377 154.712 27.042L162.725 27.814C162.797 27.8181 162.864 27.8507 162.912 27.9045C162.96 27.9584 162.985 28.0291 162.98 28.1012C162.976 28.1733 162.944 28.2408 162.89 28.2889C162.836 28.3369 162.766 28.3616 162.694 28.3575H162.669Z' fill='white'/>
      <path d='M161.507 25.4186L156.342 24.9183C156.306 24.9152 156.271 24.905 156.239 24.8884C156.208 24.8718 156.18 24.8491 156.157 24.8215C156.134 24.794 156.116 24.7621 156.106 24.7278C156.095 24.6936 156.092 24.6575 156.095 24.6218C156.103 24.5503 156.138 24.4845 156.193 24.4384C156.248 24.3924 156.319 24.3695 156.391 24.3748L161.556 24.875C161.628 24.875 161.697 24.9037 161.748 24.9546C161.799 25.0056 161.827 25.0747 161.827 25.1468C161.827 25.2189 161.799 25.288 161.748 25.339C161.697 25.3899 161.628 25.4186 161.556 25.4186H161.507Z' fill='white'/>
      <path d='M162.416 31.0063L154.404 30.2281C154.332 30.2203 154.267 30.185 154.221 30.1297C154.175 30.0744 154.152 30.0034 154.157 29.9316C154.165 29.8605 154.2 29.7954 154.256 29.7503C154.311 29.7052 154.382 29.6838 154.453 29.6907L162.466 30.4628C162.501 30.4659 162.536 30.476 162.568 30.4926C162.599 30.5093 162.628 30.532 162.651 30.5596C162.673 30.5871 162.691 30.619 162.701 30.6532C162.712 30.6875 162.716 30.7235 162.712 30.7593C162.709 30.795 162.699 30.8298 162.682 30.8616C162.666 30.8934 162.643 30.9216 162.616 30.9446C162.588 30.9675 162.556 30.9848 162.522 30.9954C162.488 31.006 162.452 31.0097 162.416 31.0063Z' fill='white'/>
      <path d='M29.2366 43.1259C29.1244 43.6099 28.9146 44.0658 28.6202 44.4657C28.3942 44.7695 28.1142 45.0291 27.7943 45.2313C26.6109 46.0216 24.7556 46.176 21.6861 46.4662L16.0155 46.9354C12.1324 47.2565 10.1909 47.417 8.87183 46.1389C8.64374 45.9215 8.44856 45.6719 8.29244 45.398C8.11214 45.0968 7.96735 44.7757 7.86098 44.441C7.39871 43.0765 7.24462 41.1069 6.99807 38.1803L6.71454 34.7844L6.22145 28.7954C5.85163 24.3499 5.66672 22.121 6.75769 20.6392C7.84866 19.1573 9.79022 18.9968 13.6733 18.6758L19.3501 18.2003C23.2332 17.8793 25.1747 17.7187 26.4938 19.003C27.8128 20.2872 27.9977 22.5038 28.3429 26.9555L28.8914 33.5619L29.1195 36.3404C29.4338 39.6004 29.6064 41.6688 29.2366 43.1259Z' fill='#00EAFF'/>
      <path d='M29.2396 43.1257C29.1273 43.6097 28.9176 44.0656 28.6232 44.4655C28.3972 44.7693 28.1172 45.0288 27.7973 45.2311C25.7547 45.5125 23.6954 45.6548 21.6336 45.6571C19.0079 45.6571 16.2281 45.6077 13.738 45.5213C11.6423 45.4472 9.75006 45.3793 8.32009 45.3669C8.13979 45.0657 7.995 44.7446 7.88863 44.4099C7.42636 43.0454 7.27226 41.0758 7.02572 38.1492L6.74219 34.7534C6.96445 34.4325 7.2031 34.1234 7.45717 33.8272C10.8472 30.2955 6.98257 29.5855 9.78088 27.0355C10.5909 26.527 11.2455 25.805 11.6731 24.9486C12.3388 22.8555 14.2372 22.1763 16.7705 22.5777C17.095 22.6308 17.426 22.6308 17.7505 22.5777C20.8755 21.5527 21.8124 23.6458 22.3424 24.43C23.0883 25.5413 23.9697 25.5413 24.7586 26.0229C27.9144 27.8752 26.9837 31.0426 26.9405 31.623C26.9384 31.809 26.9865 31.9922 27.0798 32.1531C27.1731 32.314 27.3082 32.4466 27.4706 32.5368C28.0037 32.7873 28.4978 33.1138 28.9376 33.5061L29.1656 36.2846C29.4368 39.6002 29.6094 41.6685 29.2396 43.1257Z' fill='#252040'/>
      <path d='M29.2389 43.1259C29.1267 43.6099 28.9169 44.0658 28.6225 44.4657C28.3965 44.7695 28.1165 45.029 27.7966 45.2313C26.6132 46.0216 24.7579 46.176 21.6884 46.4662L16.0178 46.9354C12.1347 47.2565 10.1932 47.417 8.87412 46.1389C8.64604 45.9215 8.45086 45.6719 8.29474 45.398C8.11444 45.0968 7.96965 44.7757 7.86328 44.441C9.37338 43.8853 11.2718 43.2494 12.936 42.8048C13.4537 42.669 13.9468 42.5455 14.3968 42.4529C14.8467 42.3603 15.1364 42.3109 15.4446 42.2738C15.8267 42.4467 17.0965 42.3788 18.471 42.2738H18.5511H18.6312C20.0057 42.1874 21.0967 42.0639 21.4665 41.8354H22.1445C22.7345 41.8585 23.3229 41.9121 23.9073 41.996C25.7086 42.2479 27.4901 42.6255 29.2389 43.1259Z' fill='#CC61FA'/>
      <path d='M21.7504 41.4714C21.3213 40.0676 21.0687 38.6157 20.9984 37.1494C19.5808 37.1494 18.2741 37.7297 16.8503 37.6927L15.0567 37.1123C15.254 38.7217 15.2062 40.3518 14.9149 41.9468C13.8116 42.4222 13.5281 45.4414 19.0507 45.0339C24.5734 44.6264 22.3791 41.6195 21.7504 41.4714Z' fill='#FCA190'/>
      <path d='M15.1683 39.526C17.332 40.5509 21.0738 38.5011 19.5204 38.6246C17.967 38.7481 15.082 37.8096 15.082 37.8096C15.1512 38.379 15.18 38.9525 15.1683 39.526Z' fill='#F07875'/>
      <path d='M24.096 30.5491C23.9101 30.4069 23.6827 30.3299 23.4488 30.3299C23.215 30.3299 22.9876 30.4069 22.8017 30.5491C22.4195 28.7648 21.7169 27.215 21.0204 26.5853C20.1944 25.795 18.746 24.7083 17.316 24.8071C15.886 24.9059 14.2342 26.3012 13.513 27.178C12.9398 27.9127 12.4837 29.5674 12.3789 31.4012C12.2795 31.3256 12.1659 31.2709 12.0449 31.2405C11.9238 31.21 11.7979 31.2045 11.6746 31.2241C11.5514 31.2437 11.4334 31.2881 11.3277 31.3546C11.2221 31.4211 11.131 31.5084 11.0599 31.6111C10.6407 32.1359 11.1585 33.661 12.5022 34.0808C12.5974 34.716 12.7671 35.3377 13.0076 35.9331C13.7287 37.6866 15.4053 39.0943 18.2282 38.94C21.0142 38.7177 22.475 37.0568 22.9126 35.2046C23.0556 34.5642 23.1259 33.9097 23.1222 33.2535C24.3241 32.5558 24.6261 30.9258 24.096 30.5491Z' fill='#FCA190'/>
      <path d='M15.8192 29.697C16.1891 29.9873 15.9055 30.0922 14.7405 30.222C14.3706 30.2652 14.087 30.327 13.9946 30.222C13.6925 29.9687 15.1165 29.3325 15.8192 29.697Z' fill='#252040'/>
      <path d='M19.0419 29.4622C18.7152 29.7957 19.0419 29.8637 20.1885 29.8266C20.5645 29.8266 20.8542 29.8266 20.9405 29.7587C21.1994 29.4313 19.6953 29.0298 19.0419 29.4622Z' fill='#252040'/>
      <path d='M17.7672 33.538C17.3542 33.5009 17.1077 33.4515 16.9967 33.2909C16.8857 33.1303 16.9967 32.9141 17.0522 32.735C17.1631 32.5486 17.2187 32.3343 17.2125 32.1174C17.2089 32.0636 17.2262 32.0106 17.2606 31.9692C17.295 31.9278 17.344 31.9012 17.3974 31.895C17.4509 31.8958 17.5025 31.9153 17.5431 31.9504C17.5836 31.9854 17.6106 32.0336 17.6193 32.0865C17.6053 32.3657 17.534 32.6391 17.4097 32.8894C17.3742 32.9263 17.3524 32.9743 17.3481 33.0253C17.4793 33.0852 17.6235 33.1107 17.7672 33.0994C17.7953 33.0976 17.8235 33.1025 17.8493 33.1136C17.8752 33.1247 17.8981 33.1418 17.9161 33.1635C17.9341 33.1852 17.9468 33.2108 17.9531 33.2383C17.9593 33.2658 17.959 33.2944 17.9522 33.3218C17.9547 33.3482 17.9519 33.3749 17.9439 33.4003C17.9359 33.4256 17.9229 33.4491 17.9056 33.4693C17.8883 33.4895 17.8672 33.5059 17.8434 33.5177C17.8196 33.5295 17.7937 33.5364 17.7672 33.538Z' fill='#F07875'/>
      <path d='M18.5507 27.036C18.6031 27.4507 18.8115 27.8298 19.1333 28.0959C19.4551 28.3621 19.8662 28.4953 20.2826 28.4684C20.6977 28.4207 21.0882 28.2465 21.4012 27.9694C21.7143 27.6922 21.9349 27.3254 22.0331 26.9187C22.1174 26.5074 22.1057 26.0822 21.9987 25.6763C21.8918 25.2703 21.6926 24.8946 21.4168 24.5786C20.869 23.9409 20.1365 23.4899 19.3211 23.2882C18.0893 22.978 16.7894 23.0884 15.6272 23.6017C14.465 24.115 13.507 25.002 12.9047 26.1222C12.1897 27.5484 12.35 28.9747 14.1375 28.95C14.9468 28.9534 15.7497 28.8047 16.5043 28.5116C16.8556 28.3573 18.5507 27.5484 18.5507 27.036Z' fill='#252040'/>
      <path d='M24.9717 43.978C20.0839 46.8922 17.1747 47.1207 12.4102 44.9659L13.384 41.0514C13.4316 40.8601 13.5195 40.6811 13.6419 40.5266C13.7642 40.3721 13.918 40.2456 14.0931 40.1556C14.2683 40.0655 14.4606 40.014 14.6572 40.0045C14.8538 39.9951 15.0502 40.0278 15.2331 40.1005C17.2474 40.7788 19.4458 40.6482 21.366 39.7363C21.6876 39.5868 22.0544 39.5676 22.3897 39.6827C22.7251 39.7977 23.0032 40.0381 23.1658 40.3537L24.9717 43.978Z' fill='#CC61FA'/>
      <path d='M12.9531 42.7741C12.9531 42.7741 17.8348 44.8425 23.943 41.9282C23.2095 42.6753 22.3404 43.4903 18.1861 43.9102C16.263 44.1016 13.1011 43.3668 12.9531 42.7741Z' fill='#811AC7'/>
      <path d='M16.2226 35.1608C15.9328 34.7101 16.2226 34.426 16.6356 34.3519C18.0287 34.142 19.2246 33.9753 19.5698 34.2099C20.3096 34.6915 19.0767 35.8214 17.9609 36.019C17.6217 36.0397 17.2829 35.9715 16.978 35.821C16.6732 35.6704 16.4128 35.4429 16.2226 35.1608Z' fill='white'/>
      <path d='M15.0456 31.0062C15.3908 30.9506 15.7237 31.2779 15.7854 31.7288C15.8018 31.8187 15.7981 31.9111 15.7746 31.9994C15.751 32.0877 15.7082 32.1697 15.6493 32.2394C15.5903 32.3091 15.5166 32.3648 15.4336 32.4026C15.3505 32.4403 15.2601 32.4591 15.1689 32.4576C14.8237 32.5132 14.5525 32.3835 14.5093 31.9326C14.4633 31.7388 14.4933 31.5346 14.5931 31.3623C14.6929 31.1899 14.8549 31.0624 15.0456 31.0062Z' fill='#252040'/>
      <path d='M19.9993 30.6294C19.6479 30.6294 19.3828 30.9876 19.3828 31.4447C19.3828 31.9018 19.6109 32.0623 20.0609 32.0623C20.4123 32.0623 20.6773 31.9079 20.6773 31.4447C20.6913 31.2474 20.6285 31.0523 20.5021 30.9003C20.3757 30.7483 20.1955 30.6512 19.9993 30.6294Z' fill='#252040'/>
      <path d='M33.0102 52.8216C37.3844 52.8216 40.9305 49.2667 40.9305 44.8815C40.9305 40.4963 37.3844 36.9414 33.0102 36.9414C28.6359 36.9414 25.0898 40.4963 25.0898 44.8815C25.0898 49.2667 28.6359 52.8216 33.0102 52.8216Z' fill='#66ADFF'/>
      <path d='M33.0147 55.3656C30.4233 55.3736 27.9209 54.4195 25.9907 52.6874C24.0605 50.9554 22.8395 48.5683 22.5635 45.9872C22.2875 43.4061 22.9762 40.8141 24.4964 38.7118C26.0166 36.6096 28.2606 35.1462 30.7949 34.6043C33.3292 34.0624 35.9741 34.4805 38.2187 35.7778C40.4633 37.075 42.1483 39.1595 42.9485 41.6285C43.7486 44.0976 43.607 46.776 42.551 49.1466C41.495 51.5171 39.5995 53.4116 37.2307 54.4641C35.9029 55.0541 34.4672 55.361 33.0147 55.3656ZM33.0147 35.3053C30.952 35.3004 28.943 35.9635 27.2872 37.1957C25.6314 38.4279 24.4176 40.1632 23.8267 42.1429C23.2358 44.1225 23.2996 46.2405 24.0085 48.1809C24.7173 50.1213 26.0333 51.7802 27.7602 52.9102C29.4871 54.0403 31.5324 54.5809 33.591 54.4515C35.6497 54.322 37.6114 53.5295 39.1837 52.1921C40.7561 50.8546 41.8547 49.0439 42.3158 47.03C42.7769 45.016 42.5758 42.9067 41.7425 41.0165C41.2401 39.8646 40.5146 38.8239 39.6078 37.9549C38.7011 37.0858 37.6312 36.4056 36.4602 35.9536C35.3593 35.5267 34.1891 35.3069 33.0086 35.3053H33.0147Z' fill='#66ADFF'/>
      <path d='M36.2337 40.8929C36.092 40.8003 34.7791 42.6897 34.7914 43.1589C34.7914 44.1962 34.2922 45.0853 33.7251 44.3938C33.6594 44.1944 33.6241 43.9862 33.6203 43.7763H33.7374C33.8286 43.7509 33.9104 43.6993 33.9728 43.6281C34.0352 43.5568 34.0755 43.4689 34.0888 43.375C34.0936 43.2606 34.0614 43.1477 33.997 43.0531C33.9326 42.9585 33.8394 42.8874 33.7313 42.8502C33.8052 42.6156 33.7867 42.2636 33.6388 42.2328C33.4909 42.2019 32.7204 42.5415 32.7451 42.3933C32.7882 42.1463 32.7451 41.8993 32.5848 41.8561C32.4246 41.8129 32.2705 41.9364 32.0979 41.992C31.9253 42.0475 31.7466 41.992 31.6048 42.0722C31.463 42.1525 31.4815 42.4612 31.6356 42.6588C31.7897 42.8564 30.9083 42.8564 30.8097 42.9922C30.7111 43.128 30.8713 43.4306 31.0501 43.6096C30.9737 43.6922 30.9278 43.7984 30.92 43.9107C30.9121 44.023 30.9428 44.1346 31.0069 44.227C31.0633 44.3024 31.1414 44.3587 31.2306 44.3885C31.3198 44.4183 31.4159 44.4201 31.5062 44.3938C31.5423 44.3852 31.5775 44.3727 31.611 44.3567C31.7215 44.548 31.7986 44.7568 31.839 44.9741C31.6788 45.8385 30.785 45.3014 30.2858 44.3567C30.0639 43.9492 27.9004 43.2762 27.8203 43.4244C27.8933 44.5641 28.3371 45.6485 29.0839 46.5115C29.7742 47.3512 31.2042 48.1539 32.4677 47.129C32.7513 47.7464 32.0548 47.9625 32.1657 48.2403C32.6033 49.3332 33.9285 48.9751 33.9285 48.9751C33.9285 48.9751 35.2599 48.617 35.0996 47.4562C35.0565 47.1598 34.2798 47.3265 34.2798 46.6412C34.5979 46.6899 34.9226 46.6715 35.2332 46.5872C35.5438 46.5029 35.8334 46.3546 36.0834 46.1518C36.3335 45.949 36.5385 45.696 36.6854 45.4093C36.8323 45.1225 36.9178 44.8081 36.9364 44.4864C37.1398 41.8191 36.394 41.0164 36.2337 40.8929Z' fill='white'/>
    </svg>);
};
export default StudentId;
