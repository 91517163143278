import React from 'react';
import { LeftRightPaddingWrapper } from '../left-right-padding-wrapper';
import { PhotoFormatText } from '../photo-format-text';
import styles from './styles.module.scss';
export const PhoneLayout = (props) => {
    const { icon, title, subtitle, button, banner, preview } = props;
    return (<LeftRightPaddingWrapper>
      <div className={styles.phoneLayout}>
        <div className={styles.main}>
          <div className={styles.makePhotoBlock}>
            {icon}
            <span className={styles.title}>{title}</span>
            <span className={styles.subtitle}>{subtitle}</span>
          </div>
          {banner}
          {preview}
        </div>
        <div className={styles.footer}>
          {button}
          <div className={styles.description}>
            <PhotoFormatText />
          </div>
        </div>
      </div>
    </LeftRightPaddingWrapper>);
};
