import waitForResult from '@common/helpers/waitForResult';
import apiVkBridge from '@common/utils/ApiVkBridge';
const MAX_ATTEMPTS_COUNT = 10;
export const bridgeAuth = () => new Promise((resolve, reject) => {
    let getSilentTokenAttemptsCount = 0;
    waitForResult(apiVkBridge.getSilentToken, {
        params: [],
        handleResponse: (response) => {
            if (response.tokens[0]) {
                const { uuid, token } = response.tokens[0];
                resolve({ uuid, token });
                return false;
            }
            if (getSilentTokenAttemptsCount >= MAX_ATTEMPTS_COUNT) {
                reject();
                return false;
            }
            getSilentTokenAttemptsCount++;
            return true;
        },
        handleError: (error) => {
            if (getSilentTokenAttemptsCount >= MAX_ATTEMPTS_COUNT) {
                reject(error);
                return false;
            }
            getSilentTokenAttemptsCount++;
            return true;
        },
        callInterval: 500,
    });
});
