import { createEffect } from 'effector/effector.umd';
import { createOffers } from '@common/entities/offers/model';
import { StudentProfileApi } from '@website/template/profile-template/api/student';
import { SubscriptionProfileApi } from '@website/template/profile-template/api/subscription';
import { paymentMethodModel } from '@common/data-layer/payment-method/model';
import { OFFERS_KEY_WORD_NEXT_WEBSITE } from '@website/shared/constants/website-keywords';
import { comboWidgetsModel } from '@common/data-layer/combo-widgets';
import { SubscriptionsModel } from '@common/entities/subscriptions';
import { StudentApi } from '@common/entities/student';
const KEYWORDS = [OFFERS_KEY_WORD_NEXT_WEBSITE];
const { $offers, updateOffers, getOffersFx, $pendingGetOffers } = createOffers();
const initProfileTemplateFx = createEffect(async () => {
    void paymentMethodModel.getPaymentMethodFx();
    void SubscriptionsModel.getSubscriptionsAndSubscriptionsStatusFx();
    void getOffersFx({ keywords: KEYWORDS, useUserCountry: true });
    void comboWidgetsModel.getComboWidgetsFx();
    void StudentApi.getStudentsStatusFx();
});
export const getNextOffersFx = createEffect(async () => {
    await getOffersFx({ keywords: KEYWORDS, useUserCountry: true });
});
export const ProfileTemplateApi = {
    initProfileTemplateFx,
    $offers,
    $pendingGetOffers,
    updateOffers,
    getNextOffersFx,
    getOffersFx,
    OFFERS_KEY_WORD_NEXT_WEBSITE,
    pullingSubscriptionDataFx: SubscriptionProfileApi.pullingSubscriptionDataFx,
    pullingStudentStatusDataFx: StudentProfileApi.pullingStudentStatusDataFx,
};
