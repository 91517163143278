import { createEffect } from 'effector';
import { checkStudentSubscription } from '../check-student-subscription';
import { showActiveStudentSubscriptionModal } from '../../lib';
import { studentFlowModel } from '../student-flow';
export const createCheckSubscriptionAndCheckStudentStatusFx = () => createEffect(async (payload) => {
    const { statusData, callbackAfterFinishedFlow, activeSubscription } = payload;
    if (checkStudentSubscription(activeSubscription)) {
        showActiveStudentSubscriptionModal();
        callbackAfterFinishedFlow && callbackAfterFinishedFlow();
        return Promise.reject();
    }
    if (statusData?.status !== 'READY') {
        studentFlowModel.startFlow({ callbackAfterFinishedFlow });
        return Promise.reject();
    }
});
