import React, { useEffect, useState } from 'react';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import { useUnit } from 'effector-react';
import useCallUnmountCallbacks from '@common/hooks/useCallUnmountCallbacks';
import { KeywordsKeeper } from '@common/entities/offers';
import apiCommon from '@common/utils/ApiCommon';
import waitForResult from '@common/helpers/waitForResult';
import ModalButton from '@common/components/modals/ModalButton';
import Typograf from '@common/components/common/Typograf';
import { useGetSubscriptionDetailsContext } from '@common/entities/subscriptions/lib/modals/show-subscription-details/lib/context';
import { renewInactiveSubscriptionModel } from '@common/features/renew-inactive-subscription/model';
import { Button } from '@vkontakte/vkui';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import messages from './messages';
import useIsSmallScreen from '@common/hooks/useIsSmallScreen';
import { showSubscriptionRecoverySuccessModal, showSubscriptionRecoveryErrorModal } from '@common/entities/subscriptions';
const InactiveRenewButton = ({ vkUiBtn = false }) => {
    const props = useGetSubscriptionDetailsContext();
    const isSmallScreen = useIsSmallScreen();
    const { subscriptionId, subscription, updateSubscriptionsWithoutReload, renewInactiveSubscription } = props;
    const [offersToRestore, setOffersToRestore] = useState([]);
    const [state, setState] = useState('initial');
    const [isApplyingOfferInProgress, setIsApplyingOfferInProgress] = useState(false);
    const [inactiveSubscriptionRenewStatus, getInactiveSubscriptionRenewStatus] = useUnit([
        renewInactiveSubscriptionModel.$inactiveSubscriptionRenewStatus,
        renewInactiveSubscriptionModel.getInactiveSubscriptionRenewStatusFx,
    ]);
    const addUnmountCallback = useCallUnmountCallbacks();
    const intl = useIntl();
    const loadOffers = async () => {
        try {
            const offersKey = await KeywordsKeeper.getKeywordsByDictionaryKey('subscriptionDetails');
            const offers = await apiCommon.getOffers({ keywords: [offersKey] });
            if (offers.data.success && offers.data.payload) {
                setOffersToRestore(offers.data.payload);
            }
        }
        catch { }
        setState('unavailable');
    };
    const renewSubscription = () => {
        if (state !== 'available') {
            return;
        }
        setState('in_progress');
        const clear = waitForResult(apiCommon.inactiveSubscriptionRenew, {
            params: [subscription.own ? undefined : { vk_subscription_id: subscriptionId }],
            handleResponse: async (response) => {
                const renewResult = response.data;
                if (renewResult.successful) {
                    const { data } = renewResult;
                    switch (data.status) {
                        case 'process': {
                            const clear = waitForResult(apiCommon.getSubscriptionStatus, {
                                params: [],
                                handleResponse: async (response) => {
                                    if (response.data.status_subscription_const === 'ACTIVE') {
                                        await updateSubscriptionsWithoutReload()
                                            .then(() => {
                                            showSubscriptionRecoverySuccessModal();
                                        })
                                            .catch(() => {
                                            showSubscriptionRecoveryErrorModal();
                                        })
                                            .finally(() => {
                                            setState('idle');
                                        });
                                        return false;
                                    }
                                    return true;
                                },
                                handleError: () => {
                                    return true;
                                },
                            });
                            addUnmountCallback(clear);
                            return false;
                        }
                        case 'need_direct_payment': {
                            if (data.order.payable) {
                                renewInactiveSubscription(data.order.uuid);
                            }
                            else {
                                await updateSubscriptionsWithoutReload()
                                    .then(() => {
                                    showSubscriptionRecoverySuccessModal();
                                })
                                    .catch(() => {
                                    showSubscriptionRecoveryErrorModal();
                                });
                            }
                            setState('available');
                            return false;
                        }
                        case 'fail': {
                            showSubscriptionRecoveryErrorModal();
                            return false;
                        }
                        case 'unavailable': {
                            void loadOffers();
                            return false;
                        }
                    }
                }
                return true;
            },
            handleError: () => {
                void loadOffers();
                return false;
            },
        });
        addUnmountCallback(clear);
    };
    const applyPublicOffer = async (args) => {
        try {
            const { applyPublicOffer } = props;
            setIsApplyingOfferInProgress(true);
            await applyPublicOffer(args);
        }
        finally {
            setIsApplyingOfferInProgress(false);
        }
    };
    useEffect(() => {
        setState('check_is_available');
        const clear = waitForResult(getInactiveSubscriptionRenewStatus, {
            params: [],
            handleResponse: (response) => {
                const checkResult = response;
                if (checkResult === 'restorable') {
                    setState('available');
                }
                else {
                    void loadOffers();
                }
                return false;
            },
            handleError: () => {
                void loadOffers();
                return false;
            },
        });
        return () => {
            clear();
        };
    }, []);
    if (state === 'unavailable') {
        const offerToRestore = offersToRestore[0];
        if (!offerToRestore) {
            return null;
        }
        const button = offerToRestore?.widgets?.offer?.buttons[0];
        if (!button) {
            return null;
        }
        return vkUiBtn ? (<Button stretched={isSmallScreen} size={'s'} {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionManagementButtonRestoreClick(button.metrics))} loading={isApplyingOfferInProgress} disabled={isApplyingOfferInProgress} onClick={() => applyPublicOffer({
                correlationId: offerToRestore.correlationId,
                button,
            })}>
        <Typograf>{button.name}</Typograf>
      </Button>) : (<ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionManagementButtonRestoreClick(button.metrics))} stretched={true} loading={isApplyingOfferInProgress} size={'s'} disabled={isApplyingOfferInProgress} onClick={() => applyPublicOffer({
                correlationId: offerToRestore.correlationId,
                button,
            })}>
        <Typograf>{button.name}</Typograf>
      </ModalButton>);
    }
    const isLoading = state === 'initial' || state === 'check_is_available' || state === 'in_progress';
    const isDisabled = state === 'initial' || state === 'check_is_available' || state === 'in_progress';
    return (!isLoading &&
        (vkUiBtn ? (<Button {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionManagementButtonRestoreClick([]))} stretched={isSmallScreen} size={'s'} loading={isLoading} disabled={isDisabled} onClick={renewSubscription} className={styles.button}>
        {inactiveSubscriptionRenewStatus === 'restorable'
                ? intl.formatMessage(messages.recoverSubBtnText)
                : intl.formatMessage(messages.buySubBtnText, { price: offersToRestore?.[0]?.with?.price })}
      </Button>) : (<ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionManagementButtonRestoreClick([]))} stretched={true} loading={isLoading} size={'s'} disabled={isDisabled} onClick={renewSubscription}>
        {inactiveSubscriptionRenewStatus === 'restorable'
                ? intl.formatMessage(messages.recoverSubBtnText)
                : intl.formatMessage(messages.buySubBtnText, { price: offersToRestore?.[0]?.with?.price })}
      </ModalButton>)));
};
export default InactiveRenewButton;
