import { useState, useMemo, useEffect } from 'react';
export const useEffectOnceByCondition = (fn, dependencyListFromParams, wasHandledDefault = false) => {
    const [wasHandled, setWasHandled] = useState(wasHandledDefault);
    const dependencyList = useMemo(() => [wasHandled].concat(dependencyListFromParams), [dependencyListFromParams]);
    useEffect(() => {
        if (wasHandled) {
            return;
        }
        const wasHandledAfter = fn();
        if (wasHandledAfter) {
            setWasHandled(wasHandledAfter);
        }
    }, dependencyList);
};
