import { VK_BRIDGE_TIMEOUT_ERROR } from '@common/utils/ApiVkBridge/consts';
import { analyticsEvent } from '@common/entities/auth-vkid/lib/analytics';
export const processingAuthErrorsVkId = (error, { driver }) => {
    if (driver === 'bridge') {
        switch (true) {
            case error.name === VK_BRIDGE_TIMEOUT_ERROR: {
                analyticsEvent({
                    result: 'fail',
                    auth_type: driver,
                    error_type: 'timeout_error',
                });
                return;
            }
            case error?.data?.error_type === 'auth_error': {
                analyticsEvent({
                    result: 'fail',
                    auth_type: driver,
                    error_type: 'auth_error',
                    error_message: error?.data?.error_data?.error,
                    error_code: error?.data?.error_data?.error_description,
                });
                return;
            }
            case error?.data?.error_type === 'client_error': {
                analyticsEvent({
                    result: 'fail',
                    auth_type: driver,
                    error_type: 'auth_error',
                    error_message: error?.data?.error_data?.error_reason,
                    error_code: error?.data?.error_data?.error_code,
                });
                return;
            }
            default: {
                analyticsEvent({
                    result: 'fail',
                    auth_type: driver,
                    error_type: 'unknown_error',
                });
            }
        }
        return;
    }
    analyticsEvent({
        result: 'fail',
        auth_type: driver,
        error_type: 'vkid_error',
    });
};
