import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    headerFor423: {
        id: 'header',
        defaultMessage: 'Проводим технические работы',
    },
    subheaderFor423: {
        id: 'subheader',
        defaultMessage: 'Сейчас кое-что поправим, чтобы стало ещё лучше.{br}Попробуйте зайти позже.',
    },
    btnTextFor423: {
        id: 'btnText',
        defaultMessage: 'Понятно',
    },
    headerFor429: {
        id: 'header',
        defaultMessage: 'Упс',
    },
    btnTextFor429: {
        id: 'btnText',
        defaultMessage: 'Понятно',
    },
    textErrorWithoutTimestampFor429: {
        id: 'textErrorWithoutTimestampFor429',
        defaultMessage: 'Ваши запросы похожи на автоматические, повторите попытку попытку позже.',
    },
    textErrorFor429: {
        id: 'textErrorWithoutTimestampFor429',
        defaultMessage: 'Ваши запросы похожи на автоматические, попробуйте следующую попытку {afterTxt}.',
    },
});
