import { StartStudentFlowModel } from '@common/features/start-student-flow';
import { ApplyOfferModel } from '@website/features/apply-offer';
import { showMessage } from './showMessage';
import { redirect } from './redirect';
import { processingBackendErrors } from './processing-backend-errors';
const student = () => {
    StartStudentFlowModel.startFlow({
        callbackAfterFinishedFlow: () => {
            ApplyOfferModel.setReady();
        },
    });
};
export const userFlowManagerModel = {
    redirect,
    showMessage,
    processingBackendErrors,
    student,
};
