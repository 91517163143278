import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    unlinkErrorTitle: {
        id: 'unlinkErrorTitle',
        defaultMessage: 'Не удалось отвязать способ оплаты',
    },
    unlinkErrorDescription: {
        id: 'unlinkErrorDescription',
        defaultMessage: 'Ваш способ оплаты продолжит действовать',
    },
    unlinkErrorCloseModalBtnText: {
        id: 'unlinkErrorCloseModalBtnText',
        defaultMessage: 'Хорошо',
    },
    unlinkSuccessTitle: {
        id: 'unlinkSuccessTitle',
        defaultMessage: 'Способ оплаты отвязан',
    },
    unlinkSuccessDescription: {
        id: 'unlinkSuccessDescription',
        defaultMessage: 'Подписка остановлена. Чтобы оформить подписку снова, выберите любой удобный способ оплаты.',
    },
    unlinkSuccessCloseModalBtnTxt: {
        id: 'unlinkSuccessCloseModalBtnTxt',
        defaultMessage: 'Хорошо',
    },
});
