import { createEvent, createStore } from 'effector';
import { createFilePreview } from '@common/shared/lib';
const DEFAULT_VALUE = {
    STUDENT_ID: {
        file: null,
        wasSuccessSendDocument: false,
    },
    SELFIE: {
        file: null,
        wasSuccessSendDocument: false,
    },
    RECORD_BOOK: {
        file: null,
        wasSuccessSendDocument: false,
    },
    SELFIE_WITH_RECORD_BOOK: {
        file: null,
        wasSuccessSendDocument: false,
    },
};
const $state = createStore(DEFAULT_VALUE);
const addDocument = createEvent();
const deleteDocument = createEvent();
const clear = createEvent();
const $previewList = $state.map((state) => Object.values(state)
    .map((item) => createFilePreview(item.file))
    .filter(Boolean));
$state.on(addDocument, (prevState, newState) => ({
    ...prevState,
    [newState.documentType]: { ...prevState[newState.documentType], ...newState.payload },
}));
$state.on(deleteDocument, (state, documentType) => ({ ...state, [documentType]: DEFAULT_VALUE[documentType] }));
$state.reset(clear);
export const uploadDocumentModel = {
    $state,
    $previewList,
    addDocument,
    deleteDocument,
    clear,
};
