import { attach, createStore, sample } from 'effector';
import { createOffers } from '@common/entities/offers/model';
import { KeywordsKeeper } from '@common/entities/offers/lib/keywords-keeper';
let KEYWORD = '';
KeywordsKeeper.getKeywordsByDictionaryKey('studentStatusInfoBannerKeyword').then((value) => (KEYWORD = value));
const { getOffersFx, $offers } = createOffers();
export const $studentOffer = createStore(null);
export const getStudentOffersFx = attach({
    effect: getOffersFx,
    mapParams: () => {
        return { keywords: [KEYWORD] };
    },
});
sample({ clock: $offers, fn: (offers) => (offers?.success ? offers.payload[0] ?? null : null), target: $studentOffer });
export const studentOfferModel = {
    $studentOffer,
    getStudentOffersFx,
};
