import { defineMessages } from '@formatjs/intl';
export const messages = defineMessages({
    title: {
        id: 'title',
        defaultMessage: 'Вы действительно хотите удалить участника?',
    },
    description: {
        id: 'description',
        defaultMessage: '{name} потеряет доступ к подписке и не сможет пользоваться её преимуществами',
    },
    button_confirm: {
        id: 'button_confirm',
        defaultMessage: 'Удалить',
    },
    button_cancel: {
        id: 'button_confirm',
        defaultMessage: 'Отмена',
    },
    remove_member_success_message: {
        id: 'remove_member_success_message',
        defaultMessage: 'Участник удален',
    },
    remove_member_error_message: {
        id: 'remove_member_error_message',
        defaultMessage: 'Пользователь не был удалён',
    },
});
