import React from 'react';
import classNames from 'classnames';
import { Button } from '@vkontakte/vkui';
import Typograf from '@common/components/common/Typograf';
import styles from './styles.module.scss';
const defaultProps = {
    size: 'l',
    stretched: true,
};
const ModalButton = (props) => {
    const { className, ...restProps } = props;
    return (<Button {...defaultProps} {...restProps} className={classNames(styles.button, className)}>
      <Typograf>{props.children}</Typograf>
    </Button>);
};
export default ModalButton;
