import { defineMessages } from 'react-intl';
const messages = defineMessages({
    activeStatusText: {
        id: 'activeStatusText',
        defaultMessage: 'Активна',
    },
    autoRenewCancelledStatusText: {
        id: 'autoRenewCancelledStatusText',
        defaultMessage: 'Активна, не продлевается',
    },
    inactiveStatusText: {
        id: 'inactiveStatusText',
        defaultMessage: 'Остановлена',
    },
    suspendedStatusText: {
        id: 'suspendedStatusText',
        defaultMessage: 'Приостановлена',
    },
    waitingStatusText: {
        id: 'waitingStatusText',
        defaultMessage: 'В ожидании',
    },
});
export default messages;
