import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    headerDefault: {
        id: 'headerDefault',
        defaultMessage: 'Ошибка',
    },
    subheaderDefault: {
        id: 'subheaderDefault',
        defaultMessage: 'Попробуйте ещё раз',
    },
});
