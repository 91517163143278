import React from 'react';
import { ButtonGroup } from '@vkontakte/vkui';
import { analyticsDictionary } from '@analytics';
import parse from 'html-react-parser';
import { typografIt } from '@common/components/common/Typograf';
import ModalButton from '@common/components/modals/ModalButton';
import { modalManagerModel } from '../../../model';
import { messages } from './messages';
import styles from './styles.module.scss';
import isArray from 'lodash/isArray';
export const showErrorModal = (params) => {
    const { title, description, errors, checkoutType, buttons, buttonClassName } = params;
    const closeModal = () => {
        modalManagerModel.closeModal();
    };
    const goTo = (href) => {
        closeModal();
        if (typeof href === 'string') {
            location.href = href;
        }
    };
    const titleParsed = title && parse(typografIt(title));
    const descriptionParsed = description && parse(typografIt(description));
    const header = titleParsed || typografIt(messages.headerDefault.defaultMessage);
    let subheader = descriptionParsed || typografIt(messages.subheaderDefault.defaultMessage);
    if (errors && errors.length > 0) {
        subheader = (<div>
        {errors.map((error, index) => {
                return (<div key={index} className={styles.error}>
              {parse(typografIt(error))}
            </div>);
            })}
      </div>);
    }
    const defaultButton = (<ModalButton className={buttonClassName} onClick={closeModal}>
      Хорошо
    </ModalButton>);
    let button = defaultButton;
    if (buttons && buttons.length > 0) {
        const btn = buttons[0];
        switch (btn?.type) {
            case 'redirect': {
                const url = !isArray(btn?.params) && btn.params.url;
                button = (<ModalButton className={buttonClassName} onClick={() => goTo(url)}>
            {btn.name}
          </ModalButton>);
                break;
            }
            default: {
                button = defaultButton;
            }
        }
    }
    modalManagerModel.showModal({
        analyticShowAttribute: analyticsDictionary.modalCheckoutFailShow(checkoutType),
        mode: 'error',
        header,
        subheader,
        actions: (<ButtonGroup stretched={true} mode='vertical'>
        {button}
      </ButtonGroup>),
    });
};
