import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import useIsSmallScreen from '@common/hooks/useIsSmallScreen';
import { footerModel } from '../models/footer-model';
export const useOffFooterModalOnSmallScreen = () => {
    const footerState = useUnit({ ...footerModel });
    const isSmallScreen = useIsSmallScreen();
    useEffect(() => {
        if (isSmallScreen) {
            footerState.setState({ offFooter: true });
        }
        return () => {
            footerState.setState({ offFooter: false });
        };
    }, [isSmallScreen]);
};
