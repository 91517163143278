import { useStoreMap } from 'effector-react';
import { Tariffs } from '@common/entities/offers';
import { ProfileTemplateModel } from '../../model';
const Offers = () => {
    const offers = useStoreMap(ProfileTemplateModel.$offers, (state) => {
        if (!state) {
            return [];
        }
        if (!state.success) {
            return [];
        }
        return state.payload;
    });
    return <Tariffs offers={offers}/>;
};
export default Offers;
