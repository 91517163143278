import React, { useEffect, useState } from 'react';
import { ButtonGroup } from '@vkontakte/vkui';
import { createAnalyticClickAttribute } from '@analytics';
import { useUnit } from 'effector-react';
import ModalButton from '@common/components/modals/ModalButton';
import { analyticsDictionary } from '@common/features/group-subscription/lib';
import { applyOffer } from '@common/components/Root/actions';
import { KeywordsKeeper } from '@common/entities/offers';
import { offersModel } from './model';
import { useIntl } from 'react-intl';
import messages from './messages';
const SuccessCancelGroupSubscriptionActions = () => {
    const $pendingGetOffers = useUnit(offersModel.$pendingGetOffers);
    const $offers = useUnit(offersModel.$offers);
    const [loading, setLoading] = useState(true);
    const [showActionButton, setShowActionButton] = useState(true);
    const getOffers = async () => {
        const offersKey = await KeywordsKeeper.getKeywordsByDictionaryKey('cancelGroupSubscriptionSuccessKeyword');
        await offersModel.getOffersFx({ keywords: [offersKey] });
    };
    const intl = useIntl();
    useEffect(() => {
        void getOffers();
    }, []);
    useEffect(() => {
        setLoading($pendingGetOffers);
    }, [$pendingGetOffers]);
    useEffect(() => {
        setShowActionButton(loading || !!($offers?.success && $offers.payload[0]?.with.price));
    }, [loading, $offers]);
    const applyOfferHandler = async () => {
        if (!$offers?.success) {
            return;
        }
        const correlationId = $offers.payload[0]?.correlationId;
        if (!correlationId) {
            return;
        }
        await applyOffer({
            correlationId,
            button: {
                type: 'purchase',
            },
        });
    };
    const onClick = async () => {
        setLoading(true);
        try {
            await applyOfferHandler();
        }
        finally {
            setLoading(false);
        }
    };
    return (<ButtonGroup mode='vertical' gap='m' stretched={true}>
      {showActionButton && (<ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalConfirmBuyButtonConfirmClick)} size={'l'} stretched={true} loading={loading} disabled={loading} onClick={() => void onClick()}>
          {intl.formatMessage(messages.modalButtonText, { price: $offers?.success && $offers.payload[0]?.with.price })}
        </ModalButton>)}
    </ButtonGroup>);
};
export default SuccessCancelGroupSubscriptionActions;
