import React from 'react';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import { useIntl } from 'react-intl';
import { useUnit } from 'effector-react';
import useIsIosOrAndroid from '@common/hooks/useIsIosOrAndroid';
import useIsSmallScreen from '@common/hooks/useIsSmallScreen';
import { modalManagerModel } from '@common/services/modal-manager';
import ModalButton from '@common/components/modals/ModalButton';
import { messages } from '@common/entities/checkout/messages';
import { showCheckoutModalModelV2 } from '../model';
import styles from './styles.module.scss';
export const Footer = (props) => {
    const { type } = props;
    const intl = useIntl();
    const [status] = useUnit([showCheckoutModalModelV2.$status]);
    const isIosOrAndroid = useIsIosOrAndroid();
    const isSmallScreen = useIsSmallScreen();
    const withButtonInHeader = isIosOrAndroid && isSmallScreen;
    const isGettingDataInProgress = status === 'process';
    const onClose = () => {
        modalManagerModel.closeModal();
    };
    return (<>
      {!withButtonInHeader && (<div className={styles.buttonContainer}>
          <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalCheckoutFormButtonCancelClick(type))} stretched={true} mode='secondary' disabled={isGettingDataInProgress} loading={isGettingDataInProgress} onClick={onClose}>
            {intl.formatMessage(messages.closeCheckoutModalBtnTitle)}
          </ModalButton>
        </div>)}
    </>);
};
