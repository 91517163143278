export const analyticsDictionary = {
    modalConfirmBuyShow: 'modal_group_subscription_confirm_buy',
    modalConfirmBuyButtonCancelClick: 'modal_group_subscription_confirm_buy-button_cancel',
    modalConfirmBuyButtonConfirmClick: 'modal_group_subscription_confirm_buy-button_confirm',
    modalConfirmLeaveShow: 'modal_group_subscription_confirm_leave',
    modalConfirmLeaveButtonCancelClick: 'modal_group_subscription_confirm_leave-button_cancel',
    modalConfirmLeaveButtonConfirmClick: 'modal_group_subscription_confirm_leave-button_confirm',
    modalConfirmLeaveSuccessShow: 'modal_group_subscription_confirm_leave_success',
    modalConfirmLeaveSuccessButtonPayClick: 'modal_group_subscription_confirm_leave_success-button_pay',
    modalConfirmLeaveSuccessButtonConfirmClick: 'modal_group_subscription_confirm_leave-button_confirm',
    modalAcceptInvitationShow: 'modal_group_subscription_accept_invitation',
    modalAcceptInvitationShowSuccess: 'modal_group_subscription_accept_invitation_success',
    modalAcceptInvitationShowError: 'modal_group_subscription_accept_invitation_error',
    modalAcceptInvitationShowCanselError: 'modal_group_subscription_accept_invitation_cansel_error',
    modalAcceptInvitationButtonCancelClick: 'modal_group_subscription_accept_invitation-button_cancel',
    modalAcceptInvitationButtonConfirmClick: 'modal_group_subscription_accept_invitation-button_confirm',
    modalSendInviteShow: 'modal_group_subscription_send-invite',
    modalSendInviteButtonCancelClick: 'modal_group_subscription_send-invite-button_cancel',
    modalSendInviteButtonConfirmClick: 'modal_group_subscription_send-invite-button_confirm',
};
