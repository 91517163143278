import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getMessagesFromCurrentLocale } from '@common/shared/messages/get-messages';
i18n.use(initReactI18next).init({
    lng: 'ru',
    resources: {
        ru: { ...getMessagesFromCurrentLocale('ru') },
    },
});
export default i18n;
