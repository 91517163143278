import { useUnit } from 'effector-react';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { usePlatform } from '@vkontakte/vkui';
import { analyticsDictionary, useAnalyticShowDirect } from '@analytics';
import waitForResult from '@common/helpers/waitForResult';
import { paymentMethodModel, paymentMethodModelApi, showSuccessfulPaymentModal, } from '@common/data-layer/payment-method';
import { subscriptionsApi } from '@common/entities/subscriptions';
import useCallUnmountCallbacks from '@common/hooks/useCallUnmountCallbacks';
import { getDataPulling } from '@common/components/Root/actions';
import { errorLog } from '@common/shared/lib/utils';
import { modalManagerModel, modalManagerTools } from '@common/services/modal-manager';
import { appEvents } from '@common/utils/apps-events';
import { CallAfterBoundCardModel, checkoutModel } from '../model';
import { isPaymentData } from '../lib';
import { messages } from '../messages';
import { commonShowSuccessfulPaymentModalParams } from './lib';
export const useCheckout = (params) => {
    const { paymentId, specification, payload, type, handleSuccess, handleError } = params;
    const intl = useIntl();
    const addUnmountCallback = useCallUnmountCallbacks();
    const platform = usePlatform();
    const [checkoutData, checkoutModelStatus, getCheckoutData, setCheckoutData, getPaymentMethod] = useUnit([
        checkoutModel.$checkoutData,
        checkoutModel.$status,
        checkoutModel.getCheckoutDataFx,
        checkoutModel.setCheckoutData,
        paymentMethodModel.getPaymentMethodFx,
    ]);
    useEffect(() => {
        if (paymentId !== undefined) {
            void getCheckoutData(paymentId);
            return;
        }
        else if (specification !== undefined && payload !== undefined) {
            setCheckoutData({ specification, payload });
            return;
        }
        errorLog('You should pass "paymentId" or "specification" AND "payload" to useCheckout hook');
    }, [paymentId, payload]);
    const paymentData = isPaymentData(checkoutData) ? checkoutData : null;
    let title = intl.formatMessage(messages.checkoutTitleVersionPay);
    const subtitle = paymentData?.specification;
    const iframeUrl = paymentData?.payload;
    if (type === 'paymethod' || type === 'bound_card_before_action') {
        title = intl.formatMessage(messages.checkoutTitleVersionPaymentMethod);
    }
    const getAppData = () => getDataPulling({ subscriptions: true });
    const onCloseHandle = () => {
        modalManagerModel.closeModal();
    };
    const onShowSuccessfulPaymentModal = (params) => {
        showSuccessfulPaymentModal(params);
    };
    const onShowAlertModal = (params) => {
        return modalManagerTools.modalTemplates.showMessageModal(params);
    };
    const onPaySuccess = () => {
        if (handleSuccess) {
            void handleSuccess({
                type,
                closeModal: onCloseHandle,
            });
            return;
        }
        if (type === 'offer') {
            appEvents.callEvent('offerPaySuccess');
            const clear = waitForResult(subscriptionsApi.getSubscriptionStatus, {
                params: [],
                handleResponse: async (params) => {
                    if (!params.data.status_subscription_const) {
                        return false;
                    }
                    const status = params.data.status_subscription_const;
                    if (status === 'FORMED' || status === 'ACTIVE' || status === 'TRIAL') {
                        await getAppData();
                        appEvents.callEvent('subscriptionActivated');
                        onShowSuccessfulPaymentModal({ ...commonShowSuccessfulPaymentModalParams(type), platform });
                        return false;
                    }
                    return true;
                },
                handleError: () => false,
                callInterval: 3000,
            });
            addUnmountCallback(clear);
            return;
        }
        if (type === 'paymethod') {
            const clear = waitForResult(paymentMethodModel.getPaymentMethodFx, {
                params: [],
                handleResponse: async (params) => {
                    const status = params?.status;
                    if (status === 'bind') {
                        await getAppData();
                        onShowAlertModal({
                            analyticShowAttribute: analyticsDictionary.modalCheckoutSuccessShow(type),
                            mode: 'success',
                            header: intl.formatMessage(messages.paymethodBindedTitle),
                            subheader: intl.formatMessage(messages.paymethodBindedDescription),
                        });
                        return false;
                    }
                    return true;
                },
                handleError: () => false,
                callInterval: 3000,
            });
            addUnmountCallback(clear);
            return;
        }
        if (type === 'bound_card_before_action') {
            const clear = waitForResult(paymentMethodModelApi.getPaymentsMethod, {
                params: [],
                handleResponse: async (params) => {
                    const status = params.status;
                    if (status === 'bind') {
                        try {
                            await getPaymentMethod();
                            await CallAfterBoundCardModel.callback();
                        }
                        catch {
                        }
                        return false;
                    }
                    return true;
                },
                handleError: () => false,
                callInterval: 3000,
            });
            addUnmountCallback(clear);
            return;
        }
    };
    const onPayError = () => {
        if (handleError) {
            void handleError({
                type,
                closeModal: onCloseHandle,
            });
            return;
        }
        const common = {
            analyticShowAttribute: analyticsDictionary.modalCheckoutFailShow(type),
            mode: 'error',
        };
        if (type === 'offer') {
            onShowAlertModal({
                ...common,
                header: intl.formatMessage(messages.tariffNotBeAppliedTitle),
                subheader: intl.formatMessage(messages.tariffNotBeAppliedDescription),
            });
            return;
        }
        if (type === 'paymethod' || type === 'bound_card_before_action') {
            onShowAlertModal({
                ...common,
                header: intl.formatMessage(messages.failedToLinkPaymentMethodTitle),
                subheader: intl.formatMessage(messages.failedToLinkPaymentMethodDescription),
            });
            return;
        }
        onShowAlertModal({
            ...common,
            header: intl.formatMessage(messages.defaultErrorTitle),
            subheader: intl.formatMessage(messages.defaultErrorDescription),
        });
        return;
    };
    const onRefreshWindow = () => {
        if (!paymentId) {
            return;
        }
        void getCheckoutData(paymentId);
    };
    useAnalyticShowDirect(analyticsDictionary.modalCheckoutFormShow(type));
    return {
        paymentData,
        status: checkoutModelStatus,
        title,
        subtitle,
        iframeUrl,
        onPaySuccess,
        onPayError,
        onRefreshWindow,
    };
};
