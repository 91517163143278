import React from 'react';
import { IMAGE } from './images';
const Icon96ComboHeadphone = (props) => {
    const { className, style, loading } = props;
    return (<picture>
      {IMAGE.map((imageData, index) => (<source key={`${imageData.srcSet}-${index}`} {...imageData} width={96} height={96}/>))}
      <img loading={loading} alt={'Картинка значка combo музыки в наушниках'} className={className} style={style}/>
    </picture>);
};
export default Icon96ComboHeadphone;
