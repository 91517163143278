import { createEffect } from 'effector/effector.umd';
import apiCommon from '@common/utils/ApiCommon';
import SendStudentFormParamsSchema from '@common/utils/ApiCommon/schemas/SendStudentFormParamsSchema.json';
import SendEmailStudentFormParamsSchema from '@common/utils/ApiCommon/schemas/SendEmailStudentFormParamsSchema.json';
import VerifyStudentEmailParamsSchema from '@common/utils/ApiCommon/schemas/VerifyStudentEmailParamsSchema.json';
const SendStudentFormResponseSchema = () => import('@common/utils/ApiCommon/schemas/SendStudentFormResponseSchema.json');
const ConfirmSendingStudentFormResponseSchema = () => import('@common/utils/ApiCommon/schemas/ConfirmSendingStudentFormResponseSchema.json');
const SendEmailStudentFormResponseSchema = () => import('@common/utils/ApiCommon/schemas/SendEmailStudentFormResponseSchema.json');
const getStudentsStatusFx = createEffect(async () => {
    const result = await apiCommon.getStudentsStatus();
    if (!result.data.successful) {
        return Promise.reject();
    }
    return result.data.data;
});
const disableStudentNotificationFx = createEffect(async () => {
    const result = await apiCommon.disableStudentNotification();
    if (!result.data.successful) {
        return Promise.reject();
    }
});
const getStudentCityList = (params) => apiCommon.makeGetRequest('/api/front/students/cities', params);
const getStudentEstablishmentList = (params) => apiCommon.makeGetRequest('/api/front/students/establishments', params);
const sendStudentForm = (params) => apiCommon.makePostRequest('api/front/students/form/submit', params, SendStudentFormParamsSchema, SendStudentFormResponseSchema);
const confirmSendingStudentForm = () => apiCommon.makePostRequest('api/front/students/student/pending', undefined, undefined, ConfirmSendingStudentFormResponseSchema);
const confirmSendingStudentFormRetry = () => apiCommon.makePostRequest('api/front/students/student/pending/retry', undefined, undefined, ConfirmSendingStudentFormResponseSchema);
const verifyStudentEmail = (params) => apiCommon.makePostRequest(`api/front/students/email-verify`, params, VerifyStudentEmailParamsSchema, ConfirmSendingStudentFormResponseSchema);
const sendEmailStudentForm = (params) => apiCommon.makePostRequest('api/front/students/email-send', params, SendEmailStudentFormParamsSchema, SendEmailStudentFormResponseSchema);
const getUniversityDomains = (params) => apiCommon.makeGetRequest('api/front/students/university-domains', params);
export const StudentApi = {
    getStudentsStatusFx,
    disableStudentNotificationFx,
    getStudentCityList,
    getStudentEstablishmentList,
    sendStudentForm,
    confirmSendingStudentForm,
    confirmSendingStudentFormRetry,
    sendEmailStudentForm,
    verifyStudentEmail,
    getUniversityDomains,
};
