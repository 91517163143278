import { combine, createEffect } from 'effector';
import dayjs from 'dayjs';
import { ACTIVE_SUBSCRIPTION_STATUS_LIST } from '@common/entities/subscriptions/lib/constants';
import { subscriptionsLocalModel } from './subscriptions';
import { subscriptionStatusLocalModel } from './subscriptionStatus';
const getSubscriptionsAndSubscriptionsStatusFx = createEffect(async ({ state } = { state: 'loading' }) => {
    const [{ subscriptions, buffer }, status] = await Promise.all([
        subscriptionsLocalModel.getSubscriptionsFx({ state }),
        subscriptionStatusLocalModel.getSubscriptionStatusFx({ state }),
    ]);
    return {
        subscriptions,
        buffer,
        status,
    };
});
const $userHasSubscriptionWithPermissionGoToLk = combine(subscriptionsLocalModel.$subscriptions, subscriptionStatusLocalModel.$hasActiveSubscriptionStatus, (subscriptions, hasActiveSubscriptionByStatus) => {
    if (hasActiveSubscriptionByStatus) {
        return true;
    }
    if (!subscriptions || subscriptions.length === 0) {
        return false;
    }
    return subscriptions.some((subscription) => {
        if (ACTIVE_SUBSCRIPTION_STATUS_LIST.includes(subscription.status)) {
            return true;
        }
        const { subscription_end_time: subscriptionEndTime } = subscription;
        if (subscription.is_combo || !subscriptionEndTime) {
            return false;
        }
        const monthAgo = dayjs().subtract(30, 'days');
        return monthAgo.isBefore(subscriptionEndTime);
    });
});
const $hasActiveSubscription = combine(subscriptionsLocalModel.$hasActiveSubscriptionByList, subscriptionStatusLocalModel.$hasActiveSubscriptionStatus, (hasActiveSubscriptionByList, hasActiveSubscriptionStatus) => hasActiveSubscriptionByList || hasActiveSubscriptionStatus);
export const SubscriptionsModel = {
    ...subscriptionsLocalModel,
    ...subscriptionStatusLocalModel,
    $hasActiveSubscription,
    $userHasSubscriptionWithPermissionGoToLk,
    getSubscriptionsAndSubscriptionsStatusFx,
};
