import { api } from '../api';
export const getRisingPricesData = async (params) => {
    try {
        const responce = await api.getRisingPricesAvailableStatus(params);
        if (!responce.successful) {
            return {
                available: false,
            };
        }
        return responce.data;
    }
    catch {
        return {
            available: false,
        };
    }
};
