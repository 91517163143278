import { memo } from 'react';
import styles from './styles.module.scss';
import Link from 'next/link';
import { analyticsDictionary, createAnalyticAttrs } from '@analytics';
import AnimatedLogo from '@common/components/common/AnimatedLogo/component';
import classNames from 'classnames';
import { PROFILE_ROUTES, ROUTES } from '@website/shared/lib/routes';
import { useUnit } from 'effector-react';
import { headerModel } from '@website/entities';
import { useRouter } from 'next/router';
const excludePages = [ROUTES.root, ...PROFILE_ROUTES];
const Logo = memo(({ showLogo, logoClassName }) => {
    const showLogoModel = useUnit(headerModel.$showLogo);
    const isShowLogoClassName = (showLogo || showLogoModel) && styles.logo__open;
    const router = useRouter();
    const needLogoToBeLink = !excludePages.some((pathname) => pathname === router.pathname);
    if (needLogoToBeLink) {
        return (<Link href={ROUTES.root} aria-label={'Логотип VK music'} {...createAnalyticAttrs(analyticsDictionary.headerLogoButtonClick, ['click'])}>
        <AnimatedLogo className={classNames(styles.logo, isShowLogoClassName, logoClassName)}/>
      </Link>);
    }
    return (<span>
      <AnimatedLogo className={classNames(styles.logo, isShowLogoClassName)}/>
    </span>);
});
Logo.displayName = 'Logo';
export default Logo;
