import React from 'react';
import className from 'classnames';
import { analyticsDictionary, createAnalyticAttrs } from '@analytics';
import { LEGAL_SUPPORT_LINKS } from '@common/shared/lib/links/legal-support-links';
import styles from './styles.module.scss';
const SupportLinksList = (props) => {
    const { linkClassName, linkListClassName } = props;
    return (<ul className={className(styles.linkList, linkListClassName)}>
      {LEGAL_SUPPORT_LINKS.map(({ title, href, eventHub }) => (<li key={title} className={styles.linkItem} {...createAnalyticAttrs(analyticsDictionary.landingFooterLinkClick(eventHub), ['click'])}>
          <a className={className(styles.link, linkClassName)} href={href} target={'_blank'} rel='noreferrer'>
            {title}
          </a>
        </li>))}
    </ul>);
};
export default SupportLinksList;
