import React, { useState } from 'react';
import { useUnit } from 'effector-react';
import { ButtonGroup } from '@vkontakte/vkui';
import { createAnalyticClickAttribute } from '@analytics';
import { modalManagerModel } from '@common/services/modal-manager';
import ModalButton from '@common/components/modals/ModalButton';
import { CheckboxRulesField } from './ui';
import { showAcceptInvitationModalModel } from './model';
import { analyticsDictionary } from '../analytics-dictionary';
import { CONTENT } from './content';
const { acceptInvitation, cancelInvitation, $checkboxRulesField } = showAcceptInvitationModalModel;
const Actions = (props) => {
    const { buttons, communityId } = props;
    const checkboxRulesField = useUnit($checkboxRulesField);
    const [loading, setLoading] = useState(false);
    const handleAcceptInvitation = () => {
        setLoading(true);
        void acceptInvitation(communityId);
    };
    const handleCancelInvitation = () => {
        setLoading(true);
        void cancelInvitation(communityId);
    };
    return (<ButtonGroup mode='vertical' gap='m' stretched={true}>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalAcceptInvitationButtonConfirmClick)} size={'l'} stretched={true} loading={loading} disabled={!checkboxRulesField || loading} onClick={() => void handleAcceptInvitation()}>
        {buttons.action.name}
      </ModalButton>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalAcceptInvitationButtonCancelClick)} size={'l'} stretched={true} disabled={loading} mode={'secondary'} onClick={() => void handleCancelInvitation()}>
        {buttons.cancel.name}
      </ModalButton>
    </ButtonGroup>);
};
const payloadToManagerModal = (payload) => {
    const { type, communityId, owner } = payload;
    const { analyticShowAttribute, icon, header, subheader, buttons } = CONTENT(owner.firstName)[type];
    return {
        analyticShowAttribute,
        icon,
        header,
        subheader: (<>
        {subheader}
        <CheckboxRulesField />
      </>),
        actions: (<Actions buttons={buttons} communityId={communityId}/>),
    };
};
export const showAcceptInvitationModal = (payload) => {
    modalManagerModel.showModal(payloadToManagerModal(payload));
};
