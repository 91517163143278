import React from 'react';
import { useGate, useUnit } from 'effector-react';
import { userDataModel } from '@common/entities/user-data';
import { showSearchFriendsModal } from '../../lib';
import { groupSubscriptionMembersModel } from '../../model';
import { default as MembersListTemplate } from './members-list';
export const MembersList = () => {
    useGate(groupSubscriptionMembersModel.gate);
    const store = useUnit(groupSubscriptionMembersModel.$members);
    const userDataStore = useUnit(userDataModel.$userData);
    return (<MembersListTemplate groupId={store.groupId} maxMembers={store.capacity} members={store.items} isOwner={store.ownerVkId === userDataStore.vkId} addMember={() => showSearchFriendsModal()}/>);
};
