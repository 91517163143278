import { useEffect, useMemo } from 'react';
import lodashOnce from 'lodash/once';
import { useUnit } from 'effector-react';
import { isValidateData } from '@common/shared/lib/validate-data/validateData';
import CheckoutFrameEventSchema from './lib/schemas/CheckoutFrameEvent.json';
import apiCommon from '@common/utils/ApiCommon';
import { checkoutProcessModel } from '@common/services/checkout-process';
import { sharedCheckoutModel } from '@common/shared/model';
import { analyticsEvent, getTimeDiff, LOADING_IFRAME_TIMEOUT } from './lib';
export const useCheckoutFrame = (params) => {
    const { orderUuid, onFormSend, onPaySuccess, onPayError, onRefreshWindow, setIframeHeight, setIframeWidth, setIframeSize, onCloseWindow, } = params;
    const timeStart = useMemo(() => performance.now(), []);
    const checkoutProcessSuccess = useUnit(checkoutProcessModel.checkoutProcessSuccess);
    const checkoutProcessError = useUnit(checkoutProcessModel.checkoutProcessError);
    const sharedCheckoutModelSuccess = useUnit(sharedCheckoutModel.success);
    const analyticsEventMemo = useMemo(() => ({
        success: lodashOnce(() => analyticsEvent({
            result: 'success',
            time: getTimeDiff(timeStart),
        })),
        failProviderTimeout: lodashOnce(() => analyticsEvent({
            result: 'fail',
            time: getTimeDiff(timeStart),
            errorType: 'provider_timeout',
        })),
        failProviderError404: lodashOnce(() => analyticsEvent({
            result: 'fail',
            time: getTimeDiff(timeStart),
            errorType: 'provider_error_404',
        })),
    }), []);
    useEffect(() => {
        LOADING_IFRAME_TIMEOUT.ID = setTimeout(() => {
            analyticsEvent({
                result: 'fail',
                time: getTimeDiff(timeStart),
                errorType: 'form_timeout',
            });
        }, LOADING_IFRAME_TIMEOUT.DELAY);
        return () => {
            clearTimeout(LOADING_IFRAME_TIMEOUT.ID);
        };
    }, []);
    useEffect(() => {
        analyticsEvent({
            result: 'open',
            time: getTimeDiff(timeStart),
        });
        const listener = async (postMessage) => {
            try {
                const data = JSON.parse(postMessage?.data);
                if (isValidateData(CheckoutFrameEventSchema, data, 'CheckoutFrame IframeEventSchema')) {
                    switch (data.action) {
                        case 'fireEvent': {
                            if (data.action_params.eventName === 'form_send') {
                                void onFormSend?.();
                            }
                            break;
                        }
                        case 'resizeFrame': {
                            const height = data.action_params.height;
                            const width = data.action_params.width ?? null;
                            setIframeHeight?.(height);
                            if (width !== null) {
                                setIframeWidth?.(width);
                            }
                            setIframeSize?.({
                                height,
                                width,
                            });
                            break;
                        }
                        case 'paySuccess': {
                            if (typeof orderUuid === 'string') {
                                await apiCommon.setWaitStatusForOrder(orderUuid).catch(() => Promise.resolve());
                            }
                            if (onPaySuccess) {
                                onPaySuccess();
                            }
                            checkoutProcessSuccess();
                            sharedCheckoutModelSuccess();
                            break;
                        }
                        case 'payError': {
                            void onPayError?.();
                            checkoutProcessError();
                            break;
                        }
                        case 'pageLoad': {
                            clearTimeout(LOADING_IFRAME_TIMEOUT.ID);
                            analyticsEventMemo.success();
                            break;
                        }
                        case 'closeWindow': {
                            onCloseWindow && void onCloseWindow();
                            break;
                        }
                        case 'timeoutError': {
                            analyticsEventMemo.failProviderTimeout();
                            break;
                        }
                        case 'refreshWindow': {
                            void onRefreshWindow();
                            break;
                        }
                        case 'putPixel': {
                            const action_params = data['action_params'];
                            const alias = action_params?.['alias'];
                            if (alias && alias === 'dmr_error_404') {
                                analyticsEventMemo.failProviderError404();
                            }
                            break;
                        }
                    }
                }
            }
            catch { }
        };
        window.addEventListener('message', listener);
        return () => {
            window.removeEventListener('message', listener);
            analyticsEvent({
                result: 'close',
                time: getTimeDiff(timeStart),
            });
        };
    }, [orderUuid]);
};
