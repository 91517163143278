export const getQueryParams = () => {
    const search = window.location.search.substr(1);
    const params = {};
    search.split('&').forEach((item) => {
        const [key, value] = item.split('=');
        if (key && typeof value !== 'undefined') {
            params[key] = value;
        }
    });
    return params;
};
