import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { Caption, Headline, Title, useModalRootContext } from '@vkontakte/vkui';
import { useAppearance } from '@vkontakte/vk-bridge-react';
import _throttle from 'lodash/throttle';
import { SkeletonIndicator } from '@common/shared/ui';
import { useGetIsMobileOrTabletUserAgent } from '@common/shared/lib/';
import Typograf from '@common/components/common/Typograf';
import { LICENSE_AGREEMENT_LINK, PRIVACY_POLICY_LINK } from '@common/shared/lib/constants';
import { ExternalLink } from '@common/shared/ui/external-link';
import { useCheckoutFrame } from '../../hooks';
import styles from './styles.module.scss';
const DESKTOP_WIDTH = 520;
const DESKTOP_HEIGHT = 560;
const DEFAULT_DESKTOP_HEIGHT = 560;
const DEFAULT_MOBILE_HEIGHT = 280;
const DESKTOP_VKUI_MODAL_VERTICAL_MARGIN = 32;
const desktopDefaultIframeParams = {
    allowtransparency: 'true',
    style: {
        background: 'transparent',
        border: 'none',
        maxHeight: '100%',
    },
    width: DESKTOP_WIDTH,
    height: DESKTOP_HEIGHT,
};
const mobileDefaultIframeParams = {
    allowtransparency: 'true',
    style: {
        background: 'transparent',
        border: 'none',
        height: '500px',
        width: '100%',
    },
};
const TEN_PIXEL_FROM_MAX_HEIGHT = 10;
const getIframeParams = (params) => {
    const { isMobileOrTablet, header, footer, iframeSize } = params;
    if (iframeSize && iframeSize.width === DESKTOP_WIDTH && iframeSize.height === DESKTOP_HEIGHT) {
        if (header && footer) {
            const containerStyles = {
                maxHeight: `calc(${window.innerHeight - header.offsetHeight - footer.offsetHeight - DESKTOP_VKUI_MODAL_VERTICAL_MARGIN * 2}px - var(--vkui_internal--safe_area_inset_top) - var(--vkui--size_panel_header_height--regular))`,
                height: 615,
            };
            return {
                iframeParams: desktopDefaultIframeParams,
                containerParams: {
                    style: containerStyles,
                },
                isMobile: false,
            };
        }
        return {
            iframeParams: desktopDefaultIframeParams,
            isMobile: false,
        };
    }
    if (isMobileOrTablet) {
        if (header && footer) {
            const headerHeight = header.offsetHeight;
            const footerHeight = footer.offsetHeight;
            const extraStyle = {
                maxHeight: `calc(${window.innerHeight - headerHeight - footerHeight - TEN_PIXEL_FROM_MAX_HEIGHT}px - var(--vkui_internal--safe_area_inset_top) - var(--vkui--size_panel_header_height--regular))`,
            };
            return {
                iframeParams: {
                    ...mobileDefaultIframeParams,
                    style: {
                        ...mobileDefaultIframeParams.style,
                    },
                },
                containerParams: {
                    style: extraStyle,
                },
                isMobile: true,
            };
        }
        return { iframeParams: mobileDefaultIframeParams, isMobile: true };
    }
    else {
        return { iframeParams: desktopDefaultIframeParams, isMobile: false };
    }
};
const CheckoutV3Frame = (props) => {
    const { title, loading, subtitle, iframeUrl, ...useCheckoutFrameParams } = props;
    const isMobileOrTablet = useGetIsMobileOrTabletUserAgent();
    const [iframeSize, setIframeSize] = useState({
        height: isMobileOrTablet ? DEFAULT_MOBILE_HEIGHT : DEFAULT_DESKTOP_HEIGHT,
        width: null,
    });
    const setIframeSizeCb = useCallback(setIframeSize, []);
    const headerRef = useRef(null);
    const footerRef = useRef(null);
    const curtainRef = useRef(null);
    const [iframeParams, setIframeParams] = useState(getIframeParams({
        isMobileOrTablet,
        header: headerRef.current,
        footer: footerRef.current,
        iframeSize,
    }));
    useEffect(() => {
        const header = headerRef.current;
        const footer = footerRef.current;
        const handler = () => {
            const iframeParamsNext = getIframeParams({
                isMobileOrTablet,
                header,
                footer,
                iframeSize,
            });
            if (JSON.stringify(iframeParamsNext) !== JSON.stringify(iframeParams)) {
                setIframeParams(iframeParamsNext);
            }
        };
        const handlerThrottled = _throttle(handler, 100);
        const observer = new ResizeObserver(handlerThrottled);
        [header, footer].forEach((element) => {
            if (element) {
                observer.observe(element);
            }
        });
        window.addEventListener('resize', handlerThrottled);
        return () => {
            observer.disconnect();
            window.removeEventListener('resize', handlerThrottled);
        };
    }, [headerRef.current, footerRef.current, iframeParams, iframeSize]);
    const { updateModalHeight } = useModalRootContext();
    const theme = useAppearance();
    const themeMemoized = useMemo(() => theme, [iframeUrl]);
    const darkTheme = themeMemoized === 'dark';
    useCheckoutFrame({ ...useCheckoutFrameParams, setIframeSize: setIframeSizeCb });
    useEffect(() => {
        updateModalHeight();
    }, [iframeSize]);
    const handleIframeLoad = () => {
        const curtainElement = curtainRef.current;
        if (curtainElement) {
            curtainElement.style.opacity = '1';
        }
    };
    return (<article className={classNames(styles.layout, {
            [styles.layout__mobile]: isMobileOrTablet,
            [styles.layout__darkTheme]: darkTheme,
        })}>
      <header className={styles.header} ref={headerRef}>
        <div className={styles.paddingWrapper}>
          <div className={styles.header__content}>
            <SkeletonIndicator content={<Title level={'2'}>
                  <Typograf>{title}</Typograf>
                </Title>} skeletonClassName={styles.header__skeleton_title} loading={loading}/>
            <SkeletonIndicator content={<Headline className={styles.subtitle} level={'2'} weight={'2'} Component='h4'>
                  <Typograf>{subtitle}</Typograf>
                </Headline>} skeletonClassName={styles.header__skeleton_subtitle} loading={loading}/>
          </div>
        </div>
      </header>
      <SkeletonIndicator skeletonClassName={classNames({
            [styles.iframe__skeleton]: !isMobileOrTablet,
            [styles.iframe__skeletonMobile]: isMobileOrTablet,
        })} content={<iframe src={iframeUrl} className={classNames(styles.iframe, {
                [styles.iframe_darkTheme]: darkTheme,
                [styles.iframe__mobile]: isMobileOrTablet,
            })} onLoad={handleIframeLoad}/>} loading={loading}/>
      <footer className={classNames(styles.footer, { [styles.footer__darkTheme]: darkTheme })} ref={footerRef}>
        <div className={styles.paddingWrapper}>
          <SkeletonIndicator content={<Caption>
                <Typograf>
                  <span className={styles.footer__text}>
                    При оплате вы принимаете условия&nbsp;
                    <ExternalLink href={LICENSE_AGREEMENT_LINK}>Лицензионного&nbsp;соглашения</ExternalLink> и&nbsp;
                    <ExternalLink href={PRIVACY_POLICY_LINK}>Политики&nbsp;конфиденциальности</ExternalLink>
                  </span>
                </Typograf>
              </Caption>} skeletonClassName={styles.footer__skeleton} loading={loading}/>
        </div>
      </footer>
    </article>);
};
export default CheckoutV3Frame;
