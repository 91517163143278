import { createEffect, createEvent, createStore, sample } from 'effector';
import { reset } from 'patronum';
import { StudentApi } from '@common/entities/student';
import { createOptionListEstablishments } from '../lib/create-option-list';
const $universityDomains = createStore([]);
const $establishmentOptions = createStore([]);
const getEstablishmentList = createEvent();
const setUniversityOptions = createEvent();
const clearEstablishmentModel = createEvent();
const getUniversityDomainsFx = createEffect(async (params) => {
    const { data } = await StudentApi.getUniversityDomains(params);
    if (!data.successful) {
        return Promise.reject();
    }
    return data.data.domains;
});
const getStudentEstablishmentListFx = createEffect(async (params) => {
    const { query, city_id } = params;
    const { data } = await StudentApi.getStudentEstablishmentList({ query, city_id });
    if (!data.successful) {
        return Promise.reject();
    }
    const { items } = data.data;
    if (items.length === 0) {
        return Promise.reject();
    }
    return createOptionListEstablishments(items);
});
sample({ clock: getUniversityDomainsFx.doneData, target: $universityDomains });
sample({
    clock: getEstablishmentList,
    target: getStudentEstablishmentListFx,
});
sample({
    clock: [getStudentEstablishmentListFx.doneData, setUniversityOptions],
    target: $establishmentOptions,
});
const $pendingStudentEstablishmentList = getStudentEstablishmentListFx.pending;
reset({ clock: clearEstablishmentModel, target: [$universityDomains, $establishmentOptions] });
export const establishmentModel = {
    $universityDomains,
    $establishmentOptions,
    $pendingStudentEstablishmentList,
    getUniversityDomainsFx,
    getEstablishmentList,
    clearEstablishmentModel,
};
