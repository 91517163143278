import { createEvent, createStore } from 'effector';
const DEFAULT_STATE = {
    step: 0,
    status: 'initial',
};
const $state = createStore(DEFAULT_STATE);
const setState = createEvent();
const increaseStep = createEvent();
const decreaseStep = createEvent();
const clear = createEvent();
$state.on(setState, (prevState, newState) => ({ ...prevState, ...newState }));
$state.on(increaseStep, (prevState) => ({ ...prevState, step: prevState.step + 1 }));
$state.on(decreaseStep, (prevState) => ({ ...prevState, step: prevState.step - 1 }));
$state.on(clear, () => ({ ...DEFAULT_STATE }));
export const modalStateModel = { $state, setState, increaseStep, decreaseStep, clear };
