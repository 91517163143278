import { createEvent, sample } from 'effector';
import { vkUIModalManager } from '@common/services/vk-ui-modal-manager';
const closeModal = createEvent();
const showModal = createEvent();
const showModalPage = createEvent();
const showModalPageWithFullHeight = createEvent();
const showModalPageWithFixedFooter = createEvent();
const setStateModalPageWithFixedFooter = createEvent();
const showTargetModalById = createEvent();
sample({ clock: showModal, target: vkUIModalManager.modalCard.showModal });
sample({ clock: showModalPage, target: vkUIModalManager.modalPage.showModal });
sample({ clock: showModalPageWithFixedFooter, target: vkUIModalManager.modalPageWithFixedFooter.showModal });
sample({ clock: showTargetModalById, target: vkUIModalManager.setActiveId });
sample({ clock: setStateModalPageWithFixedFooter, target: vkUIModalManager.modalPageWithFixedFooter.setState });
sample({
    clock: showModalPageWithFullHeight,
    fn: (clock) => ({ ...clock, settlingHeight: 100 }),
    target: vkUIModalManager.modalPage.showModal,
});
sample({ clock: closeModal, target: vkUIModalManager.closeModal });
export const modalManagerModel = {
    closeModal,
    showModal,
    showModalPage,
    showModalPageWithFullHeight,
    setStateModalPageWithFixedFooter,
    showModalPageWithFixedFooter,
    showTargetModalById,
};
