import React from 'react';
import { useIntl } from 'react-intl';
import ModalButton from '@common/components/modals/ModalButton';
import { modalManagerModel } from '@common/services/modal-manager';
import { messages } from '../messages';
export const Actions = () => {
    const intl = useIntl();
    const closeModal = () => modalManagerModel.closeModal();
    return (<ModalButton mode={'primary'} onClick={closeModal}>
      {intl.formatMessage(messages.btnText)}
    </ModalButton>);
};
