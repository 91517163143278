import apiCommon from '@common/utils/ApiCommon';
const GetPaymentsMethodResponseSchema = () => import('./schemas/GetPaymentsMethodResponseSchema.json');
const getPaymentsMethod = () => apiCommon
    .makeGetRequest('/api/front/user/payments/pay-method', undefined, undefined, GetPaymentsMethodResponseSchema)
    .then((response) => {
    if (!response.data.successful) {
        throw new Error('');
    }
    return response.data.data;
});
export const api = {
    getPaymentsMethod,
};
