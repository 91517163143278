import { userDataMappings } from '@common/entities/user-data';
const apiMemberStatus = {
    owner: 'owner',
    active: 'member',
    pending: 'invitation_sent',
    declined: 'invitation_rejected',
};
export const apiMemberGroupSubscription = (member) => {
    return {
        ...userDataMappings.apiUserVk(member),
        status: apiMemberStatus[member.status],
        state: 'idle',
    };
};
export const mappings = {
    apiMemberGroupSubscription,
};
