import { attach, createEffect, restore, sample } from 'effector';
export const createCallbackAfterFlow = (event, finishEvent) => {
    const $callbacksFromEvent = restore(event, {
        callbackAfterFinishedFlow: () => Promise.resolve(),
    });
    const finishFx = attach({
        effect: createEffect(async (payload) => {
            const { callbackAfterFinishedFlow } = payload;
            callbackAfterFinishedFlow && callbackAfterFinishedFlow();
        }),
        source: $callbacksFromEvent,
        mapParams: function (_, source) {
            return source;
        },
    });
    sample({ clock: finishEvent, target: finishFx });
};
