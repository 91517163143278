import React from 'react';
import { SimpleCell, Title } from '@vkontakte/vkui';
import { Icon28MusicOutline, Icon28DownloadCheckOutline, Icon28BookSpreadOutline, Icon28SmartphoneOutline, } from '@vkontakte/icons';
import Typograf from '@common/components/common/Typograf';
import { intl } from '@common/shared/lib/intl';
import { showBenefitsCancelSubscription as messages } from '../../messages';
const content = [
    {
        title: intl.formatMessage(messages.benefitOneTitle),
        description: intl.formatMessage(messages.benefitOneDescription),
        icon: <Icon28MusicOutline color='var(--vkui--color_accent_blue)'/>,
    },
    {
        title: intl.formatMessage(messages.benefitTwoTitle),
        description: intl.formatMessage(messages.benefitTwoDescription),
        icon: <Icon28DownloadCheckOutline color='var(--vkui--color_accent_blue)'/>,
    },
    {
        title: intl.formatMessage(messages.benefitFourTitle),
        description: intl.formatMessage(messages.benefitFourDescription),
        icon: <Icon28SmartphoneOutline color='var(--vkui--color_accent_blue)'/>,
    },
    {
        title: intl.formatMessage(messages.benefitThreeTitle),
        description: intl.formatMessage(messages.benefitThreeDescription),
        icon: <Icon28BookSpreadOutline color='var(--vkui--color_accent_blue)'/>,
    },
];
const BenefitsCancelSubscription = () => {
    return (<>
      <Title style={{ marginBottom: 10 }} level='2'>
        {intl.formatMessage(messages.header)}
      </Title>
      <div>
        {content.map((item) => (<SimpleCell style={{ paddingLeft: 0 }} key={item.title} before={item.icon} subtitle={<Typograf>{item.description}</Typograf>} hasHover={false} hasActive={false}>
            <Typograf>{item.title}</Typograf>
          </SimpleCell>))}
      </div>
    </>);
};
export default BenefitsCancelSubscription;
