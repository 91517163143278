import React from 'react';
import { File } from '@vkontakte/vkui';
import { Icon24CameraOutline } from '@vkontakte/icons';
import styles from './styles.module.scss';
import { useGetVariantInDependOnVkUIPlatform } from '@common/shared/hooks';
const ICON = {
    desktop: null,
    mobile: <Icon24CameraOutline />,
};
const UploadDocumentButton = (props) => {
    const { documentFileHandleChange, uploadButtonTitle } = props;
    const icon = useGetVariantInDependOnVkUIPlatform(ICON);
    return (<File className={styles.button} size='l' align={'center'} accept='image/png, image/jpeg' onChange={documentFileHandleChange} before={icon}>
      {uploadButtonTitle}
    </File>);
};
export default UploadDocumentButton;
