import React from 'react';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import { useIntl } from 'react-intl';
import { showConfirmLeaveModal } from '@common/features/group-subscription';
import ModalButton from '@common/components/modals/ModalButton';
import { useGetSubscriptionDetailsContext } from '../../../../lib';
import messages from './messages';
const GroupButton = () => {
    const props = useGetSubscriptionDetailsContext();
    const { closeModal } = props;
    const handleCancel = () => {
        closeModal();
        showConfirmLeaveModal();
    };
    const intl = useIntl();
    return (<ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionManagementButtonRedirectClick)} stretched={true} mode='secondary' onClick={handleCancel}>
      {intl.formatMessage(messages.leaveSubBtnText)}
    </ModalButton>);
};
export default GroupButton;
