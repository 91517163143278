import { modalManagerTools } from '@common/services/modal-manager';
const { showMessageModal } = modalManagerTools.modalTemplates;
import { analyticsDictionary } from '@common/utils/Analytics';
import { intl } from '@common/shared/lib/intl';
import { messages } from './messages';
export const showSubscriptionRecoverySuccessModal = () => {
    showMessageModal({
        analyticShowAttribute: analyticsDictionary.modalSubscriptionRestoreSuccess,
        mode: 'success',
        header: intl.formatMessage(messages.header),
    });
};
