import React from 'react';
import Typograf from '@common/components/common/Typograf';
import styles from './styles.module.scss';
const RULES_URL = 'https://music.vk.com/rules/vkmusic_students';
const AcceptRules = () => {
    return (<div className={styles.rules}>
      <Typograf>Нажимая «Продолжить», вы принимаете </Typograf>{' '}
      <a rel='noreferrer' target='_blank' href={RULES_URL}>
        <Typograf>{'условия&nbsp;соглашения'}</Typograf>
      </a>{' '}
      <Typograf>и правила обработки предоставленных вами данных</Typograf>
    </div>);
};
export default AcceptRules;
