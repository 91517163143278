import React from 'react';
import { Snackbar } from '@vkontakte/vkui';
import { useUnit } from 'effector-react';
import { snackbarManagerModel } from '../../model';
const SnackbarManager = () => {
    const activeSnack = useUnit(snackbarManagerModel.$activeSnack);
    if (!activeSnack) {
        return null;
    }
    return <Snackbar {...activeSnack}/>;
};
export default SnackbarManager;
