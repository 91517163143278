import React from 'react';
const WIDTH_DEFAULT = 80;
const HEIGHT_DEFAULT = 88;
const StudentsUnderConsiderationIcon = ({ width = WIDTH_DEFAULT }) => {
    const height = (width / WIDTH_DEFAULT) * HEIGHT_DEFAULT;
    return (<svg width={height} height={height} viewBox='0 0 80 88' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 26.8047C0 14.1647 1.33035e-05 7.84809 3.92636 3.92745C7.8527 0.00680683 14.1335 0 26.7971 0H45.2584C57.8948 0 64.2096 3.24567e-07 68.1292 3.92745C72.0487 7.8549 72.0555 14.1579 72.0555 26.8047V53.4596C72.0555 66.0928 72.0555 72.4094 68.1292 76.3368C64.2028 80.2643 57.9221 80.2643 45.2584 80.2643H26.7971C14.1675 80.2643 7.8527 80.2643 3.92636 76.3368C1.33035e-05 72.4094 0 66.1268 0 53.4596V26.8047Z' fill='#2929B2'/>
      <path d='M5.83984 28.6423C5.83984 17.7516 5.8399 12.3063 9.12659 8.90297C12.4133 5.49963 17.7073 5.49963 28.2955 5.49963H43.7628C54.351 5.49963 59.6451 5.49963 62.9317 8.90297C66.2184 12.3063 66.2253 17.7516 66.2253 28.6423V51.5944C66.2253 62.4851 66.2253 67.9304 62.9317 71.3337C59.6382 74.7371 54.351 74.7371 43.7628 74.7371H28.2955C17.7073 74.7371 12.4133 74.7371 9.12659 71.3337C5.8399 67.9304 5.83984 62.4851 5.83984 51.5944V28.6423Z' fill='white'/>
      <path d='M40.6768 51.7783H15.3223C14.2663 51.7783 13.4102 52.6347 13.4102 53.691V53.6978C13.4102 54.7541 14.2663 55.6105 15.3223 55.6105H40.6768C41.7329 55.6105 42.5889 54.7541 42.5889 53.6978V53.691C42.5889 52.6347 41.7329 51.7783 40.6768 51.7783Z' fill='#00EAFF'/>
      <path d='M25.2504 59.5583H15.3223C14.2663 59.5583 13.4102 60.4147 13.4102 61.471V61.4778C13.4102 62.5342 14.2663 63.3905 15.3223 63.3905H25.2504C26.3065 63.3905 27.1626 62.5342 27.1626 61.4778V61.471C27.1626 60.4147 26.3065 59.5583 25.2504 59.5583Z' fill='#00EAFF'/>
      <path d='M58.0466 17.6565H42.2051C41.1491 17.6565 40.293 18.5128 40.293 19.5692V19.576C40.293 20.6323 41.1491 21.4887 42.2051 21.4887H58.0466C59.1027 21.4887 59.9587 20.6323 59.9587 19.576V19.5692C59.9587 18.5128 59.1027 17.6565 58.0466 17.6565Z' fill='#00EAFF'/>
      <path d='M44.4439 25.4365H42.2051C41.1491 25.4365 40.293 26.2929 40.293 27.3492V27.356C40.293 28.4123 41.1491 29.2687 42.2051 29.2687H44.4439C45.5 29.2687 46.356 28.4123 46.356 27.356V27.3492C46.356 26.2929 45.5 25.4365 44.4439 25.4365Z' fill='#00EAFF'/>
      <path d='M52.1333 33.2234H42.2051C41.1491 33.2234 40.293 34.0797 40.293 35.1361V35.1429C40.293 36.1992 41.1491 37.0555 42.2051 37.0555H52.1333C53.1893 37.0555 54.0454 36.1992 54.0454 35.1429V35.1361C54.0454 34.0797 53.1893 33.2234 52.1333 33.2234Z' fill='#00EAFF'/>
      <path d='M58.0464 25.4365H50.4591C49.403 25.4365 48.5469 26.2929 48.5469 27.3492V27.356C48.5469 28.4123 49.403 29.2687 50.4591 29.2687H58.0464C59.1024 29.2687 59.9585 28.4123 59.9585 27.356V27.3492C59.9585 26.2929 59.1024 25.4365 58.0464 25.4365Z' fill='#00EAFF'/>
      <path d='M58.0449 51.7783H48.6543C47.5982 51.7783 46.7422 52.6347 46.7422 53.691V53.6978C46.7422 54.7541 47.5982 55.6105 48.6543 55.6105H58.0449C59.1009 55.6105 59.957 54.7541 59.957 53.6978V53.691C59.957 52.6347 59.1009 51.7783 58.0449 51.7783Z' fill='#00EAFF'/>
      <path d='M36.2994 27.4921V37.3005C36.2994 40.7039 36.2994 42.8956 35.7891 44.3659C35.6267 44.85 35.3725 45.2984 35.0406 45.6864C34.7895 45.9761 34.4903 46.2202 34.156 46.4079C32.8631 47.1294 30.9237 47.1294 27.7186 47.1294H21.7985C17.7497 47.1294 15.7287 47.1294 14.4698 45.6864C14.2574 45.4414 14.0789 45.1689 13.939 44.8764C13.7778 44.5489 13.6546 44.204 13.5716 43.8486C13.211 42.3987 13.2109 40.3499 13.2109 37.3005V27.4921C13.2109 22.8568 13.2109 20.5357 14.4698 19.0995C15.7287 17.6633 17.7497 17.6565 21.7985 17.6565H27.7186C31.8015 17.6565 33.7953 17.6565 35.0542 19.0995C36.3131 20.5425 36.2994 22.8568 36.2994 27.4921Z' fill='#00EAFF'/>
      <path d='M36.2948 34.3667V37.3003C36.2948 40.7037 36.2948 42.8954 35.7844 44.3657C35.622 44.8498 35.3679 45.2982 35.0359 45.6861C34.7849 45.9759 34.4857 46.22 34.1514 46.4077C32.0083 46.5215 29.8599 46.492 27.7208 46.3192C24.9989 46.1218 22.1069 45.7951 19.5551 45.4888C17.398 45.2437 15.4723 45.0055 13.9821 44.8762C13.8209 44.5486 13.6976 44.2038 13.6146 43.8483C13.2539 42.3985 13.2539 40.3497 13.2539 37.3003V33.72C13.5028 33.4063 13.7686 33.1064 14.0501 32.8215C17.8676 29.4658 13.9276 28.3972 17.0374 26.0149C17.9247 25.5612 18.6673 24.8679 19.1809 24.0137C20.0791 21.8764 22.0661 21.3387 24.6859 21.9717C25.0193 22.0553 25.3639 22.0852 25.7066 22.0602C29.0342 21.2638 29.7895 23.5168 30.2998 24.3949C30.9803 25.6133 31.8921 25.6881 32.6679 26.2463C35.7777 28.4449 34.5392 31.6304 34.4439 32.2294C34.4261 32.4216 34.461 32.6152 34.5449 32.7891C34.6289 32.963 34.7587 33.1107 34.9203 33.2163C35.4278 33.5367 35.89 33.9236 36.2948 34.3667Z' fill='#252040'/>
      <path d='M35.7878 44.3317C35.6254 44.8159 35.3713 45.2643 35.0393 45.6522C34.7882 45.942 34.489 46.1861 34.1547 46.3737C32.8618 47.0953 30.9224 47.0953 27.7173 47.0953H21.7972C17.7484 47.0953 15.7274 47.0953 14.4685 45.6522C14.2561 45.4072 14.0776 45.1348 13.9377 44.8422C13.7765 44.5147 13.6533 44.1699 13.5703 43.8144C15.183 43.3652 17.1972 42.8751 18.9596 42.5552C19.504 42.4599 20.028 42.3782 20.5043 42.317C20.9806 42.2557 21.2801 42.2353 21.6067 42.2217C22.4253 42.4365 23.2733 42.517 24.1177 42.4599H24.9138C25.9098 42.5563 26.915 42.4849 27.8875 42.2489L28.5679 42.3102C29.1768 42.3829 29.7811 42.4897 30.378 42.6301C32.2204 43.0645 34.0284 43.6332 35.7878 44.3317Z' fill='#CC61FA'/>
      <path d='M28.1738 41.9632C27.8476 40.473 27.7105 38.9477 27.7655 37.4232C26.2957 37.2666 24.8939 37.7839 23.4241 37.6342L21.614 36.8718C21.679 38.5539 21.4886 40.2362 21.0492 41.8611C19.8652 42.2559 19.3139 45.3529 25.0708 45.3802C30.8276 45.4074 28.8134 42.1742 28.1738 41.9632Z' fill='#FCA190'/>
      <path d='M21.5234 39.3427C23.6737 40.5883 27.7294 38.7913 26.103 38.7845C24.4767 38.7777 21.5778 37.5593 21.5778 37.5593C21.6023 38.1543 21.5841 38.7503 21.5234 39.3427Z' fill='#F07875'/>
      <path d='M31.5639 30.8682C31.3817 30.7009 31.1488 30.5992 30.9022 30.5795C30.6556 30.5598 30.4094 30.6231 30.2029 30.7593C29.9579 28.8807 29.3592 27.213 28.6991 26.5052C27.8893 25.6203 26.4876 24.3542 24.9633 24.3474C23.439 24.3406 21.663 25.6271 20.8804 26.4711C20.2 27.1518 19.6148 28.8535 19.3494 30.7457C19.2536 30.6623 19.1423 30.5988 19.0218 30.5588C18.9014 30.5187 18.7741 30.503 18.6475 30.5125C18.521 30.522 18.3975 30.5565 18.2844 30.6141C18.1712 30.6716 18.0707 30.7511 17.9884 30.8478C17.5053 31.3583 17.9136 32.9783 19.2609 33.5705C19.3072 34.2426 19.4304 34.9072 19.6283 35.5512C20.2203 37.423 21.8399 39.0226 24.7727 39.1043C27.7124 39.1043 29.3387 37.4639 29.9511 35.5921C30.1584 34.9286 30.2886 34.2434 30.339 33.5501C31.6115 32.9647 32.0606 31.3039 31.5639 30.8682Z' fill='#FCA190'/>
      <path d='M23.0615 29.2756C23.4153 29.6091 23.1091 29.6908 21.8979 29.7248C21.51 29.7248 21.2174 29.7793 21.1222 29.7248C20.8159 29.3709 22.3402 28.8399 23.0615 29.2756Z' fill='#252040'/>
      <path d='M26.3968 29.3095C26.0362 29.6294 26.3355 29.7179 27.5536 29.786C27.9415 29.786 28.2341 29.8609 28.3362 29.786C28.6356 29.4661 27.1453 28.8943 26.3968 29.3095Z' fill='#252040'/>
      <path d='M26.0629 26.7569C26.0835 27.1899 26.2679 27.5989 26.5787 27.9009C26.8896 28.203 27.3036 28.3756 27.7368 28.3837C28.1705 28.3699 28.5894 28.2235 28.9374 27.9643C29.2853 27.7051 29.5455 27.3455 29.683 26.9339C29.8089 26.5183 29.8376 26.0793 29.7668 25.6509C29.696 25.2224 29.5276 24.816 29.2747 24.4631C28.7628 23.7628 28.0512 23.2337 27.2333 22.9452C25.983 22.5193 24.6266 22.5223 23.3782 22.9537C22.1299 23.3852 21.0608 24.2204 20.3401 25.3275C19.4759 26.7365 19.5235 28.2272 21.3608 28.3565C22.1994 28.4299 23.0444 28.3444 23.8514 28.1046C24.2324 27.9821 26.0561 27.2878 26.0629 26.7569Z' fill='#252040'/>
      <path d='M31.2945 44.8356C25.9799 47.429 22.9518 47.4154 18.1953 44.7812L19.5563 40.8129C19.6224 40.6206 19.7287 40.4445 19.868 40.2963C20.0073 40.1482 20.1765 40.0313 20.3643 39.9534C20.5522 39.8755 20.7544 39.8385 20.9577 39.8447C21.1609 39.8508 21.3605 39.9001 21.5433 39.9893C23.5723 40.8593 25.8586 40.9129 27.9261 40.139C28.2748 40.0122 28.6591 40.0265 28.9973 40.1791C29.3354 40.3317 29.6006 40.6105 29.7362 40.9558L31.2945 44.8356Z' fill='#CC61FA'/>
      <path d='M22.1242 30.562C22.4916 30.562 22.8047 30.9023 22.8047 31.372C22.8107 31.4629 22.7972 31.5541 22.7651 31.6394C22.733 31.7247 22.6831 31.8022 22.6187 31.8667C22.5542 31.9311 22.4769 31.981 22.3916 32.0131C22.3063 32.0452 22.2151 32.0587 22.1242 32.0527C21.7635 32.0867 21.5117 31.9302 21.4913 31.4537C21.4647 31.2526 21.5152 31.0489 21.6326 30.8835C21.75 30.7181 21.9256 30.6032 22.1242 30.562Z' fill='#252040'/>
      <path d='M27.2853 30.5961C26.9246 30.562 26.6048 30.9092 26.5572 31.3856C26.5096 31.8621 26.7273 32.0663 27.2036 32.1071C27.2889 32.1281 27.378 32.1284 27.4634 32.108C27.5488 32.0875 27.628 32.047 27.6946 31.9897C27.7612 31.9325 27.8131 31.8601 27.846 31.7787C27.879 31.6973 27.8921 31.6092 27.8841 31.5218C27.9212 31.3202 27.8799 31.112 27.7685 30.9399C27.6572 30.7678 27.4843 30.6448 27.2853 30.5961Z' fill='#252040'/>
      <path d='M65.4144 62.0157L79.2893 81.8232C79.5851 82.2451 79.7949 82.7211 79.9068 83.2241C80.0186 83.7271 80.0303 84.2473 79.9412 84.7548C79.8521 85.2624 79.664 85.7474 79.3875 86.1822C79.111 86.617 78.7516 86.993 78.3298 87.2889C77.9081 87.5848 77.4321 87.7947 76.9293 87.9066C76.4264 88.0184 75.9064 88.0302 75.399 87.941C74.8916 87.8519 74.4067 87.6637 73.972 87.3871C73.5374 87.1106 73.1614 86.7511 72.8656 86.3292C72.772 86.1909 72.6857 86.0477 72.6071 85.9004L61.352 64.5274C61.0227 63.9858 60.9218 63.3356 61.0718 62.7197C61.2217 62.1037 61.6101 61.5726 62.1515 61.2432C62.6929 60.9137 63.3431 60.8129 63.9588 60.9629C64.5745 61.1129 65.1055 61.5014 65.4349 62.043L65.4144 62.0157Z' fill='#2929B2'/>
      <path d='M74.2664 45.1145C73.1656 50.1206 70.4599 54.6298 66.561 57.9559C62.6622 61.2821 57.7837 63.243 52.6681 63.5402C51.3749 63.6133 50.0779 63.5837 48.7894 63.4517C47.0583 63.265 45.3534 62.8861 43.7062 62.3218C43.3251 62.1925 42.9577 62.0563 42.5834 61.9066C42.2091 61.7568 41.7261 61.5458 41.3042 61.3416C35.9919 58.8421 31.8071 54.4446 29.573 49.0141C27.339 43.5837 27.2175 37.5136 29.2325 31.9981C29.4502 31.3923 29.6952 30.8001 29.9606 30.2215C30.8211 28.3725 31.9142 26.6409 33.2133 25.0689C35.2701 22.5842 37.8169 20.5505 40.6947 19.0948C43.5726 17.639 46.7197 16.7925 49.9394 16.6082C50.6607 16.5673 51.3684 16.5605 52.0761 16.6082C55.5214 16.7268 58.8986 17.601 61.969 19.169C65.0393 20.737 67.7279 22.9604 69.8446 25.6822C71.9613 28.404 73.4545 31.5576 74.2185 34.9202C74.9826 38.2828 74.9989 41.7722 74.2664 45.1417V45.1145Z' fill='#00EAFF'/>
      <path d='M74.2664 45.1145C73.1656 50.1206 70.4599 54.6298 66.561 57.9559C62.6622 61.2821 57.7837 63.243 52.6681 63.5402C51.3749 63.6133 50.0779 63.5837 48.7894 63.4517C47.0583 63.265 45.3534 62.8861 43.7062 62.3218C43.3251 62.1925 42.9577 62.0563 42.5834 61.9066C42.2091 61.7568 41.7261 61.5458 41.3042 61.3416C35.9919 58.8421 31.8071 54.4446 29.573 49.0141C27.339 43.5837 27.2175 37.5136 29.2325 31.9981C29.4502 31.3923 29.6952 30.8001 29.9606 30.2215C30.8211 28.3725 31.9142 26.6409 33.2133 25.0689C35.2701 22.5842 37.8169 20.5505 40.6947 19.0948C43.5726 17.639 46.7197 16.7925 49.9394 16.6082C50.6607 16.5673 51.3684 16.5605 52.0761 16.6082C55.6672 16.7123 59.1858 17.6441 62.3581 19.3309C67.5637 25.6542 65.2297 33.2777 65.08 34.9249C65.0616 35.5674 65.2185 36.2027 65.5338 36.7627C65.8491 37.3227 66.3109 37.7863 66.8696 38.1036C69.7835 39.9166 72.2998 42.3016 74.2664 45.1145Z' fill='#252040'/>
      <path d='M48.7905 63.4518C47.0595 63.2651 45.3545 62.8861 43.7073 62.3219C43.3263 62.1925 42.9588 62.0564 42.5845 61.9066C42.2103 61.7569 41.7272 61.5459 41.3053 61.3417C39.4338 60.4636 37.6877 59.3396 36.1133 57.9996C40.1485 57.6729 44.0204 56.2707 48.1372 56.4341C48.1917 57.353 48.2666 58.3331 48.355 59.3269C48.4707 60.6883 48.6136 62.0972 48.7905 63.4518Z' fill='#FCA190'/>
      <path d='M43.7067 62.3218C43.3256 62.1925 42.9582 62.0564 42.5839 61.9066C42.2096 61.7569 41.7266 61.5459 41.3047 61.3417C41.8485 61.3756 42.3939 61.3756 42.9378 61.3417C43.3136 61.2866 43.6972 61.3264 44.0538 61.4574C44.3464 61.6344 44.1966 61.9475 43.7067 62.3218Z' fill='#F07875'/>
      <path d='M55.7245 43.1068C55.7075 45.3697 55.422 47.6226 54.8739 49.8182C53.9505 53.6812 51.6433 57.0716 48.389 59.3475C47.0637 60.2598 45.6158 60.9794 44.0884 61.4848C43.6121 61.6482 43.1289 61.7979 42.6186 61.9272C42.1899 61.7503 41.7612 61.5665 41.3393 61.3623C36.027 58.8628 31.8422 54.4652 29.6082 49.0348C27.3742 43.6044 27.2527 37.5342 29.2676 32.0187C29.4854 31.4129 29.7303 30.8208 29.9957 30.2422C30.8562 28.3931 31.9493 26.6616 33.2484 25.0895C36.4309 21.2396 40.761 18.5086 45.6059 17.2959C46.7853 18.1721 47.906 19.1248 48.9606 20.1479C49.9109 21.1034 50.7033 22.204 51.3082 23.4083C52.9752 26.7188 54.16 30.251 54.8263 33.8974C55.4727 33.4206 56.2578 33.1697 57.0607 33.1832C57.8637 33.1967 58.6399 33.474 59.2698 33.9722C61.0186 35.2587 59.8754 40.8606 55.7245 43.1068Z' fill='#FCA190'/>
      <path d='M29.2305 31.9982C29.4482 31.3924 29.6932 30.8002 29.9586 30.2217C30.2362 30.2822 30.5041 30.3807 30.7548 30.5144C31.7279 31.3107 31.3399 31.6987 29.2305 31.9982Z' fill='#252040'/>
      <path d='M41.8679 29.888C40.7247 31.0247 41.7454 31.2493 45.7942 31.2493C47.0871 31.2493 48.0806 31.3378 48.38 31.0519C49.2918 29.9288 44.1543 28.3565 41.8679 29.888Z' fill='#252040'/>
      <path d='M37.2226 43.8283C35.8072 43.6786 34.9702 43.4948 34.5891 42.9299C34.208 42.3649 34.5075 41.6434 34.8205 41.0376C35.2088 40.3881 35.409 39.6434 35.3989 38.8867C35.3998 38.7006 35.47 38.5214 35.5958 38.3842C35.7217 38.2471 35.894 38.1617 36.0794 38.1448C36.2662 38.1455 36.4461 38.2155 36.5843 38.3413C36.7226 38.467 36.8094 38.6395 36.8279 38.8254C36.7735 39.7792 36.5086 40.709 36.0521 41.5481C35.9386 41.6859 35.8677 41.8539 35.848 42.0314C35.9705 42.1403 36.3312 42.2356 37.277 42.2968C37.3739 42.2954 37.4699 42.3161 37.5577 42.3572C37.6455 42.3983 37.7228 42.4589 37.7837 42.5343C37.8447 42.6097 37.8877 42.6979 37.9096 42.7924C37.9314 42.8869 37.9315 42.9851 37.9098 43.0796C37.9194 43.1751 37.9088 43.2716 37.8785 43.3627C37.8482 43.4538 37.7989 43.5374 37.734 43.6081C37.6691 43.6788 37.59 43.735 37.5019 43.773C37.4137 43.8109 37.3186 43.8298 37.2226 43.8283Z' fill='#F07875'/>
      <path d='M52.2137 21.3252C52.0116 22.0516 51.6946 22.741 51.2746 23.3672C50.7179 24.2621 49.9598 25.0144 49.0607 25.564C48.1615 26.1135 47.1463 26.4451 46.0962 26.5323C43.2382 26.6889 39.4548 23.8505 39.2914 20.9917C39.2914 22.1828 35.8891 23.8981 33.2148 25.0757C35.271 22.5898 37.8175 20.5548 40.6954 19.0979C43.5733 17.6409 46.7207 16.7934 49.9409 16.6082C50.6622 16.5673 51.3699 16.5605 52.0776 16.6082C52.5623 18.137 52.6094 19.771 52.2137 21.3252Z' fill='#252040'/>
      <path d='M31.2832 49.5526C30.2897 47.8985 31.4193 46.8775 32.8211 46.6189C37.8226 45.9382 42.13 45.346 43.3549 46.2241C45.9543 48.0211 41.436 52.0847 37.4348 52.7517C34.3794 53.0444 32.3244 51.0773 31.2832 49.5526Z' fill='white'/>
      <path d='M45.0865 33.9652C43.8821 33.9176 42.875 35.1496 42.8409 36.7287C42.8069 38.3079 43.569 38.9817 45.1341 38.9749C46.3386 38.9749 47.1756 38.478 47.1756 36.9329C47.1756 35.3878 46.2842 34.0129 45.0865 33.9652Z' fill='#252040'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M51.2996 15.9685C37.9919 15.9685 27.2039 26.7595 27.2039 40.0709C27.2039 53.3823 37.9919 64.1734 51.2996 64.1734C64.6073 64.1734 75.3953 53.3823 75.3953 40.0709C75.3953 26.7595 64.6073 15.9685 51.2996 15.9685ZM23.1211 40.0709C23.1211 24.504 35.7371 11.8845 51.2996 11.8845C66.8621 11.8845 79.4781 24.504 79.4781 40.0709C79.4781 55.6379 66.8621 68.2574 51.2996 68.2574C35.7371 68.2574 23.1211 55.6379 23.1211 40.0709Z' fill='#2929B2'/>
      <path fillRule='evenodd' clipRule='evenodd' d='M51.2988 18.2691C39.2614 18.2691 29.5032 28.03 29.5032 40.0708C29.5032 52.1116 39.2614 61.8726 51.2988 61.8726C63.3362 61.8726 73.0944 52.1116 73.0944 40.0708C73.0944 28.03 63.3362 18.2691 51.2988 18.2691ZM26.7812 40.0708C26.7812 26.5263 37.7581 15.5464 51.2988 15.5464C64.8395 15.5464 75.8163 26.5263 75.8163 40.0708C75.8163 53.6153 64.8395 64.5953 51.2988 64.5953C37.7581 64.5953 26.7812 53.6153 26.7812 40.0708Z' fill='white'/>
    </svg>);
};
export default StudentsUnderConsiderationIcon;
