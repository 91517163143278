export const splitBenefits = (benefits) => {
    return benefits.reduce((acc, currentValue) => {
        if (acc.length === 0 && currentValue.type === 'list_item') {
            return [
                {
                    title: '',
                    items: [currentValue.content],
                },
            ];
        }
        if (currentValue.type === 'title') {
            return [
                ...acc,
                {
                    title: currentValue.content,
                    items: [],
                },
            ];
        }
        acc[acc.length - 1]?.items.push(currentValue.content);
        return acc;
    }, []);
};
