import waitForResult from '@common/helpers/waitForResult';
import apiCommon from '@common/utils/ApiCommon';
import { getIsMobileOrTabletUserAgent } from '@common/shared/lib';
export const getCheckoutData = ({ orderUuid, paymentView = 'link', }) => new Promise((resolve, reject) => {
    const pwDevice = getIsMobileOrTabletUserAgent() ? 'mobile' : 'desktop';
    waitForResult(apiCommon.getCheckOutData, {
        params: [{ orderUuid, paymentView, pwDevice }],
        handleResponse: (result) => {
            const { data: response } = result;
            if (!response.successful) {
                reject();
                return false;
            }
            const { data } = response;
            if (data.status === 'wait') {
                return true;
            }
            resolve(data);
            return false;
        },
        handleError: () => {
            reject();
            return false;
        },
        callInterval: 3000,
    });
});
