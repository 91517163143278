import React from 'react';
const ManIdeaIcon = () => {
    return (<svg xmlns='http://www.w3.org/2000/svg' width='128' height='111' fill='none' viewBox='0 0 128 111'>
      <path fill='#2787F5' d='M67.8 110.5c21.047 0 31.573 0 38.126-6.326 6.552-6.326 6.539-16.497 6.539-36.833v-3.754c0-20.355 0-30.533-6.539-36.833-6.54-6.3-17.06-6.32-38.125-6.32H44.867c-21.046 0-31.566 0-38.125 6.327C.184 33.087.21 43.264.21 63.593v3.755c0 20.355 0 30.532 6.533 36.832 6.533 6.301 17.06 6.32 38.126 6.32H67.8z'></path>
      <mask id='mask0_515_1350' style={{ maskType: 'alpha' }} width='113' height='91' x='0' y='20' maskUnits='userSpaceOnUse'>
        <path fill='#2C2D2E' d='M67.8 110.5c21.047 0 31.573 0 38.126-6.326 6.552-6.326 6.539-16.497 6.539-36.833v-3.754c0-20.355 0-30.533-6.539-36.833-6.54-6.3-17.06-6.32-38.125-6.32H44.867c-21.046 0-31.566 0-38.125 6.327C.184 33.087.21 43.264.21 63.593v3.755c0 20.355 0 30.532 6.533 36.832 6.533 6.301 17.06 6.32 38.126 6.32H67.8z'></path>
      </mask>
      <g mask='url(#mask0_515_1350)'>
        <path fill='#F45FFF' d='M110.701 83.78C99.567 53.616 67.84 66.701 67.84 66.701s.988 5.635-10.734 5.648c-7.508.898-12.413-7.211-12.413-7.211-21.75-4.491-37.188-.375-46.054 36.91l-7.004 26.216 116.494 1.292c3.011-8.297 5.855-36.904 2.572-45.776z'></path>
        <mask id='mask1_515_1350' style={{ maskType: 'alpha' }} width='122' height='67' x='-9' y='63' maskUnits='userSpaceOnUse'>
          <path fill='#CC61FA' d='M110.701 83.78C99.567 53.616 67.84 66.701 67.84 66.701s.988 5.635-10.734 5.648c-7.508.898-12.413-7.211-12.413-7.211-21.75-4.491-37.188-.375-46.054 36.91l-7.004 26.216 116.494 1.292c3.011-8.297 5.855-36.904 2.572-45.776z'></path>
        </mask>
        <g mask='url(#mask1_515_1350)'>
          <path fill='#70059E' d='M72.777 63.102H63.73c.097 1.06.168 2.068.317 3.076.38 2.908.769 5.816 1.221 8.678a89.903 89.903 0 002.669 11.735l.174.575h9.59l-.168-.433c-2.779-7.612-3.794-15.618-4.756-23.63zM99.154 63.102h-9.047l.052.381c.168 2.023.336 4.04.549 6.036.317 2.765.646 5.557 1.06 8.323.459 2.953 1.079 5.912 1.609 8.872l.07.478h9.357l-.096-.362c-2.062-7.78-2.83-15.741-3.554-23.728zM50.393 81.719a89.863 89.863 0 01-3.606-16.09c-.123-.84-.24-1.706-.362-2.546h-9.047c.431 3.773 1.017 7.5 1.758 11.179a77.332 77.332 0 003.367 12.096.78.78 0 00.097.214l.045.142v.052l.142.361h10.029l-.168-.265a28.217 28.217 0 01-2.255-5.143z'></path>
          <path fill='#fff' d='M-1.419 80.465a59.23 59.23 0 01-4.4-14.403c-.194-.983-.362-1.971-.53-2.98h-9.111l.045.363a95.285 95.285 0 001.997 9.977 59.853 59.853 0 004.736 12.672l.213.407.078.148.265.504h11.25a21.84 21.84 0 01-4.543-6.688z'></path>
          <path fill='#70059E' d='M25.217 82.656a63.324 63.324 0 01-4.252-14.113c-.362-1.784-.646-3.586-.963-5.409h-9.046l.051.356v.077a103.94 103.94 0 002.333 12.187 63.542 63.542 0 003.774 10.914l.213.53H27.93l-.336-.407a22.93 22.93 0 01-2.378-4.135zM72.777 87.153H63.73c.097 1.053.168 2.068.317 3.076.38 2.908.769 5.816 1.221 8.678a89.883 89.883 0 002.669 11.735l.174.575h9.59l-.168-.433c-2.779-7.618-3.794-15.65-4.756-23.63zM99.154 87.127h-9.047l.052.388c.168 2.016.336 4.039.549 6.029.317 2.766.646 5.557 1.06 8.323.459 2.979 1.079 5.912 1.609 8.872l.07.478h9.357l-.096-.355c-2.062-7.793-2.83-15.754-3.554-23.735zM50.393 105.75a89.85 89.85 0 01-3.606-16.09c-.123-.84-.24-1.706-.362-2.546h-9.047c.431 3.774 1.017 7.5 1.758 11.18a77.556 77.556 0 003.367 12.096l.097.213.045.142v.052l.142.362h10.029l-.168-.265a28.806 28.806 0 01-2.255-5.144z'></path>
          <path fill='#fff' d='M-1.419 104.51a59.317 59.317 0 01-4.4-14.404 85.605 85.605 0 01-.53-2.979h-9.111l.045.362a95.283 95.283 0 001.997 9.977 59.839 59.839 0 004.736 12.672l.213.407.078.142.265.504h11.25a22.152 22.152 0 01-4.543-6.681z'></path>
          <path fill='#70059E' d='M25.217 106.674a63.211 63.211 0 01-4.252-14.112c-.362-1.777-.646-3.587-.963-5.409h-9.046l.051.362v.071a108.094 108.094 0 002.333 12.187 63.297 63.297 0 003.774 10.914l.213.53H27.93l-.336-.407a22.082 22.082 0 01-2.378-4.136z'></path>
        </g>
      </g>
      <path fill='#212121' d='M84.356 41.778c.95-18.423-4.885-35.288-15.844-34.94C66.405 2.6 54.244-1.09 43.53 1.205c-8.504 1.822-12.413 9.389-7.806 17.447-11.14 12.769-8.103 21.266-2.54 22.998-.335 6.733 5.655 20.594 30.533 18.255 6.43-.595 20.368-12.782 20.64-18.126z'></path>
      <path fill='#FFA9A9' d='M46.412 51.064c1.072 2.132.943 7.96-1.907 14.06-1.9 4.078 2.501 11.406 10.986 11.82 9.156.684 14.59-2.727 15.03-10.436 0 0-.853-17.383 1.35-23.631l-25.46 8.187z'></path>
      <path fill='#FF8481' d='M46.696 58.243c.34-1.743.417-3.527.226-5.292 0-.724 0-1.403-.064-2.03l3.496-1.124c5.298.685 13.104 1.629 14.255 1.235 1.395-.479 2.507.394-.084 4.264-3.193 4.776-13.092 5.913-17.829 2.947z'></path>
      <path fill='#FFA9A9' d='M54.128 12.842c-5.713.646-9.894 1.467-12.84 22.125-.181 1.092-2.404-1.383-3.651 1.112-.866 1.725.698 4.646 2.96 7.405 2.067 8.323 9.007 14.487 17.95 13.428 7.477-.892 10.895-5.17 13.008-13.454 0-1.066 6.863-6.074 4.82-9.86-2.041-3.787-5.97-.298-6.364-1.726-4.711-17.098-9.81-19.715-15.883-19.03z'></path>
      <path fill='#FF8481' d='M55.51 33.927c.362-.646-.613-.465-1.596-1.525-1.111-1.209.789-3.47.188-3.71-.601-.238-1.79 1.468-1.978 3.27-.226 2.191 2.792 3.063 3.386 1.965z'></path>
      <path fill='#212121' d='M65.113 21.94c.452-1.073-3.095-3.095-5.286-3.23-2.456-.182-3.36.645-2.623 1.938.898 1.57 7.463 2.39 7.909 1.292zM43.09 24.079c-.794-.853 1.842-3.968 3.877-4.872 2.25-1.021 3.115-.575 3.135.917.019 1.493-6.223 4.814-7.012 3.955zM62.773 28.111c.407 1.751-.187 2.32-1.938 2.094-1.092-.142-2.068-.853-1.396-2.688.523-1.493 2.856-1.454 3.334.594zM46.134 29.139c-.136 1.79.536 2.268 2.242 1.77 1.054-.303 1.939-1.15.97-2.862-.75-1.396-3.024-1.009-3.212 1.092zM57.649 39.568c-1.732 1.402-3.877 1.144-5.086.82l.253-.936c1.214.323 3.482.575 4.982-1.428l.775.581a5.27 5.27 0 01-.924.963zM98.586 36.936l-.918 1.719a4.522 4.522 0 00-.155 3.922 5.02 5.02 0 002.1 2.41 4.447 4.447 0 002.928.375 4.626 4.626 0 003.166-2.403l.918-1.72-8.04-4.303z'></path>
      <path fill='#FFD91D' d='M117.461 33.402a10.34 10.34 0 10-18.972-1.629 7.6 7.6 0 01-.07 4.82c-.143.427-.195.757-.053.854.647.387 2.521 1.376 3.231 1.744 1.196.647 4.045 2.217 4.711 2.514.175.071.472-.2.795-.607a7.67 7.67 0 013.967-2.585 10.34 10.34 0 006.391-5.111z'></path>
      <path fill='#fff' d='M105.907 35.696a.722.722 0 01-.697-.737c0-1.047 1.189-1.409 2.126-1.693a6.599 6.599 0 001.008-.368 7.035 7.035 0 00-.989-.42c-.918-.337-2.061-.757-2.029-1.803.032-1.047 1.189-1.41 2.119-1.693a7.264 7.264 0 001.008-.369 6.973 6.973 0 00-.988-.42c-.918-.336-2.055-.756-2.029-1.81.026-1.052 1.189-1.408 2.119-1.692.392-.1.769-.247 1.125-.44a.742.742 0 01.723-.646.715.715 0 01.698.737c0 1.047-1.189 1.409-2.119 1.693a6.658 6.658 0 00-1.015.375c.32.166.653.306.995.42.911.336 2.055.756 2.029 1.803-.025 1.047-1.189 1.408-2.126 1.7a6.582 6.582 0 00-1.008.361 6.3 6.3 0 00.989.42c.918.336 2.061.756 2.029 1.803-.032 1.047-1.189 1.409-2.119 1.7a4.456 4.456 0 00-1.118.439.722.722 0 01-.731.64z'></path>
      <path fill='#FFD91D' d='M100.441 17.693a.82.82 0 01-.718-.427l-1.55-2.895a.814.814 0 011.434-.769l1.551 2.889a.816.816 0 01-.717 1.202zM119.187 20.116a.815.815 0 01-.498-1.46l2.649-2.042a.816.816 0 01.995 1.292l-2.649 2.042a.807.807 0 01-.497.168zM126.928 28.264l-4.569-.18a.811.811 0 01-.732-.533.82.82 0 01-.05-.314.795.795 0 01.84-.782l4.575.18a.813.813 0 11-.032 1.63h-.032zM110.036 15.237a.819.819 0 01-.788-.846l.168-4.62a.826.826 0 01.259-.568.812.812 0 011.151.041.818.818 0 01.218.584l-.168 4.627a.813.813 0 01-.84.782z'></path>
    </svg>);
};
export default ManIdeaIcon;
