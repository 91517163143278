import storage from '@common/utils/Storage';
import { STORAGE_API_AUTH } from '@common/consts';
import isJWTValid from './isJWTValid';
const getJWTifValid = () => {
    try {
        const authToken = storage.get(STORAGE_API_AUTH);
        if (typeof authToken === 'string' && isJWTValid(authToken)) {
            return authToken;
        }
    }
    catch { }
    return null;
};
export default getJWTifValid;
