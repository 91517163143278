import { createOffers } from '@common/entities/offers/model';
const { $offers, getOffersFx, $offersState, $pendingGetOffers } = createOffers();
const $offersCount = $offers.map((state) => {
    if (!state) {
        return 0;
    }
    if (!state.success) {
        return 0;
    }
    return state.payload.length;
});
export const RootTemplateModel = { $offers, $offersCount, getOffersFx, $offersState, $pendingGetOffers };
