export class StrategyContext {
    constructor(context) {
        this.context = context;
    }
    setStrategy(strategy) {
        this.context = strategy;
    }
    async doStrategy(payload) {
        await this.context.do(payload);
    }
}
