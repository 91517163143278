import React from 'react';
import { calcHeight } from '@common/entities/student/lib';
const DEFAULT_WIDTH = 80;
const DEFAULT_HEIGHT = 88;
const UploadDocument = ({ width = DEFAULT_WIDTH }) => {
    const height = calcHeight({ width, defaultWidth: DEFAULT_WIDTH, defaultHeight: DEFAULT_HEIGHT });
    return (<svg width={width} height={height} viewBox={`0 0 80 88`} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='surface1'>
        <path fill='#2929B2' d='M 0 25.601562 C 0 13.984375 0 8.179688 3.617188 4.574219 C 7.234375 0.972656 13.023438 0.964844 24.691406 0.964844 L 41.703125 0.964844 C 53.339844 0.964844 59.15625 0.964844 62.773438 4.574219 C 66.394531 8.183594 66.394531 13.960938 66.394531 25.601562 L 66.394531 50.105469 C 66.394531 61.714844 66.394531 67.523438 62.773438 71.132812 C 59.15625 74.742188 53.371094 74.742188 41.703125 74.742188 L 24.691406 74.742188 C 13.054688 74.742188 7.230469 74.742188 3.617188 71.132812 C 0.0078125 67.523438 0 61.746094 0 50.105469 Z M 0 25.601562 '/>
        <path fill='#2929B2' d='M 0 25.601562 C 0 13.984375 0 8.179688 3.617188 4.574219 C 7.234375 0.972656 13.023438 0.964844 24.691406 0.964844 L 41.703125 0.964844 C 53.339844 0.964844 59.15625 0.964844 62.773438 4.574219 C 66.394531 8.183594 66.394531 13.960938 66.394531 25.601562 L 66.394531 50.105469 C 66.394531 61.714844 66.394531 67.523438 62.773438 71.132812 C 59.15625 74.742188 53.371094 74.742188 41.703125 74.742188 L 24.691406 74.742188 C 13.054688 74.742188 7.230469 74.742188 3.617188 71.132812 C 0.0078125 67.523438 0 61.746094 0 50.105469 Z M 0 25.601562 '/>
        <path fill='white' d='M 5.371094 27.292969 C 5.371094 17.28125 5.371094 12.277344 8.40625 9.148438 C 11.441406 6.019531 16.3125 6.019531 26.070312 6.019531 L 40.320312 6.019531 C 50.078125 6.019531 54.957031 6.019531 57.984375 9.148438 C 61.011719 12.277344 61.011719 17.28125 61.011719 27.292969 L 61.011719 48.390625 C 61.011719 58.398438 61.011719 63.40625 57.984375 66.535156 C 54.957031 69.664062 50.078125 69.664062 40.320312 69.664062 L 26.070312 69.664062 C 16.3125 69.664062 11.4375 69.664062 8.40625 66.535156 C 5.378906 63.40625 5.371094 58.398438 5.371094 48.390625 Z M 5.371094 27.292969 '/>
        <path fill='#00EAFF' d='M 34.226562 48.558594 L 14.121094 48.558594 C 13.148438 48.558594 12.359375 49.34375 12.359375 50.316406 L 12.359375 50.324219 C 12.359375 51.292969 13.148438 52.082031 14.121094 52.082031 L 34.226562 52.082031 C 35.199219 52.082031 35.988281 51.292969 35.988281 50.324219 L 35.988281 50.316406 C 35.988281 49.34375 35.199219 48.558594 34.226562 48.558594 Z M 34.226562 48.558594 '/>
        <path fill='#00EAFF' d='M 23.269531 55.710938 L 14.121094 55.710938 C 13.148438 55.710938 12.359375 56.496094 12.359375 57.46875 L 12.359375 57.472656 C 12.359375 58.445312 13.148438 59.230469 14.121094 59.230469 L 23.269531 59.230469 C 24.242188 59.230469 25.03125 58.445312 25.03125 57.472656 L 25.03125 57.46875 C 25.03125 56.496094 24.242188 55.710938 23.269531 55.710938 Z M 23.269531 55.710938 '/>
        <path fill='#00EAFF' d='M 53.476562 55.710938 L 30.117188 55.710938 C 29.140625 55.710938 28.351562 56.496094 28.351562 57.46875 L 28.351562 57.472656 C 28.351562 58.445312 29.140625 59.230469 30.117188 59.230469 L 53.476562 59.230469 C 54.449219 59.230469 55.238281 58.445312 55.238281 57.472656 L 55.238281 57.46875 C 55.238281 56.496094 54.449219 55.710938 53.476562 55.710938 Z M 53.476562 55.710938 '/>
        <path fill='#00EAFF' d='M 53.484375 17.195312 L 38.886719 17.195312 C 37.914062 17.195312 37.125 17.980469 37.125 18.953125 L 37.125 18.957031 C 37.125 19.929688 37.914062 20.714844 38.886719 20.714844 L 53.484375 20.714844 C 54.457031 20.714844 55.246094 19.929688 55.246094 18.957031 L 55.246094 18.953125 C 55.246094 17.980469 54.457031 17.195312 53.484375 17.195312 Z M 53.484375 17.195312 '/>
        <path fill='#00EAFF' d='M 40.949219 24.34375 L 38.886719 24.34375 C 37.914062 24.34375 37.125 25.132812 37.125 26.105469 L 37.125 26.109375 C 37.125 27.082031 37.914062 27.867188 38.886719 27.867188 L 40.949219 27.867188 C 41.925781 27.867188 42.710938 27.082031 42.710938 26.109375 L 42.710938 26.105469 C 42.710938 25.132812 41.925781 24.34375 40.949219 24.34375 Z M 40.949219 24.34375 '/>
        <path fill='#00EAFF' d='M 48.035156 31.503906 L 38.886719 31.503906 C 37.914062 31.503906 37.125 32.289062 37.125 33.261719 L 37.125 33.265625 C 37.125 34.238281 37.914062 35.027344 38.886719 35.027344 L 48.035156 35.027344 C 49.007812 35.027344 49.796875 34.238281 49.796875 33.265625 L 49.796875 33.261719 C 49.796875 32.289062 49.007812 31.503906 48.035156 31.503906 Z M 48.035156 31.503906 '/>
        <path fill='#00EAFF' d='M 53.476562 24.34375 L 46.484375 24.34375 C 45.511719 24.34375 44.722656 25.132812 44.722656 26.105469 L 44.722656 26.109375 C 44.722656 27.082031 45.511719 27.867188 46.484375 27.867188 L 53.476562 27.867188 C 54.449219 27.867188 55.238281 27.082031 55.238281 26.109375 L 55.238281 26.105469 C 55.238281 25.132812 54.449219 24.34375 53.476562 24.34375 Z M 53.476562 24.34375 '/>
        <path fill='#00EAFF' d='M 33.4375 26.234375 L 33.4375 35.25 C 33.4375 38.378906 33.4375 40.394531 32.96875 41.746094 C 32.820312 42.191406 32.585938 42.601562 32.277344 42.957031 C 32.046875 43.226562 31.765625 43.453125 31.449219 43.621094 C 30.261719 44.285156 28.480469 44.285156 25.527344 44.285156 L 20.070312 44.285156 C 16.308594 44.285156 14.472656 44.285156 13.3125 42.957031 C 13.117188 42.734375 12.953125 42.484375 12.824219 42.214844 C 12.675781 41.914062 12.5625 41.597656 12.484375 41.269531 C 12.152344 39.9375 12.152344 38.054688 12.152344 35.25 L 12.152344 26.234375 C 12.152344 21.972656 12.152344 19.839844 13.3125 18.519531 C 14.472656 17.199219 16.339844 17.195312 20.070312 17.195312 L 25.527344 17.195312 C 29.289062 17.195312 31.117188 17.195312 32.277344 18.519531 C 33.4375 19.847656 33.4375 21.972656 33.4375 26.234375 Z M 33.4375 26.234375 '/>
        <path fill='#252040' d='M 33.4375 32.546875 L 33.4375 35.25 C 33.4375 38.378906 33.4375 40.394531 32.96875 41.746094 C 32.820312 42.191406 32.585938 42.601562 32.277344 42.960938 C 32.046875 43.230469 31.765625 43.453125 31.453125 43.621094 C 29.476562 43.726562 27.496094 43.699219 25.527344 43.539062 C 23.019531 43.359375 20.359375 43.066406 18.003906 42.785156 C 16.015625 42.558594 14.238281 42.339844 12.859375 42.222656 C 12.710938 41.917969 12.597656 41.601562 12.523438 41.277344 C 12.191406 39.941406 12.191406 38.058594 12.191406 35.257812 L 12.191406 31.972656 C 12.421875 31.679688 12.667969 31.398438 12.929688 31.132812 C 16.445312 28.050781 12.816406 27.066406 15.683594 24.878906 C 16.5 24.457031 17.183594 23.820312 17.65625 23.039062 C 18.484375 21.074219 20.316406 20.578125 22.730469 21.160156 C 23.035156 21.238281 23.355469 21.265625 23.671875 21.242188 C 26.734375 20.511719 27.464844 22.582031 27.902344 23.386719 C 28.53125 24.507812 29.371094 24.578125 30.085938 25.089844 C 32.945312 27.109375 31.808594 30.039062 31.722656 30.589844 C 31.703125 30.765625 31.734375 30.945312 31.8125 31.105469 C 31.890625 31.265625 32.011719 31.402344 32.160156 31.496094 C 32.632812 31.789062 33.0625 32.140625 33.4375 32.546875 Z M 33.4375 32.546875 '/>
        <path fill='#CC61FA' d='M 32.964844 41.714844 C 32.816406 42.160156 32.582031 42.570312 32.277344 42.925781 C 32.042969 43.199219 31.761719 43.421875 31.449219 43.589844 C 30.257812 44.253906 28.476562 44.253906 25.523438 44.253906 L 20.070312 44.253906 C 16.308594 44.253906 14.46875 44.253906 13.308594 42.925781 C 13.113281 42.703125 12.949219 42.453125 12.820312 42.183594 C 12.671875 41.882812 12.558594 41.566406 12.480469 41.238281 C 13.96875 40.824219 15.832031 40.367188 17.453125 40.082031 C 17.957031 39.992188 18.433594 39.917969 18.871094 39.863281 C 19.308594 39.804688 19.59375 39.785156 19.894531 39.773438 C 20.648438 39.972656 21.429688 40.046875 22.207031 39.992188 L 22.933594 39.992188 C 23.851562 40.082031 24.777344 40.015625 25.675781 39.800781 C 25.882812 39.800781 26.09375 39.800781 26.300781 39.855469 C 26.867188 39.921875 27.425781 40.019531 27.980469 40.148438 C 29.679688 40.550781 31.34375 41.070312 32.964844 41.714844 Z M 32.964844 41.714844 '/>
        <path fill='#FCA190' d='M 25.960938 39.535156 C 25.660156 38.167969 25.53125 36.765625 25.582031 35.363281 C 24.226562 35.21875 22.929688 35.695312 21.582031 35.558594 L 19.914062 34.855469 C 19.972656 36.402344 19.796875 37.949219 19.394531 39.441406 C 18.304688 39.804688 17.796875 42.652344 23.101562 42.675781 C 28.402344 42.703125 26.546875 39.730469 25.960938 39.535156 Z M 25.960938 39.535156 '/>
        <path fill='#F07875' d='M 19.824219 37.128906 C 21.804688 38.273438 25.542969 36.621094 24.050781 36.613281 C 22.558594 36.609375 19.882812 35.488281 19.882812 35.488281 C 19.902344 36.035156 19.882812 36.582031 19.824219 37.128906 Z M 19.824219 37.128906 '/>
        <path fill='#FCA190' d='M 29.074219 29.339844 C 28.90625 29.183594 28.691406 29.089844 28.460938 29.070312 C 28.234375 29.054688 28.007812 29.113281 27.820312 29.238281 C 27.585938 27.511719 27.039062 25.980469 26.425781 25.328125 C 25.679688 24.515625 24.386719 23.351562 22.992188 23.34375 C 21.59375 23.339844 19.949219 24.519531 19.230469 25.296875 C 18.601562 25.921875 18.054688 27.488281 17.8125 29.226562 C 17.722656 29.148438 17.621094 29.089844 17.507812 29.054688 C 17.398438 29.015625 17.28125 29.003906 17.164062 29.011719 C 17.046875 29.019531 16.933594 29.050781 16.832031 29.105469 C 16.726562 29.15625 16.632812 29.230469 16.558594 29.320312 C 16.117188 29.789062 16.488281 31.277344 17.734375 31.820312 C 17.773438 32.441406 17.886719 33.050781 18.074219 33.644531 C 18.621094 35.363281 20.113281 36.832031 22.816406 36.910156 C 25.515625 36.910156 27.023438 35.402344 27.585938 33.679688 C 27.777344 33.070312 27.898438 32.441406 27.945312 31.804688 C 29.117188 31.265625 29.53125 29.738281 29.074219 29.339844 Z M 29.074219 29.339844 '/>
        <path fill='#252040' d='M 21.226562 27.875 C 21.558594 28.179688 21.277344 28.257812 20.160156 28.285156 C 19.800781 28.285156 19.53125 28.335938 19.445312 28.285156 C 19.179688 27.960938 20.585938 27.472656 21.226562 27.875 Z M 21.226562 27.875 '/>
        <path fill='#252040' d='M 24.324219 27.90625 C 23.984375 28.199219 24.261719 28.28125 25.386719 28.34375 C 25.746094 28.34375 26.015625 28.414062 26.109375 28.34375 C 26.398438 28.050781 24.980469 27.523438 24.324219 27.90625 Z M 24.324219 27.90625 '/>
        <path fill='#F07875' d='M 22.78125 31.679688 C 22.390625 31.609375 22.152344 31.546875 22.066406 31.382812 C 22.035156 31.296875 22.027344 31.203125 22.042969 31.109375 C 22.0625 31.019531 22.105469 30.933594 22.164062 30.863281 C 22.285156 30.691406 22.355469 30.488281 22.367188 30.277344 C 22.371094 30.226562 22.390625 30.179688 22.425781 30.144531 C 22.460938 30.105469 22.511719 30.085938 22.5625 30.082031 C 22.609375 30.085938 22.65625 30.109375 22.695312 30.144531 C 22.730469 30.179688 22.75 30.226562 22.753906 30.277344 C 22.722656 30.542969 22.632812 30.800781 22.492188 31.027344 C 22.457031 31.0625 22.433594 31.105469 22.421875 31.152344 C 22.460938 31.191406 22.554688 31.222656 22.816406 31.253906 C 22.84375 31.253906 22.871094 31.261719 22.894531 31.273438 C 22.917969 31.289062 22.9375 31.308594 22.953125 31.328125 C 22.96875 31.351562 22.980469 31.378906 22.984375 31.40625 C 22.984375 31.429688 22.984375 31.457031 22.976562 31.484375 C 22.976562 31.511719 22.972656 31.535156 22.964844 31.5625 C 22.953125 31.585938 22.941406 31.605469 22.921875 31.625 C 22.902344 31.644531 22.882812 31.660156 22.855469 31.667969 C 22.832031 31.675781 22.804688 31.679688 22.78125 31.679688 Z M 22.78125 31.679688 '/>
        <path fill='#252040' d='M 24.015625 25.558594 C 24.03125 25.957031 24.203125 26.335938 24.488281 26.613281 C 24.773438 26.890625 25.15625 27.046875 25.558594 27.054688 C 25.957031 27.042969 26.339844 26.90625 26.660156 26.667969 C 26.980469 26.429688 27.21875 26.101562 27.34375 25.722656 C 27.460938 25.339844 27.488281 24.9375 27.421875 24.542969 C 27.359375 24.148438 27.203125 23.773438 26.96875 23.449219 C 26.5 22.804688 25.84375 22.316406 25.085938 22.054688 C 23.9375 21.664062 22.6875 21.667969 21.539062 22.0625 C 20.386719 22.460938 19.40625 23.226562 18.742188 24.246094 C 17.9375 25.539062 17.984375 26.910156 19.683594 27.03125 C 20.453125 27.097656 21.230469 27.019531 21.96875 26.796875 C 22.320312 26.6875 24 26.046875 24.015625 25.558594 Z M 24.015625 25.558594 '/>
        <path fill='#CC61FA' d='M 28.835938 42.175781 C 23.9375 44.558594 21.140625 44.546875 16.765625 42.125 L 18.019531 38.480469 C 18.082031 38.304688 18.179688 38.140625 18.308594 38.007812 C 18.4375 37.871094 18.59375 37.761719 18.765625 37.691406 C 18.9375 37.621094 19.125 37.585938 19.308594 37.589844 C 19.496094 37.597656 19.679688 37.640625 19.851562 37.722656 C 21.71875 38.523438 23.824219 38.570312 25.730469 37.859375 C 26.054688 37.742188 26.410156 37.753906 26.722656 37.894531 C 27.035156 38.035156 27.277344 38.292969 27.40625 38.609375 Z M 28.835938 42.175781 '/>
        <path fill='white' d='M 21.175781 33.097656 C 20.9375 32.648438 21.257812 32.402344 21.632812 32.367188 C 22.980469 32.277344 24.140625 32.210938 24.449219 32.460938 C 25.121094 32.980469 23.820312 33.957031 22.769531 34.050781 C 22.441406 34.042969 22.125 33.949219 21.847656 33.785156 C 21.566406 33.617188 21.335938 33.378906 21.175781 33.097656 Z M 21.175781 33.097656 '/>
        <path fill='#252040' d='M 20.386719 29.054688 C 20.71875 29.054688 21.011719 29.371094 21.011719 29.800781 C 21.011719 29.96875 20.945312 30.125 20.828125 30.242188 C 20.710938 30.359375 20.550781 30.425781 20.386719 30.425781 C 20.054688 30.457031 19.820312 30.3125 19.796875 29.875 C 19.773438 29.691406 19.820312 29.503906 19.929688 29.351562 C 20.039062 29.199219 20.203125 29.09375 20.386719 29.054688 Z M 20.386719 29.054688 '/>
        <path fill='#252040' d='M 25.144531 29.089844 C 24.8125 29.058594 24.515625 29.375 24.464844 29.8125 C 24.414062 30.253906 24.628906 30.441406 25.0625 30.476562 C 25.492188 30.515625 25.632812 30.378906 25.6875 29.9375 C 25.726562 29.753906 25.691406 29.5625 25.589844 29.402344 C 25.488281 29.246094 25.328125 29.132812 25.144531 29.089844 Z M 25.144531 29.089844 '/>
        <path fill='#2929B2' d='M 59.175781 40.925781 C 55.242188 40.925781 51.390625 42.023438 48.050781 44.09375 C 44.710938 46.167969 42.019531 49.132812 40.285156 52.652344 C 38.546875 56.171875 37.832031 60.109375 38.222656 64.011719 C 38.613281 67.917969 40.089844 71.632812 42.492188 74.742188 C 53.59375 74.742188 59.242188 74.65625 62.773438 71.132812 C 66.304688 67.609375 66.390625 61.746094 66.390625 50.105469 L 66.390625 42.195312 C 64.078125 41.355469 61.636719 40.925781 59.175781 40.925781 Z M 59.175781 40.925781 '/>
        <path fill='white' d='M 61.015625 81.976562 C 71.5 81.976562 80 73.492188 80 63.03125 C 80 52.566406 71.5 44.085938 61.015625 44.085938 C 50.527344 44.085938 42.027344 52.566406 42.027344 63.03125 C 42.027344 73.492188 50.527344 81.976562 61.015625 81.976562 Z M 61.015625 81.976562 '/>
        <path fill='#CC61FA' d='M 67.117188 70.628906 L 55.023438 70.628906 C 54.269531 70.628906 53.65625 71.242188 53.65625 72 C 53.65625 72.753906 54.269531 73.367188 55.023438 73.367188 L 67.117188 73.367188 C 67.871094 73.367188 68.484375 72.753906 68.484375 72 C 68.484375 71.242188 67.871094 70.628906 67.117188 70.628906 Z M 67.117188 70.628906 '/>
        <path fill='#CC61FA' d='M 59.863281 67.890625 L 54.832031 63.25 C 54.640625 63.058594 54.511719 62.816406 54.457031 62.550781 C 54.402344 62.289062 54.425781 62.015625 54.523438 61.765625 C 54.621094 61.511719 54.789062 61.296875 55.007812 61.136719 C 55.226562 60.980469 55.484375 60.886719 55.753906 60.875 L 66.390625 60.875 C 66.660156 60.886719 66.917969 60.980469 67.136719 61.136719 C 67.355469 61.296875 67.523438 61.511719 67.621094 61.765625 C 67.714844 62.015625 67.738281 62.289062 67.683594 62.550781 C 67.628906 62.816406 67.5 63.058594 67.3125 63.25 L 62.28125 67.890625 C 61.949219 68.191406 61.519531 68.359375 61.070312 68.359375 C 60.625 68.359375 60.191406 68.191406 59.863281 67.890625 Z M 59.863281 67.890625 '/>
        <path fill='#CC61FA' d='M 63.203125 61.515625 L 58.925781 61.515625 L 57.976562 51.621094 C 57.964844 51.433594 57.992188 51.242188 58.058594 51.0625 C 58.121094 50.882812 58.222656 50.71875 58.355469 50.578125 C 58.484375 50.441406 58.644531 50.332031 58.816406 50.253906 C 58.992188 50.179688 59.183594 50.140625 59.375 50.140625 L 62.855469 50.140625 C 63.042969 50.140625 63.234375 50.179688 63.410156 50.253906 C 63.582031 50.332031 63.742188 50.441406 63.871094 50.578125 C 64.003906 50.71875 64.105469 50.882812 64.171875 51.0625 C 64.234375 51.242188 64.261719 51.433594 64.25 51.621094 Z M 63.203125 61.515625 '/>
      </g>
    </svg>);
};
export default UploadDocument;
