import React from 'react';
import { useUnit } from 'effector-react';
import { ButtonGroup, Spacing } from '@vkontakte/vkui';
import parser from 'html-react-parser';
import { createAnalyticClickAttribute } from '@common/utils/Analytics';
import { typografIt } from '@common/components/common/Typograf';
import ModalButton from '@common/components/modals/ModalButton';
import { intl } from '@common/shared/lib/intl';
import { modalManagerModel } from '@common/services/modal-manager';
import { snackbarManagerModel } from '@common/services/snackbar-manager';
import { groupSubscriptionMembersModel } from '@common/features/group-subscription/model';
import { analyticsDictionary } from './lib';
import { messages } from './messages';
const Actions = (props) => {
    const { userId } = props;
    const closeModal = useUnit(modalManagerModel.closeModal);
    return (<ButtonGroup stretched={true}>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.buttonCancel)} mode='secondary' onClick={() => closeModal()}>
        {intl.formatMessage(messages.button_cancel)}
      </ModalButton>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.buttonConfirm)} appearance='negative' mode='secondary' onClick={() => {
            groupSubscriptionMembersModel.removeMember({
                userId,
                errorMessage: intl.formatMessage(messages.remove_member_error_message),
            });
            closeModal();
            snackbarManagerModel.addSnackSuccess({
                children: intl.formatMessage(messages.remove_member_success_message),
            });
        }}>
        {intl.formatMessage(messages.button_confirm)}
      </ModalButton>
    </ButtonGroup>);
};
const payloadManagerModal = (payload) => {
    return {
        analyticShowAttribute: analyticsDictionary.show,
        header: parser(typografIt(intl.formatMessage(messages.title))),
        subheader: parser(typografIt(intl.formatMessage(messages.description, { name: payload.firstName }))),
        children: <Spacing size={16}/>,
        actions: <Actions {...payload}/>,
    };
};
export const showMemberManagementConfirmModal = (payload) => modalManagerModel.showModal(payloadManagerModal(payload));
