export const BUTTON_STATE = {
    IDLE: 'idle',
    LOADING: 'loading',
    PAUSE_RECURRENT: 'pause-recurrent',
};
export const SUBSCRIPTION_STATE = {
    LOADING: 'loading',
    MANAGEMENT: 'management',
    PAUSE_RECURRENT: 'pause-recurrent',
    GROUP: 'group',
};
