import ruMessages from './ru.json';
const MESSAGES_FROM_LOCALES = {
    ru: ruMessages,
};
export const getAllMessages = () => {
    return MESSAGES_FROM_LOCALES;
};
export const getMessagesFromCurrentLocale = (locale) => {
    const messages = getAllMessages();
    const result = messages[locale];
    if (!result) {
        console.error('Текстов с такой локалью не существует');
        return;
    }
    return result;
};
