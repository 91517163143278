import React from 'react';
import Typograf from '@common/components/common/Typograf';
import messages from '@common/widgets/payment-method-widget/ui/current-payment-method/lib/modals/showUnlinkCardReasonModal/messages';
import { Header as HeaderFixedModal } from '@common/services/vk-ui-modal-manager/ui/ModalPageWithFixedFooter';
import { modalManagerModel } from '@common/services/modal-manager';
import { useIntl } from 'react-intl';
export const Header = () => {
    const intl = useIntl();
    const closeModal = () => {
        modalManagerModel.closeModal();
    };
    return (<HeaderFixedModal handleClick={closeModal}>
      <Typograf>{intl.formatMessage(messages.title)}</Typograf>
    </HeaderFixedModal>);
};
