import icon90Music3XWebp from './icon-90-music@3x.webp';
import icon90Music3XPng from './icon-90-music@3x.png';
import icon90Music2XWebp from './icon-90-music@2x.webp';
import icon90Music2XPng from './icon-90-music@2x.png';
import icon90Music1XWebp from './icon-90-music@1x.webp';
import icon90Music1XPng from './icon-90-music@1x.png';
export const icon90MusicImage = {
    src: icon90Music1XPng.src,
    width: 90,
    height: 90,
    sources: [
        { width: 90, height: 90, srcSet: icon90Music3XWebp.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 90, height: 90, srcSet: icon90Music3XPng.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 90, height: 90, srcSet: icon90Music2XWebp.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 90, height: 90, srcSet: icon90Music2XPng.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 90, height: 90, srcSet: icon90Music1XWebp.src },
        { width: 90, height: 90, srcSet: icon90Music1XPng.src },
    ],
};
