import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    paymentMethodTitle: {
        id: 'paymentMethodTitle',
        defaultMessage: 'Способ оплаты',
    },
    paymentMethodStatusWait: {
        id: 'paymentMethodStatusWait',
        defaultMessage: 'В процессе привязки',
    },
    paymentMethodStatusNotBound: {
        id: 'paymentMethodStatusNotBound',
        defaultMessage: 'Не выбран',
    },
    paymentMethodStatusBound: {
        id: 'paymentMethodStatusBound',
        defaultMessage: '{paymentMethodType, select, card {•••• {value}} vkpay_checkout {VK Pay} sbp {{value}} other {}}',
    },
    buttonText: {
        id: 'buttonText',
        defaultMessage: '{smallScreen, select, true {Управлять} other {Управлять оплатой}}',
    },
});
