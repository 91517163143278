import { useUnit } from 'effector-react';
import { AbTestLkUntieCardModel } from '@common/features/ab-test-lk-untie-card';
import { SUBSCRIPTION_STATUS_TYPE_WHEN_BUTTON_IS_CANCEL } from './constants';
export const useGetIsShowNewCancelButtonType = (cancelButtonSubscriptionType, subscription) => {
    const isPauseRecurrent = useUnit(AbTestLkUntieCardModel.$isPauseRecurrent);
    if (!subscription) {
        return false;
    }
    return (cancelButtonSubscriptionType === 'new' &&
        SUBSCRIPTION_STATUS_TYPE_WHEN_BUTTON_IS_CANCEL.includes(subscription.status) &&
        !isPauseRecurrent);
};
