import jwtDecode from 'jwt-decode';
const ONE_HOUR = 1000 * 60 * 60;
const isJWTValid = (jwt, offsetMs = ONE_HOUR) => {
    try {
        if (typeof jwt !== 'string') {
            throw new Error('jwt is not a string');
        }
        const jwtData = jwtDecode(jwt);
        const expiresAtInMs = jwtData.exp * 1000;
        const now = Date.now();
        const finalValidTime = expiresAtInMs - offsetMs;
        return finalValidTime > now;
    }
    catch {
        return false;
    }
};
export default isJWTValid;
