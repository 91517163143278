import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    header: {
        id: 'header',
        defaultMessage: 'Бесплатный период подписки отключится сразу',
    },
    subheader: {
        id: 'subheader',
        defaultMessage: 'Если отменить подписку, пробный период закончится. Возобновить его будет нельзя.',
    },
    confirmButton: {
        id: 'confirmButton',
        defaultMessage: 'Отменить подписку',
    },
    cancelButton: {
        id: 'cancelButton',
        defaultMessage: 'Сохранить подписку',
    },
});
