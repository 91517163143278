const waitForResult = (fn, options) => {
    let timeoutId;
    let needContinueByResponse = false;
    let needStop = false;
    const { params, handleResponse, handleError, callInterval = 1000 } = options;
    const call = async () => {
        try {
            const response = await fn(...params);
            needContinueByResponse = await handleResponse(response);
        }
        catch (error) {
            needContinueByResponse = await handleError(error);
        }
        if (needContinueByResponse && !needStop) {
            timeoutId = setTimeout(() => void call(), callInterval);
        }
        else {
            clearTimeout(timeoutId);
        }
    };
    void call();
    return () => {
        clearTimeout(timeoutId);
        needStop = true;
    };
};
export default waitForResult;
