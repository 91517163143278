import React from 'react';
import { useUnit } from 'effector-react';
import { AuthStateModel, useAuthorizationGuard } from '@common/entities/auth-state';
const AuthGuard = ({ children }) => {
    const isAuthorized = useUnit(AuthStateModel.$isAuthorized);
    const content = isAuthorized ? children : null;
    useAuthorizationGuard({});
    return <>{content}</>;
};
export default AuthGuard;
