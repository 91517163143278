import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    oldNameButtonManageSubsciption: {
        id: 'oldNameButtonManageSubsciption',
        defaultMessage: 'Управлять подпиской',
    },
    newNameButtonManageSubscription: {
        id: 'newNameButtonManageSubscription',
        defaultMessage: 'О подписке',
    },
});
