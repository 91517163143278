import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { useCheckout } from '@common/entities/checkout/hooks';
import { CheckoutV3Frame } from '@common/entities/checkout/ui';
import { useUnit } from 'effector-react';
export const CheckoutModalV3Children = (props) => {
    const { status, ...checkoutFrameProps } = useCheckout(props);
    const isLoading = status === 'loading' || status === 'initial';
    const [closeModal] = useUnit([modalManagerModel.closeModal]);
    const onCloseWindow = () => closeModal();
    return (<CheckoutV3Frame orderUuid={props.paymentId} {...checkoutFrameProps} loading={isLoading} onCloseWindow={onCloseWindow}/>);
};
