import { useUnit } from 'effector-react';
import { useAuthorizationChecker } from '@common/entities/auth-state';
import { UserApi } from '@website/entities/user';
import storage from '@common/utils/Storage';
import { PRIVACY_VERSION, WRITE_PRIVACY_POLICY_IN_LOCAL_STORAGE_KEY } from '@common/consts';
const PrivacyPolicyChecker = ({ children }) => {
    const getPrivacyAcceptedData = useUnit(UserApi.getPrivacyAcceptedDataFx);
    useAuthorizationChecker({
        authorizedCallback: () => {
            const version = storage.get(WRITE_PRIVACY_POLICY_IN_LOCAL_STORAGE_KEY);
            if (version && version === PRIVACY_VERSION) {
                return;
            }
            void getPrivacyAcceptedData();
        },
    }, []);
    return <>{children}</>;
};
export default PrivacyPolicyChecker;
