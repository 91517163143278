import { useUnit } from 'effector-react';
import { Icon12Dropdown } from '@vkontakte/icons';
import { headerModel } from '@website/entities/header';
import { UserAvatar } from '@website/entities/user/ui';
import styles from './styles.module.scss';
export const menuUserCardId = 'header__menu_user_card';
const MenuUserCard = () => {
    const toggleOpenSubMenu = useUnit(headerModel.toggleOpenSubMenu);
    return (<div className={styles.menuUserCard} onClick={toggleOpenSubMenu} id={menuUserCardId}>
      <UserAvatar />
      <Icon12Dropdown />
    </div>);
};
export default MenuUserCard;
