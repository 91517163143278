import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import waitForResult from '@common/helpers/waitForResult';
import { api } from '../api';
export const $paymentMethodState = createStore('idle');
export const changePaymentMethodState = createEvent();
$paymentMethodState.on(changePaymentMethodState, (_state, payload) => payload);
export const PaymentMethodGate = createGate({});
export const $paymentMethod = createStore(null);
const changePaymentMethod = createEvent();
export const getPaymentMethod = createEvent();
const getPaymentMethodHandler = () => {
    return new Promise((resolve, reject) => {
        waitForResult(api.getPaymentsMethod, {
            params: [],
            handleResponse: (params) => {
                const status = params.status;
                if (status !== 'process') {
                    resolve(params);
                    return false;
                }
                changePaymentMethod({
                    status: 'process',
                });
                return true;
            },
            handleError: () => {
                reject();
                return false;
            },
            callInterval: 3000,
        });
    });
};
export const getPaymentMethodFx = createEffect(async () => {
    changePaymentMethodState('process');
    try {
        const result = await getPaymentMethodHandler();
        changePaymentMethodState('idle');
        return result;
    }
    catch {
        changePaymentMethodState('error');
    }
});
sample({
    clock: [PaymentMethodGate.open, getPaymentMethod],
    target: getPaymentMethodFx,
});
$paymentMethod.on(getPaymentMethodFx.doneData, (_state, payload) => payload);
$paymentMethod.on(changePaymentMethod, (_state, payload) => payload);
