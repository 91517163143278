import React from 'react';
import { modalManagerTools } from '@common/services/modal-manager';
const { modalTemplates, uiModalTemplates } = modalManagerTools;
const { TwoButtonTemplate } = uiModalTemplates;
export const showRisingPriceWarningModal = (params) => modalTemplates.showMessageModal({
    analyticShowAttribute: params.analyticShowAttribute,
    mode: 'warning',
    header: params.title,
    subheader: params.detail,
    actions: (<TwoButtonTemplate firstButton={{
            analyticClick: params.firstButton.analyticClick,
            handler: params.firstButton.handler,
            mode: 'primary',
            children: params.firstButton.children,
        }} secondButton={{
            analyticClick: params.secondButton.analyticClick,
            handler: params.secondButton.handler,
            mode: 'secondary',
            children: params.secondButton.children,
        }}/>),
});
