import React from 'react';
import { useUnit } from 'effector-react';
import { AuthStateModel } from '@common/entities/auth-state';
const AuthChecker = ({ authRender, unAuthRender, fallback = null }) => {
    const authState = useUnit(AuthStateModel.$authState);
    if (authState === 'authorized') {
        return <>{authRender}</>;
    }
    if (authState === 'unauthorized') {
        return <>{unAuthRender}</>;
    }
    return <>{fallback}</>;
};
export default AuthChecker;
