import { Wrapper } from '../wrapper';
import styles from './style.module.scss';
const ProfileContentLayout = ({ header, content, sideBar, offPadding }) => {
    return (<div className={styles.contentLayoutProfile}>
      <Wrapper offPadding={offPadding}>{header}</Wrapper>
      <Wrapper offPadding={offPadding}>
        <div className={styles.contentLayoutProfile__contentWrapper}>
          <div className={styles.contentLayoutProfile__content}>{content}</div>
          <aside className={styles.contentLayoutProfile__sideBar}> {sideBar}</aside>
        </div>
      </Wrapper>
    </div>);
};
export default ProfileContentLayout;
