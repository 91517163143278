import { attach, createStore, sample } from 'effector';
import { getDeactivateTrialTestVariant } from '../api';
const $abTestResult = createStore(null);
const getOnStopRecurrentTestVariantFx = attach({
    effect: async (abTestResult) => {
        if (abTestResult) {
            return abTestResult;
        }
        const result = await getDeactivateTrialTestVariant();
        if (!result.successful) {
            return Promise.reject(result.successful);
        }
        return result.data.variant;
    },
    source: $abTestResult,
});
sample({ clock: getOnStopRecurrentTestVariantFx.doneData, target: $abTestResult });
sample({
    clock: getOnStopRecurrentTestVariantFx.failData,
    fn: () => 'none',
    target: $abTestResult,
});
export const stopRecurrentModel = {
    getOnStopRecurrentTestVariantFx,
};
