import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { studentAnalytics } from '../analytics';
import { AcceptIcon, Actions, Header, Subheader } from './ui';
export const showStudentConfirmStatusPlatformNotSupportedModal = () => {
    modalManagerModel.showModal({
        analyticShowAttribute: studentAnalytics.confirm.iOSNotSupportedShow,
        icon: <AcceptIcon />,
        header: <Header />,
        subheader: <Subheader />,
        actions: <Actions />,
    });
};
