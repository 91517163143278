import React from 'react';
import { Card } from '@vkontakte/vkui';
import { CheckoutV3Frame } from '../checkout-v3-frame';
import styles from './styles.module.scss';
const CheckoutV3ContentPage = (props) => {
    return (<div className={styles.checkoutV3ContentPage}>
      <Card mode={'shadow'}>
        <CheckoutV3Frame {...props}/>
      </Card>
    </div>);
};
export default CheckoutV3ContentPage;
