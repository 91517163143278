import icon90ForTwo3XWebp from './icon-90-for-two@3x.webp';
import icon90ForTwo3XPng from './icon-90-for-two@3x.png';
import icon90ForTwo2XWebp from './icon-90-for-two@2x.webp';
import icon90ForTwo2XPng from './icon-90-for-two@2x.png';
import icon90ForTwo1XWebp from './icon-90-for-two@1x.webp';
import icon90ForTwo1XPng from './icon-90-for-two@1x.png';
export const icon90ForTwoImage = {
    src: icon90ForTwo1XPng.src,
    width: 90,
    height: 90,
    sources: [
        { width: 90, height: 90, srcSet: icon90ForTwo3XWebp.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 90, height: 90, srcSet: icon90ForTwo3XPng.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 90, height: 90, srcSet: icon90ForTwo2XWebp.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 90, height: 90, srcSet: icon90ForTwo2XPng.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 90, height: 90, srcSet: icon90ForTwo1XWebp.src },
        { width: 90, height: 90, srcSet: icon90ForTwo1XPng.src },
    ],
};
