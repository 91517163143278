export const localStorageAdapter = (localStorage) => {
    return {
        set: async (key, value) => {
            localStorage.set(key, value);
        },
        get: (key) => {
            return Promise.resolve(localStorage.get(key));
        },
    };
};
