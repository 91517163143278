import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { createAnalyticAttrs } from '@analytics';
import { MENU } from '@website/shared/config/navigation-menu';
import { ROUTES } from '@website/shared/lib/routes';
import styles from './styles.module.scss';
const Navigation = ({ mode, className, linksClass }) => {
    const router = useRouter();
    const isMobile = mode === 'mobile';
    const filteredLinks = MENU.filter((menuItem) => menuItem.excludePages ? !menuItem.excludePages.some((pageKey) => ROUTES[pageKey] === router.pathname) : menuItem);
    const mobileClassName = isMobile && styles.navigation__mobile;
    const mobileLinkClassName = isMobile && styles.navigation__item_mobile;
    return (<nav className={classNames(className)}>
      <ul className={classNames(styles.navigation, mobileClassName)}>
        {filteredLinks.map(({ name, path, icon: Icon, analytics, onCLick }) => (<li key={name} className={classNames(styles.navigation__item, mobileLinkClassName, linksClass, {
                [styles.current]: path === router.pathname,
            })}>
            {isMobile && Icon && <Icon fill={'#76787A'}/>}
            <Link href={path} onClick={onCLick} {...createAnalyticAttrs(analytics, ['click'])}>
              {name}
            </Link>
          </li>))}
      </ul>
    </nav>);
};
export default Navigation;
