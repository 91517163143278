import { intl } from '@common/shared/lib/intl';
import messages from './messages';
const STATUS_TEXT = {
    active: intl.formatMessage(messages.activeStatusText),
    auto_renew_cancelled: intl.formatMessage(messages.autoRenewCancelledStatusText),
    inactive: intl.formatMessage(messages.inactiveStatusText),
    suspended: intl.formatMessage(messages.suspendedStatusText),
    waiting: intl.formatMessage(messages.waitingStatusText),
};
export const getStatusText = (status) => {
    if (!STATUS_TEXT[status]) {
        return null;
    }
    return STATUS_TEXT[status];
};
