import React from 'react';
export const IconSbpbankColor = (props) => {
    return (<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M11.2 3C11.2926 3.02981 11.3808 3.06913 11.4657 3.11733C13.3221 4.25509 15.1835 5.38398 17.0391 6.52237C17.9105 7.04813 18.7813 7.57515 19.6413 8.1193C19.1758 8.19604 18.7027 8.14277 18.2333 8.15862C17.3099 8.1453 16.3846 8.18399 15.4625 8.14023C14.7776 7.74766 14.0996 7.33859 13.4388 6.9067C13.1825 6.57692 13.0202 6.18815 12.8084 5.83046C12.2788 4.90959 11.7854 3.96653 11.2349 3.05771L11.2 3Z' fill='#EE7024'/>
      <path d='M11.2349 3.05774C11.7854 3.96655 12.2788 4.90962 12.8083 5.83048C13.0202 6.18817 13.1825 6.57694 13.4388 6.90673C13.4286 7.735 13.4362 8.56264 13.4349 9.39091C13.4305 10.2541 13.433 11.1179 13.4426 11.981C13.4426 12.0013 13.4419 12.0419 13.4419 12.0622C12.7037 11.6113 11.9668 11.1578 11.2266 10.7094C11.233 8.15928 11.2171 5.60851 11.2349 3.05774Z' fill='#FBB61B'/>
      <path d='M5 6.94852C5.01268 6.94535 5.03742 6.93774 5.0501 6.93457C5.11733 7.10771 5.1928 7.27894 5.28856 7.4394C5.9437 8.57272 6.54683 9.73649 7.22733 10.8552C7.21972 11.6334 7.22606 12.4122 7.22352 13.1904C7.03643 13.4504 6.90452 13.7447 6.74216 14.0193C6.43774 14.5602 6.1124 15.0898 5.82954 15.6428C5.56127 16.135 5.26637 16.6119 5.01459 17.1129C4.98732 13.7256 5.01522 10.3371 5 6.94852Z' fill='#5A58A3'/>
      <path d='M5.05011 6.93457C7.11191 8.18776 9.17054 9.44665 11.2266 10.7094C11.9667 11.1577 12.7037 11.6112 13.4419 12.0621C14.945 12.9773 16.4449 13.8988 17.9467 14.8158C18.5067 15.1659 19.0825 15.4913 19.6317 15.8585C18.8498 15.9111 18.0659 15.875 17.2833 15.8864C16.6764 15.8838 16.0688 15.9155 15.4625 15.8788C14.7737 15.5001 14.1211 15.06 13.4438 14.6611C12.705 14.2083 11.9725 13.7447 11.2222 13.3109L11.2101 13.3654L11.2127 13.3254L11.2158 13.2734C10.5201 12.8498 9.83328 12.4097 9.12424 12.0082C8.48433 11.6372 7.85837 11.2415 7.22733 10.8552C6.54683 9.73649 5.9437 8.57272 5.28857 7.4394C5.19281 7.27894 5.11734 7.10771 5.05011 6.93457Z' fill='#2188CB'/>
      <path d='M19.6412 8.11936L19.6793 8.11365C19.6641 8.14092 19.6489 8.16882 19.6343 8.19673C17.5655 9.45055 15.5018 10.712 13.4425 11.981C13.433 11.1179 13.4305 10.2541 13.4349 9.39094C14.1091 8.97173 14.7921 8.56584 15.4625 8.14028C16.3846 8.18404 17.3099 8.14536 18.2333 8.15868C18.7026 8.14282 19.1757 8.19609 19.6412 8.11936Z' fill='#D90954'/>
      <path d='M9.12423 12.0082C9.83327 12.4096 10.5201 12.8498 11.2158 13.2734L11.2127 13.3254C11.0275 13.4757 10.8087 13.5759 10.6095 13.7053C8.74117 14.8526 6.86456 15.9872 5 17.1408L5.01459 17.1129C5.26637 16.6119 5.56127 16.1349 5.82954 15.6428C6.1124 15.0898 6.43774 14.5602 6.74216 14.0192C6.90452 13.7446 7.03643 13.4504 7.22352 13.1903C7.84821 12.7825 8.50588 12.4261 9.12423 12.0082Z' fill='#974995'/>
      <path d='M11.2222 13.3109C11.9724 13.7447 12.7049 14.2083 13.4438 14.6611C13.4406 15.471 13.4463 16.2809 13.4413 17.0908C13.4666 17.0794 13.5167 17.0578 13.5421 17.0464C13.4279 17.1555 13.3303 17.281 13.2535 17.4199C12.8032 18.219 12.3637 19.0245 11.9052 19.8191C11.6832 20.1958 11.498 20.5954 11.2355 20.9467C11.2133 18.4017 11.2406 15.856 11.2222 13.3109Z' fill='#62B22F'/>
      <path d='M15.4625 15.8788C16.0688 15.9156 16.6764 15.8839 17.2833 15.8864C18.0659 15.875 18.8498 15.9112 19.6318 15.8585L19.7066 15.8547C19.5246 16.0082 19.3127 16.1179 19.1117 16.2429C16.5527 17.8075 13.9918 19.3689 11.4347 20.9354C11.3668 20.988 11.2793 20.9899 11.1987 21L11.2355 20.9468C11.4981 20.5954 11.6833 20.1959 11.9052 19.8192C12.3638 19.0245 12.8033 18.2191 13.2536 17.42C13.3303 17.2811 13.428 17.1555 13.5421 17.0464C14.1833 16.6595 14.8296 16.2797 15.4625 15.8788Z' fill='#037F37'/>
    </svg>);
};
