import { createEvent, createStore } from 'effector';
import { base64ToUtf8 } from '@common/utils/helpers/dataTransformation';
const $userData = createStore({ vkId: null });
const setVkIdByToken = createEvent();
$userData.on(setVkIdByToken, (state, payload) => {
    const jsParseToken = JSON.parse(base64ToUtf8(payload.split('.')[1]));
    const vkIdString = jsParseToken.sub;
    const vkId = Number(vkIdString);
    return { ...state, vkId };
});
export const userDataModel = { $userData, setVkIdByToken };
