export default class QueryParamsManipulator {
    constructor(stringParams) {
        this.queryParams = new URLSearchParams(stringParams);
    }
    filteredParams(callback) {
        const resultParams = new URLSearchParams();
        for (const key of this.queryParams.keys()) {
            if (callback(key)) {
                resultParams.append(key, this.queryParams.get(key));
            }
        }
        return resultParams;
    }
}
