import { intl } from '@common/shared/lib/intl';
import { messages } from './messages';
const OLD_NAME_BUTTON_MANAGE_SUBSCRIPTION = intl.formatMessage(messages.oldNameButtonManageSubsciption);
const NEW_NAME_BUTTON_MANAGE_SUBSCRIPTION = intl.formatMessage(messages.newNameButtonManageSubscription);
const VARIANTS = {
    a: {
        widgetButtonText: OLD_NAME_BUTTON_MANAGE_SUBSCRIPTION,
        cancelButtonSubscriptionType: 'old',
    },
    b: {
        widgetButtonText: NEW_NAME_BUTTON_MANAGE_SUBSCRIPTION,
        cancelButtonSubscriptionType: 'new',
    },
    c: {
        widgetButtonText: OLD_NAME_BUTTON_MANAGE_SUBSCRIPTION,
        cancelButtonSubscriptionType: 'new',
    },
    d: {
        widgetButtonText: NEW_NAME_BUTTON_MANAGE_SUBSCRIPTION,
        cancelButtonSubscriptionType: 'old',
    },
    none: {
        widgetButtonText: OLD_NAME_BUTTON_MANAGE_SUBSCRIPTION,
        cancelButtonSubscriptionType: 'old',
    },
};
export const getVariant = (variant) => {
    if (!variant || !VARIANTS[variant]) {
        return VARIANTS.none;
    }
    return VARIANTS[variant];
};
