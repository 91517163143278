import cn from 'classnames';
import { useUnit } from 'effector-react';
import { headerModel } from '@website/entities';
import styles from './styles.module.scss';
export const burgerMenuId = 'header__burger_menu';
const BurgerMenu = (props) => {
    const toggleOpenSubMenu = useUnit(headerModel.toggleOpenSubMenu);
    const openSubMenu = useUnit(headerModel.$openSubMenu);
    const handleClick = () => {
        toggleOpenSubMenu();
    };
    return (<div id={burgerMenuId} className={cn(props.className, styles.burgerMenu)} onClick={handleClick}>
      <span className={styles.title}>Меню</span>
      <div className={cn(styles.burger, openSubMenu && styles.burger__open)}>
        <div />
        <div />
        <div />
      </div>
    </div>);
};
export default BurgerMenu;
