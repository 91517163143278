import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    home: {
        id: 'home',
        defaultMessage: 'Моя подписка',
    },
    faq: {
        id: 'faq',
        defaultMessage: 'FAQ',
    },
    otherTariffs: {
        id: 'otherTariffs',
        defaultMessage: 'Другие тарифы',
    },
    paymentMethod: {
        id: 'paymentMethod',
        defaultMessage: 'Способ оплаты',
    },
    notifications: {
        id: 'notifications',
        defaultMessage: 'Уведомления',
    },
});
