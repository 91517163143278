import React from 'react';
const WIDTH_DEFAULT = 86;
const HEIGHT_DEFAULT = 88;
const StudentsConfirmed = ({ width = WIDTH_DEFAULT }) => {
    const height = (width / WIDTH_DEFAULT) * HEIGHT_DEFAULT;
    return (<svg width={width} height={height} viewBox='0 0 86 88' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 26.7643C0 14.1433 1.31776e-05 7.83627 3.88918 3.92153C7.77835 0.00679657 13.9997 0 26.5434 0H44.8299C57.34 0 63.595 3.24078e-07 67.4842 3.92153C71.3733 7.84307 71.3734 14.1162 71.3734 26.7643V53.3791C71.3734 65.9932 71.3733 72.3003 67.4842 76.2218C63.595 80.1434 57.3737 80.1434 44.8299 80.1434H26.5434C14.0334 80.1434 7.77161 80.1434 3.88918 76.2218C0.00675334 72.3003 0 66.0272 0 53.3791V26.7643Z' fill='#2929B2'/>
      <path d='M0 26.7643C0 14.1433 1.31776e-05 7.83627 3.88918 3.92153C7.77835 0.00679657 13.9997 0 26.5434 0H44.8299C57.34 0 63.595 3.24078e-07 67.4842 3.92153C71.3733 7.84307 71.3734 14.1162 71.3734 26.7643V53.3791C71.3734 65.9932 71.3733 72.3003 67.4842 76.2218C63.595 80.1434 57.3737 80.1434 44.8299 80.1434H26.5434C14.0334 80.1434 7.77161 80.1434 3.88918 76.2218C0.00675334 72.3003 0 66.0272 0 53.3791V26.7643Z' fill='#2929B2'/>
      <path d='M5.77734 28.5995C5.77734 17.7253 5.7774 12.2881 9.03972 8.88991C12.302 5.4917 17.5392 5.4917 28.0272 5.4917H43.3479C53.8359 5.4917 59.0799 5.4917 62.3355 8.88991C65.591 12.2881 65.591 17.7253 65.591 28.5995V51.517C65.591 62.3913 65.591 67.8284 62.3355 71.2267C59.0799 74.6249 53.8359 74.6249 43.3479 74.6249H28.0272C17.5392 74.6249 12.2953 74.6249 9.03972 71.2267C5.78414 67.8284 5.77734 62.3913 5.77734 51.517V28.5995Z' fill='white'/>
      <path d='M36.7954 51.7002H15.1792C14.1332 51.7002 13.2852 52.5552 13.2852 53.61V53.6168C13.2852 54.6715 14.1332 55.5266 15.1792 55.5266H36.7954C37.8415 55.5266 38.6895 54.6715 38.6895 53.6168V53.61C38.6895 52.5552 37.8415 51.7002 36.7954 51.7002Z' fill='#00EAFF'/>
      <path d='M25.0133 59.4688H15.1792C14.1332 59.4688 13.2852 60.3238 13.2852 61.3785V61.3853C13.2852 62.4401 14.1332 63.2951 15.1792 63.2951H25.0133C26.0594 63.2951 26.9073 62.4401 26.9073 61.3853V61.3785C26.9073 60.3238 26.0594 59.4688 25.0133 59.4688Z' fill='#00EAFF'/>
      <path d='M57.4889 59.4688H32.3745C31.3285 59.4688 30.4805 60.3238 30.4805 61.3785V61.3853C30.4805 62.4401 31.3285 63.2951 32.3745 63.2951H57.4889C58.535 63.2951 59.383 62.4401 59.383 61.3853V61.3785C59.383 60.3238 58.535 59.4688 57.4889 59.4688Z' fill='#00EAFF'/>
      <path d='M57.4957 17.6299H41.8042C40.7582 17.6299 39.9102 18.4849 39.9102 19.5397V19.5465C39.9102 20.6012 40.7582 21.4563 41.8042 21.4563H57.4957C58.5417 21.4563 59.3897 20.6012 59.3897 19.5465V19.5397C59.3897 18.4849 58.5417 17.6299 57.4957 17.6299Z' fill='#00EAFF'/>
      <path d='M44.0217 25.3984H41.8042C40.7582 25.3984 39.9102 26.2535 39.9102 27.3082V27.315C39.9102 28.3698 40.7582 29.2248 41.8042 29.2248H44.0217C45.0678 29.2248 45.9158 28.3698 45.9158 27.315V27.3082C45.9158 26.2535 45.0678 25.3984 44.0217 25.3984Z' fill='#00EAFF'/>
      <path d='M51.6383 33.1733H41.8042C40.7582 33.1733 39.9102 34.0284 39.9102 35.0831V35.0899C39.9102 36.1447 40.7582 36.9997 41.8042 36.9997H51.6383C52.6844 36.9997 53.5323 36.1447 53.5323 35.0899V35.0831C53.5323 34.0284 52.6844 33.1733 51.6383 33.1733Z' fill='#00EAFF'/>
      <path d='M57.4876 25.3984H49.9722C48.9262 25.3984 48.0781 26.2535 48.0781 27.3082V27.315C48.0781 28.3698 48.9262 29.2248 49.9722 29.2248H57.4876C58.5336 29.2248 59.3817 28.3698 59.3817 27.315V27.3082C59.3817 26.2535 58.5336 25.3984 57.4876 25.3984Z' fill='#00EAFF'/>
      <path d='M35.9459 27.4507V37.2443C35.9459 40.6426 35.9459 42.831 35.4404 44.299C35.2795 44.7825 35.0278 45.2301 34.6989 45.6175C34.4487 45.9107 34.1471 46.1549 33.8093 46.3379C32.5286 47.0584 30.6143 47.0584 27.4396 47.0584H21.5755C17.5313 47.0584 15.5564 47.0584 14.3094 45.6175C14.0991 45.3729 13.9222 45.1009 13.7837 44.8088C13.624 44.4817 13.502 44.1374 13.4198 43.7825C13.0625 42.3349 13.0625 40.2891 13.0625 37.2443V27.4507C13.0625 22.8223 13.0625 20.5048 14.3094 19.0707C15.5564 17.6367 17.565 17.6299 21.5755 17.6299H27.4396C31.4838 17.6299 33.452 17.6299 34.6989 19.0707C35.9459 20.5116 35.9459 22.8223 35.9459 27.4507Z' fill='#00EAFF'/>
      <path d='M35.9445 34.3083V37.2444C35.9445 40.6426 35.9445 42.831 35.439 44.2991C35.2782 44.7825 35.0265 45.2302 34.6976 45.6176C34.4474 45.9107 34.1458 46.1549 33.8079 46.338C31.6851 46.4517 29.5571 46.4222 27.4383 46.2496C24.7421 46.0525 21.8842 45.7331 19.3499 45.4273C17.2132 45.1826 15.3057 44.9447 13.8228 44.8156C13.6632 44.4886 13.541 44.1443 13.4588 43.7893C13.1016 42.3417 13.1016 40.296 13.1016 37.2512V33.6831C13.3501 33.3652 13.6156 33.0611 13.897 32.7723C17.6783 29.4217 13.7756 28.3547 16.856 25.9759C17.7332 25.5205 18.4682 24.8289 18.9792 23.9778C19.8689 21.8437 21.8371 21.3068 24.4321 21.9389C24.7623 22.0227 25.1036 22.0525 25.4431 22.0272C28.7392 21.232 29.521 23.4816 29.9929 24.3584C30.6669 25.5749 31.5701 25.6497 32.3385 26.207C35.4121 28.4023 34.1921 31.583 34.0977 32.1811C34.0785 32.3732 34.1124 32.567 34.1957 32.7409C34.279 32.9149 34.4083 33.0622 34.5695 33.1665C35.077 33.4826 35.5396 33.8667 35.9445 34.3083Z' fill='#252040'/>
      <path d='M35.4425 44.2651C35.2816 44.7485 35.0299 45.1962 34.7011 45.5836C34.4508 45.8767 34.1493 46.1209 33.8114 46.304C32.5307 47.0244 30.6164 47.0244 27.4417 47.0244H21.5777C17.5335 47.0244 15.5585 47.0244 14.3116 45.5836C14.1012 45.339 13.9243 45.0669 13.7858 44.7748C13.6262 44.4478 13.5041 44.1035 13.4219 43.7486C15.0193 43.3 17.0212 42.8039 18.7669 42.4912C19.3061 42.3961 19.8184 42.3145 20.2902 42.2534C20.7621 42.1922 21.0654 42.1718 21.3889 42.1582C22.1997 42.3727 23.0397 42.453 23.876 42.3961H24.658C25.6446 42.4931 26.6403 42.4218 27.6035 42.1854C27.8259 42.1854 28.0551 42.1854 28.2775 42.2466C28.885 42.3197 29.488 42.4264 30.084 42.566C31.9087 43.0003 33.6996 43.5682 35.4425 44.2651Z' fill='#CC61FA'/>
      <path d='M27.9038 41.9001C27.5808 40.4122 27.4448 38.8891 27.4993 37.3669C26.0434 37.2106 24.6481 37.7271 23.199 37.5776L21.4061 36.8164C21.4672 38.4958 21.2785 40.175 20.8466 41.7982C19.6738 42.1924 19.1278 45.2847 24.8302 45.3119C30.5325 45.3391 28.5374 42.1108 27.9038 41.9001Z' fill='#FCA190'/>
      <path d='M21.3125 39.2831C23.4424 40.5269 27.4597 38.7326 25.8555 38.7258C24.2513 38.719 21.3731 37.5024 21.3731 37.5024C21.3963 38.0967 21.3761 38.6919 21.3125 39.2831Z' fill='#F07875'/>
      <path d='M31.2536 30.8215C31.0737 30.6533 30.8428 30.5511 30.5983 30.5314C30.3537 30.5117 30.1097 30.5756 29.9056 30.7128C29.6562 28.837 29.0698 27.1719 28.4092 26.465C27.6071 25.5815 26.2185 24.3174 24.7155 24.3106C23.2124 24.3038 21.4464 25.5883 20.6713 26.4311C19.9972 27.1107 19.4109 28.8098 19.148 30.6992C19.0532 30.616 18.9429 30.5525 18.8236 30.5125C18.7043 30.4726 18.5782 30.4569 18.4528 30.4663C18.3275 30.4758 18.2052 30.5103 18.0931 30.5678C17.981 30.6252 17.8814 30.7046 17.8 30.8011C17.3281 31.3109 17.7257 32.9284 19.0671 33.5197C19.1063 34.1917 19.2286 34.8561 19.4311 35.4975C20.0175 37.3665 21.6217 38.9636 24.5268 39.0452C27.4319 39.0452 29.0495 37.4073 29.6562 35.5382C29.8615 34.8758 29.9904 34.1916 30.0403 33.4993C31.3008 32.9148 31.7457 31.2565 31.2536 30.8215Z' fill='#FCA190'/>
      <path d='M22.8164 29.2316C23.1736 29.5646 22.8703 29.6462 21.6705 29.6802C21.2863 29.6802 20.9965 29.7346 20.9021 29.6802C20.619 29.3268 22.1288 28.7966 22.8164 29.2316Z' fill='#252040'/>
      <path d='M26.1454 29.2656C25.7815 29.585 26.0781 29.6734 27.2913 29.7414C27.6755 29.7414 27.9654 29.8161 28.0665 29.7414C28.3766 29.4219 26.8532 28.851 26.1454 29.2656Z' fill='#252040'/>
      <path d='M24.4887 33.3635C24.0707 33.2887 23.8146 33.2207 23.7202 33.044C23.6865 32.9489 23.6786 32.8463 23.6976 32.7471C23.7166 32.6479 23.7617 32.5556 23.8281 32.4799C23.9555 32.2903 24.03 32.0697 24.0438 31.8411C24.047 31.7862 24.0701 31.7345 24.1086 31.6957C24.1471 31.6568 24.1984 31.6336 24.2528 31.6304C24.3066 31.635 24.3572 31.6587 24.3954 31.6972C24.4337 31.7358 24.4571 31.7867 24.4617 31.8411C24.4262 32.1302 24.3297 32.4083 24.1786 32.6566C24.1413 32.6937 24.1156 32.741 24.1045 32.7926C24.1449 32.8333 24.246 32.8673 24.5291 32.9013C24.5583 32.9029 24.5866 32.9112 24.612 32.9257C24.6374 32.9402 24.659 32.9604 24.6753 32.9848C24.6917 33.0092 24.7022 33.037 24.706 33.0661C24.7099 33.0953 24.7071 33.1249 24.6976 33.1528C24.6996 33.181 24.6956 33.2092 24.6858 33.2357C24.676 33.2622 24.6607 33.2863 24.6409 33.3062C24.621 33.3262 24.5972 33.3417 24.5709 33.3515C24.5447 33.3614 24.5166 33.3655 24.4887 33.3635Z' fill='#F07875'/>
      <path d='M25.8169 26.7169C25.8358 27.1498 26.018 27.5591 26.3262 27.861C26.6345 28.163 27.0454 28.3348 27.4751 28.3413C27.9037 28.3271 28.3177 28.1807 28.6612 27.9218C29.0047 27.663 29.2612 27.3041 29.3961 26.8936C29.5224 26.4789 29.5516 26.0402 29.4814 25.6122C29.4112 25.1841 29.2436 24.7783 28.9917 24.4266C28.4874 23.7246 27.7816 23.1955 26.9696 22.911C25.7322 22.4862 24.3896 22.4894 23.1542 22.9202C21.9188 23.3511 20.8611 24.1848 20.1484 25.2897C19.2856 26.6966 19.3328 28.185 21.1594 28.3141C21.9879 28.3875 22.8226 28.3022 23.6196 28.0626C23.997 27.9403 25.8034 27.2471 25.8169 26.7169Z' fill='#252040'/>
      <path d='M30.9986 44.7681C25.7344 47.3575 22.7282 47.3439 18.0234 44.7137L19.3715 40.7514C19.4379 40.56 19.5437 40.385 19.6819 40.2376C19.82 40.0903 19.9874 39.974 20.1732 39.8964C20.3589 39.8188 20.5589 39.7815 20.7599 39.7872C20.9609 39.7928 21.1585 39.8412 21.3397 39.9291C23.3495 40.7977 25.6141 40.8513 27.6621 40.0786C28.0083 39.9516 28.39 39.9658 28.7261 40.1181C29.0621 40.2704 29.326 40.5488 29.4618 40.8941L30.9986 44.7681Z' fill='#CC61FA'/>
      <path d='M22.764 34.9064C22.5079 34.4171 22.8516 34.152 23.256 34.1113C24.7052 34.0161 25.9521 33.9413 26.2824 34.2132C27.0036 34.7773 25.6084 35.8375 24.476 35.9395C24.1264 35.9313 23.7849 35.8318 23.4848 35.6507C23.1848 35.4696 22.9364 35.2132 22.764 34.9064Z' fill='white'/>
      <path d='M21.913 30.5161C22.2703 30.5161 22.5871 30.8559 22.5871 31.3249C22.5871 31.5051 22.516 31.678 22.3896 31.8055C22.2632 31.9329 22.0918 32.0045 21.913 32.0045C21.5558 32.0385 21.3064 31.8822 21.2794 31.4064C21.2549 31.2049 21.3065 31.0015 21.4239 30.8366C21.5412 30.6717 21.7159 30.5573 21.913 30.5161Z' fill='#252040'/>
      <path d='M27.0286 30.5497C26.6714 30.5157 26.3546 30.8623 26.3007 31.338C26.2468 31.8138 26.4759 32.0177 26.941 32.0585C27.4061 32.0992 27.5544 31.9497 27.615 31.474C27.6555 31.2733 27.617 31.0646 27.5075 30.8921C27.3981 30.7196 27.2263 30.5968 27.0286 30.5497Z' fill='#252040'/>
      <path d='M63.6151 43.4087C59.388 43.4073 55.2451 44.5999 51.6558 46.8513C48.0665 49.1027 45.1744 52.3228 43.3073 56.1468C41.4402 59.9707 40.6728 64.2456 41.092 68.4868C41.5112 72.7281 43.1003 76.7661 45.6791 80.1433C57.6162 80.1433 63.6892 80.0482 67.484 76.2218C71.2789 72.3954 71.3732 66.0272 71.3732 53.379V44.7884C68.8872 43.8741 66.2613 43.4071 63.6151 43.4087Z' fill='#2929B2'/>
      <path d='M65.5893 87.9999C76.8613 87.9999 85.9991 78.7861 85.9991 67.4204C85.9991 56.0546 76.8613 46.8408 65.5893 46.8408C54.3174 46.8408 45.1797 56.0546 45.1797 67.4204C45.1797 78.7861 54.3174 87.9999 65.5893 87.9999Z' fill='white'/>
      <path d='M76.9686 62.4522C76.9996 61.9649 76.8561 61.4826 76.5642 61.0929C76.2943 60.7057 75.939 60.387 75.5262 60.1618C75.1064 59.911 74.6109 59.8217 74.131 59.9103C73.0188 60.121 72.0549 61.2085 70.1272 63.3901L66.083 67.9845C64.1552 70.1661 63.1914 71.2536 63.1105 72.3954C63.0759 72.8831 63.2197 73.3667 63.5149 73.7546C63.7829 74.1453 64.1386 74.4666 64.553 74.6925C64.9709 74.9417 65.4637 75.031 65.9415 74.944C67.0603 74.7333 68.0242 73.6391 69.9857 71.4643L74.0298 66.8631C75.9239 64.6814 76.908 63.594 76.9686 62.4522Z' fill='#00DC7F'/>
      <path d='M58.8034 64.7157C58.3728 64.6395 57.9298 64.7372 57.57 64.9876C57.2326 65.2027 56.9618 65.5087 56.7881 65.8711C56.6884 66.0668 56.6281 66.2804 56.6107 66.4998C56.5933 66.7191 56.6193 66.9397 56.687 67.1488C56.9701 68.1955 58.035 69.1878 60.2054 71.1111L61.0547 71.8859C63.2183 73.8297 64.2968 74.8016 65.355 74.9647C65.7855 75.0345 66.2261 74.9373 66.5885 74.6929C66.9247 74.4735 67.1951 74.1656 67.3704 73.8025C67.5771 73.4148 67.6232 72.9603 67.4985 72.5384C67.2154 71.4985 66.1504 70.4995 63.98 68.5829L63.1308 67.8081C60.9402 65.8507 59.8617 64.8788 58.8034 64.7157Z' fill='#00DC7F'/>
    </svg>);
};
export default StudentsConfirmed;
