import React from 'react';
import classNames from 'classnames';
import { Button, ModalCard } from '@vkontakte/vkui';
import { Icon56CheckCircleOutline, Icon56ErrorOutline } from '@vkontakte/icons';
import Typograf from '@common/components/common/Typograf';
import styles from './styles.module.scss';
const AdditionalButton = (props) => (<Button mode='tertiary' {...props} className={classNames(styles.additionalButton, props.className)}>
    <Typograf>{props.children}</Typograf>
  </Button>);
const ModalSimple = (props) => {
    const { id, header, subheader, actions, icon, mode, children } = props;
    let iconToRender = null;
    if (icon) {
        iconToRender = icon;
    }
    else {
        switch (mode) {
            case 'success': {
                iconToRender = <Icon56CheckCircleOutline className={styles.icon_success}/>;
                break;
            }
            case 'warning': {
                iconToRender = <Icon56ErrorOutline className={styles.icon_warning}/>;
                break;
            }
            case 'error': {
                iconToRender = <Icon56ErrorOutline className={styles.icon_error}/>;
                break;
            }
            default:
                null;
                break;
        }
    }
    return (<ModalCard className={styles.modal} id={id} icon={iconToRender} header={header && <Typograf>{header}</Typograf>} subheader={subheader && <Typograf>{subheader}</Typograf>} actions={actions} subheaderComponent='h5' headerComponent='h2'>
      {children}
    </ModalCard>);
};
export default ModalSimple;
export { AdditionalButton, styles };
