import { createEvent, createStore, sample } from 'effector';
import { modalCardModel } from './modal-card';
import { modalPageModel } from './modal-page';
import { modalPageWithFixedFooterModel } from './modal-page-with-fixed-footer';
const $activeId = createStore(null);
const setActiveId = createEvent();
const closeModal = createEvent();
$activeId.on(closeModal, () => null);
sample({
    clock: [modalCardModel.$activeId, modalPageModel.$activeId, modalPageWithFixedFooterModel.$activeId],
    filter: (activeId) => activeId !== null,
    target: $activeId,
    batch: true,
});
export const vkUIModalManager = {
    modalCard: modalCardModel,
    modalPage: modalPageModel,
    modalPageWithFixedFooter: modalPageWithFixedFooterModel,
    closeModal,
    $activeId,
    setActiveId,
};
