import { defineMessages } from 'react-intl';
const messages = defineMessages({
    lastName: {
        id: 'lastName',
        defaultMessage: 'Фамилия',
    },
    firstName: {
        id: 'firstName',
        defaultMessage: 'Имя',
    },
    middleName: {
        id: 'middleName',
        defaultMessage: 'Отчество',
    },
    city: {
        id: 'city',
        defaultMessage: 'Город',
    },
    cityPlaceholder: {
        id: 'cityPlaceholder',
        defaultMessage: 'Введите название',
    },
    university: {
        id: 'university',
        defaultMessage: 'Название учебного заведения',
    },
    universityPlaceholder: {
        id: 'universityPlaceholder',
        defaultMessage: 'Введите название',
    },
    email: {
        id: 'email',
        defaultMessage: 'Студенческая почта',
    },
    emailPlaceholder: {
        id: 'emailPlaceholder',
        defaultMessage: 'С доменом вуза, например student@hse.ru',
    },
    verificationType: {
        id: 'verificationType',
        defaultMessage: 'Способ верификации',
    },
    verificationTypeEmail: {
        id: 'verificationTypeEmail',
        defaultMessage: 'По почте',
    },
    verificationTypeStudentId: {
        id: 'verificationTypeStudentId',
        defaultMessage: 'По студенческому билету',
    },
});
export default messages;
