import { createEffect, createEvent, createStore, sample } from 'effector';
import { StudentApi } from '@common/entities/student';
import { createOptionList } from '../lib/create-option-list';
const getCityList = createEvent();
const clearCityList = createEvent();
const $cityOptions = createStore([]);
const getStudentCityListFx = createEffect(async ({ query, need_all = true }) => {
    const { data } = await StudentApi.getStudentCityList({ query, need_all: need_all });
    if (!data.successful) {
        return Promise.reject();
    }
    const { items } = data.data;
    if (items.length === 0) {
        return Promise.reject();
    }
    return createOptionList(items);
});
const $pendingStudentCityList = getStudentCityListFx.pending;
sample({
    clock: getCityList,
    target: getStudentCityListFx,
});
sample({
    clock: getStudentCityListFx.doneData,
    target: $cityOptions,
});
$cityOptions.reset(clearCityList);
export const cityModel = {
    getCityList,
    clearCityList,
    $cityOptions,
    $pendingStudentCityList,
    getStudentCityListFx,
};
