import React from 'react';
const DEFAULT_WIDTH = 158;
const DEFAULT_HEIGHT = 99;
const StudentWithId = ({ width = DEFAULT_WIDTH }) => {
    const height = (width / DEFAULT_WIDTH) * DEFAULT_HEIGHT;
    return (<svg width={width} height={height} viewBox='0 0 158 99' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M40.2985 53.4148C35.3638 53.1159 37.4607 64.2599 40.1927 64.4657C51.0678 65.3092 91.7898 76.6125 109.723 74.6334C116.708 73.8564 118.448 67.8527 115.935 63.5293C113.07 58.6082 113.057 57.7249 115.776 51.5088C118.495 45.2926 111.807 38.3658 109.544 36.6723C109.104 36.3183 108.768 35.8506 108.572 35.3195C108.377 34.7883 108.328 34.2137 108.433 33.6572C108.863 31.9372 113.249 22.9383 104.768 15.726C102.658 13.893 100.025 13.4414 98.3585 9.74891C97.1876 7.05258 95.4545 0.285203 85.5783 1.77947C84.578 1.77149 83.5858 1.59874 82.6412 1.2681C75.1795 -1.24227 69.259 -0.179681 66.1037 5.84389C65.6539 6.60098 63.7951 9.04494 59.3696 11.1568C49.7316 17.2734 60.9175 21.3909 48.9708 30.2304C44.4131 34.1686 40.4308 39.289 43.6788 44.841C46.0337 49.4367 42.5212 50.7583 40.2985 53.4148Z' fill='#252040'/>
      <path d='M158 99.0001H4.02246C4.02246 99.0001 8.48759 83.6855 12.3111 78.9769C13.5575 77.5083 15.3059 76.5608 17.2128 76.3204C19.0848 75.9618 21.2347 75.8821 23.6227 75.5235C27.8299 74.8594 32.7713 73.3253 38.308 67.7201C41.7544 66.352 45.2908 65.2247 48.892 64.3464C51.3793 63.6822 54.1841 63.058 57.26 62.4669C64.4173 61.0763 71.6815 60.3141 78.9705 60.189C81.8017 60.189 84.6859 60.2355 87.57 60.4613L88.3373 60.5077C93.464 60.8797 100.304 62.779 106.238 64.8312C109.658 65.9895 113.018 67.3194 116.306 68.8159C116.769 69.035 117.185 69.2409 117.543 69.4269C120.792 71.5834 123.808 74.0764 126.539 76.865C127.862 78.1933 129.185 79.5613 130.508 80.9892C132.856 78.9304 137.48 77.9675 141.8 78.1866C145.465 78.3659 148.911 79.3953 150.565 81.3146C153.978 85.306 158 99.0001 158 99.0001Z' fill='#00EAFF'/>
      <path d='M106.733 90.7582C109.157 93.2481 111.281 96.0142 113.064 98.9999H99.5892C97.5716 96.8052 95.3326 94.8266 92.9081 93.0959C90.6049 91.4374 88.1714 89.9693 85.6316 88.7061C84.4409 88.1017 83.1972 87.6103 81.9536 87.0591C86.3485 85.2976 90.487 82.9499 94.2576 80.0792C96.1819 78.5851 97.9705 76.9227 99.6025 75.1115C102.363 72.0652 104.609 68.5857 106.251 64.811C109.671 65.9693 113.031 67.2992 116.319 68.7958C115.125 71.6478 113.646 74.3713 111.906 76.9246C110.535 78.9414 109.032 80.8649 107.408 82.6825C106.019 84.2498 104.451 85.6046 102.93 87.0856C104.262 88.2405 105.532 89.4664 106.733 90.7582Z' fill='#FEFEFE'/>
      <path d='M143.737 99.0002H128.278C126.32 96.9325 124.137 95.0924 121.769 93.5145C117.414 90.6545 112.652 88.4735 107.646 87.046C109.873 86.4245 112.057 85.6546 114.182 84.7415C116.992 83.5434 119.678 82.0685 122.199 80.3384C123.719 79.2683 125.173 78.1062 126.552 76.8584C127.875 78.1866 129.198 79.5547 130.521 80.9826C132.869 78.9238 137.493 77.9608 141.813 78.18C141.746 78.2862 141.674 78.3859 141.601 78.4855C139.583 81.2546 137.311 83.8272 134.814 86.1694C133.795 87.139 133.736 87.1257 134.754 87.9691C136.363 89.3746 137.85 90.9142 139.2 92.5715C140.621 94.2316 141.917 95.9964 143.076 97.8512C143.292 98.232 143.513 98.615 143.737 99.0002Z' fill='#FEFEFE'/>
      <path d='M73.0628 87.0458C76.7309 90.5961 79.918 94.6152 82.5421 98.9999H71.4355C69.7251 96.8131 67.8402 94.7698 65.7995 92.89C63.4611 90.6817 60.9006 88.7231 58.1592 87.0458C59.4798 86.2656 60.7502 85.4028 61.9628 84.4624C63.9899 82.959 65.8924 81.2932 67.6517 79.4815C70.6318 76.4401 73.1986 73.0169 75.2854 69.3005C76.8785 66.4072 78.1264 63.3355 79.0031 60.1489C81.8343 60.1489 84.7184 60.1954 87.6026 60.4212L88.3699 60.4677C85.9885 70.8877 80.7494 79.6342 73.0628 87.0458Z' fill='#FEFEFE'/>
      <path d='M45.5511 86.6608C45.4429 86.7703 45.3821 86.9183 45.3821 87.0725C45.3821 87.2268 45.4429 87.3748 45.5511 87.4843C47.3392 89.939 48.991 92.4909 50.4991 95.1283C51.2268 96.399 51.9147 97.6896 52.563 99.0001H44.0297C42.0856 95.5647 39.8737 92.2892 37.4147 89.2044C36.932 88.6059 36.4151 88.036 35.8667 87.4976C35.6154 87.2386 35.5492 87.0991 35.8271 86.7803C37.6835 84.6821 39.4005 82.4636 40.9669 80.1391C43.2231 76.7683 45.1946 73.2139 46.8609 69.5132C47.638 67.8124 48.3269 66.0722 48.9248 64.2999C51.412 63.6358 54.2168 63.0115 57.2928 62.4204C56.8712 64.1967 56.3523 65.9482 55.7383 67.667C54.3204 71.614 52.5491 75.4239 50.4462 79.05C48.9694 81.684 47.3347 84.2256 45.5511 86.6608Z' fill='#FEFEFE'/>
      <path d='M23.7606 99H17.6219C16.7421 96.0048 15.7498 93.0229 14.751 90.0941C14.4335 89.171 14.0895 88.2678 13.719 87.358C13.6764 87.2618 13.6543 87.1577 13.6543 87.0525C13.6543 86.9472 13.6764 86.8431 13.719 86.747C14.8436 84.0374 15.7498 81.2547 16.6098 78.4521C16.8281 77.7349 17.0464 77.0176 17.2713 76.3004C19.1433 75.9417 21.2932 75.862 23.6812 75.5034C23.5159 76.0812 23.3505 76.6656 23.1785 77.2434C22.2524 80.3847 21.2271 83.4928 20.1488 86.5809C20.0118 86.8738 19.9859 87.2067 20.0761 87.5173C21.2866 90.745 22.3384 94.0257 23.3042 97.3264C23.41 97.8842 23.5688 98.4421 23.7606 99Z' fill='#FEFEFE'/>
      <path d='M88.4301 59.6046C87.5304 57.1341 87.1864 50.9246 87.1864 46.3222C82.8999 45.8839 78.7986 47.4246 74.5121 46.9863L69.22 44.7881C69.422 49.7102 68.8891 54.635 67.6391 59.3987C66.5079 63.1178 65.4429 69.6992 78.7126 69.6992C90.2955 69.6992 89.3495 62.5599 88.4301 59.6046Z' fill='#FCA190'/>
      <path d='M68.9883 52.0665C75.2791 55.6926 87.0936 50.3663 82.3506 50.3663C77.6076 50.3663 69.1206 46.8398 69.1206 46.8398C69.1979 48.583 69.1537 50.3295 68.9883 52.0665Z' fill='#F07875'/>
      <path d='M99.96 28.6366C99.4715 28.1243 98.8295 27.7866 98.1319 27.6751C97.4344 27.5635 96.7196 27.6843 96.0968 28.019C95.7925 22.4802 94.4298 17.4727 92.6504 15.2546C90.4939 12.4786 86.6969 8.46063 82.2715 8.10201C77.7468 7.73674 72.3489 11.1171 69.8154 13.415C67.7581 15.3343 65.595 20.096 64.3977 25.5484C63.637 24.8843 62.0494 23.9612 60.3559 25.5484C58.8477 26.9232 59.6614 31.758 63.465 33.7238C63.4593 35.6954 63.6813 37.661 64.1265 39.5813C65.4495 45.1997 69.7955 50.2072 78.3157 51.1303C86.8622 51.7214 91.9756 47.3515 94.1917 42.012C94.9422 40.1576 95.4707 38.2203 95.766 36.2408C99.6292 34.7797 101.316 30.0246 99.96 28.6366Z' fill='#FCA190'/>
      <path d='M72.6591 26.332C73.7439 26.332 74.6436 27.4278 74.6039 28.8092C74.5642 30.1906 73.5654 30.6953 72.4805 30.7086C71.3956 30.7219 70.6746 30.2238 70.6812 28.8424C70.6878 27.461 71.5742 26.3453 72.6591 26.332Z' fill='#252040'/>
      <path d='M87.1597 27.4346C86.0881 27.2553 85.0561 28.2182 84.8444 29.573C84.6328 30.9278 85.2479 31.6119 86.6503 31.7846C87.7286 31.924 88.5091 31.592 88.7142 30.2305C88.9193 28.8691 88.2247 27.6205 87.1597 27.4346Z' fill='#252040'/>
      <path d='M75.524 22.0817C76.4831 23.131 75.5769 23.317 72.0378 23.1443C70.9133 23.0845 70.0534 23.1443 69.7888 22.8787C69.028 21.8692 73.5924 20.6339 75.524 22.0817Z' fill='#252040'/>
      <path d='M85.2811 22.925C84.1367 23.7751 84.9966 24.1138 88.5356 24.5853C89.6602 24.7381 90.5201 24.9506 90.8112 24.7381C91.7571 23.888 87.464 21.8691 85.2811 22.925Z' fill='#252040'/>
      <path d='M79.5063 34.5805C78.2891 34.2684 77.5879 34.0094 77.3167 33.4648C77.0455 32.9202 77.4093 32.3291 77.7599 31.8377C78.1759 31.3151 78.4413 30.6881 78.5272 30.0246C78.5501 29.8678 78.6315 29.7255 78.755 29.6268C78.8785 29.528 79.0348 29.48 79.1922 29.4927C79.3496 29.5054 79.4963 29.5777 79.6025 29.695C79.7088 29.8123 79.7666 29.9658 79.7642 30.1243C79.597 30.9602 79.2493 31.7491 78.7455 32.4354C78.6285 32.5415 78.5456 32.6802 78.5074 32.8339C78.6066 32.9468 78.9043 33.073 79.7179 33.2456C79.8035 33.2548 79.8857 33.2837 79.9582 33.3301C80.0307 33.3766 80.0914 33.4393 80.1357 33.5133C80.1799 33.5874 80.2064 33.6707 80.2131 33.7568C80.2199 33.8429 80.2066 33.9293 80.1744 34.0094C80.1704 34.0918 80.1496 34.1726 80.1133 34.2466C80.077 34.3207 80.026 34.3865 79.9634 34.44C79.9008 34.4936 79.8279 34.5336 79.7493 34.5578C79.6707 34.5819 79.588 34.5897 79.5063 34.5805Z' fill='#F07875'/>
      <path d='M84.8773 15.3874C84.6458 17.9177 86.8618 20.3085 89.3888 20.5343C90.6498 20.5794 91.8947 20.2383 92.9581 19.5563C94.0215 18.8742 94.8531 17.8834 95.3423 16.7156C95.7988 15.5288 95.9766 14.2523 95.8618 12.9853C95.7471 11.7183 95.343 10.495 94.6808 9.41028C93.3397 7.23698 91.365 5.53049 89.0249 4.52236C82.0395 1.29474 72.9439 3.69221 68.4324 9.94158C65.6078 13.8665 65.4094 18.2431 70.7344 19.0267C73.1502 19.4211 75.6182 19.358 78.0109 18.8408C79.2744 18.5552 84.7384 16.9414 84.8773 15.3874Z' fill='#252040'/>
      <path d='M74.0951 38.6848C73.4336 37.1971 74.5118 36.4998 75.7157 36.46C79.989 36.5064 83.6538 36.5729 84.5865 37.4495C86.571 39.2625 82.3241 42.0585 78.8975 42.0983C77.8711 41.9958 76.8888 41.6273 76.0469 41.0289C75.205 40.4305 74.5324 39.6227 74.0951 38.6848Z' fill='white'/>
      <path d='M17.2059 59.4517C17.2059 59.6907 17.8211 60.4412 18.1386 68.3907C17.7152 73.6373 16.9082 74.4276 16.2269 77.6884C15.6513 80.4113 26.6455 84.0042 29.9001 81.4207C30.1779 80.4113 30.4888 79.4284 30.7997 78.4986C31.2702 77.1016 32.0148 75.8135 32.9897 74.7105C33.9646 73.6074 35.1498 72.7118 36.4754 72.0766C38.1092 71.3101 39.5925 70.2551 40.8546 68.9619L25.7459 50.6388L24.4229 49.3105L17.2059 59.4517Z' fill='#FCA190'/>
      <path d='M147.918 72.1229C147.654 67.8925 153.64 60.5008 149.168 55.2675C147.998 53.7799 147.005 53.2287 144.769 52.3521C143.83 52.113 143.208 51.8805 143.03 50.984C142.758 43.7783 145.735 35.3838 141.945 36.3733C139.2 36.9179 138.432 47.0657 138.214 49.4963C137.996 51.927 137.91 52.8701 137.466 52.9697C134.675 53.9858 138.128 38.6048 134.159 38.7376C130.851 38.8837 131.778 53.4213 131.334 56.5227C130.369 63.1639 132.095 67.3346 133.259 71.7975C134.22 74.9758 134.837 78.2489 135.098 81.5601C135.112 81.7049 135.165 81.8431 135.251 81.9601C135.338 82.0771 135.454 82.1683 135.588 82.2242C140.04 84.0638 146.483 81.8124 148.494 81.0155C148.668 80.9462 148.813 80.8182 148.903 80.6534C148.994 80.4885 149.024 80.2972 148.99 80.1123C148.48 77.4721 148.122 74.8045 147.918 72.1229Z' fill='#FCA190'/>
      <path d='M131.679 58.8739C133.974 58.1633 138.234 53.7469 139.471 54.7696C140.291 55.4337 140.86 56.503 138.347 59.7439L133.002 65.3225' fill='#FCA190'/>
      <path d='M133.002 65.6148C132.926 65.6142 132.853 65.5859 132.797 65.5351C132.742 65.4793 132.711 65.4042 132.711 65.3259C132.711 65.2477 132.742 65.1726 132.797 65.1168L138.135 59.5514C140.735 56.2308 139.828 55.4405 139.292 54.9956C138.757 54.5506 136.865 55.8921 135.357 56.9879C134.266 57.8751 133.06 58.608 131.771 59.1662C131.734 59.1774 131.696 59.1811 131.657 59.1771C131.619 59.1731 131.582 59.1615 131.548 59.143C131.514 59.1245 131.484 59.0994 131.46 59.0692C131.436 59.0389 131.418 59.0042 131.407 58.967C131.386 58.893 131.395 58.8138 131.43 58.7458C131.466 58.6777 131.527 58.6261 131.599 58.6017C132.825 58.0582 133.971 57.3499 135.006 56.4965C137.024 55.0421 138.757 53.7935 139.636 54.5041C140.516 55.2147 141.158 56.4965 138.558 59.8835L133.207 65.4555C133.188 65.4964 133.159 65.5323 133.123 65.56C133.088 65.5877 133.046 65.6065 133.002 65.6148Z' fill='#F07875'/>
      <path d='M139.234 64.9042H139.174C138.949 64.9015 138.728 64.8482 138.526 64.7482C138.325 64.6482 138.148 64.504 138.01 64.3264C137.87 64.153 137.771 63.9499 137.72 63.7324C137.67 63.515 137.669 63.2889 137.719 63.0712C138.076 61.2771 138.638 59.5301 139.392 57.8645C139.42 57.8103 139.464 57.7662 139.518 57.7383C141.059 56.9148 143.361 56.4101 146.358 56.2972H146.556C146.63 56.2917 146.703 56.3147 146.761 56.3614C146.818 56.4081 146.856 56.4751 146.866 56.5487C146.876 56.6223 146.858 56.697 146.815 56.7576C146.772 56.8182 146.708 56.8602 146.636 56.875H146.378C143.527 57.0012 141.337 57.4461 139.875 58.2032C139.167 59.8028 138.634 61.4752 138.288 63.1908C138.259 63.324 138.259 63.462 138.289 63.595C138.318 63.7281 138.377 63.853 138.46 63.9611C138.547 64.0689 138.657 64.1562 138.781 64.217C138.906 64.2778 139.042 64.3106 139.181 64.3131C139.523 64.3561 139.871 64.3017 140.184 64.1561C140.498 64.0106 140.764 63.7796 140.953 63.4896C141.181 63.0954 141.385 62.6872 141.562 62.2676C142.124 60.9394 142.501 60.3483 144.87 60.2753C144.947 60.2753 145.021 60.306 145.075 60.3608C145.13 60.4156 145.161 60.49 145.161 60.5675C145.161 60.645 145.13 60.7193 145.075 60.7741C145.021 60.8289 144.947 60.8597 144.87 60.8597C142.779 60.9527 142.574 61.4175 142.085 62.5333C141.889 62.9751 141.668 63.4053 141.423 63.8217C141.188 64.1821 140.861 64.4726 140.476 64.663C140.09 64.8534 139.662 64.9367 139.234 64.9042Z' fill='#F07875'/>
      <path d='M139.591 54.8694C139.544 54.8699 139.499 54.8593 139.457 54.8385C139.416 54.8177 139.38 54.7873 139.352 54.7498C139.308 54.6861 139.29 54.6074 139.302 54.5304C139.314 54.4535 139.356 54.3845 139.419 54.3381C140.417 53.6208 141.787 53.488 143.004 53.3684H143.308C143.348 53.3597 143.39 53.3596 143.43 53.368C143.47 53.3765 143.508 53.3933 143.541 53.4174C143.574 53.4415 143.602 53.4724 143.622 53.508C143.642 53.5436 143.655 53.5831 143.659 53.624C143.664 53.6648 143.659 53.7061 143.646 53.7452C143.634 53.7842 143.613 53.8201 143.585 53.8505C143.558 53.8809 143.524 53.9052 143.487 53.9217C143.449 53.9382 143.409 53.9465 143.368 53.9462H143.057C141.919 54.0591 140.629 54.1853 139.749 54.8096C139.704 54.8449 139.648 54.8657 139.591 54.8694Z' fill='#F07875'/>
      <path d='M26.0831 68.9816C28.5307 69.9379 28.2131 68.1713 37.5006 67.8924C36.4753 67.8924 35.3838 67.0357 34.2526 66.9958C29.1524 67.5205 15.1353 63.4428 20.6389 66.1657C21.3798 66.5309 24.5418 68.3772 26.0831 68.9816Z' fill='#F07875'/>
      <path d='M31.2822 66.5578L33.5445 51.3362C33.5799 51.1046 33.6981 50.8939 33.8771 50.7435C34.0561 50.5931 34.2836 50.5134 34.5169 50.5194L64.2315 51.5155C64.346 51.52 64.4583 51.5485 64.5612 51.5992C64.6641 51.6499 64.7552 51.7217 64.8287 51.8099C64.9023 51.8981 64.9566 52.0008 64.9882 52.1114C65.0198 52.222 65.0279 52.338 65.0121 52.4519L63.2988 66.9032C63.2218 67.4728 62.9356 67.993 62.4962 68.3616C62.0568 68.7302 61.4961 68.9206 60.924 68.8955L32.1818 67.6204C32.0515 67.615 31.9237 67.5825 31.8064 67.525C31.6892 67.4676 31.5851 67.3864 31.5006 67.2866C31.4161 67.1868 31.3531 67.0706 31.3155 66.9452C31.2779 66.8197 31.2666 66.6879 31.2822 66.5578Z' fill='white'/>
      <path d='M61.0306 69.3206H60.9115L32.1627 68.0455C31.9751 68.0375 31.7914 67.9895 31.6238 67.9048C31.4561 67.8201 31.3083 67.7005 31.1903 67.554C31.0697 67.4089 30.9814 67.2397 30.9312 67.0576C30.881 66.8755 30.8701 66.6847 30.8992 66.4981L33.1615 51.2764C33.2073 50.9491 33.373 50.6507 33.6262 50.4395C33.8794 50.2282 34.2019 50.1194 34.5308 50.1341L64.2454 51.1303C64.415 51.135 64.5816 51.1758 64.7343 51.25C64.8869 51.3241 65.0221 51.43 65.1309 51.5606C65.2398 51.6912 65.3197 51.8436 65.3655 52.0075C65.4113 52.1715 65.4218 52.3433 65.3965 52.5117L63.6831 66.9563C63.6014 67.6062 63.2874 68.2041 62.7995 68.639C62.3116 69.0739 61.683 69.3161 61.0306 69.3206ZM31.6599 66.6176C31.6488 66.6965 31.6542 66.7768 31.6759 66.8534C31.6975 66.9301 31.7349 67.0013 31.7856 67.0626C31.8357 67.1239 31.8981 67.1739 31.9688 67.2094C32.0395 67.2449 32.1168 67.265 32.1957 67.2684L60.9446 68.5369C61.425 68.5623 61.8971 68.4036 62.2655 68.093C62.6339 67.7823 62.8711 67.3428 62.9291 66.8633L64.649 52.4054C64.6645 52.2826 64.6312 52.1585 64.5563 52.0601C64.5177 52.0129 64.4693 51.9748 64.4145 51.9484C64.3596 51.922 64.2996 51.908 64.2388 51.9073L34.5242 50.9112C34.3852 50.905 34.249 50.9514 34.1425 51.0411C34.0359 51.1309 33.9668 51.2575 33.9487 51.396L31.6599 66.6176Z' fill='#0077FF'/>
      <path d='M1.8785 62.9779L30.1775 67.4607C30.3014 67.4805 30.428 67.4753 30.5499 67.4455C30.6718 67.4157 30.7866 67.3618 30.8875 67.287C30.9885 67.2122 31.0736 67.118 31.138 67.0099C31.2023 66.9018 31.2446 66.7819 31.2623 66.6572L33.5511 51.4222C33.5896 51.1752 33.5301 50.9228 33.3855 50.7192C33.2408 50.5156 33.0224 50.377 32.7772 50.3331L4.71632 45.3854C4.48634 45.3446 4.25057 45.3501 4.02274 45.4017C3.79491 45.4533 3.57958 45.5499 3.38932 45.6859C3.19907 45.8219 3.03768 45.9945 2.91458 46.1938C2.79147 46.393 2.70911 46.6149 2.67229 46.8465L0.409965 60.9457C0.373192 61.176 0.382029 61.4113 0.435966 61.6382C0.489904 61.865 0.587871 62.0789 0.724283 62.2677C0.860695 62.4565 1.03287 62.6164 1.23093 62.7382C1.42898 62.8601 1.64904 62.9416 1.8785 62.9779Z' fill='white'/>
      <path d='M30.33 67.8595H30.1183L1.8193 63.3767C1.25384 63.2853 0.747575 62.9725 0.411515 62.5069C0.0754553 62.0412 -0.0629591 61.4608 0.0266425 60.8929L2.28898 46.8003C2.33546 46.5177 2.43713 46.2472 2.58816 46.0042C2.73919 45.7613 2.93659 45.5508 3.16899 45.3847C3.4014 45.2187 3.66421 45.1004 3.94231 45.0368C4.22042 44.9731 4.50833 44.9653 4.78946 45.0138L32.8437 49.9615C33.0153 49.9897 33.1797 50.0516 33.3275 50.1437C33.4753 50.2357 33.6035 50.3561 33.7049 50.498C33.8063 50.6399 33.8788 50.8004 33.9183 50.9705C33.9578 51.1405 33.9636 51.3167 33.9352 51.4889L31.6464 66.7637C31.6015 67.0829 31.4425 67.3747 31.1991 67.5849C30.9556 67.795 30.6444 67.9092 30.3234 67.906L30.33 67.8595ZM4.4124 45.751C4.08536 45.7502 3.76886 45.8669 3.52013 46.0801C3.2714 46.2933 3.10688 46.5888 3.05633 46.9132L0.793986 61.0058C0.736767 61.3685 0.825087 61.7393 1.03959 62.0368C1.25408 62.3343 1.57728 62.5343 1.93839 62.593L30.2374 67.0758C30.3122 67.0891 30.3889 67.0872 30.463 67.0702C30.5371 67.0532 30.607 67.0215 30.6686 66.9769C30.7303 66.9323 30.7824 66.8758 30.822 66.8107C30.8615 66.7455 30.8877 66.6731 30.8989 66.5977L33.1811 51.3628C33.1953 51.2885 33.1946 51.2122 33.1788 51.1382C33.1631 51.0643 33.1328 50.9942 33.0896 50.9323C33.0464 50.8704 32.9912 50.8178 32.9274 50.7776C32.8636 50.7375 32.7924 50.7106 32.718 50.6986L4.66377 45.751C4.58014 45.7436 4.49603 45.7436 4.4124 45.751Z' fill='#0077FF'/>
      <path d='M29.556 55.281H29.4899L14.5863 52.6245C14.5361 52.6158 14.4882 52.5971 14.4454 52.5695C14.4025 52.542 14.3655 52.5061 14.3366 52.4641C14.3077 52.4221 14.2874 52.3747 14.2768 52.3247C14.2663 52.2747 14.2658 52.2231 14.2753 52.1729C14.2931 52.0709 14.3504 51.9801 14.4347 51.9204C14.519 51.8606 14.6234 51.8368 14.7252 51.8542L29.6221 54.5107C29.6725 54.515 29.7216 54.5293 29.7665 54.5527C29.8114 54.5761 29.8512 54.6081 29.8838 54.6469C29.9163 54.6858 29.9409 54.7307 29.9562 54.7791C29.9715 54.8275 29.9771 54.8785 29.9728 54.929C29.9684 54.9796 29.9542 55.0289 29.9309 55.0739C29.9076 55.119 29.8757 55.159 29.837 55.1917C29.7983 55.2244 29.7535 55.2491 29.7053 55.2644C29.6571 55.2797 29.6064 55.2854 29.556 55.281Z' fill='#66ADFF'/>
      <path d='M28.7283 58.8142H28.6622L14.301 56.1577C14.203 56.1354 14.1173 56.0759 14.0618 55.9918C14.0064 55.9076 13.9854 55.8051 14.0033 55.7058C14.0212 55.6065 14.0766 55.518 14.1579 55.4586C14.2393 55.3993 14.3403 55.3738 14.4399 55.3874L28.8011 58.0438C28.9028 58.0535 28.9966 58.1034 29.0617 58.1825C29.1269 58.2616 29.158 58.3634 29.1484 58.4655C29.1387 58.5677 29.089 58.6618 29.0103 58.7272C28.9315 58.7926 28.8301 58.8239 28.7283 58.8142Z' fill='#66ADFF'/>
      <path d='M27.8696 62.354H27.7969L13.3431 59.7705C13.2931 59.7614 13.2455 59.7424 13.2028 59.7148C13.1602 59.6871 13.1233 59.6513 13.0945 59.6094C13.0656 59.5675 13.0452 59.5202 13.0345 59.4704C13.0239 59.4205 13.023 59.3691 13.0322 59.3189C13.0413 59.2688 13.0602 59.2209 13.0877 59.1781C13.1152 59.1353 13.1509 59.0983 13.1927 59.0693C13.2345 59.0404 13.2815 59.0199 13.3311 59.0092C13.3808 58.9985 13.432 58.9976 13.482 59.0068L27.9358 61.5902C28.0375 61.599 28.1316 61.6481 28.1974 61.7265C28.2631 61.805 28.2951 61.9065 28.2864 62.0086C28.2776 62.1108 28.2288 62.2053 28.1506 62.2713C28.0725 62.3373 27.9714 62.3694 27.8696 62.3606V62.354Z' fill='#66ADFF'/>
      <path d='M45.1019 56.4964L38.6588 56.3237C38.5565 56.3202 38.4596 56.2765 38.3891 56.202C38.3185 56.1274 38.28 56.028 38.2818 55.9252C38.2852 55.8219 38.3286 55.7239 38.4026 55.652C38.4767 55.5801 38.5757 55.54 38.6787 55.54L45.1283 55.7194C45.1793 55.7202 45.2295 55.7312 45.2762 55.7518C45.3229 55.7724 45.365 55.8021 45.4001 55.8392C45.4352 55.8763 45.4626 55.92 45.4807 55.9679C45.4987 56.0157 45.5071 56.0667 45.5054 56.1178C45.5037 56.1693 45.4919 56.2199 45.4707 56.2668C45.4494 56.3137 45.4193 56.3559 45.3818 56.3911C45.3443 56.4262 45.3003 56.4536 45.2523 56.4717C45.2042 56.4898 45.1531 56.4981 45.1019 56.4964Z' fill='#66ADFF'/>
      <path d='M44.6121 60.0629L38.169 59.8902C38.114 59.8975 38.058 59.8928 38.0049 59.8766C37.9517 59.8604 37.9026 59.833 37.8609 59.7962C37.8192 59.7594 37.7857 59.7141 37.7628 59.6634C37.7399 59.6126 37.728 59.5575 37.728 59.5017C37.728 59.4459 37.7399 59.3908 37.7628 59.34C37.7857 59.2892 37.8192 59.244 37.8609 59.2072C37.9026 59.1704 37.9517 59.143 38.0049 59.1268C38.058 59.1106 38.114 59.1059 38.169 59.1132L44.6121 59.2859C44.706 59.2982 44.7922 59.3444 44.8547 59.4159C44.9172 59.4874 44.9516 59.5793 44.9516 59.6744C44.9516 59.7695 44.9172 59.8613 44.8547 59.9328C44.7922 60.0043 44.706 60.0505 44.6121 60.0629Z' fill='#66ADFF'/>
      <path d='M43.7588 63.702L37.3092 63.5294C37.2541 63.5366 37.1981 63.532 37.145 63.5158C37.0918 63.4996 37.0428 63.4721 37.001 63.4354C36.9593 63.3986 36.9258 63.3533 36.9029 63.3025C36.88 63.2517 36.8682 63.1966 36.8682 63.1409C36.8682 63.0851 36.88 63.03 36.9029 62.9792C36.9258 62.9284 36.9593 62.8831 37.001 62.8463C37.0428 62.8096 37.0918 62.7822 37.145 62.7659C37.1981 62.7497 37.2541 62.7451 37.3092 62.7523L43.7522 62.925C43.8461 62.9374 43.9323 62.9836 43.9948 63.0551C44.0573 63.1266 44.0917 63.2184 44.0917 63.3135C44.0917 63.4086 44.0573 63.5005 43.9948 63.572C43.9323 63.6435 43.8461 63.6897 43.7522 63.702H43.7588Z' fill='#66ADFF'/>
      <path d='M60.4884 56.8817L48.5814 56.4301C48.5304 56.4292 48.4802 56.4182 48.4335 56.3976C48.3868 56.377 48.3447 56.3473 48.3096 56.3102C48.2745 56.2731 48.2471 56.2294 48.229 56.1816C48.211 56.1337 48.2026 56.0827 48.2043 56.0316C48.206 55.9801 48.2178 55.9295 48.2391 55.8826C48.2603 55.8358 48.2904 55.7935 48.3279 55.7584C48.3654 55.7232 48.4094 55.6958 48.4574 55.6777C48.5055 55.6597 48.5566 55.6513 48.6078 55.6531L60.5148 56.1047C60.5658 56.1055 60.6161 56.1165 60.6627 56.1371C60.7094 56.1577 60.7515 56.1874 60.7866 56.2245C60.8217 56.2616 60.8491 56.3053 60.8672 56.3532C60.8853 56.401 60.8937 56.452 60.8919 56.5031C60.8868 56.6065 60.8418 56.7038 60.7665 56.7744C60.6912 56.8451 60.5915 56.8835 60.4884 56.8817Z' fill='#66ADFF'/>
      <path d='M59.4831 60.5872L47.576 60.1357C47.5254 60.1348 47.4754 60.1238 47.4291 60.1031C47.3828 60.0825 47.3411 60.0526 47.3065 60.0154C47.2719 59.9782 47.2451 59.9344 47.2278 59.8866C47.2105 59.8388 47.2029 59.788 47.2056 59.7372C47.2065 59.686 47.2174 59.6355 47.2379 59.5887C47.2584 59.5418 47.288 59.4996 47.325 59.4643C47.3619 59.4291 47.4055 59.4016 47.4531 59.3834C47.5008 59.3653 47.5516 59.3569 47.6025 59.3586L59.5095 59.8102C59.6034 59.8226 59.6897 59.8688 59.7522 59.9403C59.8146 60.0118 59.8491 60.1036 59.8491 60.1987C59.8491 60.2938 59.8146 60.3857 59.7522 60.4572C59.6897 60.5287 59.6034 60.5749 59.5095 60.5872H59.4831Z' fill='#66ADFF'/>
      <path d='M58.8673 64.1605L46.9603 63.7155C46.9093 63.7138 46.8592 63.702 46.8129 63.6806C46.7665 63.6592 46.7249 63.6288 46.6904 63.5911C46.6559 63.5533 46.6292 63.5091 46.612 63.4609C46.5947 63.4127 46.5872 63.3616 46.5899 63.3104C46.5907 63.2596 46.6017 63.2094 46.6223 63.1629C46.6429 63.1164 46.6725 63.0745 46.7096 63.0398C46.7466 63.0051 46.7903 62.9782 46.8379 62.9608C46.8855 62.9434 46.9362 62.9358 46.9868 62.9385L58.8938 63.3835C58.9877 63.3958 59.074 63.4421 59.1364 63.5135C59.1989 63.585 59.2334 63.6769 59.2334 63.772C59.2334 63.8671 59.1989 63.959 59.1364 64.0304C59.074 64.1019 58.9877 64.1481 58.8938 64.1605H58.8673Z' fill='#66ADFF'/>
      <path d='M47.8996 62.6259H47.7276C47.0419 62.5923 46.3815 62.3554 45.8298 61.9452C45.2781 61.535 44.8598 60.9698 44.6279 60.321C44.396 59.6723 44.3609 58.9691 44.5269 58.3003C44.6929 57.6315 45.0526 57.0271 45.5606 56.5635C46.0686 56.0998 46.7021 55.7978 47.381 55.6954C48.0599 55.593 48.7539 55.695 49.3751 55.9884C49.9963 56.2818 50.517 56.7535 50.8713 57.3438C51.2256 57.9342 51.3977 58.6167 51.3658 59.3053C51.3234 60.1997 50.9397 61.0435 50.2944 61.6617C49.6491 62.2799 48.7916 62.6252 47.8996 62.6259ZM47.8996 56.4296C47.1722 56.4471 46.481 56.7517 45.9758 57.2774C45.4706 57.8032 45.1922 58.5076 45.2009 59.2381C45.2096 59.9685 45.5047 60.6661 46.0223 61.1795C46.5399 61.6929 47.2381 61.9809 47.9658 61.9809C48.6934 61.9809 49.3916 61.6929 49.9092 61.1795C50.4268 60.6661 50.7219 59.9685 50.7306 59.2381C50.7393 58.5076 50.4609 57.8032 49.9557 57.2774C49.4505 56.7517 48.7593 56.4471 48.0319 56.4296H47.8996Z' fill='#66ADFF'/>
      <path d='M56.0243 66.8034H55.8523C54.9705 66.7807 54.1332 66.4102 53.5216 65.7721C52.91 65.134 52.5734 64.2797 52.5847 63.3942C52.5961 62.5088 52.9543 61.6634 53.582 61.0413C54.2097 60.4192 55.0562 60.0703 55.9383 60.0703C56.8203 60.0703 57.6668 60.4192 58.2945 61.0413C58.9222 61.6634 59.2805 62.5088 59.2918 63.3942C59.3031 64.2797 58.9665 65.134 58.355 65.7721C57.7434 66.4102 56.906 66.7807 56.0243 66.8034ZM56.0243 60.6071C55.4981 60.6091 54.9841 60.7659 54.5457 61.058C54.1073 61.35 53.7638 61.7647 53.5576 62.2506C53.3514 62.7366 53.2915 63.2725 53.3853 63.7923C53.4792 64.312 53.7227 64.7928 54.0857 65.1751C54.4488 65.5575 54.9154 65.8246 55.428 65.9436C55.9406 66.0626 56.4768 66.0282 56.9701 65.8446C57.4634 65.661 57.8924 65.3364 58.2039 64.9107C58.5155 64.4851 58.696 63.9771 58.7232 63.4496C58.7574 62.7323 58.5073 62.0305 58.0276 61.4979C57.5478 60.9652 56.8775 60.6449 56.1632 60.6071H56.0243Z' fill='#66ADFF'/>
      <path d='M35.252 50.114L36.0391 48.8521C36.3818 48.2775 36.8805 47.8129 37.4769 47.513C38.0734 47.213 38.7426 47.0901 39.4062 47.1586H40.0214C40.3584 47.1704 40.6848 47.2802 40.9608 47.4747C41.2367 47.6693 41.4504 47.9402 41.5759 48.2544C41.9397 49.1443 42.3829 50.0874 42.7732 51.0304C42.8558 51.2127 42.8964 51.4114 42.8917 51.6115C42.8871 51.8117 42.8374 52.0083 42.7464 52.1865C42.6554 52.3647 42.5255 52.5199 42.3664 52.6406C42.2072 52.7613 42.0229 52.8443 41.8273 52.8833C41.2201 53.0192 40.5891 53.0055 39.9883 52.8435C39.6115 52.7333 39.2604 52.5485 38.9559 52.2998C38.6514 52.0511 38.3996 51.7437 38.2155 51.3957L37.64 50.3331' fill='#FCA190'/>
      <path d='M29.2588 49.1908L30.4693 46.9926C30.7477 46.4975 31.174 46.1028 31.688 45.8645C32.2021 45.6261 32.7778 45.5561 33.3336 45.6643L34.4119 45.8636C34.6838 45.9158 34.9339 46.0488 35.1297 46.2454C35.3254 46.4419 35.4579 46.693 35.51 46.966L36.5022 52.2059C36.5419 52.419 36.5363 52.638 36.4859 52.8488C36.4355 53.0596 36.3413 53.2573 36.2096 53.4291C36.0779 53.6008 35.9115 53.7427 35.7214 53.8455C35.5313 53.9483 35.3218 54.0097 35.1065 54.0256C34.4176 54.0733 33.7332 53.8833 33.1666 53.4872C32.6 53.091 32.1851 52.5125 31.9908 51.8473L31.0382 48.5997' fill='#FCA190'/>
      <path d='M24.4224 48.4871L25.6329 46.1759C25.9138 45.6817 26.3416 45.288 26.8563 45.0499C27.3711 44.8117 27.9471 44.741 28.5038 44.8477L29.5821 45.0536C29.8544 45.105 30.1049 45.2377 30.3008 45.4344C30.4968 45.6311 30.629 45.8826 30.6802 46.156L31.6724 51.3959C31.7069 51.6042 31.6983 51.8173 31.647 52.022C31.5958 52.2267 31.503 52.4186 31.3744 52.5857C31.2459 52.7528 31.0844 52.8914 30.9001 52.9929C30.7157 53.0945 30.5125 53.1567 30.3031 53.1758C29.6147 53.2263 28.9298 53.0383 28.3627 52.6431C27.7957 52.2479 27.3809 51.6693 27.1874 51.0041L26.4664 48.8125L24.4224 48.4871Z' fill='#FCA190'/>
      <path d='M18.377 47.3914L19.4155 46.0631C19.7831 45.5479 20.2899 45.1491 20.876 44.9138C21.462 44.6784 22.1029 44.6163 22.723 44.7349H22.7958C23.1674 44.7845 23.5112 44.9594 23.7708 45.2309C24.0304 45.5024 24.1904 45.8544 24.2246 46.2292C24.3098 47.2188 24.4825 48.1989 24.7406 49.1579C24.7879 49.33 24.7968 49.5104 24.7667 49.6864C24.7367 49.8623 24.6684 50.0295 24.5668 50.1759C24.4651 50.3224 24.3325 50.4446 24.1785 50.5338C24.0245 50.623 23.8528 50.677 23.6756 50.692C23.1766 50.7166 22.6787 50.6257 22.2203 50.4264C21.8427 50.2676 21.507 50.023 21.2396 49.7118C20.9721 49.4007 20.7802 49.0315 20.679 48.6333L20.5004 47.7766L18.377 47.3914Z' fill='#FCA190'/>
      <path d='M32.7642 73.3049C42.1442 71.897 43.0307 71.5184 44.056 69.0545C44.433 68.1513 42.3493 65.7339 40.8543 63.2368C39.5313 61.0917 39.7232 60.773 39.0683 60.6733C38.4134 60.5737 36.753 61.4902 36.9118 63.4494C37.0705 65.4085 38.4862 68.2642 38.4862 68.2642' fill='#FCA190'/>
      <path d='M31.6996 51.5218C31.6328 51.5217 31.5681 51.4986 31.5163 51.4562C31.4645 51.4139 31.4288 51.355 31.4151 51.2894L30.5221 46.6937C30.5144 46.6566 30.5141 46.6183 30.5213 46.5811C30.5285 46.5439 30.5429 46.5085 30.5639 46.477C30.5848 46.4455 30.6118 46.4185 30.6432 46.3975C30.6747 46.3766 30.7099 46.3621 30.747 46.355C30.7848 46.3473 30.8238 46.3472 30.8616 46.3547C30.8995 46.3623 30.9354 46.3773 30.9674 46.3989C30.9995 46.4205 31.0268 46.4483 31.0481 46.4807C31.0693 46.5131 31.0839 46.5493 31.091 46.5874L31.984 51.1831C31.9917 51.2202 31.992 51.2585 31.9848 51.2957C31.9776 51.3329 31.9631 51.3682 31.9422 51.3998C31.9213 51.4313 31.8943 51.4583 31.8629 51.4793C31.8314 51.5002 31.7962 51.5147 31.7591 51.5218H31.6996Z' fill='#F07875'/>
      <path d='M13.1041 53.0692L12.6411 55.7921L12.4492 56.9411C12.3593 57.8908 12.1366 58.8231 11.7877 59.7104C11.6911 59.8908 11.5608 60.0509 11.4041 60.182C11.2828 60.2826 11.1437 60.3592 10.9939 60.4078C10.4383 60.607 9.67093 60.4742 8.41408 60.2617L6.07898 59.8632C4.47815 59.5909 3.67774 59.4514 3.27422 58.8006C3.20677 58.6857 3.15347 58.5631 3.11547 58.4353C3.0753 58.2971 3.05306 58.1542 3.04932 58.0103C3.04962 57.1168 3.14723 56.226 3.34038 55.3538L3.57851 53.9525L3.99526 51.482C4.30616 49.6424 4.45831 48.7259 5.05366 48.2411C5.64901 47.7563 6.44942 47.8891 8.04364 48.1614L10.3854 48.5599C11.9862 48.8322 12.78 48.965 13.1835 49.6225C13.587 50.2799 13.415 51.2296 13.1041 53.0692Z' fill='#00EAFF'/>
      <path d='M12.6415 55.7922L12.4497 56.9411C12.3598 57.8908 12.1371 58.8231 11.7882 59.7105C11.6915 59.8909 11.5613 60.0509 11.4045 60.182C11.2833 60.2827 11.1441 60.3593 10.9944 60.4078C10.1386 60.305 9.29009 60.1475 8.45422 59.9363C7.39581 59.6773 6.28449 59.3519 5.29224 59.0597C4.45875 58.8139 3.70465 58.5881 3.12914 58.4354C3.08897 58.2971 3.06673 58.1543 3.06299 58.0103C3.0633 57.1168 3.1609 56.2261 3.35405 55.3539L3.5922 53.9526C3.71194 53.8435 3.83781 53.7415 3.96924 53.6471C5.69576 52.5778 4.214 51.8872 5.60315 51.1566C5.97404 51.0343 6.30535 50.8141 6.56233 50.5191C7.05846 49.7288 7.88533 49.6491 8.87096 50.0741C8.9958 50.1333 9.13027 50.1693 9.26787 50.1804C9.45833 50.1465 9.65368 50.1519 9.84198 50.1964C10.0303 50.2409 10.2076 50.3235 10.363 50.4391C10.5184 50.5547 10.6487 50.701 10.7459 50.8689C10.8431 51.0368 10.9051 51.2228 10.9282 51.4156C11.1135 51.9403 11.4707 52.0333 11.7419 52.3056C12.0588 52.6292 12.26 53.0492 12.3138 53.4998C12.3677 53.9504 12.2712 54.4063 12.0396 54.796C12.0179 54.8715 12.0183 54.9516 12.0405 55.027C12.0628 55.1023 12.1061 55.1696 12.1652 55.221C12.3509 55.3871 12.5113 55.5795 12.6415 55.7922Z' fill='#252040'/>
      <path d='M11.775 59.7107C11.6784 59.8911 11.5481 60.0512 11.3914 60.1823C11.2701 60.2829 11.1309 60.3595 10.9812 60.4081C10.4256 60.6073 9.65823 60.4745 8.40138 60.2619L6.06629 59.8635C4.46545 59.5912 3.66504 59.4517 3.26153 58.8009C3.19408 58.686 3.14075 58.5634 3.10276 58.4356C3.06258 58.2974 3.04037 58.1545 3.03662 58.0106C3.69812 57.9375 4.53161 57.8778 5.24603 57.8711C5.47094 57.8711 5.68261 57.8711 5.87444 57.8711C6.02208 57.8633 6.17003 57.8633 6.31766 57.8711C6.70593 58.0402 7.11948 58.1435 7.54144 58.1766H7.60757C7.99291 58.2821 8.39344 58.3203 8.79167 58.2895L9.0695 58.3626C9.2878 58.429 9.52594 58.5153 9.731 58.6149C10.4387 58.9284 11.1219 59.2947 11.775 59.7107Z' fill='#CC61FA'/>
      <path d='M8.93053 58.2561C8.89982 57.6435 8.94874 57.0296 9.07606 56.4297C8.50717 56.2704 7.91183 56.3833 7.34294 56.2239L6.68144 55.7988C6.59408 56.4695 6.40677 57.1233 6.12578 57.7381C5.62965 57.8178 5.20629 59.0065 7.44878 59.405C9.69127 59.8035 9.16867 58.3823 8.93053 58.2561Z' fill='#FCA190'/>
      <path d='M6.47656 56.7816C7.2439 57.4457 8.96381 56.9742 8.32216 56.8679C7.72137 56.6858 7.14908 56.4198 6.62209 56.0776C6.58875 56.3152 6.54014 56.5503 6.47656 56.7816Z' fill='#F07875'/>
      <path d='M11.0147 54.0855C10.9536 54.0097 10.8696 53.9558 10.7754 53.9318C10.6812 53.9079 10.5818 53.9152 10.4921 53.9527C10.546 53.3402 10.4388 52.7242 10.1812 52.1662C9.89669 51.6411 9.42361 51.2445 8.85821 51.0572C8.21881 51.0327 7.59123 51.2349 7.08539 51.6283C6.65769 52.0712 6.3522 52.6182 6.19898 53.2155C6.16632 53.1749 6.1259 53.1413 6.0801 53.1165C6.0343 53.0918 5.98405 53.0765 5.93228 53.0716C5.88052 53.0666 5.82828 53.0721 5.77866 53.0877C5.72904 53.1033 5.68301 53.1287 5.6433 53.1624C5.41839 53.3351 5.47133 54.0059 5.96745 54.318C5.93451 54.5871 5.93451 54.8592 5.96745 55.1282C6.05407 55.5706 6.27249 55.976 6.59379 56.291C6.91508 56.6059 7.32409 56.8154 7.76663 56.8917C8.20918 56.968 8.66439 56.9076 9.07195 56.7184C9.47951 56.5292 9.8202 56.2201 10.0489 55.8322C10.1731 55.5873 10.2686 55.3288 10.3334 55.0618C10.889 54.9223 11.1933 54.2914 11.0147 54.0855Z' fill='#FCA190'/>
      <path d='M9.11591 52.0868C9.08924 52.2602 9.13202 52.4371 9.23489 52.579C9.33777 52.7209 9.49239 52.8161 9.66497 52.8438C9.83745 52.8682 10.0132 52.8385 10.1683 52.7589C10.3234 52.6792 10.4502 52.5535 10.5315 52.3989C10.608 52.2429 10.6477 52.0713 10.6477 51.8975C10.6477 51.7236 10.608 51.5521 10.5315 51.3961C10.3729 51.082 10.124 50.8228 9.81712 50.6523C9.35084 50.4007 8.81454 50.3119 8.29248 50.3996C7.77043 50.4874 7.2922 50.7468 6.93296 51.1371C6.49637 51.6352 6.41699 52.2329 7.13803 52.4055C7.46374 52.4913 7.80277 52.5138 8.1369 52.4719C8.30889 52.452 9.07622 52.2993 9.11591 52.0868Z' fill='#252040'/>
      <path d='M9.96905 59.6043C7.70011 60.2684 6.50279 60.0692 4.80273 58.7011L5.59654 57.2201C5.63561 57.1483 5.6894 57.0857 5.75437 57.0364C5.81934 56.9871 5.89399 56.9521 5.9734 56.9339C6.05281 56.9157 6.13517 56.9147 6.21502 56.9308C6.29488 56.9469 6.37042 56.9799 6.43664 57.0275C7.18005 57.5107 8.08066 57.6867 8.95034 57.5189C9.09666 57.4915 9.2479 57.5227 9.37157 57.6059C9.49524 57.6891 9.58149 57.8176 9.61184 57.9639L9.96905 59.6043Z' fill='#CC61FA'/>
      <path d='M7.34982 55.1678C7.28367 54.9553 7.43582 54.8756 7.60119 54.8822C8.01338 54.8754 8.42205 54.9593 8.79851 55.1279C9.04988 55.4002 8.43468 55.7323 7.96501 55.6924C7.82587 55.6663 7.69594 55.6041 7.58805 55.5121C7.48016 55.4201 7.39804 55.3014 7.34982 55.1678Z' fill='white'/>
      <path d='M15.2679 62.8253H15.076C14.071 62.7754 13.1265 62.3284 12.4486 61.5818C11.7707 60.8353 11.4145 59.8499 11.4576 58.8406C11.5091 57.8317 11.9546 56.8838 12.6976 56.2025C13.4406 55.5212 14.4211 55.1614 15.4266 55.2012C16.4375 55.2528 17.3868 55.7047 18.0664 56.4579C18.7459 57.2111 19.1002 58.2041 19.0516 59.2191C18.993 60.1895 18.5699 61.1015 17.8676 61.7708C17.1653 62.4401 16.2362 62.817 15.2679 62.8253ZM15.2679 55.9384C14.5146 55.939 13.7884 56.2206 13.2304 56.7287C12.7852 57.1344 12.4698 57.6635 12.3239 58.2491C12.1779 58.8347 12.208 59.4506 12.4105 60.019C12.6129 60.5874 12.9785 61.0829 13.4611 61.4429C13.9438 61.8029 14.5218 62.0112 15.1223 62.0417C15.5315 62.08 15.9441 62.0348 16.3354 61.9087C16.7266 61.7827 17.0884 61.5783 17.3988 61.3081C17.7093 61.0378 17.962 60.7072 18.1418 60.3362C18.3215 59.9653 18.4245 59.5616 18.4447 59.1495C18.4648 58.7375 18.4015 58.3256 18.2588 57.9388C18.1161 57.5519 17.8968 57.1981 17.6141 56.8986C17.3315 56.5992 16.9914 56.3603 16.6143 56.1964C16.2372 56.0325 15.8309 55.947 15.42 55.945L15.2679 55.9384Z' fill='#66ADFF'/>
    </svg>);
};
export default StudentWithId;
