import React from 'react';
import { usePlatform } from '@vkontakte/vkui';
const VALID_PLATFORM = ['vkcom', 'ios', 'android'];
const filteredNotValidPlatformType = (platform) => {
    return !VALID_PLATFORM.includes(platform);
};
const VkuiPlatformChooser = (props) => {
    const platform = usePlatform();
    if (filteredNotValidPlatformType(platform)) {
        return null;
    }
    if ('desktop' in props) {
        if (platform === 'vkcom') {
            return <>{props.desktop}</>;
        }
        return <>{props.mobile}</>;
    }
    return <>{props[platform]}</>;
};
export default VkuiPlatformChooser;
