import React, { useEffect } from 'react';
import { useGate, useUnit } from 'effector-react';
import { callAnalyticShowDirect } from '@analytics';
import { Gradient, Subhead, Title } from '@vkontakte/vkui';
import { StudentModel } from '../../../model';
import { showStudentStatusReadyModal } from '../model';
import styles from './styles.module.scss';
export const Children = () => {
    useGate(showStudentStatusReadyModal.gate);
    const [status] = useUnit([StudentModel.$status]);
    const [stepValue] = useUnit([showStudentStatusReadyModal.$stepValue]);
    const { title, description, icon, subheader, showKey } = stepValue;
    const analyticShow = status ? showKey(status) : null;
    useEffect(() => {
        if (analyticShow) {
            callAnalyticShowDirect(analyticShow);
        }
    }, [analyticShow]);
    return (<>
      <Gradient mode='tint' to='top' className={styles.gradient}>
        {icon}
      </Gradient>

      <div className={styles.content}>
        <Subhead className={styles.subhead} Component='h5'>
          {subheader}
        </Subhead>
        <Title level='2' weight='2' Component='h2'>
          {title}
        </Title>
        <Subhead className={styles.description} Component='h5'>
          {description}
        </Subhead>
      </div>
    </>);
};
