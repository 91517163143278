import { IS_CLIENT, IS_MINIAPP, IS_STORYBOOK } from '@common/consts';
class Analytics {
    get isReadyToSend() {
        return this.isInited && this.isAuthorized !== null;
    }
    constructor(config) {
        this.staleEvents = [];
        this.isInited = false;
        this.isAuthorized = null;
        this.requestHeaders = {};
        this.setHeader = (header, value) => {
            this.requestHeaders[header] = value;
        };
        this.setAuthToken = (token) => {
            this.setHeader('authorization', `Bearer ${token}`);
            this.isAuthorized = true;
            if (this.isReadyToSend) {
                this.sendStaleEvents();
            }
        };
        this.setUnauthorized = () => {
            this.isAuthorized = false;
            if (this.isReadyToSend) {
                this.sendStaleEvents();
            }
        };
        this.init = async () => {
            if (!IS_CLIENT || IS_STORYBOOK) {
                return;
            }
            const promises = [];
            promises.push(this.getMyTrackerLvid().then((result) => {
                if (result) {
                    this.setHeader('x-mytracker-lvid', result);
                }
            }));
            promises.push(this.getSessionId().then((result) => {
                let xComboTrToken = '';
                if (result) {
                    try {
                        xComboTrToken = Buffer.from(result, 'base64').toString().split('|')[0];
                    }
                    catch (error) {
                    }
                }
                if (result) {
                    this.setHeader('x-session-id', result);
                }
                if (xComboTrToken) {
                    this.setHeader('x-combo-tr-token', xComboTrToken);
                }
            }));
            if (IS_MINIAPP) {
                promises.push((async () => {
                    const vKWebAppGetConfig = await (await import('../ApiVkBridge')).default.getConfig();
                    const gaid = vKWebAppGetConfig?.gaid;
                    if (gaid) {
                        this.setHeader('x-app-gaid', gaid);
                    }
                    return Promise.resolve();
                })());
            }
            await Promise.allSettled(promises);
            this.isInited = true;
            await this.sendStaleEvents();
        };
        this.addEvent = async (eventRaw) => {
            const event = await this.configureEvent(eventRaw);
            event.payload = {
                ...{ url: location.href, referer: document.referrer },
                ...event.payload,
            };
            if (!this.isReadyToSend) {
                this.staleEvents.push(event);
                return;
            }
            if (this.staleEvents.length > 0) {
                this.staleEvents.push(event);
                await this.sendStaleEvents();
                return;
            }
            try {
                const response = await this.sendEvents([event], this.requestHeaders);
                const resJson = (await response.json());
                if (!resJson.success) {
                    this.staleEvents.push(event);
                }
            }
            catch {
                this.staleEvents.push(event);
            }
        };
        this.sendStaleEvents = async () => {
            if (!this.isReadyToSend) {
                return;
            }
            if (this.staleEvents.length === 0) {
                return;
            }
            const events = this.staleEvents;
            this.staleEvents = [];
            try {
                const response = await this.sendEvents(events, this.requestHeaders);
                const resJson = (await response.json());
                if (!resJson.success) {
                    this.staleEvents = [...this.staleEvents, ...events];
                }
            }
            catch {
                this.staleEvents = [...this.staleEvents, ...events];
            }
        };
        this.getMyTrackerLvid = config.getMyTrackerLvid;
        this.getSessionId = config.getSessionId;
        this.sendEvents = config.sendEvents;
        this.configureEvent = config.configureEvent;
        void this.init();
    }
}
export default Analytics;
