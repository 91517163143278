import React from 'react';
const WinbackIcon = () => {
    return (<svg xmlns='http://www.w3.org/2000/svg' width='182' height='111' fill='none' viewBox='0 0 182 111'>
      <path fill='#8024C0' d='M153.003 86.158a106.84 106.84 0 01-11.496 24.769c-5.257 8.453-11.066 15.46-16.634 20.483l-19.119-16c5.574-5.028 11.414-12.048 16.633-20.476a107.3 107.3 0 0011.504-24.77l19.112 15.994z' opacity='0.3'></path>
      <path fill='#212121' d='M70.509 4.766c2.853 3.881 2.251 8.542 5.44 12.201 3.311 3.805 9.773 3.291 14.618 5.327 3.767 1.579 6.538 5.32 7.901 8.878 3.856 10.05-5.853 13.412 1.63 28.34 11.129 22.194-27.268 25.492-46.42 26.633-3.246.21 5.239 14.522 2.747 16.456-9.512 7.394-6.608-2.537-22.652 4.382-18.618 8.066-15.454-3.139-21.078-11.719-1.662-2.537-8.244-4.927-10.051-8.174-7.94-14.141 10.672-17.953 6.417-30.718-3.323-9.962-5.117-22.886 5.073-30.597 7.711-5.815 11.332-3.919 15.98-12.004 1.7-2.714 3.767-5.453 6.875-6.747a8.206 8.206 0 015.827-.14c2.771 1.047 4.61.577 7.28-1.268 12.803-8.7 17.762-4.433 20.413-.85z'></path>
      <path fill='#FFA9A9' d='M53.99 43.335c2.72 2.498 3.285 8.934-.476 9.264 6.71.634 24.313 18.764 30.439 15.727 3.862-3.976-7.363-12.752-7.363-12.752s-6.462-6.133-2.232-9.81c7.825-6.799-20.368-2.43-20.368-2.43z'></path>
      <path fill='#FF8481' d='M74.853 53.557c-1.642-2.39-2.803-5.707-.73-7.546 3.52-3.171-.405-3.628-5.707-3.805l-7.419 3.627c-3.982.032 1.573 7.838 13.856 7.724z'></path>
      <path fill='#FFA9A9' d='M56.33 15.477c4.762-3.577 9.829-1.268 16.855 6.297 7.762 8.39 11.68 19.779.228 24.972-8.117 3.678-18.276-2.752-18.276-2.752s-7.127-2.194-4.756-5.898c1.605-2.536 4.845-.513 3.672-4.014-1.173-3.5-3.621-14.179 2.277-18.605z'></path>
      <path fill='#fff' d='M75.411 28.978c3.221 4.616-.241 11.287-9.068 8.77-5.676-1.61-4.534-3.526-.064-5.631 6.342-2.98 8.067-4.649 9.132-3.14z'></path>
      <path fill='#212121' d='M53.9 25.21c.318-1.978 2.835-6.17 4.326-4.146.285.393-.508 1.674-1.18 2.473a14.934 14.934 0 01-1.96 2.25c-.633.54-1.274.064-1.185-.576zM68.752 17.709c-1.464 1.142-6.087 2.353-6.024 0 0-.463 1.446-.799 2.486-.9a16.866 16.866 0 012.961-.254c.806.07 1.047.78.578 1.154z'></path>
      <path fill='#FF8481' d='M65.892 28.153c-.78-.412.87-.976.596-2.01-.577-2.13-2.739-2.663-2.403-3.025.336-.361 2.955 1.408 3.722 2.714.413.691-.748 2.924-1.915 2.321z'></path>
      <path fill='#212121' d='M60.249 28.115a.998.998 0 00.469-1.458 1.903 1.903 0 00-2.359-.895c-.875.362-.742 1.383-.488 2.17a1.006 1.006 0 001.338.633l1.04-.45zM69.773 24.063a.982.982 0 00.54-1.433 1.903 1.903 0 00-2.309-1.008c-.894.317-.812 1.344-.59 2.143a1.003 1.003 0 001.3.678l1.06-.38z'></path>
      <path fill='#F45FFF' d='M112.134 112.405l6.576.767 11.332-42.9S92.628 58.154 76.14 55.092c6.411 11.046-6.246 2.834-6.246 2.834-7.8-5.707-22.6-11.782-29.608-2.08-5.257 7.28-4.623 16.488-1.217 25.682 20.355 67.561-3.995 100.486 13.805 110.004-2.848-6.341 14.674-22.163 34.027-26.849 22.068-5.346 29.139 1.598 32.557 2.739-2.169-21.453-.209-32.011-6.589-52.513a46.205 46.205 0 01-.735-2.504z'></path>
      <path fill='#6F16AA' d='M112.134 112.405l6.576.767 11.332-42.9S92.628 58.154 76.14 55.092c6.411 11.046-6.246 2.834-6.246 2.834-7.8-5.707-22.6-11.782-29.608-2.08-5.257 7.28-4.623 16.488-1.217 25.682 20.355 67.561-3.995 100.486 13.805 110.004-2.848-6.341 14.674-22.163 34.027-26.849 22.068-5.346 29.139 1.598 32.557 2.739-2.169-21.453-.209-32.011-6.589-52.513a46.205 46.205 0 01-.735-2.504z'></path>
      <path fill='#212121' d='M96.02 98.143c3.59 2.536 3.025 9.671-6.468 17.965-7.4 6.468-15.853 9.512-19.455 6.976-3.602-2.537-1.32-7.724 5.035-12.734L96.02 98.143z'></path>
      <path fill='#07F' d='M105.215 94.687c2.023-16.78 3.031-25.175 8.878-29.76 5.847-4.585 14.268-3.583 31.073-1.573l3.082.368c16.823 2.01 25.232 3.012 29.836 8.878 4.603 5.866 3.583 14.23 1.56 31.009l-.368 3.088c-2.023 16.78-3.031 25.176-8.878 29.76-5.847 4.585-14.262 3.59-31.073 1.579l-3.088-.374c-16.824-2.01-25.232-3.018-29.804-8.878-4.573-5.859-3.596-14.236-1.573-31.015l.355-3.082z'></path>
      <path fill='#F45FFF' d='M109.597 43.043c-1.997-13.951-2.993-20.927.723-25.847 3.716-4.921 10.704-5.955 24.662-7.965l2.536-.368c13.951-2.017 20.927-3.018 25.898.685 4.972 3.703 5.949 10.672 7.94 24.598l.374 2.537c1.991 13.95 2.987 20.926-.729 25.847-3.716 4.921-10.692 5.96-24.649 7.971l-2.568.361c-13.951 2.01-20.927 3.019-25.886-.684-4.959-3.704-5.961-10.673-7.952-24.598l-.349-2.537zM169.346 88.288c0 1.713-2.765 2.854-3.38 4.344-.616 1.49.482 4.3-.679 5.46-1.16 1.16-3.925.038-5.466.679-1.541.64-2.632 3.38-4.338 3.38s-2.853-2.765-4.337-3.38c-1.484-.615-4.3.482-5.466-.679-1.167-1.16-.038-3.919-.679-5.46-.64-1.54-3.38-2.631-3.38-4.344 0-1.712 2.765-2.853 3.38-4.337.615-1.484-.476-4.3.685-5.46 1.16-1.16 3.919-.044 5.46-.678 1.541-.635 2.631-3.38 4.337-3.38s2.854 2.758 4.344 3.38c1.49.62 4.3-.482 5.46.678 1.161 1.16.038 3.92.679 5.46.64 1.541 3.38 2.619 3.38 4.337z'></path>
      <path fill='#FFA9A9' d='M97.092 97.553s4.794 7.559 9.734 9.379c4.299 1.37 26.386 8.06 30.312 8.415 3.925.355 13.982-3.691 18.713-2.689 6.976 1.484 13.127 1.402 12.86 3.272-.266 1.871-6.423 2.055-9.366 3.482-2.942 1.427-1.167 3.805 3.291 7.971s11.77 8.244 10.147 10.197c-1.624 1.953-11.916-5.2-12.632-4.223-.717.976 9.195 6.544 7.527 8.503-1.668 1.96-4.325 1.269-4.325 1.269a4.6 4.6 0 01-4.604-.635c-1.084-.811-4.521-3.804-4.699-2.105-.177 1.7 3.329 6.208 1.3 6.5a2.09 2.09 0 01-2.441-.939c-1.972-2.866-9.512-13.545-14.478-14.47-2.631-.495-38.048-2.867-47.008-8.916-4.357-2.937-16.253-12.201-16.253-12.201a137.51 137.51 0 0121.922-12.81z'></path>
      <path fill='#fff' d='M136.262 98.85l-14.567-6.506a1.294 1.294 0 00-1.709.653l-1.795 4.018a1.294 1.294 0 00.654 1.71l14.567 6.506a1.293 1.293 0 001.709-.653l1.795-4.018a1.294 1.294 0 00-.654-1.71z'></path>
      <path fill='#2929B2' d='M180.525 100.692c1.484-15.473 2.226-23.21-2.131-28.472a11.954 11.954 0 00-2.282-2.138l-.983-.634s1.731 66.21-28.828 63.997c14.154 1.028 21.421 1.808 26.456-2.34 5.276-4.35 6.018-12.086 7.502-27.559l.266-2.854z'></path>
      <path fill='#FFA9A9' d='M161.527 41.546c.05 2.226 2.929 1.77 2.929 1.77s-2.156 2.238-1.268 3.982a2.648 2.648 0 002.784 1.382 2.24 2.24 0 00-.634 2.346 2.142 2.142 0 001.763 1.23c3.646.083 11.579-1.712 11.592-2.644.05-3.55-.825-9.512-2.486-13.228a3.306 3.306 0 00-3.201-1.92 3.31 3.31 0 00-1.27.335c-3.748 1.814-10.248 5.168-10.209 6.747z'></path>
      <path fill='#FFA9A9' d='M178.839 50.811c-.216 1.902-9.119 6.075-10.508 4.274-1.389-1.8 10.362-6.69 10.362-6.69l.146 2.416z'></path>
      <path fill='#fff' stroke='#fff' d='M121.558 33.993v.002a8.943 8.943 0 008.94 8.933h0a8.947 8.947 0 008.94-8.933v-.002a8.944 8.944 0 00-8.94-8.94h0a8.947 8.947 0 00-8.94 8.94zm3.777-5.162a7.32 7.32 0 015.163-2.143 7.31 7.31 0 017.306 7.306 7.31 7.31 0 01-7.306 7.3 7.32 7.32 0 01-7.306-7.3 7.318 7.318 0 012.143-5.163zM150.827 39.871H150.856a7.304 7.304 0 016.938 10.147 7.296 7.296 0 01-4.079 3.964 7.304 7.304 0 01-2.859.495H150.827a7.308 7.308 0 01-5.3-12.542 7.314 7.314 0 015.3-2.064zm-3.468 15.632a8.945 8.945 0 003.483.608 8.943 8.943 0 008.467-5.459 8.936 8.936 0 00-2.002-9.886 8.934 8.934 0 00-6.465-2.53 8.945 8.945 0 00-8.468 5.46 8.936 8.936 0 002.002 9.886 8.944 8.944 0 002.983 1.92zM134.962 53.778h-.009a.844.844 0 01-.326-.078.819.819 0 01-.406-1.077h0l10.958-24.345.005-.01h0a.823.823 0 01.451-.416l.177.467-.177-.467a.824.824 0 01.614.012l.002.001a.814.814 0 01.437.451l-11.726 25.462zm0 0h.009m-.009 0h.009m0 0a.813.813 0 00.741-.49l10.962-24.34h0m-11.703 24.83l11.703-24.83m0 0l.002-.004m-.002.004l.002-.004m0 0a.805.805 0 00.012-.628l-.012.628z'></path>
      <path fill='#fff' d='M153.549 87.73a3.169 3.169 0 01-1.528-.4 3.101 3.101 0 01-1.565-1.876 3.105 3.105 0 01.272-2.428 3.098 3.098 0 011.941-1.485 3.115 3.115 0 012.415.374 3.086 3.086 0 011.465 1.902 3.113 3.113 0 01-1.616 3.623c-.43.213-.904.323-1.384.322v-.031zm0-5.586c-.225 0-.449.032-.666.095a2.478 2.478 0 00-1.693 3.063 2.479 2.479 0 002.086 1.796 2.472 2.472 0 002.455-1.245 2.46 2.46 0 00-.939-3.367c-.373-.228-.8-.35-1.237-.355l-.006.013zM157.411 95.118a3.114 3.114 0 01-.831-6.107 3.109 3.109 0 013.159 5.036 3.109 3.109 0 01-2.328 1.071zm0-5.587a2.48 2.48 0 102.385 1.814 2.465 2.465 0 00-2.385-1.814zM151.685 91.37a.305.305 0 01-.254-.126.319.319 0 01.064-.444l7.4-5.435a.302.302 0 01.348-.02c.035.021.066.05.089.084a.315.315 0 01-.063.443l-7.4 5.441a.32.32 0 01-.184.057z'></path>
      <path fill='#212121' d='M96.686 97.87a.337.337 0 01-.247-.114c-.127-.152-13.057-15.473-21.56-28.016a.323.323 0 01.089-.444.317.317 0 01.437.089c8.484 12.511 21.389 27.807 21.522 27.959a.323.323 0 01-.038.45.324.324 0 01-.203.076z'></path>
      <path fill='#FF8481' d='M78.1 112.639l19.854-13.824-.976-1.268c-8.808 4.198-16.025 8.307-21.834 12.797l2.956 2.295z'></path>
    </svg>);
};
export default WinbackIcon;
