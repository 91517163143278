import React from 'react';
import { Caption } from '@vkontakte/vkui';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import messages from './messages';
const PhotoFormatText = ({ className }) => {
    const intl = useIntl();
    return (<Caption className={classNames(styles.description, className)}>
      {intl.formatMessage(messages.pictureRequirements)}
    </Caption>);
};
export default PhotoFormatText;
