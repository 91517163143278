import axios from 'axios';
import storage from '@common/utils/Storage';
import { STORAGE_API_AUTH } from '@common/consts';
import { initAppAuth } from '@common/features/auth-user';
import getJWTifValid from '@common/helpers/getJWTifValid';
let numberOfCallApi = 1;
export const autoReAuthAndRecallAPI = async (error) => {
    const { config, response } = error;
    if (response?.status !== 401) {
        return Promise.reject(error);
    }
    if (!config) {
        return Promise.reject(error);
    }
    if (!storage.get(STORAGE_API_AUTH)) {
        return Promise.reject(error);
    }
    if (getJWTifValid()) {
        return Promise.reject(error);
    }
    if (numberOfCallApi !== 1) {
        return Promise.reject(error);
    }
    numberOfCallApi++;
    storage.remove(STORAGE_API_AUTH);
    try {
        await initAppAuth();
        const token = getJWTifValid();
        if (!token) {
            throw new Error('Отсутствует валидный токен');
        }
        const Authorization = `Bearer ${token}`;
        const headers = { ...config.headers, Authorization };
        return await axios.request({ ...config, headers });
    }
    catch {
        return Promise.reject(error);
    }
};
export const redirectToMainPage = (error) => {
    if (error.status && error.status === 401) {
        window.location.href = '/';
    }
    return Promise.reject(error);
};
