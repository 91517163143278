const ERUDA_KEY_WORD = 'ERUDA';
const TRUE = 'true';
import storage from '@common/utils/Storage';
let eruda = {
    init: () => {
        return;
    },
    destroy: () => {
        return;
    },
};
const runEruda = async () => {
    const erudaPkg = await import('eruda');
    eruda = erudaPkg.default;
    eruda.init();
};
const stopEruda = () => {
    eruda?.destroy();
};
try {
    const isErudaOnState = storage.get(ERUDA_KEY_WORD);
    if (isErudaOnState === TRUE) {
        void runEruda();
    }
}
catch { }
let timeoutId = null;
let count = 0;
const callEruda = () => {
    if (timeoutId === null) {
        timeoutId = setTimeout(() => {
            timeoutId = null;
            count = 0;
        }, 3000);
    }
    count++;
    if (count >= 10) {
        const isErudaOnState = storage.get(ERUDA_KEY_WORD);
        if (isErudaOnState !== TRUE) {
            storage.set(ERUDA_KEY_WORD, TRUE);
            void runEruda();
            alert('Debug mod is "on"');
        }
        else {
            storage.remove(ERUDA_KEY_WORD);
            stopEruda();
            alert('Debug mod is "off"');
        }
        clearTimeout(timeoutId);
        timeoutId = null;
        count = 0;
    }
};
export { callEruda, runEruda };
