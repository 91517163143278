import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    title: {
        id: 'title',
        defaultMessage: 'В процессе подключения',
    },
    description: {
        id: 'description',
        defaultMessage: 'Подписка в процессе подключения. Подождите, данные скоро обновятся',
    },
});
