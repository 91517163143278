import { modalManagerModel } from '@common/services/modal-manager';
import { analyticsDictionary } from '@analytics';
import ModalButton from '@common/components/modals/ModalButton';
import React from 'react';
import throttle from 'lodash/throttle';
import { intl } from '@common/shared/lib/intl';
import { messages } from './messages';
import { SERVICE_ON_MAINTAIN_CODE } from '@common/consts';
import { Icon56ErrorOutline } from '@vkontakte/icons';
const showModal = () => {
    modalManagerModel.showModal({
        icon: <Icon56ErrorOutline />,
        analyticShowAttribute: analyticsDictionary.modalServiceOnMaintain,
        header: intl.formatMessage(messages.headerFor423),
        subheader: <>{intl.formatMessage(messages.subheaderFor423)}</>,
        actions: (<ModalButton mode='secondary' onClick={() => modalManagerModel.closeModal()}>
        {intl.formatMessage(messages.btnTextFor423)}
      </ModalButton>),
    });
};
const showModalThrottled = throttle(showModal, 3000, { trailing: false });
export const showMaintainModal = (error) => {
    if (error?.response?.status === SERVICE_ON_MAINTAIN_CODE) {
        showModalThrottled();
    }
    return Promise.reject(error);
};
