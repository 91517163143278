import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    emailWarningBannerTitle: {
        id: 'emailWarningBannerTitle',
        defaultMessage: 'Подписка доступна только студентам вузов',
    },
    emailWarningBannerDescription: {
        id: 'emailWarningBannerDescription',
        defaultMessage: 'Если вы учитесь в вузе на дневном или&nbsp;вечернем отделении введите свою личную студенческую почту с доменом вуза в поле ниже',
    },
    email: {
        id: 'emailStudentForm',
        defaultMessage: 'Студенческий email',
    },
});
