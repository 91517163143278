import React from 'react';
import { Tabs, TabsItem } from '@vkontakte/vkui';
import { useRouter } from 'next/router';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import { Icon28HelpCircleOutline, Icon28Notifications } from '@vkontakte/icons';
import { PROFILE_ROUTE, TARIFFS_ROUTE, PAYMENT_METHOD_ROUTE } from '@website/shared/lib/profile-routes';
import styles from './styles.module.scss';
import { AbTestLkUntieCardV2Model } from '@common/features/ab-test-lk-untie-card-v2/model';
const Header = ({ title = 'Личный кабинет', onGoToProfile, onGoToTariffs, onGoToNotification, onGoToFaq, onGoToPaymentMethod, }) => {
    const router = useRouter();
    const IS_HOME_ROUTE = router.pathname === PROFILE_ROUTE;
    const IS_TARIFFS_ROUTE = router.pathname === TARIFFS_ROUTE;
    const IS_PAYMENT_METHOD_ROUTE = router.pathname === PAYMENT_METHOD_ROUTE;
    const needCustomizePaymentMethodView = AbTestLkUntieCardV2Model.useNeedCustomizePaymentMethodView();
    return (<>
      <div className={styles.header}>
        <h1 className={styles.header__title}>{title}</h1>
        <div className={styles.header__icons}>
          <Icon28Notifications onClick={onGoToNotification}/>
          <Icon28HelpCircleOutline onClick={onGoToFaq}/>
        </div>
      </div>
      <div className={styles.tabs}>
        <Tabs mode={'secondary'}>
          <TabsItem {...createAnalyticClickAttribute(analyticsDictionary.blockNavigationButtonClick('home'))} selected={IS_HOME_ROUTE} onClick={onGoToProfile} className={styles.tab}>
            Моя подписка
          </TabsItem>
          <TabsItem {...createAnalyticClickAttribute(analyticsDictionary.blockNavigationButtonClick('other_offers'))} selected={IS_TARIFFS_ROUTE} onClick={onGoToTariffs} className={styles.tab}>
            Другие тарифы
          </TabsItem>
          {needCustomizePaymentMethodView && (<TabsItem {...createAnalyticClickAttribute(analyticsDictionary.blockNavigationButtonClick('paymethod'))} selected={IS_PAYMENT_METHOD_ROUTE} onClick={onGoToPaymentMethod} className={styles.tab}>
              Способ оплаты
            </TabsItem>)}
        </Tabs>
      </div>
    </>);
};
export default Header;
