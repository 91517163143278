import React, { useCallback, useState } from 'react';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import { paymentMethodModel } from '@common/data-layer/payment-method/model';
import tryToGetErrorsMessages from '@common/helpers/tryToGetErrorsMessages';
import ModalButton from '@common/components/modals/ModalButton';
import styles from './styles.module.scss';
import { useUnit } from 'effector-react';
const Footer = (props) => {
    const { changePaymentMethod, showAlertModal } = props;
    const paymentMethod = useUnit(paymentMethodModel.$paymentMethod);
    const [isAddingInProgress, setIsAddingInProgress] = useState(false);
    const changePaymentMethodMemo = useCallback(() => {
        setIsAddingInProgress(true);
        changePaymentMethod()
            .catch((error) => {
            showAlertModal({
                mode: 'error',
                ...tryToGetErrorsMessages(error, {
                    title: 'Не удалось привязать способ оплаты',
                    description: 'Во время привязки способа оплаты произошла ошибка',
                }),
            });
        })
            .finally(() => {
            setIsAddingInProgress(false);
        });
    }, []);
    const common = {
        stretched: true,
        disabled: isAddingInProgress,
        loading: isAddingInProgress,
        onClick: changePaymentMethodMemo,
    };
    return (<div className={styles.footer}>
      {paymentMethod && paymentMethod?.status === 'bind' ? (<ModalButton mode='secondary' {...createAnalyticClickAttribute(analyticsDictionary.modalPaymethodManageButtonChangeClick)} {...common}>
          Изменить способ оплаты
        </ModalButton>) : (<ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalPaymethodManageButtonLinkClick)} {...common}>
          Добавить способ оплаты
        </ModalButton>)}
    </div>);
};
export default Footer;
