import { analytic } from '@analytics';
import storage from '@common/utils/Storage';
const ANALYTICS_EVENT_SUBJECT_NAME = 'front_authorization';
const getTimeDiff = () => {
    const timeStart = Number(storage.get('frontAuthorizationAnalyticsEventTimeStart'));
    const timeFinish = performance.now();
    return Math.round(timeFinish - timeStart) / 1000;
};
export const analyticsEvent = (params) => {
    const { result, auth_type, error_type, error_code, error_message } = params;
    if (result === 'start') {
        storage.set('frontAuthorizationAnalyticsEventTimeStart', performance.now());
    }
    void analytic.addEvent({
        subject: ANALYTICS_EVENT_SUBJECT_NAME,
        payload: {
            result,
            auth_type,
            error_type,
            error_code,
            error_message,
            time: getTimeDiff(),
        },
    });
};
