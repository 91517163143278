import React from 'react';
import { Div } from '@vkontakte/vkui';
import { intl } from '@common/shared/lib/intl';
import WarningBanner from '@common/components/common/WarningBanner';
import { studentStatusRetryLabelTypeGuard } from '../../';
import { messages } from '../messages';
import { FormUserData, PhotoRecordBook, PhotoStudentID, PhotoWithStudentID, ResultStudentForm, SelfieWithRecordBook, } from '../ui';
import { MAP_STATUS_REJECT_INFO_TO_STUDENT_FORM_METHODS } from './consts';
export class PipeBuilder {
    constructor() {
        this._pipeList = [];
    }
    get pipeList() {
        return this._pipeList;
    }
    set pipeList(value) {
        this._pipeList = value;
    }
    addPipe(value) {
        this._pipeList.push(value);
    }
    getPipe(step) {
        return this.pipeList[step] ?? null;
    }
    map(labels) {
        this.pipeList = [];
        labels.forEach((label) => {
            if (studentStatusRetryLabelTypeGuard(label)) {
                this[MAP_STATUS_REJECT_INFO_TO_STUDENT_FORM_METHODS['FORM']]();
                return;
            }
            this[MAP_STATUS_REJECT_INFO_TO_STUDENT_FORM_METHODS[label.documentType]]();
        });
        this.addResult();
        return this;
    }
    defaultFlow() {
        this.addForm().addUploadStudentId().addUploadSelfie().addResult();
    }
    addForm() {
        this.addPipe({
            getContent: () => (<>
          <Div>
            <WarningBanner type={'attention'} title={intl.formatMessage(messages.warningBannerTitle)} description={intl.formatMessage(messages.warningBannerDescription)}/>
          </Div>
          <FormUserData />
        </>),
            headerTitle: intl.formatMessage(messages.formHeaderTitle),
            hideFooterOnMobile: false,
        });
        return this;
    }
    addResult() {
        this.addPipe({
            getContent: () => <ResultStudentForm />,
            headerTitle: intl.formatMessage(messages.resultTitle),
            hideFooterOnMobile: true,
        });
        return this;
    }
    addUploadRecordBook() {
        this.addPipe({
            getContent: () => <PhotoRecordBook />,
            headerTitle: intl.formatMessage(messages.addUploadRecordBookTitle),
            hideFooterOnMobile: true,
        });
        return this;
    }
    addUploadSelfie() {
        this.addPipe({
            getContent: () => <PhotoWithStudentID />,
            headerTitle: intl.formatMessage(messages.addUploadStudentIdTitle),
            hideFooterOnMobile: true,
        });
        return this;
    }
    addUploadSelfieWithRecordBook() {
        this.addPipe({
            getContent: () => <SelfieWithRecordBook />,
            headerTitle: intl.formatMessage(messages.addUploadSelfieWithRecordBookTitle),
            hideFooterOnMobile: true,
        });
        return this;
    }
    addUploadStudentId() {
        this.addPipe({
            getContent: () => <PhotoStudentID />,
            headerTitle: intl.formatMessage(messages.addUploadStudentIdTitle),
            hideFooterOnMobile: true,
        });
        return this;
    }
}
