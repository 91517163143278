import apiCommon from '@common/utils/ApiCommon';
import getJWTifValid from '@common/helpers/getJWTifValid';
import getJWTifValidFromVkBridge from '@common/helpers/getJWTifValidFromVkBridge';
import { Config } from '@vkontakte/superappkit';
import { STORAGE_API_AUTH, WEB_VK_CONNECT_APP_ID } from '@common/consts';
import storageUtils from '@common/utils/Storage';
import { authVKID, getPayloadForRedirectAuth, VK_ID_PAYLOAD_FIELD_NAME, VK_ID_STATE_FIELD_NAME, } from '@common/entities/auth-vkid';
import { analytic } from '@common/utils/Analytics';
import { AuthStateModel } from '@common/entities/auth-state';
import { userDataModel } from '@common/entities/user-data';
import { errorLog } from '@common/shared/lib/utils';
import { localStorageAdapter, vkBridgeStorageAdapter } from '@common/shared/lib/storage-adapters';
import apiVkBridge from '@common/utils/ApiVkBridge';
import { modifyNextSearchParams } from '@common/shared/lib/modify-next-search-params';
const onAuthSuccess = (token, storage = localStorageAdapter(storageUtils)) => {
    commitToken(token, storage);
    AuthStateModel.authSuccess();
    analytic.setAuthToken(token);
};
const commitToken = (token, storage) => {
    storage.set(STORAGE_API_AUTH, token);
    userDataModel.setVkIdByToken(token);
    apiCommon.setAuthToken(token);
};
const silentAuth = async () => {
    const accessToken = await authVKID.silent();
    onAuthSuccess(accessToken);
};
const redirectAuth = async () => {
    const accessToken = await authVKID.redirect();
    onAuthSuccess(accessToken);
};
const bridgeAuth = async () => {
    const accessToken = await authVKID.bridge();
    onAuthSuccess(accessToken, vkBridgeStorageAdapter(apiVkBridge));
};
export const initMiniAppAuth = async () => {
    AuthStateModel.changeAuthLoadingState('process');
    try {
        const authTokenFromStore = await getJWTifValidFromVkBridge();
        if (authTokenFromStore) {
            onAuthSuccess(authTokenFromStore, vkBridgeStorageAdapter(apiVkBridge));
            return;
        }
        await bridgeAuth();
        AuthStateModel.changeAuthLoadingState('idle');
    }
    catch {
        AuthStateModel.changeAuthLoadingState('error');
        analytic.setUnauthorized();
    }
};
export const initAppAuth = async () => {
    Config.init({
        appId: Number(WEB_VK_CONNECT_APP_ID),
    });
    try {
        if (getPayloadForRedirectAuth().hasPayload) {
            try {
                await redirectAuth();
                return;
            }
            catch (e) {
                errorLog(e);
                modifyNextSearchParams({ paramsToRemove: [VK_ID_PAYLOAD_FIELD_NAME, VK_ID_STATE_FIELD_NAME] });
            }
        }
        const authTokenFromStore = getJWTifValid();
        if (authTokenFromStore) {
            onAuthSuccess(authTokenFromStore);
            return;
        }
        else {
            analytic.setUnauthorized();
        }
        void (await silentAuth());
    }
    catch {
        analytic.setUnauthorized();
        AuthStateModel.authError();
    }
};
