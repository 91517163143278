import { defineMessages } from '@formatjs/intl';
export const messages = defineMessages({
    description: {
        id: 'description',
        defaultMessage: 'Участник',
    },
    button_confirm: {
        id: 'button_confirm',
        defaultMessage: 'Удалить из подписки',
    },
    remove_member_error_message: {
        id: 'remove_member_error_message',
        defaultMessage: 'Пользователь не был удалён',
    },
});
