export const nextImgToSource = ({ src, width, height }, media) => {
    const resultImg = {
        srcSet: src,
        width,
        height,
    };
    if (/@2x/.test(resultImg.srcSet)) {
        resultImg.media = '(-webkit-min-device-pixel-ratio: 2)';
        resultImg.width /= 2;
        resultImg.height /= 2;
    }
    if (/@3x/.test(resultImg.srcSet)) {
        resultImg.media = '(-webkit-min-device-pixel-ratio: 3)';
        resultImg.width /= 3;
        resultImg.height /= 3;
    }
    if (media) {
        if (resultImg.media) {
            resultImg.media = [resultImg.media, media].join(' and ');
        }
        else {
            resultImg.media = media;
        }
    }
    return resultImg;
};
export const nextImgArrayToSources = (images, media) => images.map((nextImg) => nextImgToSource(nextImg, media));
