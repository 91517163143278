import { silentAuth } from './silent-auth';
import { redirectAuth } from './redirect-auth';
import { bridgeAuth } from './bridge-auth';
import { authCombo } from './combo-auth';
import { analyticsEvent, processingAuthErrorsVkId } from '../lib';
const DRIVER = {
    SILENT: 'silent',
    REDIRECT: 'redirect',
    BRIDGE: 'bridge',
};
const onAuthDriver = async ({ driver }) => {
    const mapping = {
        silent: silentAuth,
        redirect: redirectAuth,
        bridge: bridgeAuth,
    };
    analyticsEvent({
        result: 'start',
        auth_type: driver,
    });
    const { token, uuid } = await mapping[driver]()
        .then((res) => {
        analyticsEvent({
            result: 'success',
            auth_type: driver,
        });
        return res;
    })
        .catch((error) => {
        processingAuthErrorsVkId(error, { driver });
        throw error;
    });
    analyticsEvent({
        result: 'start',
        auth_type: 'authority',
    });
    return authCombo({ token, uuid })
        .then((res) => {
        analyticsEvent({
            result: 'success',
            auth_type: 'authority',
        });
        return res;
    })
        .catch((error) => {
        analyticsEvent({
            result: 'fail',
            auth_type: 'authority',
            error_type: 'combo_error',
            error_code: error.code,
            error_message: error.message,
        });
        throw error;
    });
};
export const authVKID = {
    silent: () => onAuthDriver({ driver: DRIVER.SILENT }),
    redirect: () => onAuthDriver({ driver: DRIVER.REDIRECT }),
    bridge: () => onAuthDriver({ driver: DRIVER.BRIDGE }),
};
export { VK_ID_PAYLOAD_FIELD_NAME, VK_ID_STATE_FIELD_NAME, redirectToVKID, getRedirectAuthState, getPayloadForRedirectAuth, } from './redirect-auth';
