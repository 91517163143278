export class StrategyContext {
    constructor(context) {
        this.context = context;
    }
    setStrategy(strategy) {
        this.context = strategy;
    }
    doStrategy(state) {
        this.context.do(state);
    }
}
