import React, { useState } from 'react';
import { createAnalyticClickAttribute } from '@analytics';
import ModalButton from '@common/components/modals/ModalButton';
import { applyOffer } from '@common/components/Root/actions';
import { studentAnalytics } from '@common/entities/student';
import { getOfferButtonMetric } from '@common/entities/offers';
const ApplyStudentOfferButtonInConfirmModal = (props) => {
    const { offer, applyOfferParams } = props;
    const [state, setState] = useState('initial');
    const isLoadingState = state === 'loading';
    const widget = offer?.widgets.offer;
    const button = widget?.buttons?.[0];
    const buttonText = button?.name ?? 'Оплатить 89 ₽ ';
    const analytics = getOfferButtonMetric(offer, 'offer', 'click') ?? studentAnalytics.confirm.click(offer?.correlationId ?? '');
    const onClick = async () => {
        try {
            setState('loading');
            await applyOffer(applyOfferParams);
        }
        catch {
            setState('error');
        }
        finally {
            setState('idle');
        }
    };
    return (<ModalButton size='l' mode='primary' loading={isLoadingState} disabled={isLoadingState} onClick={onClick} {...createAnalyticClickAttribute(analytics)}>
      {buttonText}
    </ModalButton>);
};
export default ApplyStudentOfferButtonInConfirmModal;
