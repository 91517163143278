import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    header: {
        id: 'title',
        defaultMessage: 'Упс',
    },
    subheader: {
        id: 'subheader',
        defaultMessage: 'Что то пошло не так',
    },
});
