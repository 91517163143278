import React from 'react';
import CustomGroup from '@common/components/common/CustomGroup';
import SkeletonItem from '@common/components/common/SkeletonItem';
import styles from './styles.module.scss';
const DesktopNavigationSkeleton = () => {
    return (<CustomGroup>
      <div className={styles.container}>
        <SkeletonItem className={styles.skeletonItem}/>
        <SkeletonItem className={styles.skeletonItem}/>
        <SkeletonItem className={styles.skeletonItem}/>
        <SkeletonItem className={styles.skeletonItem}/>
      </div>
    </CustomGroup>);
};
export default DesktopNavigationSkeleton;
