import React, { useEffect } from 'react';
import { Text } from '@vkontakte/vkui';
import { useGate, useUnit } from 'effector-react';
import Typograf from '@common/components/common/Typograf';
import SkeletonItem from '@common/components/common/SkeletonItem';
import { CheckoutFrameV2 } from '@common/entities/checkout/ui';
import { useCheckout } from '@common/entities/checkout/hooks';
import { Header } from '@common/services/vk-ui-modal-manager/ui/ModalPageWithFixedFooter';
import { showCheckoutModalModelV2 } from '../model';
import styles from './styles.module.scss';
import { modalManagerModel } from '@common/services/modal-manager';
const LOADING_STATUSES = ['initial', 'loading'];
export const Children = (props) => {
    useGate(showCheckoutModalModelV2.gate);
    const { type, paymentId, specification, payload, handleSuccess, handleError } = props;
    const [setStatus, setTitle, closeModal] = useUnit([
        showCheckoutModalModelV2.setStatus,
        showCheckoutModalModelV2.setTitle,
        modalManagerModel.closeModal,
    ]);
    const { status, title, subtitle, iframeUrl, onPaySuccess, onPayError, onRefreshWindow } = useCheckout({
        type,
        paymentId,
        specification,
        payload,
        handleSuccess,
        handleError,
    });
    const onFormSend = () => {
        setStatus('process');
    };
    useEffect(() => {
        if (title) {
            setTitle(<Header handleClick={closeModal}>
          <Typograf>{title}</Typograf>
        </Header>);
        }
    }, [title]);
    return (<>
      {!LOADING_STATUSES.includes(status) && iframeUrl ? (<>
          <div className={styles.textContainer}>
            <Text>
              <Typograf>{subtitle}</Typograf>
            </Text>
          </div>
        </>) : (<>
          <div className={styles.textContainer}>
            <SkeletonItem className={styles.textSkeleton}/>
            <SkeletonItem className={styles.textSkeleton}/>
          </div>
        </>)}
      <div className={styles.iframeContainer}>
        <CheckoutFrameV2 orderUuid={paymentId} iframeUrl={iframeUrl} onFormSend={onFormSend} onPaySuccess={onPaySuccess} onPayError={onPayError} onRefreshWindow={onRefreshWindow}/>
      </div>
    </>);
};
