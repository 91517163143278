import { createEffect, createEvent, sample } from 'effector';
import { showEmailStudentForm } from '../../lib';
import { initAuthorizedWay } from './init-authorized-way';
export const finishStartEmailAuthorizedWay = createEvent();
export const startEmailAuthorizedWay = createEvent();
const showEmailStudentFormFx = createEffect(() => {
    showEmailStudentForm();
});
const { doneData } = initAuthorizedWay({
    startEvent: startEmailAuthorizedWay,
    initStartFlowState: { callbackAfterFinishedFlow: Promise.resolve },
});
sample({ clock: doneData, target: showEmailStudentFormFx });
sample({ clock: showEmailStudentFormFx.doneData, target: finishStartEmailAuthorizedWay });
