import React from 'react';
import { useUnit } from 'effector-react';
import { Button, Headline, Subhead } from '@vkontakte/vkui';
import { Icon28MoneyCircleOutline, Icon28PaymentCardOutline, Icon28WalletOutline } from '@vkontakte/icons';
import { useIntl } from 'react-intl';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import useIsSmallScreen from '@common/hooks/useIsSmallScreen';
import CustomGroup from '@common/components/common/CustomGroup';
import SkeletonItem from '@common/components/common/SkeletonItem/component';
import { paymentMethodModel } from '@common/data-layer/payment-method';
import { IconSbpbank } from '../../lib/fc-icons/IconSbpbank';
import { showPaymentManagementModal } from '../../lib';
import { messages } from './messages';
import styles from './styles.module.scss';
export const PaymentMethodWidget = () => {
    const paymentMethod = useUnit(paymentMethodModel.$paymentMethod);
    const paymentMethodState = useUnit(paymentMethodModel.$paymentMethodState);
    const isSmallScreen = useIsSmallScreen();
    const intl = useIntl();
    const paymentMethodTitle = intl.formatMessage(messages.paymentMethodTitle);
    if (!paymentMethod && paymentMethodState === 'process') {
        return (<CustomGroup>
        <div className={styles.managePaymentMethod}>
          <div className={styles.managePaymentMethod__content}>
            <Subhead className={styles.mpmContent__subhead} weight='3' Component='h5'>
              {paymentMethodTitle}
            </Subhead>
            <SkeletonItem className={styles.titleSkeleton}/>
          </div>
        </div>
      </CustomGroup>);
    }
    let Icon = null;
    let content = null;
    switch (paymentMethod?.status) {
        case 'bind': {
            const { type } = paymentMethod;
            if (type === 'card') {
                Icon = Icon28PaymentCardOutline;
                content = intl.formatMessage(messages.paymentMethodStatusBound, {
                    paymentMethodType: type,
                    value: paymentMethod.attributes?.last_card_digits?.slice(-4) ?? '',
                });
                break;
            }
            if (type === 'vkpay_checkout') {
                Icon = Icon28MoneyCircleOutline;
                content = intl.formatMessage(messages.paymentMethodStatusBound, { paymentMethodType: type });
                break;
            }
            if (type === 'sbp') {
                Icon = IconSbpbank;
                content = intl.formatMessage(messages.paymentMethodStatusBound, {
                    paymentMethodType: type,
                    value: paymentMethod.attributes.bank_name,
                });
                break;
            }
            return null;
        }
        case 'process': {
            Icon = Icon28WalletOutline;
            content = intl.formatMessage(messages.paymentMethodStatusWait);
            break;
        }
        case 'not_bound': {
            Icon = Icon28WalletOutline;
            content = intl.formatMessage(messages.paymentMethodStatusNotBound);
            break;
        }
        default: {
            return null;
        }
    }
    const buttonText = intl.formatMessage(messages.buttonText, { smallScreen: isSmallScreen });
    return (<CustomGroup>
      <div className={styles.managePaymentMethod}>
        <div className={styles.managePaymentMethod__content}>
          <Subhead className={styles.mpmContent__subhead} weight='3' Component='h5'>
            {paymentMethodTitle}
          </Subhead>
          <div className={styles.mpmContent__wrapper}>
            {Icon && <Icon className={styles.managePaymentMethod__icon}/>}
            <Headline level='1' weight='2' Component='h4'>
              {content}
            </Headline>
          </div>
        </div>
        <Button size={'s'} mode={isSmallScreen ? 'tertiary' : 'secondary'} {...createAnalyticClickAttribute(analyticsDictionary.mainPaymentMethodButtonClick())} onClick={() => showPaymentManagementModal()}>
          {buttonText}
        </Button>
      </div>
    </CustomGroup>);
};
