import React from 'react';
import { Spinner } from '@vkontakte/vkui';
import { modalManagerModel } from '@common/services/modal-manager';
import ModalButton from '@common/components/modals/ModalButton';
import { analyticsDictionary, createAnalyticClickAttribute } from '@common/utils/Analytics';
import { StudentApi } from '@common/entities/student';
import waitForResult from '@common/helpers/waitForResult';
const GET_STUDENTS_STATUS_INTERVAL = 2000;
export const checkToken = async (token) => {
    modalManagerModel.showModal({
        analyticShowAttribute: analyticsDictionary.modalStudentByEmailShow,
        mode: 'success',
        header: '',
        subheader: 'Подождите, перечитываем конспект',
        actions: <Spinner />,
    });
    try {
        const response = await StudentApi.verifyStudentEmail({ token });
        if (response.data.successful) {
            await new Promise((resolve, reject) => {
                waitForResult(StudentApi.getStudentsStatusFx, {
                    params: [],
                    handleResponse: (response) => {
                        const isConfirmedStatus = response.status === 'CONFIRMED';
                        if (isConfirmedStatus) {
                            resolve(response);
                            return false;
                        }
                        return true;
                    },
                    handleError: () => {
                        reject();
                        return false;
                    },
                    callInterval: GET_STUDENTS_STATUS_INTERVAL,
                });
            });
            return;
        }
        let title = '';
        let detail = '';
        const error = response.data.errors?.[0];
        if (error) {
            title = error.title;
            detail = error.detail;
        }
        modalManagerModel.showModal({
            analyticShowAttribute: analyticsDictionary.modalRequestErrorShow,
            mode: 'error',
            header: title,
            subheader: detail,
            actions: (<ModalButton size='l' mode='primary' {...createAnalyticClickAttribute(analyticsDictionary.modalRequestErrorButtonConfirmClick)} onClick={() => modalManagerModel.closeModal()}>
          Понятно
        </ModalButton>),
        });
        return Promise.reject();
    }
    catch (error) {
        modalManagerModel.closeModal();
        console.error(error);
        return Promise.reject();
    }
};
