import { findMetric } from '@common/utils/Analytics/dictionary';
export { getOffersTabs } from './getOffersTabs';
export const offerHasTag = (item, tag) => {
    return item.tags.includes(tag);
};
export const checkOfferButtonType = (button, buttonType) => {
    return button.type === buttonType;
};
export const getOfferTagByTagName = (offer, tag) => {
    return offer.tags.find((tagItem) => tagItem === tag);
};
export const getOfferButtonMetric = (offer, widget, metricType) => {
    if (!offer) {
        return null;
    }
    return findMetric(offer.widgets[widget]?.buttons?.[0]?.metrics, metricType)?.value ?? null;
};
export const getOfferMetric = (offer, widget, metricType) => {
    if (!offer) {
        return null;
    }
    return findMetric(offer.widgets[widget]?.metrics, metricType)?.value ?? null;
};
