import React, { useState } from 'react';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import { useStoreMap, useUnit } from 'effector-react';
import { ButtonGroup } from '@vkontakte/vkui';
import ModalButton from '@common/components/modals/ModalButton';
import { useGetSubscriptionDetailsContext } from '@common/entities/subscriptions/lib/modals/show-subscription-details/lib/context';
import { manualStoppedPauseRecurrentModel } from '@common/features/manual-stopped-pause-recurrent';
import { paymentMethodModel } from '@common/data-layer/payment-method';
import { showUnboundCardInfoModal } from '@common/features/show-unbound-card-info-modal';
import { analytics } from '../manual-stopped-recurrent-renew-button/analytics';
import { CancelSubscriptionButton } from '../cancel-subscription-button';
import { useIntl } from 'react-intl';
import messages from './messages';
const SubscriptionResumePauseRecurrentButton = (props) => {
    const { handleCancelSubscription } = props;
    const intl = useIntl();
    const { subscription, updateData } = useGetSubscriptionDetailsContext();
    const manualStoppedPauseRecurrent = useUnit(manualStoppedPauseRecurrentModel.manualStoppedPauseRecurrent);
    const getPaymentsMethod = useUnit(paymentMethodModel.getPaymentMethodFx);
    const isNotBoundCard = useStoreMap(paymentMethodModel.$paymentMethod, (state) => state?.status === 'not_bound');
    const [isLoading, setIsLoading] = useState(false);
    const manualStoppedPauseRecurrentHandle = async () => {
        await manualStoppedPauseRecurrent({
            subscription: subscription,
            successCallback: updateData,
        });
    };
    const renewSubscription = async () => {
        setIsLoading(true);
        try {
            await getPaymentsMethod();
            if (isNotBoundCard) {
                showUnboundCardInfoModal({
                    showModalAnalytics: analytics.modalRecurrentRenewWithoutLinkCardShow,
                    buttonAnalytic: analytics.modalRecurrentRenewWithoutLinkCardButtonConfirm,
                    callbackAfterBoundCardModel: () => manualStoppedPauseRecurrentHandle(),
                });
                return;
            }
            await manualStoppedPauseRecurrentHandle();
        }
        finally {
            setIsLoading(false);
        }
    };
    return (<ButtonGroup mode={'vertical'} stretched={true}>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionManagementButtonResumePauseRecurrentClick)} stretched={true} loading={isLoading} disabled={isLoading} onClick={renewSubscription}>
        {intl.formatMessage(messages.recoverBtnText)}
      </ModalButton>
      <CancelSubscriptionButton handleCancelSubscription={handleCancelSubscription} disabled={isLoading} type={'pause-recurrent'}/>
    </ButtonGroup>);
};
export default SubscriptionResumePauseRecurrentButton;
