import { createEvent, createStore } from 'effector';
import { reset } from 'patronum';
const resetAllHeaderModel = createEvent();
const $showLogo = createStore(false);
const setShowLogo = createEvent();
const $openSubMenu = createStore(false);
const toggleOpenSubMenu = createEvent();
const closeSubMenu = createEvent();
const $isTransparentBackgroundColor = createStore(false);
const setIsTransparentBackgroundColor = createEvent();
const $blur = createStore(false);
const setBlur = createEvent();
$showLogo.on(setShowLogo, (_, payload) => payload);
$openSubMenu.on(toggleOpenSubMenu, (state) => !state);
$openSubMenu.on(closeSubMenu, () => false);
$isTransparentBackgroundColor.on(setIsTransparentBackgroundColor, (state) => !state);
$blur.on(setBlur, (_, payload) => payload);
reset({
    clock: resetAllHeaderModel,
    target: [$showLogo, $openSubMenu, $openSubMenu, $isTransparentBackgroundColor, $blur],
});
export const headerModel = {
    $showLogo,
    $openSubMenu,
    $isTransparentBackgroundColor,
    $blur,
    setShowLogo,
    toggleOpenSubMenu,
    closeSubMenu,
    setIsTransparentBackgroundColor,
    setBlur,
    resetAllHeaderModel,
};
