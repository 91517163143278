export const DATE_FORMAT_FROM_BACKEND = 'YYYY-MM-DD HH:mm:ss';
export const DATE_FIELDS_WHICH_NEED_CHANGE_FORMAT = [
    'subscription_start_time',
    'subscription_end_time',
    'next_recurrent_payment_time',
    'last_recurrent_payment_time',
];
export const ACTIVE_STATUS_LIST = ['ACTIVE', 'FORMED', 'TRIAL'];
export const ACTIVE_SUBSCRIPTION_STATUS_LIST = ['active', 'auto_renew_cancelled', 'suspended'];
export const SUBSCRIPTION_TYPE_WHICH_NEED_REDIRECT_TO_OLD_PROFILE = {
    tele2Multisub: 'TELE2_MULTISUB',
    ogonBase: 'OGON_BASE',
};
export const SUBSCRIPTION_TYPE_WHICH_NEED_REDIRECT_TO_OLD_PROFILE_LIST = [
    SUBSCRIPTION_TYPE_WHICH_NEED_REDIRECT_TO_OLD_PROFILE.tele2Multisub,
    SUBSCRIPTION_TYPE_WHICH_NEED_REDIRECT_TO_OLD_PROFILE.ogonBase,
];
