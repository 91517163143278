import React from 'react';
import { useStoreMap, useUnit } from 'effector-react';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import ModalButton from '@common/components/modals/ModalButton';
import { paymentMethodModel } from '@common/data-layer/payment-method';
import { ManualRecurrentRenewModel } from '@common/features/manual-recurrent-renew';
import { showUnboundCardInfoModal } from '@common/features/show-unbound-card-info-modal';
import { analytics } from './analytics';
import { useIntl } from 'react-intl';
import messages from './messages';
const ManualStoppedRecurrentRenewButton = () => {
    const isNotBoundCard = useStoreMap(paymentMethodModel.$paymentMethod, (state) => state?.status === 'not_bound');
    const renewManualPausedRecurrentFxPending = useUnit(ManualRecurrentRenewModel.$renewManualPausedRecurrentFxPending);
    const handleRenewManualPausedRecurrent = useUnit(ManualRecurrentRenewModel.AttachedRenewManualPausedRecurrentFx);
    const getPaymentsMethodFxPending = useUnit(paymentMethodModel.getPaymentMethodFx.pending);
    const getPaymentsMethod = useUnit(paymentMethodModel.getPaymentMethodFx);
    const pending = renewManualPausedRecurrentFxPending || getPaymentsMethodFxPending;
    const intl = useIntl();
    const handleClick = async () => {
        await getPaymentsMethod();
        if (isNotBoundCard) {
            showUnboundCardInfoModal({
                showModalAnalytics: analytics.modalRecurrentRenewWithoutLinkCardShow,
                buttonAnalytic: analytics.modalRecurrentRenewWithoutLinkCardButtonConfirm,
                callbackAfterBoundCardModel: () => handleRenewManualPausedRecurrent(),
            });
            return;
        }
        await handleRenewManualPausedRecurrent();
    };
    return (<ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionManagementButtonRestoreClick([]))} disabled={pending} loading={pending} stretched={true} onClick={handleClick}>
      {intl.formatMessage(messages.recoverBtnText)}
    </ModalButton>);
};
export default ManualStoppedRecurrentRenewButton;
