import React from 'react';
import { Group, Header, MiniInfoCell } from '@vkontakte/vkui';
import { Icon16Done } from '@vkontakte/icons';
import { splitBenefits } from '@common/entities/subscriptions/lib/modals/show-subscription-details/ui/subscription-details-benefits/model';
const SubscriptionDetailsBenefits = (props) => {
    const benefits = splitBenefits(props.benefits);
    return (<>
      {benefits.map((benefit) => (<Group key={benefit.title} mode='plain' header={<Header multiline={true}>{benefit.title} </Header>}>
          {benefit.items.map((item) => (<MiniInfoCell key={item} mode='accent' before={<Icon16Done color='var(--vkui--color_accent_blue)'/>}>
              {item}
            </MiniInfoCell>))}
        </Group>))}
    </>);
};
export default SubscriptionDetailsBenefits;
