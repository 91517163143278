import { createEffect, createEvent, createStore } from 'effector';
import { api } from '../api';
import { showCheckoutModal } from '@common/entities/checkout';
export const $linkPaymentMethodState = createStore('idle');
const changeLinkPaymentMethodState = createEvent();
$linkPaymentMethodState.on(changeLinkPaymentMethodState, (_state, payload) => payload);
export const linkPaymentMethodFx = createEffect(async () => {
    changeLinkPaymentMethodState('process');
    try {
        const linkPaymentMethodResponse = (await api.linkPaymentMethod()).data;
        if (!linkPaymentMethodResponse.successful) {
            throw new Error();
        }
        await showCheckoutModal({ paymentId: linkPaymentMethodResponse.data.order.uuid, type: 'paymethod' });
        changeLinkPaymentMethodState('idle');
    }
    catch {
        changeLinkPaymentMethodState('error');
    }
});
