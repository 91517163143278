export const analyticsDictionary = {
    modalCancelSubscriptionBenefitsShow: 'modal_cancel_subscription_benefits',
    modalCancelSubscriptionBenefitsButtonCancelClick: 'modal_cancel_subscription_benefits-button_cancel',
    modalCancelSubscriptionBenefitsButtonConfirmClick: 'modal_cancel_subscription_benefits-button_confirm',
    modalCancelSubscriptionWinbackShow: 'modal_cancel_subscription_winback',
    modalCancelSubscriptionWinbackButtonCancelClick: 'modal_cancel_subscription_winback-button_cancel',
    modalCancelSubscriptionWinbackButtonConfirmClick: 'modal_cancel_subscription_winback-button_confirm',
    modalCancelSubscriptionAudiobooksShow: 'modal_cancel_subscription_audiobooks',
    modalCancelSubscriptionAudiobooksButtonCancelClick: 'modal_cancel_subscription_audiobooks-button_cancel',
    modalCancelSubscriptionAudiobooksButtonConfirmClick: 'modal_cancel_subscription_audiobooks-button_confirm',
};
