import { Icon24DownloadOutline, Icon28PlaylistOutline, Icon28SmartphoneOutline, Icon56CheckCircleOutline, } from '@vkontakte/icons';
import classNames from 'classnames';
import MobileImg from './images/mobile.webp';
import { Button } from '@vkontakte/vkui';
import React from 'react';
import { createAnalyticClickAttribute } from '@analytics';
import { getPlatform } from '../../lib/dataForLink';
import styles from './styles.module.scss';
import { useIntl } from 'react-intl';
import messages from './messages';
export const Content = ({ description, title }) => {
    const intl = useIntl();
    return (<div className={styles.container}>
      <div>
        <div className={styles.topPart}>
          <Icon56CheckCircleOutline className={styles.iconSuccess}/>
          <h3 className={styles.title}>{title ?? intl.formatMessage(messages.defaultTitle)} </h3>
          <p className={styles.description}>{description ?? intl.formatMessage(messages.defaultDescription)}</p>
        </div>
        <div className={classNames(styles.wrapper, styles.wrapper_big)} style={{ backgroundImage: `url(${MobileImg})` }}>
          <h3 className={styles.coloredTxt}>{intl.formatMessage(messages.coloredText)}</h3>
          <p>{intl.formatMessage(messages.benefitsText)}</p>
        </div>
        <div className={styles.wrapper}>
          <Icon28PlaylistOutline className={styles.icon}/>
          {intl.formatMessage(messages.playlistFeatureText)}
        </div>
        <div className={styles.wrapper}>
          <Icon28SmartphoneOutline className={styles.icon}/>
          {intl.formatMessage(messages.appFeatureText)}
        </div>
      </div>
    </div>);
};
export const Footer = ({ platform, type }) => {
    const platformObject = getPlatform(platform);
    const { link, analytic } = platformObject;
    const handleClick = () => {
        window.open(link, '_blank');
    };
    const intl = useIntl();
    return (<div className={styles.buttonWrap}>
      <Button onClick={handleClick} size={'l'} stretched={true} className={styles.button} {...createAnalyticClickAttribute(analytic.click(type))}>
        <Icon24DownloadOutline />
        {intl.formatMessage(messages.footerButtonText)}
      </Button>
    </div>);
};
