import ApiCommon from './ApiCommon';
import { sessionIdManager } from '../session-id-manager';
import { mytrackerManager } from '../mytracker-manager';
const params = {
    getSessionId: sessionIdManager.getSessionId,
    getMyTrackerLvid: mytrackerManager.getMyTrackerLvid,
    getMyTrackerApp: mytrackerManager.getMyTrackerApp,
};
const apiCommon = new ApiCommon(params);
export default apiCommon;
