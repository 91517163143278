import classNames from 'classnames';
import { Content, Footer, Header } from './ui';
import style from './style.module.scss';
import { Seo } from '@website/shared/ui/molecules/seo';
const MainLayout = ({ children, title, className, metaTags }) => {
    const hasMetaTags = Boolean(metaTags && metaTags.length > 0);
    const isRenderHead = hasMetaTags || Boolean(title);
    return (<div className={classNames(style.mainLayout, style.theme_music_dark, className)}>
      {isRenderHead && (<Seo title={title} metaTags={metaTags}/>)}
      {children}
    </div>);
};
MainLayout.Header = Header;
MainLayout.Content = Content;
MainLayout.Footer = Footer;
export default MainLayout;
