import React from 'react';
import * as copy from 'copy-to-clipboard';
import { Icon28CheckCircleOutline } from '@vkontakte/icons';
import { MINIAPP_URL } from '@common/consts/environment';
import { snackbarManagerModel } from '@common/services/snackbar-manager';
export const copySubscriptionInviteLink = (groupId) => {
    void copy.default(`${MINIAPP_URL}#joinGroup=${groupId}`);
    snackbarManagerModel.addSnack({
        before: <Icon28CheckCircleOutline fill='var(--vkui--color_icon_positive)'/>,
        children: 'Приглашение скопировано',
    });
};
