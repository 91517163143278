import apiCommon from '@common/utils/ApiCommon';
import { Centrifuge } from 'centrifuge';
import { CENTRIFUGE_URL } from '@common/consts';
const getCentrifugeTokenData = async () => {
    const { data } = await apiCommon.getCentrifugeToken();
    if (!data.successful) {
        throw new Error('Failed to retrieve Centrifuge token');
    }
    return data.data;
};
const initCentrifuge = async () => {
    if (!CENTRIFUGE_URL) {
        console.error('in the file .env not specified CENTRIFUGE_URL');
        return;
    }
    try {
        const data = await getCentrifugeTokenData();
        const centrifuge = new Centrifuge(CENTRIFUGE_URL, {
            token: data.token,
        });
        return { centrifuge, data };
    }
    catch (error) {
        console.error(error);
    }
};
export const getNotificationSubscription = async () => {
    const result = await initCentrifuge();
    if (result) {
        const { centrifuge, data } = result;
        centrifuge.connect();
        const sub = centrifuge.newSubscription(`notifications#${data.sub}`);
        return sub;
    }
    return undefined;
};
