import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import Router from 'next/router';
import { AuthStateModel } from '../model';
import { ROUTES } from '@website/shared/lib/routes';
const defaultCallback = () => {
    void Router.push(ROUTES.root);
};
export const useAuthorizationGuard = ({ callback = defaultCallback }) => {
    const authState = useUnit(AuthStateModel.$authState);
    useEffect(() => {
        if (authState === 'unauthorized') {
            callback();
        }
    }, [authState]);
};
