import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
const Banner = ({ header, subheader, className, ...divProps }) => {
    return (<div className={classNames(styles.banner, className)} {...divProps}>
      <div className={styles.bannerContent}>
        {header && <span className={styles.bannerHeader}>{header}</span>}
        {subheader && <span className={styles.bannerSubHeader}>{subheader}</span>}
      </div>
    </div>);
};
export default Banner;
