import Analytics from './Analytics';
import QueryParamsManipulator from '@common/utils/QueryParamsManipulator';
import createAnalyticAttrs from './createAnalyticAttrs';
import createUseAnalyticGlobalClick, { createAnalyticClickAttribute } from './useAnalyticGlobalClick';
import createUseAnalyticGlobalShow, { createAnalyticShowAttribute } from './useAnalyticGlobalShow';
import { createCallAnalyticShowDirect, createUseAnalyticShowDirect } from './useAnalyticShowDirect';
import analyticsDictionary from './dictionary';
import logEventInDevelop from './logEventInDevelop';
import { sessionIdManager } from '../session-id-manager';
import { mytrackerManager } from '../mytracker-manager';
import getDefaultPayload from './getDefaultPayload';
import getAnalyticContext from './getAnalyticContext';
import { BASE_API_URL, IS_DEVELOPMENT, IS_STORYBOOK } from '@common/consts';
const configureEvent = async (data) => {
    const context = await getAnalyticContext();
    return {
        ...data,
        context: {
            ...data.context,
            ...context,
        },
    };
};
const params = {
    configureEvent,
    getSessionId: sessionIdManager.getSessionId,
    getMyTrackerLvid: mytrackerManager.getMyTrackerLvid,
    sendEvents: (events, headers) => {
        if (IS_STORYBOOK || IS_DEVELOPMENT) {
            logEventInDevelop(events);
            return Promise.resolve(new Response(JSON.stringify({ success: true })));
        }
        return fetch(`${BASE_API_URL}/api/hole/drop`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
                ...headers,
            },
            body: JSON.stringify(events),
        });
    },
};
const analytic = new Analytics(params);
const addEvent = (type, elementKey) => {
    const payload = {
        ...getDefaultPayload(),
        element: elementKey,
    };
    void analytic.addEvent({
        subject: type,
        payload,
    });
};
const cashPageVisitMap = new Map();
const callAnalyticVisit = () => {
    const pathname = window.location.pathname;
    const initialURLParams = window.location.hash.substring(1) || window.location.search.substring(1);
    const sanitizedURLParams = unescape(initialURLParams);
    const queryParamsManipulator = new QueryParamsManipulator(sanitizedURLParams);
    let utmParams;
    if (!cashPageVisitMap.get(pathname)) {
        utmParams = queryParamsManipulator
            .filteredParams((key) => /^utm_(source|medium|campaign|term|content|referrer)$/.test(key))
            .toString()
            .split('&')
            .reduce((obj, current) => {
            const arr = current.split('=');
            obj[arr[0]] = arr[1];
            return obj;
        }, {});
    }
    else {
        utmParams = {};
    }
    const payload = {
        ...getDefaultPayload(),
        ...utmParams,
    };
    cashPageVisitMap.set(pathname, true);
    void analytic.addEvent({
        payload,
        subject: 'page_visit',
    });
};
const callAnalyticSubmit = (elementKey, data) => {
    void analytic.addEvent({
        payload: {
            ...getDefaultPayload(),
            element: elementKey,
            data,
        },
        subject: 'submit',
    });
};
const callReceivedOffersServiceError = ({ is_logged_in, }) => {
    void analytic.addEvent({
        subject: 'offers_receiving_failed',
        payload: {
            ...getDefaultPayload(),
            is_logged_in,
        },
    });
};
const callReceivedPaymentsServiceError = ({ is_logged_in, }) => {
    void analytic.addEvent({
        subject: 'payment_receiving_failed',
        payload: {
            ...getDefaultPayload(),
            is_logged_in,
        },
    });
};
const useAnalyticShowDirect = createUseAnalyticShowDirect((elementKey) => {
    addEvent('show', elementKey);
});
const callAnalyticShowDirect = createCallAnalyticShowDirect((elementKey) => {
    addEvent('show', elementKey);
});
const callAnalyticClick = (elementKey) => {
    addEvent('click', elementKey);
};
const useAnalyticGlobalClick = createUseAnalyticGlobalClick((elementKey) => {
    addEvent('click', elementKey);
});
const useAnalyticGlobalShow = createUseAnalyticGlobalShow((elementKey) => {
    addEvent('show', elementKey);
});
const AVAILABLE_METRICS = [
    'CLS',
    'FCP',
    'FID',
    'INP',
    'LCP',
    'TTFB',
    'SPEED',
];
const reportWebVitals = (metric) => {
    const { name, value } = metric;
    if (!AVAILABLE_METRICS.includes(name)) {
        return;
    }
    const connection_type = navigator.connection?.effectiveType;
    const payload = {
        name,
        value: Math.round(value),
        url: location.host + location.pathname,
        environment: 'production',
        connection_type,
    };
    void analytic.addEvent({
        subject: 'web_vitals_measurement',
        payload: payload,
    });
};
const getDefaultMetrics = (metrics, type, defaultValue) => metrics?.find((metric) => metric.type === type)?.value || defaultValue;
export { analytic, createAnalyticAttrs, useAnalyticGlobalClick, useAnalyticGlobalShow, useAnalyticShowDirect, callAnalyticShowDirect, callAnalyticClick, callAnalyticVisit, callAnalyticSubmit, callReceivedOffersServiceError, callReceivedPaymentsServiceError, createAnalyticClickAttribute, createAnalyticShowAttribute, reportWebVitals, analyticsDictionary, getDefaultMetrics, };
