import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import ModalButton from '@common/components/modals/ModalButton';
import { StudentsConfirmed } from '@common/entities/student';
export const showActiveStudentSubscriptionModal = () => modalManagerModel.showModal({
    analyticShowAttribute: '',
    icon: <StudentsConfirmed />,
    header: 'У вас уже есть подписка!',
    subheader: 'Вы уже подтвердили свой статус студента и пользуетесь всеми преимуществами VK музыки',
    actions: <ModalButton onClick={() => modalManagerModel.closeModal()}>Хорошо</ModalButton>,
});
