import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { PanelSpinner } from '@vkontakte/vkui';
import { callAnalyticShowDirect, createAnalyticClickAttribute } from '@analytics';
import { KeywordsKeeper } from '@common/entities/offers';
import api from '@common/utils/ApiCommon';
import { IS_MINIAPP } from '@common/consts';
import { getOfferMetric } from '@common/entities/offers/lib/utils';
import { findMetric } from '@common/utils/Analytics/dictionary';
import ModalButton from '@common/components/modals/ModalButton';
import { modalManagerModel } from '@common/services/modal-manager';
import { applyOffer as applyOfferCommon } from '@common/components/Root/actions';
import { messages } from './messages';
import { getAnalytic } from './lib';
export const useShowStudentRejectModalModel = (params) => {
    const { activeSubscription, status, tryAfterDays } = params;
    const [isApplyInProgress, setIsApplyInProgress] = useState(false);
    const intl = useIntl();
    const hasActiveSubscription = Boolean(activeSubscription);
    const [offersToRestore, setOffersToRestore] = useState([]);
    const [state, setState] = useState(hasActiveSubscription ? 'unavailable' : 'loading');
    let subheader;
    let actions = null;
    const offer = offersToRestore[0];
    const isRetry = status === 'RETRY';
    const analyticFromFrontend = getAnalytic(status);
    const widget = offer?.widgets.trial || offer?.widgets.offer;
    const applyOfferCallback = useCallback((args) => {
        setIsApplyInProgress(true);
        void applyOfferCommon(args).finally(() => {
            setIsApplyInProgress(false);
        });
    }, []);
    const closeModal = () => {
        modalManagerModel.closeModal();
    };
    const loadOffers = async () => {
        setState('loading');
        try {
            const offersKey = await KeywordsKeeper.getKeywordsByDictionaryKey('studentsRejected');
            const offers = await api.getOffers({ keywords: [offersKey], useUserCountry: true });
            if (!offers.data.success) {
                setState('unavailable');
                return;
            }
            if (offers.data.payload.length === 0) {
                setState('unavailable');
                if (IS_MINIAPP) {
                    const { platform } = await (await import('@common/utils/ApiVkBridge')).default.getClientVersion();
                    if (platform === 'ios') {
                        callAnalyticShowDirect(analyticFromFrontend.iosNotSupportedShow);
                    }
                }
                return;
            }
            setOffersToRestore(offers.data.payload);
            setState('available');
            const analyticFromBackend = getOfferMetric(offers.data.payload[0], 'offer', 'show');
            if (analyticFromBackend) {
                callAnalyticShowDirect(analyticFromBackend);
                return;
            }
            const correlationId = offers.data.payload[0]?.correlationId;
            callAnalyticShowDirect(analyticFromFrontend.show(correlationId));
        }
        catch (error) {
            setState('unavailable');
        }
    };
    const getRestoreAction = () => {
        return offer && widget ? (<>
        {widget.buttons.map((button, index) => {
                const analyticFromBackend = findMetric(button.metrics, 'click')?.value;
                const analytic = analyticFromBackend ?? analyticFromFrontend.click(offer.correlationId);
                return (<ModalButton {...createAnalyticClickAttribute(analytic)} key={index} disabled={isApplyInProgress} loading={isApplyInProgress} onClick={() => applyOfferCallback({
                        correlationId: offer?.correlationId,
                        button,
                    })}>
              {button.name}
            </ModalButton>);
            })}
      </>) : (<ModalButton disabled={isApplyInProgress} onClick={closeModal}>
        {intl.formatMessage(messages.ok)}
      </ModalButton>);
    };
    useEffect(() => {
        if (!hasActiveSubscription) {
            void loadOffers();
            return;
        }
        callAnalyticShowDirect(analyticFromFrontend.show());
    }, []);
    if (state === 'loading') {
        subheader = '';
        actions = (<>
        <PanelSpinner size={'large'}/>
      </>);
    }
    else if (hasActiveSubscription && status === 'REJECTED') {
        subheader = activeSubscription?.management
            ? intl.formatMessage(messages.nowHasActiveManagementSubscriptionRejected, {
                subscriptionType: activeSubscription?.subscription_type,
                distributorName: activeSubscription?.management?.distributor?.name ?? null,
            })
            : intl.formatMessage(messages.nowHasActiveSubscriptionRejected, {
                subscriptionType: activeSubscription?.subscription_type,
                subscriptionPrice: activeSubscription?.cost?.display,
            });
        actions = (<>
        <ModalButton disabled={isApplyInProgress} onClick={closeModal}>
          {intl.formatMessage(messages.ok)}
        </ModalButton>
      </>);
    }
    else if (isRetry) {
        subheader = intl.formatMessage(messages.temporaryRejected, {
            daysCount: tryAfterDays,
            descriptions: widget?.description ?? null,
        });
        actions = getRestoreAction();
    }
    else if (offer && widget) {
        subheader =
            widget &&
                intl.formatMessage(messages.permanentRejected, {
                    descriptions: widget?.description ?? null,
                });
        actions = getRestoreAction();
    }
    else {
        actions = (<>
        <ModalButton disabled={isApplyInProgress} onClick={closeModal}>
          {intl.formatMessage(messages.ok)}
        </ModalButton>
      </>);
    }
    return {
        subheader,
        actions,
    };
};
