import React from 'react';
import { useUnit } from 'effector-react';
import { modalManagerModel, modalManagerTools } from '@common/services/modal-manager';
import { intl } from '@common/shared/lib/intl';
import { messages } from './messages';
import { analytics } from './analytics';
import { showUnlinkCardReasonModal } from '@common/widgets/payment-method-widget/ui/current-payment-method/lib/modals/showUnlinkCardReasonModal/showUnlinkCardReasonModal';
import { showAbTestDeactivateTrialSuccessModal } from '../show-ab-test-deactivate-trial-success-modal';
import { analyticsDictionary } from '@analytics';
const unlinkPaymentMethod = () => {
    showUnlinkCardReasonModal({
        successHandler: () => showAbTestDeactivateTrialSuccessModal({
            analyticShowAttribute: analyticsDictionary.modalPaymethodUnlinkSuccessShow,
        }),
    });
};
const Actions = () => {
    const closeModal = useUnit(modalManagerModel.closeModal);
    return modalManagerTools.uiModalTemplates.TwoButtonTemplate({
        firstButton: {
            analyticClick: analytics.confirm,
            children: intl.formatMessage(messages.confirmButton),
            handler: unlinkPaymentMethod,
            mode: 'secondary',
        },
        secondButton: {
            analyticClick: analytics.cancel,
            children: intl.formatMessage(messages.cancelButton),
            handler: closeModal,
        },
    });
};
export const showAbTestUnlinkDeactivationWarningModal = () => {
    modalManagerTools.modalTemplates.showMessageModal({
        analyticShowAttribute: analytics.show,
        mode: 'warning',
        header: intl.formatMessage(messages.header),
        subheader: intl.formatMessage(messages.subheader),
        actions: <Actions />,
    });
};
