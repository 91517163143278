import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import ModalButton from '@common/components/modals/ModalButton';
import { studentAnalytics } from '@common/entities/student';
export const showSuccessSendEmailModal = () => {
    const analyticShowAttribute = studentAnalytics.emailForm.emailFormSuccessShow;
    const onClick = () => {
        modalManagerModel.closeModal();
    };
    modalManagerModel.showModal({
        analyticShowAttribute,
        mode: 'success',
        header: 'Заявка принята',
        subheader: 'Отправили вам письмо с инструкцией на указанную почту.',
        actions: <ModalButton onClick={onClick}>Понятно</ModalButton>,
    });
};
