import { createEvent, createStore } from 'effector';
const $isInProgress = createStore(false);
const setIsInProgress = createEvent();
$isInProgress.on(setIsInProgress, (_state, payload) => payload);
const INIT_FORM_VALUE = {
    noAdvantageChecked: false,
    badPriceChecked: false,
    techProblemsChecked: false,
    anotherSubscriptionChecked: false,
    anotherSubscriptionText: '',
    otherChecked: false,
    otherText: '',
};
const $form = createStore(INIT_FORM_VALUE);
const setOption = createEvent();
const toggleOption = createEvent();
$form.on(setOption, (state, payload) => {
    const { key, value } = payload;
    const result = { ...state, [key]: value };
    return result;
});
$form.on(toggleOption, (state, payload) => {
    const result = { ...state };
    result[payload] = !state[payload];
    return result;
});
export const unlinkCardModel = { $form, setOption, toggleOption };
