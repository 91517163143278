import { Icon28LogoVk } from '@vkontakte/icons';
import styles from './styles.module.scss';
import { redirectToVKIDWithCheckDeepLink } from '@common/entities/auth-vkid/model/redirect-auth';
const AuthButton = ({ children }) => {
    const onClick = () => {
        redirectToVKIDWithCheckDeepLink();
    };
    return (<button type={'button'} className={styles.authButton} onClick={onClick}>
      <Icon28LogoVk />
      <div className={styles.authButton__content}>{children}</div>
    </button>);
};
export default AuthButton;
