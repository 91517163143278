import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    stepOneSubhead: {
        id: 'stepOneSubhead',
        defaultMessage: '1 из 2',
    },
    stepOneTitle: {
        id: 'stepOneTitle',
        defaultMessage: 'А вы точно студент?',
    },
    stepOneDescription: {
        id: 'stepOneDescription',
        defaultMessage: 'Подписка доступна студентам вузов и ссузов',
    },
    stepOneBtnCancelText: {
        id: 'stepOneBtnCancelText',
        defaultMessage: 'Отмена',
    },
    stepOneBtnSureText: {
        id: 'stepOneBtnSureText',
        defaultMessage: 'Точно',
    },
    stepTwoSubhead: {
        id: 'stepTwoSubhead',
        defaultMessage: '2 из 2',
    },
    stepTwoTitle: {
        id: 'stepTwoTitle',
        defaultMessage: 'Подготовьте студенческий билет',
    },
    stepTwoDescription: {
        id: 'stepTwoDescription',
        defaultMessage: 'Он понадобится вам для заполнения анкеты',
    },
    stepTwoBtnCancelText: {
        id: 'stepTwoBtnCancelText',
        defaultMessage: 'Отмена',
    },
    stepTwoBtnStartText: {
        id: 'stepTwoBtnStartText',
        defaultMessage: 'Хорошо',
    },
});
