import React from 'react';
import { Button, Spacing, Text } from '@vkontakte/vkui';
import Antenna from '@common/components/icons/Antenna';
import Typograf from '../Typograf';
import styles from './styles.module.scss';
const Error = (props) => {
    const { title, buttonText, buttonOnClick, buttonLoading } = props;
    return (<div className={styles.container}>
      <Antenna />
      <Spacing size={17}/>
      <Text className={styles.text}>
        <Typograf>{title}</Typograf>
      </Text>
      <Spacing size={13}/>
      <Button type='button' loading={buttonLoading} disabled={buttonLoading} onClick={buttonOnClick} size={'s'} mode='tertiary'>
        {buttonText}
      </Button>
    </div>);
};
export default Error;
