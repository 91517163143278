import { createEffect } from 'effector';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { applyOffer } from '@common/components/Root/actions';
import { attach } from 'effector/effector.umd';
const applyGroupSubscription = createEffect(async ({ correlationId, finishedCallback }) => {
    const applyOfferParams = {
        correlationId: correlationId,
        button: {
            type: 'purchase',
        },
    };
    await applyOffer(applyOfferParams);
    finishedCallback && finishedCallback();
});
const start = attach({
    effect: createEffect(async (params) => {
        const { ...applyOfferHandlerProps } = params;
        await applyGroupSubscription(applyOfferHandlerProps);
    }),
    source: { subscription: SubscriptionsModel.$subscriptionExcludeWaitingStatus },
    mapParams: (params, source) => {
        return { ...params, ...source };
    },
});
export const groupSubscriptionFlowModel = { start };
