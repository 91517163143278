const getAnalyticPrefixShow = (market, type) => `modal_checkout_success_${type}-${market}`;
const getAnalyticPrefixClick = (market, type) => `modal_checkout_success_${type}-${market}-button_confirm`;
const APP_STORE_LINK = 'https://trk.mail.ru/c/igfgj1';
const GOOGLE_PLAY = 'https://trk.mail.ru/c/y12u28';
const data = {
    ios: {
        link: APP_STORE_LINK,
        analytic: {
            show: (type) => getAnalyticPrefixShow('app_store', type),
            click: (type) => getAnalyticPrefixClick('app_store', type),
        },
    },
    android: {
        link: GOOGLE_PLAY,
        analytic: {
            show: (type) => getAnalyticPrefixShow('play_google', type),
            click: (type) => getAnalyticPrefixClick('play_google', type),
        },
    },
};
export const getPlatform = (platform) => {
    return data[platform];
};
