import storage from '../Storage';
import waitForResult from '@common/helpers/waitForResult';
import { X_SESSION_ID_STORAGE_KEY, BASE_API_URL } from '@common/consts';
const MAX_ATTEMPTS = 20;
const INTERVAL = 250;
export class SessionIdManager {
    constructor() {
        this.xSessionId = null;
        this.init = async () => {
            try {
                const xSessionIdFromStorage = storage.get(X_SESSION_ID_STORAGE_KEY);
                if (typeof xSessionIdFromStorage === 'string') {
                    this.xSessionId = xSessionIdFromStorage;
                    this.resolveInitPromise();
                    return;
                }
            }
            catch { }
            try {
                let count = 0;
                const handleBadStatus = () => {
                    if (count >= MAX_ATTEMPTS) {
                        this.resolveInitPromise();
                        return false;
                    }
                    count++;
                    return true;
                };
                waitForResult(() => fetch(`${BASE_API_URL}/api/session/touch`), {
                    params: [],
                    handleResponse: async (response) => {
                        try {
                            const result = (await response.json());
                            if (result.success) {
                                const xSessionIdFromHeaders = response.headers.get('x-session-id') || '';
                                this.xSessionId = xSessionIdFromHeaders;
                                this.resolveInitPromise();
                                try {
                                    storage.set(X_SESSION_ID_STORAGE_KEY, xSessionIdFromHeaders);
                                }
                                catch { }
                                return false;
                            }
                            return handleBadStatus();
                        }
                        catch {
                            return handleBadStatus();
                        }
                    },
                    handleError: handleBadStatus,
                    callInterval: INTERVAL,
                });
            }
            catch {
                this.resolveInitPromise();
            }
        };
        this.getSessionId = async () => {
            await this.initPromise;
            return this.xSessionId;
        };
        this.resolveInitPromise = Promise.resolve;
        this.initPromise = new Promise((resolve) => {
            this.resolveInitPromise = resolve;
        });
        this.init();
    }
}
