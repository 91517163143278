import { showActiveStudentSubscriptionModal } from '@common/features/start-student-flow/lib';
import { showStudentStatusReadyModal } from '../show-student-status-ready-modal';
export class ReadyStrategy {
    async do({ activeSubscription }) {
        if (activeSubscription?.features?.student?.is_student_subscription) {
            showActiveStudentSubscriptionModal();
            return;
        }
        showStudentStatusReadyModal();
    }
}
