import { defineMessages } from 'react-intl';
const messages = defineMessages({
    recoverSubBtnText: {
        id: 'recoverSubBtnText',
        defaultMessage: 'Возобновить подписку',
    },
    buySubBtnText: {
        id: 'buySubBtnText',
        defaultMessage: 'Подключить за {price} ₽',
    },
});
export default messages;
