import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { getPlatform } from './lib/dataForLink';
import { Content, Footer } from '@common/components/modals/SubscriptionSuccessFormedModal/ui/content/';
const payloadToManagerModal = (params) => {
    const { platform, type, title, description } = params;
    const { analytic } = getPlatform(platform);
    return {
        analyticShowAttribute: analytic.show(type),
        header: '',
        children: (<Content title={title} description={description}/>),
        footer: (<Footer platform={platform} type={type}/>),
    };
};
export const showSubscriptionSuccessFormedModal = (params) => modalManagerModel.showModalPageWithFixedFooter(payloadToManagerModal(params));
