import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
export const useMatchMedia = (query) => {
    const [match, setMatch] = useState(false);
    useEffect(() => {
        const checker = debounce(() => {
            const isMatches = window.matchMedia(query).matches;
            setMatch(isMatches);
        });
        window.addEventListener('resize', checker);
        checker();
        return () => {
            window.removeEventListener('resize', checker);
        };
    }, []);
    return match;
};
