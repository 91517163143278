import React, { useMemo } from 'react';
import { SimpleCell, CellButton, IconButton, Avatar, GridAvatar, Spinner, Group, Header } from '@vkontakte/vkui';
import { Icon24Add, Icon16StarCircleFillBlue, Icon24ChevronCompactRight } from '@vkontakte/icons';
import { MembersListAvatarOverlay } from '../members-list-avatar-overlay';
import { memberManagement } from './model';
import styles from './styles.module.scss';
const badge = (<GridAvatar.Badge>
    <Icon16StarCircleFillBlue className={styles.friendsSubscription__badge} width={16} height={16}/>
  </GridAvatar.Badge>);
const memberStatusMapping = {
    owner: badge,
    invitation_sent: <MembersListAvatarOverlay type={'invitation_sent'}/>,
    invitation_rejected: <MembersListAvatarOverlay type={'invitation_rejected'}/>,
    member: null,
};
const MembersList = (props) => {
    const { groupId, maxMembers, isOwner, members, addMember } = props;
    const itemsRef = useMemo(() => new Map(), []);
    const actionIcon = (item) => {
        const { status, state } = item;
        if (!isOwner) {
            return null;
        }
        if (status === 'owner' || status === 'invitation_rejected') {
            return null;
        }
        return (<IconButton disabled={state === 'loading'} onClick={() => memberManagement({
                groupId,
                ...item,
            })}>
        <Icon24ChevronCompactRight className={styles.membersList__actionIcon}/>
      </IconButton>);
    };
    return (<Group mode='plain' header={<Header aside={<span className={styles.membersListTitle__membersCount}>{`${members.length}/${maxMembers}`}</span>}>
          Друзья в подписке
        </Header>}>
      {members.map((item) => (<SimpleCell getRootRef={(node) => {
                const map = itemsRef;
                if (node) {
                    map.set(item.userId, node);
                }
                else {
                    map.delete(item.userId);
                }
            }} hasHover={false} hasActive={false} key={item.userId} before={<Avatar size={40} src={item.defaultAvatarUrl} className={styles.friendsSubscription__friend}>
              {(item.state === 'idle' && memberStatusMapping[item.status]) || (item.state === 'loading' && <Spinner />)}
            </Avatar>} after={actionIcon(item)} subtitle={item.status === 'owner' && 'Владелец подписки'}>
          {item.fullName}
        </SimpleCell>))}

      {isOwner && maxMembers - members.length > 0 && (<CellButton size={40} before={<Avatar noBorder={true} size={40}>
              <Icon24Add />
            </Avatar>} onClick={addMember}>
          Пригласить друга
        </CellButton>)}
    </Group>);
};
export default MembersList;
