import React from 'react';
import { Spinner } from '@vkontakte/vkui';
import cn from 'classnames';
import styles from './styles.module.scss';
const stylesMode = {
    primary: styles.primary,
    secondary: styles.secondary,
};
const stylesAppearance = {
    accent: styles.accent,
};
const Button = (props) => {
    const { children, mode = 'primary', appearance = 'accent', type = 'button', description, before, after, loading, disabled, stretched, disableSpinnerAnimation, ...buttonProps } = props;
    return (<button {...buttonProps} type={type} className={cn(buttonProps.className, styles.button, stylesMode[mode], stylesAppearance[appearance], stretched && styles.stretched, loading && styles.loading, description && styles.button__description)} disabled={loading || disabled}>
      {loading && (<Spinner size='small' className={styles.spinner} disableAnimation={disableSpinnerAnimation} noColor={true}/>)}
      <span className={styles.in}>
        {before && (<span className={styles.before} role='presentation'>
            {before}
          </span>)}
        <span>
          {children && <div className={styles.content}>{children}</div>}
          {description && <div className={styles.description}>{description}</div>}
        </span>
        {after && (<span className={styles.after} role='presentation'>
            {after}
          </span>)}
      </span>
    </button>);
};
export default Button;
