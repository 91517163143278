import { analyticsDictionary } from '@analytics';
import { intl } from '@common/shared/lib/intl';
import { messages } from '@common/entities/checkout/messages';
export const commonShowSuccessfulPaymentModalParams = (type = 'offer') => ({
    modalElementKey: analyticsDictionary.modalCheckoutSuccessShow(type),
    mode: 'success',
    title: intl.formatMessage(messages.successfulPaymentModalTitle),
    description: intl.formatMessage(messages.successfulPaymentModalDescription),
    type: type ?? 'offer',
    platform: 'vkcom',
});
