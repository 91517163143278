import React from 'react';
import { CancelSubscriptionButton, GroupButton, LoadingButton, ManagementButton, ManualStoppedRecurrentRenewButton, SubscriptionResumePauseRecurrentButton, } from '../ui';
import { InactiveRenewButton } from '@common/widgets/inactive-renew-button';
const BUTTON_MAP = {
    loading: () => <LoadingButton />,
    management: () => <ManagementButton />,
    'pause-recurrent': (handleCancelSubscription) => (<SubscriptionResumePauseRecurrentButton handleCancelSubscription={handleCancelSubscription}/>),
    group: () => <GroupButton />,
    active: (handleCancelSubscription) => (<CancelSubscriptionButton handleCancelSubscription={handleCancelSubscription}/>),
    suspended: (handleCancelSubscription) => (<CancelSubscriptionButton handleCancelSubscription={handleCancelSubscription}/>),
    auto_renew_cancelled: () => <ManualStoppedRecurrentRenewButton />,
    inactive: () => <InactiveRenewButton />,
    waiting: () => null,
};
export const getButton = (params) => {
    const { status, handleCancelSubscription } = params;
    if (!BUTTON_MAP[status]) {
        return null;
    }
    return BUTTON_MAP[status](handleCancelSubscription);
};
