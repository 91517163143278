import { useEffect, useState } from 'react';
const regex = new RegExp(['^Mozilla\\/5\\.0 \\(iPhone;', '.*', 'Mobile\\/15E148$'].join(''));
const isIphoneContainVkClientUserAgent = (str) => {
    const containsVkClient = str.includes('com.vk.vkclient');
    const containsIPhone = str.includes('iPhone');
    return containsVkClient && containsIPhone;
};
const checkUserAgent = (str) => {
    return regex.test(str) || isIphoneContainVkClientUserAgent(str);
};
export const useInsets = () => {
    const [value, setValue] = useState(undefined);
    useEffect(() => {
        const { userAgent } = navigator;
        if (checkUserAgent(userAgent)) {
            setValue({
                bottom: 35,
            });
        }
    }, []);
    return value;
};
