import { defineMessages } from '@formatjs/intl';
export const showBenefitsCancelSubscription = defineMessages({
    header: {
        id: 'showBenefitsCancelSubscriptionHeader',
        defaultMessage: 'Столько хорошего в вашей подписке!',
    },
    benefitOneTitle: {
        id: 'showBenefitsCancelSubscriptionBenefitOneTitle',
        defaultMessage: 'Музыка без рекламы',
    },
    benefitOneDescription: {
        id: 'showBenefitsCancelSubscriptionBenefitOneDescription',
        defaultMessage: 'Никаких перерывов между треками',
    },
    benefitTwoTitle: {
        id: 'showBenefitsCancelSubscriptionBenefitTwoTitle',
        defaultMessage: 'Возможность скачивать любимое',
    },
    benefitTwoDescription: {
        id: 'showBenefitsCancelSubscriptionBenefitTwoDescription',
        defaultMessage: 'А потом включать и слушать без интернета',
    },
    benefitThreeTitle: {
        id: 'showBenefitsCancelSubscriptionBenefitThreeTitle',
        defaultMessage: 'Доступ к коллекции аудиокниг',
    },
    benefitThreeDescription: {
        id: 'showBenefitsCancelSubscriptionBenefitThreeDescription',
        defaultMessage: 'Можно слушать любимые книги и эксклюзивы',
    },
    benefitFourTitle: {
        id: 'showBenefitsCancelSubscriptionBenefitThreeTitle',
        defaultMessage: 'Фоновое звучание',
    },
    benefitFourDescription: {
        id: 'showBenefitsCancelSubscriptionBenefitThreeDescription',
        defaultMessage: 'Музыка играет даже с выключенным экраном',
    },
    confirmButton: {
        id: 'showBenefitsCancelSubscriptionConfirmButton',
        defaultMessage: 'Всё равно отменить',
    },
    cancelButton: {
        id: 'showBenefitsCancelSubscriptionCancelButton',
        defaultMessage: 'Сохранить подписку',
    },
});
