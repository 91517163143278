import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    header: {
        id: 'header',
        defaultMessage: 'Отменить бесплатный период &nbsp;и отвязать способ оплаты?',
    },
    subheader: {
        id: 'subheader',
        defaultMessage: 'Текущая подписка будет остановлена сразу. Возобновить бесплатный пробный период &nbsp;не получится.',
    },
    confirmButton: {
        id: 'confirmButton',
        defaultMessage: 'Отвязать способ оплаты',
    },
    cancelButton: {
        id: 'cancelButton',
        defaultMessage: 'Оставить подписку',
    },
});
