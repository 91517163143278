import { createEffect, createEvent, createStore, sample } from 'effector';
const $selectedMembers = createStore([]);
const addMember = createEvent();
const addMembers = createEvent();
const removeMember = createEvent();
const clearMembers = createEvent();
$selectedMembers.on(addMember, (state, payload) => [...state, payload]);
$selectedMembers.on(addMembers, (state, payload) => [...state, ...payload]);
$selectedMembers.on(removeMember, (state, payload) => state.filter((item) => item.userId !== payload.userId));
$selectedMembers.on(clearMembers, () => []);
const sendMembers = createEvent();
const sendMembersFx = createEffect();
sample({ clock: sendMembersFx.done, target: clearMembers });
export const selectedMembersModel = {
    $selectedMembers,
    addMember,
    addMembers,
    removeMember,
    clearMembers,
    sendMembers,
};
