import icon90Music123XWebp from './icon-90-music-12@3x.webp';
import icon90Music123XPng from './icon-90-music-12@3x.png';
import icon90Music122XWebp from './icon-90-music-12@2x.webp';
import icon90Music122XPng from './icon-90-music-12@2x.png';
import icon90Music121XWebp from './icon-90-music-12@1x.webp';
import icon90Music121XPng from './icon-90-music-12@1x.png';
export const icon90Music12Image = {
    src: icon90Music121XPng.src,
    width: 90,
    height: 90,
    sources: [
        { width: 90, height: 90, srcSet: icon90Music123XWebp.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 90, height: 90, srcSet: icon90Music123XPng.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 90, height: 90, srcSet: icon90Music122XWebp.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 90, height: 90, srcSet: icon90Music122XPng.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 90, height: 90, srcSet: icon90Music121XWebp.src },
        { width: 90, height: 90, srcSet: icon90Music121XPng.src },
    ],
};
