import { createStore, sample } from 'effector';
import { StudentModel } from '@common/entities/student/model';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { studentOfferModel } from '../student-offer';
import { createBeforeStartFlowFx } from '../before-start-flow';
import { createCheckSubscriptionAndCheckStudentStatusFx, } from './check-subscription-and-check-student-status';
export const initAuthorizedWay = (payload) => {
    const { initStartFlowState, startEvent } = payload;
    const beforeStartFlowFx = createBeforeStartFlowFx();
    const checkSubscriptionAndCheckStudentStatusFx = createCheckSubscriptionAndCheckStudentStatusFx();
    const $startFlowState = createStore(initStartFlowState);
    sample({ clock: startEvent, target: $startFlowState });
    sample({
        clock: $startFlowState,
        source: {
            studentStatusData: StudentModel.$sourceData,
            activeSubscription: SubscriptionsModel.$activeSubscription,
            studentOffer: studentOfferModel.$studentOffer,
        },
        target: beforeStartFlowFx,
    });
    sample({
        clock: beforeStartFlowFx.doneData,
        source: {
            statusData: StudentModel.$sourceData,
            startFlowState: $startFlowState,
            activeSubscription: SubscriptionsModel.$activeSubscription,
        },
        fn: (sourceData) => ({
            statusData: sourceData.statusData,
            activeSubscription: sourceData.activeSubscription,
            callbackAfterFinishedFlow: sourceData?.startFlowState?.callbackAfterFinishedFlow,
        }),
        target: checkSubscriptionAndCheckStudentStatusFx,
    });
    return {
        $startFlowState,
        doneData: checkSubscriptionAndCheckStudentStatusFx.doneData,
        failData: checkSubscriptionAndCheckStudentStatusFx.failData,
    };
};
