import { createEffect } from 'effector';
import { StudentApi } from '@common/entities/student';
import { mapErrorFromApiToErrorStatusInState, mapFormFieldToApiTypeParams } from '../../lib';
export const sendFormFx = createEffect(async (fields) => {
    const response = await StudentApi.sendStudentForm(mapFormFieldToApiTypeParams(fields));
    const { data } = response;
    if (!data.successful) {
        const { errors } = data;
        const errorStatuses = mapErrorFromApiToErrorStatusInState(errors);
        return Promise.reject(errorStatuses);
    }
});
