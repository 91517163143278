import { createEvent, createStore } from 'effector';
import { payloadTemplates } from '../lib/payload-templates';
const $snacks = createStore([]);
const $activeSnack = $snacks.map((state) => state[0] || null);
const addSnack = createEvent();
const addSnackError = createEvent();
const addSnackSuccess = createEvent();
const removeSnack = createEvent();
$snacks.on(addSnack, (state, payload) => [...state, { ...payload, key: Date.now(), onClose: removeSnack }]);
$snacks.on(addSnackError, (state, payload) => [
    ...state,
    { ...payload, ...payloadTemplates.errorIcon, key: Date.now(), onClose: removeSnack },
]);
$snacks.on(addSnackSuccess, (state, payload) => [
    ...state,
    { ...payload, ...payloadTemplates.successIcon, key: Date.now(), onClose: removeSnack },
]);
$snacks.on(removeSnack, (state) => state.slice(1));
export const snackbarManagerModel = {
    $activeSnack,
    addSnack,
    addSnackSuccess,
    addSnackError,
};
