import { Validator } from 'jsonschema';
const logDataErrors = (_schema, data, validate, place) => {
    const logData = {
        message: `data validation error - ${place}`,
        data,
        errors: validate.errors?.map((error) => {
            return error;
        }),
    };
    if (validate.errors.length) {
        console.error(JSON.stringify(logData, null, 2));
    }
    return logData;
};
export const validate = (schema, data) => {
    const validator = new Validator();
    const validate = validator.validate(data, schema, { required: true, allowUnknownAttributes: true });
    return validate;
};
export const validateData = (schema, data, reject, place) => {
    const validateResult = validate(schema, data);
    const { valid } = validateResult;
    const logData = logDataErrors(schema, data, validateResult, place);
    if (!valid) {
        reject(logData);
    }
};
export const isValidateData = (schema, data, place) => {
    const validateResult = validate(schema, data);
    const { valid } = validateResult;
    void logDataErrors(schema, data, validateResult, place);
    return valid;
};
