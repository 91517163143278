import { createEffect, createEvent, sample } from 'effector';
const callback = createEffect(() => { });
const setCallback = createEvent();
sample({
    clock: setCallback,
    fn: (clockData) => {
        callback.use(clockData);
    },
});
sample({
    clock: callback.done,
    fn: () => {
        callback.use(() => { });
    },
});
export const CallAfterBoundCardModel = {
    setCallback,
    callback,
};
