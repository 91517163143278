import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import ModalButton from '@common/components/modals/ModalButton';
import { analyticsDictionary } from '@common/utils/Analytics';
export const showCountryNotSupportedModal = () => {
    modalManagerModel.showModal({
        analyticShowAttribute: analyticsDictionary.modalCountryNotSupportedShow,
        icon: <Icon56ErrorOutline />,
        header: 'Подписка недоступна для покупки в вашем регионе',
        actions: <ModalButton onClick={() => modalManagerModel.closeModal()}>Понятно</ModalButton>,
    });
};
