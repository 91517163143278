import _ from 'lodash';
import { createEffect, createEvent, sample } from 'effector';
import { comboWidgetsModel } from '@common/data-layer/combo-widgets';
import { BANNER_ID_MULTIPLY_SUBSCRIPTIONS, BANNER_ID_PAYMENTS_PAY_METHOD_LINK, BANNER_ID_RECURRENT, BANNER_ID_SUBSCRIPTION_STOPPED, } from '../consts';
const BANNERS_ID = [
    BANNER_ID_MULTIPLY_SUBSCRIPTIONS,
    BANNER_ID_PAYMENTS_PAY_METHOD_LINK,
    BANNER_ID_RECURRENT,
    BANNER_ID_SUBSCRIPTION_STOPPED,
];
export const $infoBanners = comboWidgetsModel.$comboWidgets.map((state) => {
    const banners = state.filter((widget) => BANNERS_ID.includes(widget.id));
    return banners.map((widget) => {
        const { id, elements, metrics } = widget;
        return {
            id,
            metrics,
            title: _.get(elements.find((item) => item.id === 'title'), 'attributes.text', ''),
            text: _.get(elements.find((item) => item.id === 'text'), 'attributes.text', ''),
            button: elements.find((item) => item.id === 'button'),
        };
    });
});
export const getInfoBanners = createEvent();
export const getInfoBannersFx = createEffect(comboWidgetsModel.getComboWidgetsFx);
sample({
    clock: getInfoBanners,
    target: comboWidgetsModel.getComboWidgets,
});
