import { studentStatusInfoBannerAnalytic } from './student-status-info-banner-analytics';
import { studentFormAnalytics } from './student-form-analytics';
import { confirmedAnalytics } from './confirmed';
import { createMainStudentElementAnalyticString } from './constants';
import { rejectAnalytics } from './reject';
import { retryAnalytics } from './retry';
import { emailForm } from './email-form';
import { useOfferConfirmModalAnalytics } from './use-offer-confirm-modal-analytics';
export const studentAnalytics = {
    banner: studentStatusInfoBannerAnalytic,
    form: studentFormAnalytics,
    confirm: confirmedAnalytics,
    reject: rejectAnalytics,
    retry: retryAnalytics,
    wait: {
        show: `${createMainStudentElementAnalyticString()('WAIT')}_status`,
    },
    emailForm,
    useOfferConfirmModal: useOfferConfirmModalAnalytics,
};
