import { createEvent, createStore } from 'effector';
const DEFAULT_STATE = {
    status: 'initial',
    analytic: '',
    click: () => { },
};
const $state = createStore(DEFAULT_STATE);
const setState = createEvent();
const clear = createEvent();
$state.on(setState, (prevValue, newValue) => ({ ...prevValue, ...newValue }));
$state.reset(clear);
export const nextStepButtonModel = { $state, setState, clear };
