import { attach, createEffect, sample } from 'effector';
import { ManualRecurrentRenewApi } from '@common/features/manual-recurrent-renew/api';
import { showSubscriptionRecoverySuccessModal, showSubscriptionRecoveryErrorModal } from '@common/entities/subscriptions';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { getDataPulling } from '@common/components/Root/actions';
const $subscriptionWithAutoRenewCancelledStatus = SubscriptionsModel.$subscriptions.map((subscription) => subscription ? subscription.find(({ status }) => status === 'auto_renew_cancelled') ?? null : null);
const { renewManualPausedRecurrentFx } = ManualRecurrentRenewApi;
const AttachedRenewManualPausedRecurrentFx = attach({
    source: $subscriptionWithAutoRenewCancelledStatus,
    effect: renewManualPausedRecurrentFx,
});
const showSuccessModalFx = createEffect(() => {
    showSubscriptionRecoverySuccessModal();
});
const showErrorModalFx = createEffect((errors) => {
    showSubscriptionRecoveryErrorModal(errors);
});
const updateDataFx = createEffect(async () => {
    await getDataPulling({ subscriptions: true, banners: true, paymentMethod: true });
});
const $renewManualPausedRecurrentFxPending = AttachedRenewManualPausedRecurrentFx.pending;
sample({
    clock: AttachedRenewManualPausedRecurrentFx.doneData,
    target: [showSuccessModalFx, updateDataFx],
});
sample({ clock: AttachedRenewManualPausedRecurrentFx.failData, target: showErrorModalFx });
export const ManualRecurrentRenewModel = {
    $renewManualPausedRecurrentFxPending,
    AttachedRenewManualPausedRecurrentFx,
};
