import { createEffect } from 'effector/effector.umd';
import { StudentApi } from '@common/entities/student';
import waitForResult from '@common/helpers/waitForResult';
const pullingStudentStatusDataFx = createEffect(() => {
    return waitForResult(StudentApi.getStudentsStatusFx, {
        params: [],
        handleResponse: (params) => {
            const { status } = params;
            return status === 'WAIT';
        },
        handleError: () => false,
        callInterval: 3000,
    });
});
export const StudentProfileApi = {
    pullingStudentStatusDataFx,
};
