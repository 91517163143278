import React from 'react';
import { useUnit } from 'effector-react';
import { ModalRoot } from '@vkontakte/vkui';
import { MODAL_ID_MODAL_MANAGER_CARD_1, MODAL_ID_MODAL_MANAGER_CARD_2, MODAL_ID_MODAL_MANAGER_PAGE_1, MODAL_ID_MODAL_MANAGER_PAGE_2, MODAL_ID_MODAL_MANAGER_PAGE_WITH_FIXED_FOOTER_1, MODAL_ID_MODAL_MANAGER_PAGE_WITH_FIXED_FOOTER_2, ModalManagerCard, ModalManagerPage, ModalManagerPageWithFixedFooter, vkUIModalManager, } from '@common/services/vk-ui-modal-manager';
export const RootModals = () => {
    const modalId = useUnit(vkUIModalManager.$activeId);
    const closeModal = useUnit(vkUIModalManager.closeModal);
    const closeModalGeneral = () => {
        closeModal();
    };
    return (<ModalRoot activeModal={modalId} onClose={closeModalGeneral}>
      
      <ModalManagerCard id={MODAL_ID_MODAL_MANAGER_CARD_1}/>
      <ModalManagerCard id={MODAL_ID_MODAL_MANAGER_CARD_2}/>
      <ModalManagerPage id={MODAL_ID_MODAL_MANAGER_PAGE_1}/>
      <ModalManagerPage id={MODAL_ID_MODAL_MANAGER_PAGE_2}/>
      <ModalManagerPageWithFixedFooter id={MODAL_ID_MODAL_MANAGER_PAGE_WITH_FIXED_FOOTER_1} settlingHeight={100}/>
      <ModalManagerPageWithFixedFooter id={MODAL_ID_MODAL_MANAGER_PAGE_WITH_FIXED_FOOTER_2} settlingHeight={100}/>
    </ModalRoot>);
};
