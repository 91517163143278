import { createEffect, createEvent, createStore, sample } from 'effector';
import Router from 'next/router';
export const $notificationPageData = createStore(null);
export const openNotificationPage = createEvent();
const openNotificationPageFx = createEffect((payload) => {
    void Router.push(`/${payload.mode}`);
});
$notificationPageData.on(openNotificationPage, (_state, payload) => payload);
sample({
    clock: [openNotificationPage],
    target: openNotificationPageFx,
});
