import { defineMessages } from '@formatjs/intl';
export const showWinbackCancelSubscription = defineMessages({
    header: {
        id: 'showWinbackCancelSubscriptionHeader',
        defaultMessage: '{price} ₽ за следующий месяц',
    },
    subheader: {
        id: 'showWinbackCancelSubscriptionSubheader',
        defaultMessage: 'Дарим вам скидку на следующий месяц! Оставайтесь, чтобы слушать музыку и аудиокниги без ограничений.',
    },
    confirmButton: {
        id: 'showWinbackCancelSubscriptionConfirmButton',
        defaultMessage: 'Всё равно отменить',
    },
    cancelButton: {
        id: 'showWinbackCancelSubscriptionCancelButton',
        defaultMessage: 'Остаться со скидкой',
    },
});
