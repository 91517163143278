import React from 'react';
import { ButtonGroup } from '@vkontakte/vkui';
import { modalManagerModel } from '@common/services/modal-manager';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import Typograf from '@common/components/common/Typograf';
import { LinkCardButton } from '@common/features/show-unbound-card-info-modal/ui';
export const showUnboundCardInfoModal = (props) => {
    const { showModalAnalytics, ...linkCardProps } = props;
    modalManagerModel.showModal({
        analyticShowAttribute: showModalAnalytics,
        icon: <Icon56ErrorOutline style={{ color: '#E64646' }}/>,
        header: <Typograf>Добавьте способ оплаты</Typograf>,
        subheader: <Typograf>Выберите удобный способ оплаты, чтобы подписка продлевалась автоматически</Typograf>,
        actions: (<ButtonGroup mode='vertical' gap='m' stretched={true}>
        <LinkCardButton {...linkCardProps}/>
      </ButtonGroup>),
    });
};
