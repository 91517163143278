import { useUnit } from 'effector-react';
import React from 'react';
import { useAuthorizationChecker } from '@common/entities/auth-state';
import { HASH_PART_STUDENTS } from './lib';
import { StartStudentFlowModel } from '@common/features/start-student-flow';
import { ParamsOrHashGetter } from '@common/utils/params-or-hash-getter';
const UrlHashChecker = (props) => {
    const { children, hash, confirmedStatusCallback, cleanHash } = props;
    const startStudentFlow = useUnit(StartStudentFlowModel.startFlow);
    const isStudentHash = new ParamsOrHashGetter(hash).has(HASH_PART_STUDENTS);
    const authorizedCallback = () => {
        if (!isStudentHash) {
            return;
        }
        startStudentFlow({ strategyCallback: confirmedStatusCallback });
        cleanHash();
    };
    useAuthorizationChecker({ authorizedCallback }, [isStudentHash]);
    return <>{children}</>;
};
export default UrlHashChecker;
