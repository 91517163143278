import React from 'react';
import { useUnit } from 'effector-react';
import { analyticsDictionary } from '@analytics';
import { modalManagerModel, modalManagerTools } from '@common/services/modal-manager';
import { intl } from '@common/shared/lib/intl';
import { showSubscriptionCancelReasonModal } from '@common/entities/subscriptions';
import { showAbTestDeactivateTrialSuccessModal } from '../show-ab-test-deactivate-trial-success-modal';
import { analytics } from './analytics';
import { messages } from './messages';
const cancelSubscriptionMethod = (subscriptionId) => {
    showSubscriptionCancelReasonModal({
        subscriptionId,
        successHandle: () => showAbTestDeactivateTrialSuccessModal({
            analyticShowAttribute: analyticsDictionary.modalSubscriptionCancelSuccess,
        }),
    });
};
const Actions = (props) => {
    const closeModal = useUnit(modalManagerModel.closeModal);
    return modalManagerTools.uiModalTemplates.TwoButtonTemplate({
        firstButton: {
            analyticClick: analytics.confirm,
            children: intl.formatMessage(messages.confirmButton),
            handler: () => cancelSubscriptionMethod(props.subscriptionId),
            mode: 'secondary',
        },
        secondButton: {
            analyticClick: analytics.cancel,
            children: intl.formatMessage(messages.cancelButton),
            handler: closeModal,
        },
    });
};
export const showAbTestDeactivateTrialStopRecurrentWarningModal = (props) => {
    modalManagerTools.modalTemplates.showMessageModal({
        analyticShowAttribute: analytics.show,
        mode: 'warning',
        header: intl.formatMessage(messages.header),
        subheader: intl.formatMessage(messages.subheader),
        actions: <Actions {...props}/>,
    });
};
