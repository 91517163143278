import { createEffect, createEvent, sample } from 'effector';
import { HASH_PART_STUDENTS_BY_EMAIL, HASH_PART_STUDENTS_BY_EMAIL_TOKEN, } from '@common/features/student/ui/url-hash-checker/lib';
import { emailStudentFlowModel } from './email-student-flow';
import { ParamsOrHashGetter } from '@common/utils/params-or-hash-getter';
export const checkHashAndStartFlow = createEvent();
const checkHashFx = createEffect((payload) => {
    const { hash, callbackAfterFinishedFlow } = payload;
    const paramsOrHashGetter = new ParamsOrHashGetter(hash);
    if (paramsOrHashGetter.has(HASH_PART_STUDENTS_BY_EMAIL)) {
        emailStudentFlowModel.startEmailStudentFlow({ callbackAfterFinishedFlow });
        return;
    }
    if (paramsOrHashGetter.has(HASH_PART_STUDENTS_BY_EMAIL_TOKEN)) {
        emailStudentFlowModel.startConfirmingEmailFlow({ hash, callbackAfterFinishedFlow });
        return;
    }
});
sample({ clock: checkHashAndStartFlow, target: checkHashFx });
