import React from 'react';
import ModalButton from '@common/components/modals/ModalButton';
import { modalManagerModel } from '@common/services/modal-manager';
import { studentAnalytics } from './analytics';
import { createAnalyticClickAttribute } from '@analytics';
import StudentsRejected from '@common/entities/student/icons/StudentsRejected';
export const showDescriptionAdditionalLoadingModal = (params) => {
    const { title = 'Загрузите документы ещё раз', body = 'Загрузите документы, чтобы отправить заявку повторно', button = 'Загрузить', onClick, } = params;
    modalManagerModel.showModal({
        analyticShowAttribute: studentAnalytics.retry.show(null),
        icon: <StudentsRejected />,
        header: title,
        subheader: body,
        actions: (<ModalButton onClick={onClick} {...createAnalyticClickAttribute(studentAnalytics.retry.click(null))}>
        {button}
      </ModalButton>),
    });
};
