import React from 'react';
export const ErrorIcon = () => {
    return (<svg xmlns='http://www.w3.org/2000/svg' width='120' height='111' fill='none' viewBox='0 0 120 111'>
      <mask id='mask0_526_1598' style={{ maskType: 'luminance' }} width='120' height='111' x='0' y='0' maskUnits='userSpaceOnUse'>
        <path fill='#fff' d='M119.914 53.182v4.645c0 24.802.062 37.234-7.662 44.949-7.724 7.715-20.156 7.724-45.02 7.724H52.83c-24.873 0-37.306 0-45.03-7.724C.077 95.052.086 82.629.086 57.827v-4.645c0-24.81 0-37.234 7.715-44.958C15.517.5 27.958.5 52.831.5h14.4c16.111 0 27.058 0 34.836 2.112l.268.072a21.57 21.57 0 019.917 5.54c6.265 6.328 7.411 15.806 7.617 32.651.045 3.724.045 7.814.045 12.307z'></path>
      </mask>
      <g mask='url(#mask0_526_1598)'>
        <path fill='#2787F5' d='M131.567 60.351v5.11c0 27.264.072 40.931-8.422 49.416s-22.152 8.485-49.487 8.485H57.825c-27.353 0-41.02 0-49.505-8.485S-.165 92.725-.165 65.462v-5.11c0-27.273 0-40.931 8.485-49.425s22.152-8.494 49.505-8.494h15.833c17.73 0 29.742 0 38.29 2.327l.295.072a23.658 23.658 0 0110.902 6.095c6.9 6.955 8.153 17.373 8.377 35.891.045 4.1.045 8.593.045 13.533z'></path>
        <path fill='#0009B4' d='M135.657 69.57c-12.056 2.569-26.117 12.665-38.11 30.664 0 0-9.774 7.107-4.36 23.056 17.382-.295 27.344-1.701 34.012-8.413 8.091-8.047 8.449-20.765 8.458-45.307z'></path>
        <path fill='#212121' d='M126.922 9.46a25.06 25.06 0 00-11.546-6.454h-.269l-.044-.063C106.22-5.9 83.414-10.044 73.649.483c-4.323 4.671-6.032 11.42-2.685 19.976-6.265 1.182-11.993 5.2-15.574 12.683-2.685 5.612-1.987 12.629 1.092 19.35a18.053 18.053 0 01.206 14.456c-.179.42-.349.895-.51 1.306-3.464 9.667-2.685 19.458 5.227 28.516 14.32 16.317 45.056 9.846 40.859-16.021a32.934 32.934 0 00-5.666-13.775 18.297 18.297 0 005.674-6.936c3.894.895 11.475.224 25.822-7.205a33.729 33.729 0 007.519-5.263c-.242-19.628-1.379-30.745-8.691-38.11z'></path>
        <path fill='#F45FFF' d='M98.54 85.224c-3.983-11.242-9.845-22.447-21.48-18.617-17.284 6.982-20.864 19.512-30.763 38.165-.045.071-3.912-7.545-3.912-7.545l-28.91 10.74a71.488 71.488 0 005.452 11.385c8.717 3.992 21.704 3.992 42.961 3.992h15.834c11.751 0 20.98 0 28.354-.671-.931-11.851-5.325-24.22-7.536-37.449z'></path>
        <path fill='#FFA9A9' d='M107.517 52.314c-3.329 3.455 2.184 12.978 5.952 15.502-8.207 1.288 0-6.471-28.74 12.88-14.078 13.64-20.997 11.635-17.497 6.703 5.03-7.062 14.66-16.316 14.66-16.316s6.543-8.468.699-13.56c-.331-.287-.331-.815-.672-1.101-8.905-7.608 25.598-4.108 25.598-4.108z'></path>
        <path fill='#FF8481' d='M81.722 71.253c4.896-6.543 1.7-15.27-1.145-17.48-4.816-3.787 1.79 2.685 8.592 2.031l11.081-1.36c5.182-.314-2.596 15.94-18.528 16.809z'></path>
        <path fill='#FFA9A9' d='M109.298 41.77c-1.897 1.334-4.233-2.488-4.26-17.006a10.984 10.984 0 00-3.777-8.1 10.742 10.742 0 00-15.278 1.29c-5.755 6.846-19.136 25.132-9.73 37.528 3.661 4.833 11.582 6.06 16.04 5.165 8.368-1.79 9.845-6.731 12.656-7.465a20.384 20.384 0 007.697-4.135c4.833-4.77.778-10.168-3.348-7.277z'></path>
        <path fill='#231F20' d='M95.085 30.627c-.751 1.584.17 1.557 2.13 2.032 1.54.367 2.086-.143 1.674-1.844a2.023 2.023 0 00-1.835-1.45 1.97 1.97 0 00-1.181.322c-.354.224-.63.552-.788.94zM79.968 29.257c-.331 1.71.555 1.468 2.569 1.432 1.584 0 1.978-.67 1.163-2.21a2.04 2.04 0 00-.895-.815 2.095 2.095 0 00-1.217-.125 1.97 1.97 0 00-1.065.609c-.299.3-.493.69-.555 1.11z'></path>
        <path fill='#FF8481' d='M89.053 35.621c.635-.716-1.71-.814-1.549-2.622.26-3.026 4.153-5.37 3.231-5.88-1.494-.77-4.367 2.684-5.701 4.985-.868 1.521 3.052 4.61 4.019 3.517z'></path>
        <path fill='#231F20' d='M84.9 19.269c-1.298.528-3.035 3.141-3.017 3.983a23.27 23.27 0 013.151-.278c1.36 0 3.142-.385 2.98-2.076-.107-1.012-1.342-1.772-3.114-1.63zM105.423 23.753c.761-1.459-2.291-3.858-4.699-5.218-1.95-1.1-3.714-.895-4.206.242-.672 1.557.895 2.461 2.21 2.882 4.171 1.405 5.943 3.598 6.695 2.094z'></path>
        <path fill='#F45FFF' d='M131.325 109.184a85.462 85.462 0 00-1.172-30.807c-3.724-15.475-15.305-22.313-30.664-11.009-11.743 7.859-20.97 20.488-28.803 31.73 3.5.098 7.34 2.299 7.859 5.915a87.808 87.808 0 004.564 18.349c23.791 0 36.223-.573 44.126-8.485a21.73 21.73 0 004.09-5.693z'></path>
        <path fill='#70059E' d='M86.144 115.512a.433.433 0 01-.412-.268l-5.039-11.636a.455.455 0 01.242-.59.445.445 0 01.582.241l4.475 10.32c1.1-5.621 5.432-25.804 12.459-34.414a.457.457 0 01.635-.063.447.447 0 01.063.627c-7.886 9.666-12.53 35.184-12.53 35.443a.457.457 0 01-.394.367l-.081-.027z'></path>
        <path fill='#231F20' d='M92.159 43.077c0 1.343-.761 2.363-1.96 2.363a3.293 3.293 0 01-2.811-2.363c-.627-1.683.206-2.793 1.79-2.793a2.891 2.891 0 012.98 2.793z'></path>
        <path fill='#70059E' d='M65.803 111.847c8.715-3.184 15.287-7.113 14.68-8.775-.607-1.662-8.165-.429-16.88 2.755-8.714 3.184-15.287 7.113-14.68 8.775.608 1.662 8.165.429 16.88-2.755zM28.95 105.604c7.981-2.916 13.96-6.627 13.352-8.29-.608-1.662-7.57-.645-15.551 2.27-7.981 2.917-13.96 6.628-13.352 8.29.607 1.662 7.57.646 15.551-2.27z'></path>
        <path fill='#FFA9A9' d='M56.214 70.367s-16.415-15.816-17.9-14.858c-4.127 2.578 13.29 17.9 13.29 17.9l4.61-3.042z'></path>
        <path fill='#FFA9A9' d='M80.8 103.402c-1.933-3.159-7.68-15.314-11.734-19.923C58.961 71.987 53.61 67.95 52.76 67.95c-5.791-1.226-7.662-2.98-9.29 5.836-1.057 4.233-1.012 8.52 1.11 10.498 2.12 1.978 7.607 6.588 9.415 8.172 1.808 1.584 5.862 12.62 7.33 20.747 8.467-1.736 18.572-6.498 19.476-9.801z'></path>
        <path fill='#FF8481' d='M60.617 109.694c.287 1.2.582 2.686.788 3.786 8.628-2.192 18.527-6.811 19.431-10.078-2.541 3.947-18.169 7.349-20.219 6.266v.026zM16.85 66.428S-2.93 45.395-2.93 52.868c.313 2.077 13.318 19.96 13.318 19.96l6.462-6.4z'></path>
        <path fill='#FFA9A9' d='M42.412 97.227c-6.175-13.48-11.778-20.488-14.5-23.352-5.316-5.585-12.082-10.06-18.267-10.57-6.184-.51-9.997.725-9.532 3.58a22.125 22.125 0 002.739 6.543c-.063.895.582 4.886 3.043 6.605 1.745 1.226 3.688 2.542 5.37 3.697a21.391 21.391 0 018.503 11.322c1.209 3.804 3.106 9.049 4.046 12.324 7.446-1.369 19.502-6.936 18.598-10.15z'></path>
        <path fill='#FF8481' d='M17.897 70.16c-1.154 2.39-3.58.761-5.092 2.82-.314.43-2.462 3.75.178 5.191 8.494 4.95 8.28 6.265 17.99-.528-.187-7.393-12.664-9.944-14.669-10.91 2.05 3.105 1.647 1.145 1.593 3.428z'></path>
        <path fill='#231F20' d='M25.049 79.362l4.475-1.271c1.853-.52 3.455-1.092 3.92-1.88.492-.937.822-1.95.976-2.998-.305-1.942-6.212-2.059-9.846-7.554l-8.162-12.406c-3.58-5.495-5.424-8.26-7.375-9.738-1.952-1.476-3.285-1.1-5.961-.358l-1.549.439c-1.53.42-1.888-1.038-2.48-.483-.447.402-.778 2.98-.572 4.01.618 2.55 1.441 4.26 5.066 9.764l8.163 12.406c3.58 5.495 5.424 8.252 7.384 9.737 1.96 1.486 3.293 1.092 5.96.332z'></path>
        <path fill='#231F20' d='M26.069 77.267l4.591-1.387c2.686-.752 4.001-1.128 3.706-3.07-.296-1.942-2.112-4.7-5.728-10.204l-8.163-12.405c-3.58-5.495-5.433-8.252-7.375-9.738-1.943-1.486-3.294-1.1-5.97-.358l-4.583 1.397c-2.685.742-4.01 1.118-3.714 3.06.295 1.943 2.112 4.708 5.728 10.23l8.163 12.397c3.58 5.505 5.424 8.252 7.384 9.738 1.96 1.486 3.293 1.137 5.96.34z'></path>
        <path fill='#231F20' d='M11.019 43.674c.189-.151.172-.487-.038-.75-.21-.262-.533-.352-.723-.2-.189.15-.172.486.038.749.21.262.534.352.723.201zM9.028 44.053L5.07 45.297a.349.349 0 01-.411-.215.286.286 0 01.17-.394l3.947-1.244a.366.366 0 01.448.332.286.286 0 01-.197.276z'></path>
        <path fill='#F45FFF' d='M28.62 70.984l-6.033 1.79a1.307 1.307 0 01-1.485-.671.896.896 0 01.527-1.271l6.033-1.79a1.316 1.316 0 011.495.662.896.896 0 01-.537 1.28z'></path>
        <path fill='#FFA9A9' d='M17.566 70.662c.277-.895-.608-2.533-2.757-3.231.063-1.271-.188-2.551-4.117-3.679a14.865 14.865 0 00-7.778 0S9.878 78.27 11.175 74.95c1.45-3.347 7.805-.465 6.39-4.287z'></path>
        <path fill='#FF8481' d='M14.97 67.502c-2.309-1.485-13.22-4.34-13.112 4.082l1.03 1.861c.447-5.593 2.98-8.637 12.082-5.942z'></path>
        <path fill='#FF8481' d='M4.06 77.894a6.176 6.176 0 001.835 2.157c-.098-.17-1.521-2.085.224-6.758 1.074-2.909 5.996-4.251 11.447-2.631-14.311-4.18-13.595 4.135-13.506 7.232zM42.403 97.227c-3.222 4.126-17.274 7.348-19.69 6.462.447 1.36.895 2.685 1.163 3.705 7.384-1.387 19.44-6.954 18.527-10.167z'></path>
        <path fill='#FF3985' d='M16.43 61.568a9.111 9.111 0 01-6.928-3.813c-2.3-3.114-1.97-6.677.734-7.939a6.05 6.05 0 014.68 0 9.702 9.702 0 014.378 3.33 7.008 7.008 0 011.512 4.672 3.858 3.858 0 01-2.247 3.267 4.968 4.968 0 01-2.13.483zM12.911 50.99a3.75 3.75 0 00-1.567.331A2.827 2.827 0 009.7 53.728a5.075 5.075 0 001.11 3.419c1.682 2.291 4.663 3.401 6.64 2.47a2.82 2.82 0 001.648-2.399 5.147 5.147 0 00-1.11-3.428 7.08 7.08 0 00-3.213-2.443 5.478 5.478 0 00-1.862-.358z'></path>
        <path fill='#FF3985' d='M19.67 58.525L8.883 53.871l.25-1.459 10.786 4.654-.25 1.46z'></path>
      </g>
    </svg>);
};
