import { useEffect, useState } from 'react';
export const useDevicePixelRatio = () => {
    const [devicePixelRatio, setDevicePixelRatio] = useState(1);
    useEffect(() => {
        try {
            const devicePixelRatio = window.devicePixelRatio;
            if (typeof devicePixelRatio === 'number' && devicePixelRatio > 1) {
                setDevicePixelRatio(devicePixelRatio);
            }
        }
        catch { }
    }, []);
    return devicePixelRatio;
};
