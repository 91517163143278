export const createOptionList = (array) => {
    return array.map((item) => {
        const descriptionItems = [];
        if ('region' in item && item.region) {
            descriptionItems.push(item.region);
        }
        if ('region' in item && item.area) {
            descriptionItems.push(item.area);
        }
        return {
            value: item.id,
            label: item.title,
            description: descriptionItems.join(', ') || undefined,
        };
    });
};
export const createOptionListEstablishments = (array) => {
    return array.map((item) => {
        const description = item.type;
        return {
            value: item.id,
            label: item.title,
            description,
        };
    });
};
