import { intl } from '@common/shared/lib/intl';
import { modalManagerModel } from '@common/services/modal-manager';
import { showCancelSubscriptionPayloadTemplate } from '../show-cancel-subscription-payload-template';
import messages from './messages';
import { analyticsDictionary } from './lib';
export const showCancelGroupSubscriptionModal = (params) => {
    const { cancelSubscriptionHandler } = params;
    const content = {
        analyticsShow: analyticsDictionary.show,
        header: intl.formatMessage(messages.header),
        subheader: intl.formatMessage(messages.subheaderSuspendedGroupSub),
    };
    return showCancelSubscriptionPayloadTemplate({
        ...content,
        firstButton: {
            analyticClick: analyticsDictionary.buttonConfirm,
            handler: modalManagerModel.closeModal,
            mode: 'primary',
            children: intl.formatMessage(messages.leaveSubForTwoBtnText),
        },
        secondButton: {
            analyticClick: analyticsDictionary.buttonCancel,
            handler: async () => {
                await cancelSubscriptionHandler();
            },
            mode: 'secondary',
            children: intl.formatMessage(messages.cancelSubBtnText),
        },
    });
};
