import React, { useRef } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
const SkeletonItem = (props) => {
    const { className, classNameBlink, style } = props;
    const refContainer = useRef(null);
    return (<div ref={refContainer} className={classNames(styles.container, className)} style={style}>
      <div className={classNames(styles.blink, classNameBlink)}/>
    </div>);
};
export default SkeletonItem;
