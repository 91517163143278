import { createEvent, createStore } from 'effector';
import ApiCommon from '@common/utils/ApiCommon';
import { writePrivacyPolicyInLocalStorage } from '../lib/write-privacy-policy-in-local-storage';
import { PRIVACY_VERSION } from '@common/consts';
import { modalManagerModel } from '@common/services/modal-manager';
const $isAccepted = createStore(false);
const onChangeAcceptedCheckBox = createEvent();
$isAccepted.on(onChangeAcceptedCheckBox, (state) => !state);
const closeModal = modalManagerModel.closeModal;
const onClickAcceptPrivacyPolicy = async () => {
    try {
        closeModal();
        await ApiCommon.privacyAccept();
        writePrivacyPolicyInLocalStorage(PRIVACY_VERSION);
    }
    catch {
        closeModal();
    }
};
export const privacyPolicyModel = {
    $isAccepted,
    onChangeAcceptedCheckBox,
    onClickAcceptPrivacyPolicy,
};
