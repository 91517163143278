import { useState } from 'react';
import { useUnit } from 'effector-react';
import classNames from 'classnames';
import { UserModel } from 'vk-music-site/src/entities/user/model';
import { camera200Image } from './images/camera/camera_200';
import styles from './styles.module.scss';
import { CustomImg } from '@apps/common/shared/ui/custom-img';
const UserAvatar = ({ className, height, width }) => {
    const avatar = useUnit(UserModel.$avatar);
    const [errorLoadImage, setErrorLoadImage] = useState(false);
    const style = height || width ? { height, width, minHeight: height, minWidth: width } : {};
    const common = {
        className: classNames(styles.userAvatar, className),
        style: style,
        alt: 'Аватар пользователя',
    };
    if (!errorLoadImage && avatar) {
        return (<img {...common} src={avatar} onError={() => setErrorLoadImage(true)}/>);
    }
    return (<CustomImg {...common} {...camera200Image}/>);
};
export default UserAvatar;
