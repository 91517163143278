const STATE_TO_API_PARAMS = {
    lastName: 'last_name',
    firstName: 'first_name',
    middleName: 'middle_name',
    cityId: 'city_id',
    cityName: 'city_name',
    establishmentId: 'establishment_id',
    establishmentName: 'establishment_name',
    establishmentType: 'establishment_type',
};
export const mapFormFieldToApiTypeParams = (formFields) => {
    return Object.keys(STATE_TO_API_PARAMS).reduce((studentFormParams, fieldKey) => {
        const apiParamsKey = STATE_TO_API_PARAMS[fieldKey];
        return { ...studentFormParams, [apiParamsKey]: formFields[fieldKey] };
    }, {});
};
