import React from 'react';
import { useUnit } from 'effector-react';
import { getSubscriptionById } from '@common/entities/subscriptions';
import { AbTestLkUntieCardModel } from '@common/features/ab-test-lk-untie-card';
import { AbTestLkUntieCardV2Model } from '@common/features/ab-test-lk-untie-card-v2/model';
import { SubscriptionDetailsContext, useGetIsShowNewCancelButtonType } from '../lib';
import { Button } from './button';
export const Footer = (props) => {
    const { subscriptionId, contextProps } = props;
    const subscription = useUnit(getSubscriptionById(subscriptionId));
    const { cancelButtonSubscriptionType } = useUnit(AbTestLkUntieCardModel.$chosenVariant);
    const useGetIsShowNewCancelButtonTypeResult = useGetIsShowNewCancelButtonType(cancelButtonSubscriptionType, subscription);
    const abTestNeedCustomizeSubscriptionDetail = AbTestLkUntieCardV2Model.useNeedCustomizeSubscriptionDetail();
    const isShowButtonInFooter = Boolean(subscription &&
        (cancelButtonSubscriptionType === 'old' || !useGetIsShowNewCancelButtonTypeResult) &&
        !abTestNeedCustomizeSubscriptionDetail);
    return (<>
      {subscription && isShowButtonInFooter && (<SubscriptionDetailsContext.Provider value={{ ...contextProps, subscription: subscription }}>
          <Button />
        </SubscriptionDetailsContext.Provider>)}
    </>);
};
