import React from 'react';
import { Icon56ErrorOutline } from '@vkontakte/icons';
import ModalButton from '@common/components/modals/ModalButton';
import { modalManagerModel } from '../../model';
export const alertPayloadTemplate = {
    icon: <Icon56ErrorOutline color={'var(--vkui--color_accent_red)'}/>,
    header: 'Упс',
    subheader: 'Что то пошло не так',
    actions: (<ModalButton size='l' mode='primary' onClick={() => modalManagerModel.closeModal()}>
      Хорошо
    </ModalButton>),
};
