import { createEvent, createStore } from 'effector';
const $correlationIdInProgress = createStore(null);
const setInProgress = createEvent();
const setReady = createEvent();
$correlationIdInProgress.on(setInProgress, (_state, correlationId) => correlationId);
$correlationIdInProgress.on(setReady, () => null);
export const ApplyOfferModel = {
    $correlationIdInProgress,
    setInProgress,
    setReady,
};
