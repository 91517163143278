export const messages = {
    mobile: {
        title: 'Сделайте селфи с зачётной книжкой',
        subtitle: 'Сфотографируйтесь с раскрытым документом так, чтобы ваше лицо и зачетка были хорошо видны',
        uploadButtonTitle: 'Сделать селфи с зачётной книжкой',
    },
    desktop: {
        title: 'Сделайте селфи с зачётной книжкой',
        subtitle: 'Сфотографируйтесь с раскрытым документом так, чтобы ваше лицо и зачетка были хорошо видны',
        uploadButtonTitle: 'Прикрепить фото',
    },
};
