class HistoryClass {
    constructor() {
        this._navigate = null;
    }
    get navigate() {
        return this._navigate;
    }
    set navigate(navigate) {
        this._navigate = navigate;
    }
    to(to) {
        if (!this._navigate) {
            return;
        }
        this._navigate(to);
    }
}
export const history = new HistoryClass();
