import React from 'react';
import { useUnit } from 'effector-react';
import { unlinkCardModel, } from '@common/widgets/payment-method-widget/ui/current-payment-method/lib/modals/showUnlinkCardReasonModal/model';
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';
import { Checkbox, Headline, Text, Textarea } from '@vkontakte/vkui';
import Typograf from '@common/components/common/Typograf';
import messages from '@common/widgets/payment-method-widget/ui/current-payment-method/lib/modals/showUnlinkCardReasonModal/messages';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
export const Content = () => {
    const [form, setOption, toggleOption] = useUnit([
        unlinkCardModel.$form,
        unlinkCardModel.setOption,
        unlinkCardModel.toggleOption,
    ]);
    const handleToggle = (key) => () => {
        toggleOption(key);
    };
    const handleSetValue = (key) => (event) => {
        setOption({ key, value: event.target.value });
    };
    const intl = useIntl();
    return (<div className={styles.container}>
      <Headline className={styles.title} Component='h4'>
        <Typograf>{intl.formatMessage(messages.subtitle)}</Typograf>
      </Headline>
      <section className={styles.section}>
        <Checkbox {...createAnalyticClickAttribute(analyticsDictionary.modalPaymethodCancelReasonClick('no_advantage'))} className={styles.checkbox} checked={form.noAdvantageChecked} onChange={handleToggle('noAdvantageChecked')}>
          <Typograf>{intl.formatMessage(messages.noAdvantageText)}</Typograf>
        </Checkbox>
        {form.noAdvantageChecked && (<Text className={styles.sectionDescription}>
            <Typograf>{intl.formatMessage(messages.noAdvantageDescription)}</Typograf>
          </Text>)}
      </section>

      <section className={styles.section}>
        <Checkbox {...createAnalyticClickAttribute(analyticsDictionary.modalPaymethodCancelReasonClick('bad_price'))} className={styles.checkbox} checked={form.badPriceChecked} onChange={handleToggle('badPriceChecked')}>
          <Typograf>{intl.formatMessage(messages.badPriceText)}</Typograf>
        </Checkbox>
        {form.badPriceChecked && (<Text className={styles.sectionDescription}>
            <Typograf>{intl.formatMessage(messages.badPriceDescription)}</Typograf>
          </Text>)}
      </section>

      <section className={styles.section}>
        <Checkbox {...createAnalyticClickAttribute(analyticsDictionary.modalPaymethodCancelReasonClick('tech_problems'))} className={styles.checkbox} checked={form.techProblemsChecked} onChange={handleToggle('techProblemsChecked')}>
          <Typograf>{intl.formatMessage(messages.techProblemsText)}</Typograf>
        </Checkbox>
        {form.techProblemsChecked && (<Text className={styles.sectionDescription}>
            <Typograf>{intl.formatMessage(messages.techProblemsDescription)}</Typograf>
          </Text>)}
      </section>

      <section className={styles.section}>
        <Checkbox {...createAnalyticClickAttribute(analyticsDictionary.modalPaymethodCancelReasonClick('another_subscription'))} className={styles.checkbox} checked={form.anotherSubscriptionChecked} onChange={handleToggle('anotherSubscriptionChecked')}>
          <Typograf>{intl.formatMessage(messages.anotherSubscriptionText)}</Typograf>
        </Checkbox>
        {form.anotherSubscriptionChecked && (<Textarea className={styles.sectionTextArea} value={form.anotherSubscriptionText} placeholder={intl.formatMessage(messages.anotherSubscriptionPlaceholder)} onChange={handleSetValue('anotherSubscriptionText')}/>)}
      </section>

      <section className={styles.section}>
        <Checkbox {...createAnalyticClickAttribute(analyticsDictionary.modalPaymethodCancelReasonClick('other'))} className={styles.checkbox} checked={form.otherChecked} onChange={handleToggle('otherChecked')}>
          <Typograf>{intl.formatMessage(messages.otherSubscriptionText)}</Typograf>
        </Checkbox>
        {form.otherChecked && (<Textarea className={styles.sectionTextArea} value={form.otherText} placeholder={intl.formatMessage(messages.otherSubscriptionPlaceholder)} onChange={handleSetValue('otherText')}/>)}
      </section>
    </div>);
};
