import React from 'react';
import { Text } from '@vkontakte/vkui';
import { Icon16CheckOutline } from '@vkontakte/icons';
import Typograf from '@common/components/common/Typograf';
import styles from './styles.module.scss';
const BENEFIT_TYPE_COMPONENTS = {
    title: (content) => (<Text className={styles.benefitTitle}>
      <Typograf>{content}</Typograf>
    </Text>),
    list_item: (content) => (<div className={styles.benefitListItem}>
      <Icon16CheckOutline className={styles.benefitListItemIcon}/>

      <Text className={styles.benefitListItemText}>
        <Typograf>{content}</Typograf>
      </Text>
    </div>),
};
const getBenifit = (type, content) => BENEFIT_TYPE_COMPONENTS[type](content) ?? null;
const Benefits = ({ benefits }) => {
    return (<ul className={styles.benefits}>
      {benefits.map(({ type, content }, index) => (<li key={`${content}_${index}`}>{getBenifit(type, content)}</li>))}
    </ul>);
};
export default Benefits;
