import React from 'react';
import { modalManagerModel, modalManagerTools } from '@common/services/modal-manager';
import { getProcessingBackendErrorsActions } from './lib';
import { Icon96ComboHeadphone, Icon96MusicHeadphone } from '@common/shared/ui/icons';
export const processingBackendErrors = (params) => {
    let icon = null;
    if (params.code) {
        switch (params.code) {
            case 'has_active_vk_combo': {
                icon = <Icon96ComboHeadphone />;
                break;
            }
            case 'has_active_vk_music': {
                icon = <Icon96MusicHeadphone />;
                break;
            }
            default: {
                icon = null;
            }
        }
        modalManagerModel.showModal({
            analyticShowAttribute: '',
            icon,
            header: params.title,
            subheader: params.description,
            actions: getProcessingBackendErrorsActions({ buttons: params?.buttons }),
        });
        return;
    }
    modalManagerTools.modalTemplates.showMessageModal({
        mode: 'error',
        analyticShowAttribute: '',
        header: params.title,
        subheader: params.description,
        actions: getProcessingBackendErrorsActions({ buttons: params?.buttons }),
    });
};
