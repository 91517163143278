import React from 'react';
export const SuccessIcon = () => {
    return (<svg xmlns='http://www.w3.org/2000/svg' width='196' height='129' fill='none' viewBox='0 0 196 129'>
      <path fill='#2787F5' d='M5.415 61.286c-4.013-.402-5.037-2.98-4.51-4.833.529-1.852 3.004-3.57 6.441-1.876 2.491 1.222 4.399 2.894 3.87 4.73-.527 1.837-3.034 2.255-5.801 1.979zM113.583 128.469c25.675 0 38.508 0 46.51-7.718 8.001-7.717 7.977-20.133 7.977-44.933v-4.58c0-24.832 0-37.248-7.977-44.933-7.978-7.686-20.812-7.71-46.51-7.71H85.598c-25.675 0-38.509 0-46.51 7.71-8.001 7.71-7.978 20.133-7.978 44.972v4.58c0 24.832 0 37.248 7.978 44.933 7.978 7.686 20.803 7.71 46.51 7.71l27.985-.031z'></path>
      <mask id='mask0_526_1872' style={{ maskType: 'alpha' }} width='138' height='111' x='31' y='18' maskUnits='userSpaceOnUse'>
        <path fill='#000' d='M113.583 128.469c25.675 0 38.508 0 46.51-7.718 8.001-7.717 7.977-20.133 7.977-44.933v-4.58c0-24.832 0-37.248-7.977-44.933-7.978-7.686-20.812-7.71-46.51-7.71H85.598c-25.675 0-38.509 0-46.51 7.71-8.001 7.71-7.978 20.133-7.978 44.972v4.58c0 24.832 0 37.248 7.978 44.933 7.978 7.686 20.803 7.71 46.51 7.71l27.985-.031z'></path>
      </mask>
      <g mask='url(#mask0_526_1872)'>
        <path fill='#F45FFF' d='M126.944 82.045c19.306 1.04 44.074 13.11 40.685 49.364l-146.065 1.75c16.12-54.078 44.736-51.43 72.208-51.405l33.172.291z'></path>
        <mask id='mask1_526_1872' style={{ maskType: 'alpha' }} width='147' height='53' x='21' y='81' maskUnits='userSpaceOnUse'>
          <path fill='#07F' d='M126.944 82.045c19.306 1.04 44.074 13.11 40.685 49.364l-146.065 1.75c16.12-54.078 44.736-51.43 72.208-51.405l33.172.291z'></path>
        </mask>
        <g fill='#70059E' mask='url(#mask1_526_1872)'>
          <path d='M160.226 73.69h-12.471l.063.528c.237 2.782.466 5.573.789 8.324.433 3.815.867 7.662 1.458 11.478.631 4.115 1.498 8.159 2.223 12.242l.103.662h12.904l-.134-.496c-2.877-10.753-3.941-21.726-4.935-32.739zM123.838 73.72h-12.471c.134 1.46.229 2.855.434 4.242.528 4.02 1.056 8.001 1.687 11.982a123.634 123.634 0 003.681 16.184l.237.788h13.235l-.236-.599c-3.879-10.54-5.242-21.584-6.567-32.596zM92.952 99.396a123.76 123.76 0 01-4.974-22.19c-.165-1.168-.33-2.366-.496-3.517H74.979c.6 5.203 1.395 10.343 2.42 15.42a106.854 106.854 0 004.643 16.688l.134.299.064.198s.039 0 .039.07l.197.497h13.827l-.229-.37a39.102 39.102 0 01-3.122-7.095zM58.228 100.657c-2.854-6.235-4.549-12.81-5.873-19.47-.497-2.46-.86-4.944-1.324-7.466H38.52l.07.496v.103a150.234 150.234 0 003.216 16.815 88.555 88.555 0 005.211 15.056l.292.733h14.63l-.465-.567a30.091 30.091 0 01-3.247-5.7z'></path>
          <path d='M123.838 106.861h-12.471c.134 1.458.229 2.846.434 4.241.528 4.012 1.056 7.993 1.687 11.974a123.66 123.66 0 003.681 16.208l.237.788h13.235l-.236-.599c-3.879-10.555-5.242-21.568-6.567-32.612zM160.226 106.861h-12.471l.063.528c.237 2.783.466 5.573.789 8.325.433 3.815.867 7.662 1.458 11.477.631 4.115 1.498 8.159 2.223 12.243l.103.662h12.904l-.134-.497c-2.877-10.752-3.941-21.725-4.935-32.738zM92.952 132.528a123.637 123.637 0 01-4.974-22.191c-.165-1.158-.33-2.364-.496-3.515H74.979c.6 5.242 1.395 10.35 2.42 15.427a106.857 106.857 0 004.643 16.688l.134.292v.055l.064.197s.039 0 .039.063l.197.497h13.827l-.229-.363a38 38 0 01-3.122-7.15zM58.228 133.829a87.286 87.286 0 01-5.873-19.471c-.497-2.46-.86-4.943-1.324-7.465H38.52l.07.496v.103a143.419 143.419 0 003.216 16.814 88.566 88.566 0 005.211 15.057l.292.733h14.63l-.465-.568a31.098 31.098 0 01-3.247-5.699z'></path>
        </g>
      </g>
      <path fill='#212121' d='M132.951 40.423c-.614-24.06-10.334-32.77-20.369-31.611 0 0-14.663-12.25-31.099-7.473-10.902 3.153-8.167 11.39-2.948 18.28C60.6 31.24 61.744 45.635 67.885 49.67 65.14 57.71 69.217 73.809 100.71 74.7c29.277 1.324 32.541-20.804 32.241-34.276z'></path>
      <path fill='#FFA9A9' d='M115.64 55.96c-1.577 7.78-1.91 24.685 1.133 25.978 0 0-19.319 15.613-27.573 13.603-6.393-1.577-4.864-13.744-4.864-13.744s4.896-9.953 2.704-16.763'></path>
      <path fill='#FF8481' d='M106.811 62.535c-.615-1.364-2.704-1.94-5.321-2.09l-14.45 4.589c.67 2.262.741 3.941.142 8.064 9.09 1.466 22.751-3.634 19.629-10.563z'></path>
      <path fill='#FFA9A9' d='M116.625 38.019c-1.442 1.403-4.927 2.893-5.518-4.683-1.261-5.99-2.112-15.766-11.761-16.554-2.767-.244-14.19-3.193-17.879 14.19-5.999 27.235.434 41.874 20.457 36.782 7.788-1.987 11.603-7.095 14.347-13.339.67-1.505 1.245-3.05 1.829-4.627a25.747 25.747 0 012.577-5.037c3.729-5.857.071-10.965-4.052-6.732z'></path>
      <path fill='#fff' d='M84.163 45.145c1.411-1.805 4.115-.93 8.845-.639 6.732.41 9.018-.52 10.571.946 2.617 2.452-2.57 12.542-12.613 11.17-7.047-.898-8.892-8.828-6.803-11.477z'></path>
      <path fill='#212121' d='M81.562 27.156c.41 1.9 9.128-1.088 7.52-4.06-2.341-3.224-7.993 1.884-7.52 4.06z'></path>
      <path fill='#FF8481' d='M94.159 41.44c.512-.907-.875-.662-2.263-2.168-1.576-1.71 1.53-5.589.67-5.928-.788 1.206-2.766 3.398-3.2 5.313-.323 3.098 3.95 4.328 4.793 2.783z'></path>
      <path fill='#212121' d='M105.841 24.01c-2.104-2.168-8.805-5.7-8.9-1.9.095 1.98 1.719 2.492 3.233 2.901 1.55.395 3.133.643 4.729.741 1.206.095 1.601-1.024.938-1.742z'></path>
      <path fill='#212121' d='M100.773 6.849c-13.543-2.215-16.5 11.036-6.189 17.059 4.849 2.854 11.415 1.892 12.763 8.324 1.726 8.293 4.194 8.853 7.489 7.324 16.278-7.568-3.941-29.475-15.608-31.808M83.588 32.327a5.108 5.108 0 015.203.875.379.379 0 01-.3.662 12.155 12.155 0 00-5.518.6c-.442.307-.788-1.404.615-2.137z'></path>
      <path fill='#212121' d='M104.793 34.637c.118 1.26-.576.086-3.287-.544-2.484-.481-4.25.3-3.193-1.033 1.884-2.105 5.967-.946 6.48 1.577z'></path>
      <path fill='#FFD91D' d='M45.713 58.25a.788.788 0 01-.56 1.05 8.672 8.672 0 01-1.892.307 7.283 7.283 0 01-2.94-.442 6.59 6.59 0 01-2.53-1.687 8.951 8.951 0 01-1.711-2.46 8.609 8.609 0 01-.686-6.006c.544-2.42 4.73-5.652 7.78-1.94a4.73 4.73 0 01.095 5.061c-2.057 3.154-8.71 4.439-12.06 4.391a14.773 14.773 0 01-12.424-11.146 5.305 5.305 0 015.384-6.504 5.392 5.392 0 015.092 4.864c.37 3.153 2.302 6.228 5.235 7.095h-.26c1.663.244 3.665.394 5.115-.3a1.623 1.623 0 00.946-1.009c.268-1.04-1.45-1.781-2.364.363-1.16 2.672.394 6.377 3.034 7.591a6.078 6.078 0 003.832.292.788.788 0 01.93.528l-.016-.047z'></path>
      <path fill='#07F' d='M148.106 68.617a.7.7 0 01.898-.268 5.268 5.268 0 003.335.52c2.499-.497 4.572-3.35 4.123-5.865-.363-2.018-1.979-1.734-1.963-.788.019.21.084.412.189.594.104.182.247.34.418.462 1.096.883 2.838 1.159 4.312 1.285h-.229c2.673-.134 4.951-2.404 5.897-4.99a4.727 4.727 0 015.337-3.153 4.656 4.656 0 013.285 2.506 4.657 4.657 0 01-.006 4.131 12.989 12.989 0 01-12.865 7.016c-2.862-.646-8.293-3.066-9.405-6.196a4.099 4.099 0 011.096-4.296c3.35-2.554 6.307 1.048 6.259 3.224a7.572 7.572 0 01-1.805 4.99 8 8 0 01-1.955 1.758 5.86 5.86 0 01-2.499.93 6.383 6.383 0 01-2.601-.22 7.395 7.395 0 01-1.577-.647.707.707 0 01-.355-.441.705.705 0 01.087-.56l.024.008z'></path>
      <path fill='#17D685' d='M158.409 23.597c-1.38 5.644-6.653 10.563-12.558 10.035a1.176 1.176 0 01-.118-2.325c4.146-.71 6.756-4.73 6.661-8.672.237-4.793 7.237-3.713 6.015.946v.016z'></path>
      <path fill='#FF22A6' d='M26.565 71.936a4.73 4.73 0 005.155-.442 2.365 2.365 0 013.264.686c1.23 1.963-.67 3.492-2.23 4.533a9.76 9.76 0 01-5.282 1.576 14.27 14.27 0 01-2.752-.291c-4.532-1.08-2.514-7.678 1.845-6.038v-.024z'></path>
      <path fill='#07F' d='M52.256 35.926a4.304 4.304 0 00-3.059-3.484 2.54 2.54 0 111.971-4.683 9.87 9.87 0 012.215 1.64 8.504 8.504 0 012.562 6.18 1.86 1.86 0 01-3.689.347z'></path>
      <path fill='#FFD91D' d='M186.819 24.709c3.319-3.359 6.417-2.365 7.71-.544 1.293 1.82 1.041 5.344-3.019 7.228-2.948 1.372-5.873 1.892-7.166 0-1.293-1.892.189-4.367 2.475-6.684z'></path>
      <path fill='#FF22A6' d='M181.802 82.73c2.405-.999 3.758-3.243 3.023-5.012-.735-1.769-3.28-2.393-5.684-1.394-2.404.999-3.758 3.243-3.023 5.011.735 1.77 3.28 2.394 5.684 1.395z'></path>
    </svg>);
};
