import apiCommon from '@common/utils/ApiCommon';
import InvitationExistsGroupSubscriptionMemberParamsSchema from './schemas/InvitationExistsGroupSubscriptionMemberParamsSchema.json';
const InvitationExistsGroupSubscriptionMemberResponseSchema = () => import('./schemas/InvitationExistsGroupSubscriptionMemberResponseSchema.json');
import GetCommunityFriendsParamsSchema from './schemas/GetCommunityFriendsParamsSchema.json';
const GetCommunityFriendsResponseSchema = () => import('./schemas/GetCommunityFriendsResponseSchema.json');
const GetMembersGroupSubscriptionResponseSchema = () => import('./schemas/GetMembersGroupSubscriptionResponseSchema.json');
const InviteGroupSubscriptionMemberResponseSchema = () => import('./schemas/InviteGroupSubscriptionMemberResponseSchema.json');
const RemoveGroupSubscriptionMemberResponseSchema = () => import('./schemas/RemoveGroupSubscriptionMemberResponseSchema.json');
const LeaveGroupSubscriptionMemberResponseSchema = () => import('./schemas/LeaveGroupSubscriptionMemberResponseSchema.json');
import InvitationConfirmGroupSubscriptionMemberParamsSchema from './schemas/InvitationConfirmGroupSubscriptionMemberParamsSchema.json';
const InvitationConfirmGroupSubscriptionMemberResponseSchema = () => import('./schemas/InvitationConfirmGroupSubscriptionMemberResponseSchema.json');
const getCommunityFriends = (params = {}) => apiCommon
    .makeGetRequest(`/api/community/friends/search`, {
    q: params.q || '',
    count: params.count || 20,
    offset: params.offset || 0,
}, GetCommunityFriendsParamsSchema, GetCommunityFriendsResponseSchema)
    .then((res) => res.data);
const getGroupSubscriptionMembers = () => apiCommon
    .makeGetRequest(`/api/community/get`, undefined, undefined, GetMembersGroupSubscriptionResponseSchema)
    .then((response) => response.data);
const addMember = (id) => apiCommon.makePostRequest(`/api/community/user/invite`, {
    user_id: id,
}, undefined, InviteGroupSubscriptionMemberResponseSchema);
const removeMember = (id) => apiCommon.makePostRequest(`/api/community/user/exclude`, {
    user_id: id,
}, undefined, RemoveGroupSubscriptionMemberResponseSchema);
const leaveMember = () => apiCommon.makePostRequest(`/api/community/leave`, undefined, undefined, LeaveGroupSubscriptionMemberResponseSchema);
const invitationExists = (communityId) => apiCommon.makePostRequest(`/api/community/invitation/exists`, { community_id: communityId }, InvitationExistsGroupSubscriptionMemberParamsSchema, InvitationExistsGroupSubscriptionMemberResponseSchema);
const invitationConfirm = (communityId) => apiCommon.makePostRequest(`/api/front/community/invitation/confirm`, { community_id: communityId }, InvitationConfirmGroupSubscriptionMemberParamsSchema, InvitationConfirmGroupSubscriptionMemberResponseSchema);
const invitationDecline = (communityId) => apiCommon.makePostRequest(`/api/community/invitation/decline`, { community_id: communityId }, InvitationConfirmGroupSubscriptionMemberParamsSchema, InvitationConfirmGroupSubscriptionMemberResponseSchema);
export const api = {
    getCommunityFriends,
    getGroupSubscriptionMembers,
    addMember,
    removeMember,
    leaveMember,
    invitationExists,
    invitationConfirm,
    invitationDecline,
};
