import { IS_CLIENT } from '@common/consts';
export class VkPixel {
    constructor({ vkRtgId }) {
        this.initPromiseResolve = () => null;
        this.initPromise = new Promise((resolve) => {
            this.initPromiseResolve = resolve;
        });
        this.init = (vkRtgId) => {
            this.addScript(vkRtgId);
            this.addNoscript(vkRtgId);
        };
        this.addScript = async (vkRtgId) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://vk.com/js/api/openapi.js?169';
            script.onload = () => {
                window?.VK?.Retargeting.Init(vkRtgId);
                window?.VK?.Retargeting.Hit();
                this.initPromiseResolve();
            };
            document.head.appendChild(script);
        };
        this.addNoscript = (vkRtgId) => {
            const noscript = document.createElement('noscript');
            const img = document.createElement('img');
            img.style.position = 'fixed';
            img.style.left = '-999px';
            img.alt = `https://vk.com/rtrg?p=${vkRtgId}`;
            noscript.appendChild(img);
            document.body.appendChild(noscript);
        };
        this.sendSubscriptionGoalReached = async () => {
            await this.initPromise;
            window?.VK?.Goal('subscribe');
        };
        if (IS_CLIENT && vkRtgId) {
            this.init(vkRtgId);
        }
    }
}
