import { api } from '../../../../api';
export const applyActionFlow = async (action, conditions = [], conditionIgnore) => {
    const applyActionParams = {
        action,
        conditions: conditionIgnore ? { ignore: true } : conditions,
    };
    const applyAction = await api.applyAction(applyActionParams);
    if (!applyAction.successful) {
        return {
            type: 'error',
            ...applyAction,
        };
    }
    if ('condition' in applyAction.data) {
        const { condition } = applyAction.data;
        return {
            type: 'condition',
            condition,
            conditions,
            successful: true,
        };
    }
    return {
        type: 'purchase',
        ...applyAction,
    };
};
