import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    header: {
        id: 'header',
        defaultMessage: 'Ваша заявка на подписку для студентов была одобрена',
    },
    subheader: {
        id: 'subheader',
        defaultMessage: 'Теперь вы можете подключить подписку для студентов в VK Музыке. Обратите внимание, что оплата в приложении недоступна.',
    },
    btnText: {
        id: 'btnText',
        defaultMessage: 'Понятно',
    },
});
