import { InlineMenu } from '@website/widgets/header/ui/inline-menu';
import { BurgerMenu } from '@website/widgets/header/ui/burger-menu';
import styles from './styles.module.scss';
const MenuTypeSelector = ({ linksClass }) => {
    return (<>
      <div className={styles.menuTypeSelector__mobile}>
        <BurgerMenu />
      </div>
      <div className={styles.menuTypeSelector__desktop}>
        <InlineMenu linksClass={linksClass}/>
      </div>
    </>);
};
export default MenuTypeSelector;
