import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    emptyTitle: {
        id: 'emptyTitle',
        defaultMessage: 'Пока нет доступных тарифов',
    },
    emptyDescription: {
        id: 'emptyDescription',
        defaultMessage: 'Список других доступных тарифов появится позже',
    },
    studentStatusWaitTitle: {
        id: 'studentStatusWaitTitle',
        defaultMessage: 'Заявка на рассмотрении',
    },
});
