import { createEvent, sample } from 'effector';
import { formModel } from './form';
import { modalStateModel } from './modal-state-model';
import { footerModel } from './footer-model';
import { nextStepButtonModel } from './next-step-button-model';
import { createGate } from 'effector-react';
import { uploadDocumentModel } from './upload-document-model';
import { cityModel } from './city-model';
import { establishmentModel } from './establishment-model';
export { formModel } from './form';
export { modalStateModel } from './modal-state-model';
export { footerModel } from './footer-model';
export { nextStepButtonModel } from './next-step-button-model';
export { uploadDocumentModel } from './upload-document-model';
export { cityModel } from './city-model';
export { establishmentModel } from './establishment-model';
export const StudentFormGate = createGate({});
const clearAllModels = createEvent();
const closeGateClearList = [
    modalStateModel.clear,
    footerModel.clear,
    nextStepButtonModel.clear,
    cityModel.clearCityList,
    establishmentModel.clearEstablishmentModel,
];
const closeModalClearList = [formModel.clear, uploadDocumentModel.clear, ...closeGateClearList];
sample({
    clock: clearAllModels,
    target: closeModalClearList,
});
sample({
    clock: StudentFormGate.close,
    target: closeGateClearList,
});
export const showStudentFormModel = { clearAllModels };
