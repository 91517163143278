import { useEffect } from 'react';
const createUseAnalyticShowDirect = (handleShow) => (elementKey) => {
    useEffect(() => {
        handleShow(elementKey);
    }, []);
};
const createCallAnalyticShowDirect = (handleShow) => (elementKey) => {
    handleShow(elementKey);
};
export { createUseAnalyticShowDirect, createCallAnalyticShowDirect };
