export const dateFormatter = (date) => {
    const datePattern = /(\d{2})\.(\d{2})\.(\d{4})/;
    if (!datePattern.test(date)) {
        return date;
    }
    const [day, month, year] = date.split('.');
    const tmpDate = new Date();
    tmpDate.setFullYear(Number(year));
    tmpDate.setMonth(Number(month) - 1);
    tmpDate.setDate(Number(day));
    return tmpDate.toISOString();
};
