import React from 'react';
import { analyticsDictionary } from '@analytics';
import { modalManagerModel } from '@common/services/modal-manager';
import styles from './styles.module.scss';
export const preloadPayloadShowCheckoutProcessModal = async () => {
    const header = import('./ui/header').then((result) => result.default);
    const footer = import('./ui/footer').then((result) => result.default);
    const content = import('./ui/content').then((result) => result.default);
    const [Header, Footer, Content] = await Promise.all([header, footer, content]);
    return {
        analyticShowAttribute: analyticsDictionary.modalCheckoutFormShow('offer'),
        className: styles.container,
        header: <Header />,
        children: <Content />,
        footer: <Footer />,
    };
};
export const showCheckoutProcessModal = async () => {
    const payload = await preloadPayloadShowCheckoutProcessModal();
    modalManagerModel.showModalPageWithFixedFooter(payload);
};
