import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { Children, Footer, Header } from './ui';
export const showCheckoutModalV2 = (params) => {
    const { type } = params;
    modalManagerModel.showModalPageWithFixedFooter({
        analyticShowAttribute: '',
        header: <Header />,
        children: <Children {...params}/>,
        footer: <Footer type={type}/>,
    });
};
