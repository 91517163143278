import React from 'react';
import ModalButton from '@common/components/modals/ModalButton';
import { modalManagerModel } from '../../../model';
import { SuccessIcon, ErrorIcon, WarningIcon } from './fc-icons';
export const showMessageModal = (params) => {
    const { analyticShowAttribute, mode, header, subheader, actions } = params;
    const closeModal = () => {
        modalManagerModel.closeModal();
    };
    const modeMapping = {
        success: <SuccessIcon />,
        warning: <WarningIcon />,
        error: <ErrorIcon />,
    };
    modalManagerModel.showModal({
        icon: modeMapping[mode],
        header,
        subheader,
        actions: actions ? (actions) : (<ModalButton size='l' mode='primary' onClick={closeModal}>
        Хорошо
      </ModalButton>),
        analyticShowAttribute,
    });
};
