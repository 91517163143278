import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useGate, useUnit } from 'effector-react';
import { useAnalyticShowDirect } from '@analytics';
import debounce from 'lodash/debounce';
import { CustomSelect, CustomSelectOption, FormItem, FormLayoutGroup, Input, Radio, RadioGroup, } from '@vkontakte/vkui';
import { useIntl } from 'react-intl';
import { studentAnalytics, StudentApi, StudentModel } from '@common/entities/student';
import { showSuccessSendEmailModal } from '@common/features/start-student-flow/lib';
import { cityModel, establishmentModel, formModel, nextStepButtonModel } from '../../models';
import { alertManagerModel } from '@common/entities/alert-manager';
import messages from './messages';
import { MAX_NAME_LENGTH, MIN_STRING_LENGTH_FOR_CITY_SEARCH, MIN_STRING_LENGTH_FOR_ESTABLISHMENT_SEARCH, SEARCH_DELAY, } from './lib';
import { FormUIGate } from './model';
const Form = () => {
    useGate(FormUIGate);
    const intl = useIntl();
    const sendForm = useUnit(formModel.sendFormFx);
    const studentStatus = useUnit(StudentModel.$status);
    const [cityOptions, pendingStudentCityList, getCityList] = useUnit([
        cityModel.$cityOptions,
        cityModel.$pendingStudentCityList,
        cityModel.getCityList,
    ]);
    const [establishmentOptions, universityDomains, pendingStudentEstablishmentList, getUniversityDomains, getEstablishmentList,] = useUnit([
        establishmentModel.$establishmentOptions,
        establishmentModel.$universityDomains,
        establishmentModel.$pendingStudentEstablishmentList,
        establishmentModel.getUniversityDomainsFx,
        establishmentModel.getEstablishmentList,
    ]);
    const { $fields: fields, setFields } = useUnit(formModel.fields);
    const { $errorsFieldsStatus: errors, clear: clearErrors, setErrors } = useUnit(formModel.errors);
    const clearMainFormModel = useUnit(formModel.clear);
    const nextStepButtonState = useUnit({ ...nextStepButtonModel });
    const studentModel = useUnit({ ...StudentModel });
    const alertManager = useUnit({ ...alertManagerModel });
    const generalClickProps = {
        analytic: studentAnalytics.form.fill.click(studentModel.$status),
    };
    const { lastName, middleName, firstName, cityName, cityId, establishmentName, establishmentId, establishmentType, flow, email, } = fields;
    const { lastNameStatus, firstNameStatus, cityIdStatus, establishmentIdStatus, emailStatus } = errors;
    const showVerificationType = universityDomains.length > 0 && establishmentId;
    const setFieldsForm = (newState) => {
        setFields(newState);
    };
    const inputChangeHandler = (fieldName) => (event) => {
        const value = event.target.value.slice(0, MAX_NAME_LENGTH);
        const newFormState = { [fieldName]: value };
        setFieldsForm(newFormState);
    };
    const citiesOptions = useMemo(() => {
        let cities = [];
        if (cityOptions.length > 0) {
            cities = cityOptions;
        }
        else if (cityId !== null && cityName !== null) {
            cities.push({
                value: cityId,
                label: cityName,
            });
        }
        return cities;
    }, [cityId, cityName, cityOptions]);
    const establishmentsOptions = useMemo(() => {
        let establishmentsOptions = [];
        if (establishmentOptions.length > 0) {
            establishmentsOptions = establishmentOptions;
        }
        else if (establishmentId !== null && establishmentName !== null) {
            establishmentsOptions.push({
                value: establishmentId,
                label: establishmentName,
                description: establishmentType,
            });
        }
        return establishmentsOptions;
    }, [establishmentId, establishmentName, establishmentOptions]);
    const handleCitySelectOnChange = useCallback((event) => {
        const cityId = Number(event.target.value);
        const option = citiesOptions.find((city) => city.value === cityId);
        if (!option) {
            return;
        }
        if (fields.cityId === option.value) {
            return;
        }
        const newFormState = {
            cityId: option.value,
            cityName: option.label,
            establishmentId: null,
            establishmentName: null,
            establishmentType: null,
        };
        setFieldsForm(newFormState);
        void getEstablishmentList({ city_id: newFormState.cityId, query: '' });
    }, [citiesOptions]);
    const handleCitySearch = debounce((event) => {
        const query = event.target.value;
        if (query.length < MIN_STRING_LENGTH_FOR_CITY_SEARCH) {
            return;
        }
        getCityList({ query });
    }, SEARCH_DELAY);
    const handleEstablishmentSelectOnChange = useCallback(async (event) => {
        const establishmentId = Number(event.target.value);
        const option = establishmentsOptions.find((establishment) => establishment.value === establishmentId);
        if (!option) {
            return;
        }
        const newState = {
            establishmentId: option.value,
            establishmentName: option.label,
            establishmentType: option.description,
        };
        setFieldsForm(newState);
        if (studentStatus !== 'READY') {
            return;
        }
        try {
            alertManager.showScreenSpinner({ state: 'loading' });
            let data = [];
            if (option.description === 'university') {
                data = await getUniversityDomains({ university_id: establishmentId });
            }
            if (data.length > 0) {
                setFieldsForm({ flow: 'email' });
            }
            else {
                setFieldsForm({ flow: 'studentId' });
            }
        }
        finally {
            setTimeout(() => {
                alertManager.closePopout();
            }, 500);
        }
    }, [establishmentsOptions, studentStatus]);
    const handleEstablishmentSearch = debounce((event) => {
        const query = event.target.value;
        if (query.length < MIN_STRING_LENGTH_FOR_ESTABLISHMENT_SEARCH || cityId === null) {
            return;
        }
        getEstablishmentList({ query, city_id: cityId });
    }, SEARCH_DELAY);
    const renderCityOption = useCallback((params) => {
        const description = params.description;
        return (<CustomSelectOption description={description} {...params}/>);
    }, []);
    const setActionStudentIdFlow = () => {
        nextStepButtonState.setState({
            ...generalClickProps,
            click: async () => {
                try {
                    await sendForm(fields);
                }
                catch (error) {
                    setErrors(error);
                    return Promise.reject(error);
                }
            },
        });
    };
    const emailActionFlow = async () => {
        if (!fields.email) {
            setErrors({ emailStatus: 'Укажите вашу учебную почту' });
            return;
        }
        try {
            const { data } = await StudentApi.sendEmailStudentForm({ email: fields.email });
            if (!data.successful) {
                setErrors({ emailStatus: data.errors[0]?.detail });
                return;
            }
            showSuccessSendEmailModal();
            clearMainFormModel();
        }
        catch (error) {
            setErrors({ emailStatus: 'Оформление заявки по почте в данный момент недоступно, попробуйте позднее' });
            return Promise.reject(error);
        }
    };
    const setActionEmailFlow = () => {
        nextStepButtonState.setState({ ...generalClickProps, click: emailActionFlow });
    };
    const studentIdFlowRadioButtonClick = () => {
        setFieldsForm({ flow: 'studentId' });
        clearErrors();
        setActionStudentIdFlow();
    };
    const emailFlowRadioButtonClick = () => {
        setFieldsForm({ flow: 'email' });
        clearErrors();
        setActionEmailFlow();
    };
    useEffect(() => {
        if (fields.flow === 'studentId') {
            setActionStudentIdFlow();
        }
        if (fields.flow === 'email') {
            setActionEmailFlow();
        }
    }, [sendForm, fields]);
    const formWrapperRef = useRef(null);
    const emailRef = useRef(null);
    useEffect(() => {
        if (emailRef.current) {
            formWrapperRef.current?.scrollTo({ top: 1000, behavior: 'smooth' });
        }
    }, [emailRef.current, formWrapperRef.current]);
    const availableCondition = useMemo(() => {
        return Object.values(errors).every((error) => error === null);
    }, [Object.values(errors)]);
    useEffect(() => {
        nextStepButtonState.setState({ status: availableCondition ? 'idle' : 'error' });
    }, [availableCondition]);
    useAnalyticShowDirect(studentAnalytics.form.fill.show(studentStatus));
    return (<>
      <FormLayoutGroup getRootRef={formWrapperRef}>
        <FormItem top={intl.formatMessage(messages.lastName)} bottom={lastNameStatus} status={lastNameStatus ? 'error' : 'default'} topComponent='h5' htmlFor={'name_last'} bottomId={'name_last_bottom'}>
          <Input id={'name_last'} aria-describedby={'name_last_bottom'} name='name_last' value={lastName} onChange={inputChangeHandler('lastName')}/>
        </FormItem>

        <FormItem top={intl.formatMessage(messages.firstName)} bottom={firstNameStatus} status={firstNameStatus ? 'error' : 'default'} topComponent='h5' htmlFor={'name_first'} bottomId={'name_first_bottom'}>
          <Input name='name_first' id={'name_first'} aria-describedby={'name_first_bottom'} value={firstName} onChange={inputChangeHandler('firstName')}/>
        </FormItem>

        <FormItem top={intl.formatMessage(messages.middleName)} topComponent='h5' htmlFor={'name_middle'} bottomId={'name_middle_bottom'}>
          <Input id={'name_first'} aria-describedby={'name_middle_bottom'} name='name_middle' value={middleName} onChange={inputChangeHandler('middleName')}/>
        </FormItem>

        <FormItem top={intl.formatMessage(messages.city)} bottom={cityIdStatus} status={cityIdStatus ? 'error' : 'default'} topComponent='h5' htmlFor={'city'} bottomId={'city_bottom'}>
          <CustomSelect id={'city'} aria-describedby={'city_bottom'} placeholder={intl.formatMessage(messages.cityPlaceholder)} searchable={true} value={cityId ?? undefined} options={citiesOptions} fetching={pendingStudentCityList} renderOption={renderCityOption} filterFn={() => true} onInputChange={handleCitySearch} onChange={handleCitySelectOnChange} forceDropdownPortal={false}/>
        </FormItem>

        <FormItem top={intl.formatMessage(messages.university)} bottom={establishmentIdStatus} status={establishmentIdStatus ? 'error' : 'default'} topComponent='h5' htmlFor={'university'} bottomId={'university_bottom'}>
          <CustomSelect id={'university'} aria-describedby={'university_bottom'} key={cityId} placeholder={intl.formatMessage(messages.universityPlaceholder)} searchable={true} value={establishmentId ?? undefined} disabled={cityId === null} options={establishmentsOptions} fetching={pendingStudentEstablishmentList} tabIndex={cityId === null ? -1 : 0} filterFn={() => true} onInputChange={handleEstablishmentSearch} onChange={handleEstablishmentSelectOnChange} forceDropdownPortal={false}/>
        </FormItem>
        {showVerificationType && (<>
            <FormItem top={intl.formatMessage(messages.verificationType)}>
              <RadioGroup>
                <Radio name='verificationType' value='email' checked={flow === 'email'} onChange={emailFlowRadioButtonClick}>
                  {intl.formatMessage(messages.verificationTypeEmail)}
                </Radio>
                <Radio name='verificationType' value='studentId' checked={flow === 'studentId'} onChange={studentIdFlowRadioButtonClick}>
                  {intl.formatMessage(messages.verificationTypeStudentId)}
                </Radio>
              </RadioGroup>
            </FormItem>
            {flow === 'email' && (<FormItem top={intl.formatMessage(messages.email)} bottom={emailStatus} status={emailStatus ? 'error' : 'default'} getRootRef={(ref) => {
                    ref?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                }} htmlFor={'email'} bottomId={'email_bottom'}>
                <Input id={'email'} aria-describedby={'email_bottom'} name='email_address' type={'email'} placeholder={intl.formatMessage(messages.emailPlaceholder)} value={email} onChange={inputChangeHandler('email')}/>
              </FormItem>)}
          </>)}
      </FormLayoutGroup>
    </>);
};
export default Form;
