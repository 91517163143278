import React from 'react';
export const WarningIcon = () => {
    return (<svg xmlns='http://www.w3.org/2000/svg' width='158' height='111' fill='none' viewBox='0 0 158 111'>
      <path fill='#2787F5' d='M53.102 110.486h51.844c24.861 0 37.295 0 45.015-7.72 7.72-7.72 7.654-20.15 7.654-44.95V53.17c0-24.794 0-37.225-7.654-44.945C142.306.504 129.807.5 104.946.5H53.102C28.238.5 15.808.5 8.087 8.224.367 15.948.384 28.374.384 53.169v4.648c0 24.798 0 37.225 7.72 44.949 7.721 7.724 20.134 7.72 44.998 7.72z'></path>
      <path fill='#212121' d='M100.084 13.94s-10.131-9.594-19.253-.27a61.212 61.212 0 00-12.295 18.1c-.909 2.116-.177 5.33 3.885 7.079 2.84 1.224-4.468 14.494.027 22.467 4.495 7.974 15.15 3.76 15.486 4.485 2.681 5.75 20.22 7.117 23.127 7.55 10.689 1.592 23.806 4.474 24.736 1.3 4.716-16.172-13.225-19.342-12.139-25.813 3.461-20.574-11.373-41.793-23.574-34.898z'></path>
      <path fill='#FFA9A9' d='M87.5 50.627c.809 1.706 1.482 9.92-1.255 14.143-1.935 2.983-5.365 4.96-6.347 5.79-4.855 4.099 8.463 8.906 12.992 7.976 4.53-.93 15.393-4.491 15.608-15.18 0 0-4.731-12.743-2.886-17.647l-18.111 4.918z'></path>
      <path fill='#FF8481' d='M106.639 57.36c-1.1-4.24-1.533-7.284-1.478-10.683-.104-.895-.614.274-.576-.347l-4.297 1.093c-4.228 2.226-7.921 5.618-8.695 5.337-1.04-.385-1.387 2.05 1.197 4.443 3.367 3.108 11.102 2.16 13.849.156z'></path>
      <path fill='#FFA9A9' d='M92.755 20.685c-4.363.323-12.552 3.215-12.364 21.923.004 2.405.624 4.77 1.8 6.867C84.598 53.68 88.912 56.43 94.236 56c5.934-.482 9.912-4.252 11.827-10.724.045-.843 4.446-4.117 2.948-7.165-1.498-3.049-4.707-.416-4.974-1.554-3.218-13.651-6.465-16.231-11.282-15.87z'></path>
      <path fill='#313131' d='M98.013 34.042c.468 1.342.052 1.841-1.36 1.814-.867-.021-1.685-.472-1.325-1.998.295-1.224 2.133-1.394 2.685.184zM84.986 34.964c-.374 1.388.076 1.839 1.478 1.71.863-.08 1.657-.61 1.182-2.08-.381-1.197-2.22-1.239-2.66.37z'></path>
      <path fill='#FF8481' d='M92.09 40.004c.304-.493-.476-.382-1.218-1.252-.84-.989.725-2.716.256-2.924-.468-.208-1.46 1.103-1.657 2.525-.243 1.723 2.129 2.507 2.618 1.65z'></path>
      <path fill='#313131' d='M100.407 30.537c.135-1.267-2.747-2.123-4.509-2.127-1.328 0-2.386.375-1.866 1.478.146.312.621.5 1.27.524 3.645.125 5.057.579 5.105.125zM82.583 32.12c-.302-1.227 2.143-2.542 3.704-2.833 1.182-.222 2.178-.021 1.876 1.155-.087.346-.482.593-1.058.721-3.215.711-4.404 1.401-4.522.957z'></path>
      <path fill='#F45FFF' d='M108.491 63.349c-.292 9.017-8.392 11.451-15.724 10.539-10.058-1.755-6.38-9.34-6.38-9.34s-19.838-.887-30.028 7.63c-5.63 4.96-34.374-30.416-38.921-21.6-.517 6.774 1.006 26.13 4.658 40.32 1.796 6.984 6.954 11.614 14.126 10.865 6.191-.652 10.245-1.016 14.432-1.449a10.402 10.402 0 0110.665 6.315l1.453 3.871h42.174c22.308 0 34.607 0 42.476-5.584 0 0-2.927-26.14-11.511-33.99-8.584-7.848-27.42-7.577-27.42-7.577z'></path>
      <path fill='#000' d='M68.082 72.928L15.908 83.173A176.374 176.374 0 008.68 46.41l52.174-10.256c.333 13.235 2.742 25.493 7.228 36.774z'></path>
      <path fill='#F45FFF' d='M37.67 55.459l-14.089 2.77a.985.985 0 00.38 1.932l14.09-2.77a.985.985 0 10-.38-1.932zM52.936 52.46L42.553 54.5a.985.985 0 10.38 1.934l10.383-2.041a.985.985 0 10-.38-1.933zM32.556 62.922l-7.753 1.523a.985.985 0 00.38 1.933l7.752-1.523a.985.985 0 00-.38-1.933zM54.154 58.675l-17.428 3.426a.985.985 0 00.38 1.933l17.428-3.426a.985.985 0 00-.38-1.933z'></path>
      <path fill='#FFA9A9' d='M32.677 41.98l1.943 2.196c1.945 2.195 3.402 3.496 4.786 1.956 1.384-1.54-4.162-8.619-6.021-8.324-3.486.635-3.791 4.509-3.791 4.509 1.38-1.152 1.866-.905 3.083-.337z'></path>
      <path fill='#FFA9A9' d='M38.112 42.882c1.537 2.196 3.24 3.17 4.54 1.956 1.3-1.214-2.587-7.148-5.018-7.557-3.084-.347-3.74 3.323-3.74 3.323 1.301-.971 2.762.083 4.218 2.278zM28.13 43.517l2.352 1.79c2.352 1.79 4.05 2.684 5.109.981 1.058-1.703-4.766-7.63-6.795-7.228-3.974.725-5.369 4.287-5.369 4.287 2.515-.319 3.406-.319 4.704.17z'></path>
      <path fill='#FFA9A9' d='M24.728 40.427c1.706-.566 3.406 1.953 4.856 4.633 1.696 3.008-1.062 3.573-1.873 2.92-.812-.651-3.468-4.55-3.468-4.55l-2.92.483c.072.069 1.702-2.92 3.405-3.486z'></path>
      <path fill='#212121' d='M109.58 78.29c2.525 1.145 7.536 1.526 11.175-1.675 2.639-2.32 6.666-9.635 11.657-8.216-1.041-3.85-5.786-8.255-18.095-8.407-1.859-.021-5.49-5.203-7.155-4.416-.825.392 1.089 6.712 1.284 7.6.194.887-7.894 11.029 1.134 15.114z'></path>
      <path fill='#70059E' d='M90.428 101.972s-2.847 5.362-.135 6.204c2.712.843 11.663-16.332 11.663-16.332l-11.528 10.128z'></path>
      <path fill='#70059E' d='M129.512 103.858h-.049c-6.787-.912-19.8-6.79-27.691-11.712a.349.349 0 01-.111-.475.349.349 0 01.476-.111c7.831 4.88 20.719 10.71 27.42 11.612a.344.344 0 01-.045.686z'></path>
      <path fill='#FF8481' d='M73.048 85.678s.219 3.888-1.89 4.071c-1.932.174-7.977-3.291-9.742-5.202l11.632 1.13z'></path>
      <path fill='#FFA9A9' d='M102.199 91.615C98.263 101.04 94 105.034 94 105.034s-7.183-6.517-19.769-17.882c-2.98-2.237-10.457-1.04-12.729-2.55-2.271-1.508.916-9.253.916-9.253s-10.64-5.743-8.84-7.283c1.002-.85 2.448.714 14.424 2.656 3.122.58 5.313 1.11 7.18 1.86a22.198 22.198 0 015.22 3.006l21.797 16.027z'></path>
      <path fill='#70059E' d='M92.769 110.486a.345.345 0 01-.232-.09l-2.942-2.681a.358.358 0 01-.114-.241.348.348 0 01.33-.363.346.346 0 01.25.091l2.944 2.681a.345.345 0 01.02.489.348.348 0 01-.256.114z'></path>
      <path fill='#FF8481' d='M22.793 42.837c-.933.566-1.436 1.117-1.478 1.075l2.92-.482s-.613-.808-1.442-.593zM91.836 42.89c2.611-.694 3.874-1.357 3.957-1.041.208.763-.444 2.376-3.177 3.121-1.536.416-3.832-.09-4.124-1.082-.16-.562 1.783-.583 3.344-.999z'></path>
    </svg>);
};
