import React from 'react';
import { useIntl } from 'react-intl';
import { Paragraph } from '@vkontakte/vkui';
import Typograf from '../Typograf';
import styles from './styles.module.scss';
import { messages } from './messages';
const STATUSES = {
    waiting: {
        text: messages.waiting,
        statusClassName: styles.text_waiting,
    },
    active: {
        text: messages.active,
        statusClassName: styles.text_active,
    },
    auto_renew_cancelled: {
        text: messages.auto_renew_cancelled,
        statusClassName: styles.text_auto_renew_cancelled,
    },
    suspended: {
        text: messages.suspended,
        statusClassName: styles.text_suspended,
    },
    inactive: {
        text: messages.inactive,
        statusClassName: styles.text_inactive,
    },
};
const SubscriptionStatus = (props) => {
    const { status } = props;
    const intl = useIntl();
    const { text, statusClassName } = STATUSES[status];
    return (<Paragraph Component={'div'} weight={'2'} className={statusClassName}>
      <Typograf>{intl.formatMessage(text)}</Typograf>
    </Paragraph>);
};
export default SubscriptionStatus;
