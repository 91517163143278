import React from 'react';
import { Icon32LogoVkColor } from '@vkontakte/icons';
import { callEruda } from '@common/utils/eruda';
import styles from './styles.module.scss';
const FooterLogoWithErude = () => {
    return (<div className={styles.logo}>
      <span onClick={callEruda}>проект</span>
      <Icon32LogoVkColor />
    </div>);
};
export default FooterLogoWithErude;
