import React, { useMemo } from 'react';
import TypografLib from 'typograf';
const tp = new TypografLib({ locale: ['ru', 'en-US'] });
export const typografIt = (text) => tp.execute(text);
const Typograf = (props) => {
    const { children } = props;
    const content = useMemo(() => {
        if (typeof children === 'string') {
            return tp.execute(props.children);
        }
        return children;
    }, [props.children]);
    return <>{content}</>;
};
export default Typograf;
