import { DATA_ANALYTIC_CLICK_ATTRIBUTE } from './useAnalyticGlobalClick';
import { DATA_ANALYTIC_SHOW_ATTRIBUTE } from './useAnalyticGlobalShow';
const click = 'click';
const show = 'show';
const createAnalyticAttrs = (elementKey, events) => {
    const attrs = {};
    if (events.includes(click)) {
        attrs[DATA_ANALYTIC_CLICK_ATTRIBUTE] = elementKey;
    }
    if (events.includes(show)) {
        attrs[DATA_ANALYTIC_SHOW_ATTRIBUTE] = elementKey;
    }
    return attrs;
};
export default createAnalyticAttrs;
