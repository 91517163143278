import { defineMessages } from 'react-intl';
const messages = defineMessages({
    firstButtonText: {
        id: 'firstButtonText',
        defaultMessage: 'Оставить подписку',
    },
    secondButtonText: {
        id: 'secondButtonText',
        defaultMessage: 'Всё равно отменить',
    },
});
export default messages;
