import React from 'react';
import { useUnit } from 'effector-react';
import { Checkbox, Headline, Text, Textarea } from '@vkontakte/vkui';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import { intl } from '@common/shared/lib/intl';
import { getSubscriptionById } from '@common/entities/subscriptions';
import { modalManagerModel } from '@common/services/modal-manager';
import Typograf from '@common/components/common/Typograf';
import { Header } from '@common/services/vk-ui-modal-manager';
import ModalButton from '@common/components/modals/ModalButton';
import messages from './messages';
import { subscriptionCancelReasonModalModel } from './model';
import styles from './styles.module.scss';
const closeModal = modalManagerModel.closeModal;
const Content = () => {
    const optionsCancelReasonForm = useUnit(subscriptionCancelReasonModalModel.$optionsCancelReasonForm);
    const changeOptionsReasonForm = useUnit(subscriptionCancelReasonModalModel.changeOptionsReasonForm);
    const { optionNoAdvantageChecked, optionBadPriceChecked, optionTechProblemsChecked, optionAnotherSubscriptionChecked, optionAnotherSubscriptionText, optionOtherChecked, optionOtherText, } = optionsCancelReasonForm;
    const onChangeCheckbox = (event, fieldName) => changeOptionsReasonForm({ [fieldName]: event.target.checked });
    return (<div className={styles.container}>
      <Headline className={styles.title}>
        <Typograf>{intl.formatMessage(messages.subtitle)}</Typograf>
      </Headline>
      <section className={styles.section}>
        <Checkbox {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionCancelReasonCheckboxClick('no_advantage'))} className={styles.checkbox} checked={optionNoAdvantageChecked} onChange={(event) => onChangeCheckbox(event, 'optionNoAdvantageChecked')}>
          <Typograf>{intl.formatMessage(messages.noAdvantageText)}</Typograf>
        </Checkbox>
        {optionNoAdvantageChecked && (<Text className={styles.sectionDescription}>
            <Typograf>{intl.formatMessage(messages.noAdvantageDescription)}</Typograf>
          </Text>)}
      </section>

      <section className={styles.section}>
        <Checkbox {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionCancelReasonCheckboxClick('bad_price'))} className={styles.checkbox} checked={optionBadPriceChecked} onChange={(event) => onChangeCheckbox(event, 'optionBadPriceChecked')}>
          <Typograf>{intl.formatMessage(messages.badPriceText)}</Typograf>
        </Checkbox>
        {optionBadPriceChecked && (<Text className={styles.sectionDescription}>
            <Typograf>{intl.formatMessage(messages.badPriceDescription)}</Typograf>
          </Text>)}
      </section>

      <section className={styles.section}>
        <Checkbox {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionCancelReasonCheckboxClick('tech_problems'))} className={styles.checkbox} checked={optionTechProblemsChecked} onChange={(event) => onChangeCheckbox(event, 'optionTechProblemsChecked')}>
          <Typograf>{intl.formatMessage(messages.techProblemsText)}</Typograf>
        </Checkbox>
        {optionTechProblemsChecked && (<Text className={styles.sectionDescription}>
            <Typograf>{intl.formatMessage(messages.techProblemsDescription)}</Typograf>
          </Text>)}
      </section>

      <section className={styles.section}>
        <Checkbox {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionCancelReasonCheckboxClick('another_subscription'))} className={styles.checkbox} checked={optionAnotherSubscriptionChecked} onChange={(event) => onChangeCheckbox(event, 'optionAnotherSubscriptionChecked')}>
          <Typograf>{intl.formatMessage(messages.anotherSubscriptionText)}</Typograf>
        </Checkbox>
        {optionAnotherSubscriptionChecked && (<Textarea className={styles.sectionTextArea} value={optionAnotherSubscriptionText} placeholder={intl.formatMessage(messages.anotherSubscriptionPlaceholder)} onChange={(event) => changeOptionsReasonForm({ optionAnotherSubscriptionText: event.target.value })}/>)}
      </section>

      <section className={styles.section}>
        <Checkbox {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionCancelReasonCheckboxClick('other'))} className={styles.checkbox} checked={optionOtherChecked} onChange={(event) => onChangeCheckbox(event, 'optionOtherChecked')}>
          <Typograf>{intl.formatMessage(messages.otherSubscriptionText)}</Typograf>
        </Checkbox>
        {optionOtherChecked && (<Textarea className={styles.sectionTextArea} value={optionOtherText} placeholder={intl.formatMessage(messages.otherSubscriptionPlaceholder)} onChange={(event) => changeOptionsReasonForm({ optionOtherText: event.target.value })}/>)}
      </section>
    </div>);
};
const Footer = (props) => {
    const { subscriptionId, successHandle } = props;
    const sendForm = useUnit(subscriptionCancelReasonModalModel.sendForm);
    const subscription = useUnit(getSubscriptionById(subscriptionId));
    const isInSendFormProgress = useUnit(subscriptionCancelReasonModalModel.$isInSendFormProgress);
    return (<div className={styles.footer}>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionCancelReasonButtonConfirm)} disabled={isInSendFormProgress} loading={isInSendFormProgress} stretched={true} mode='secondary' onClick={() => sendForm({ subscription, successHandle })}>
        <Typograf>{intl.formatMessage(messages.submit)}</Typograf>
      </ModalButton>
    </div>);
};
const payloadToModalManager = (payload) => {
    return {
        analyticShowAttribute: analyticsDictionary.modalSubscriptionCancelReasonShow,
        header: (<Header handleClick={closeModal}>
        <Typograf>{intl.formatMessage(messages.title)}</Typograf>
      </Header>),
        footer: <Footer {...payload}/>,
        children: <Content />,
    };
};
export const showSubscriptionCancelReasonModal = (payload) => modalManagerModel.showModalPageWithFixedFooter(payloadToModalManager(payload));
