import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { StudentModel } from '@common/entities/student';
import { PipeBuilder } from './lib';
import { showStudentFormModel, StudentFormGate } from '@common/entities/student/lib/show-student-form-modal/models';
export const preloadShowStudentFormModalPayload = async () => {
    const header = import('./ui/header');
    const footer = import('./ui/footer');
    const content = import('./ui/content');
    const promises = [header, footer, content];
    const [Header, Footer, Content] = await Promise.all(promises).then((result) => result.map((item) => item.default));
    if (!Header || !Footer || !Content) {
        return null;
    }
    const sourceData = StudentModel.$sourceData.getState();
    const pipeBuilder = new PipeBuilder();
    if (sourceData?.status === 'RETRY' && sourceData.rejectInfo) {
        pipeBuilder.map(sourceData.rejectInfo.problems);
    }
    else {
        pipeBuilder.defaultFlow();
    }
    return {
        analyticShowAttribute: '',
        header: <Header pipeBuilder={pipeBuilder}/>,
        children: <Content pipeBuilder={pipeBuilder}/>,
        footer: <Footer pipeBuilder={pipeBuilder}/>,
        gate: StudentFormGate,
        onClose: () => {
            modalManagerModel.closeModal();
            showStudentFormModel.clearAllModels();
        },
    };
};
export const showStudentFormModal = async () => {
    const payload = await preloadShowStudentFormModalPayload();
    if (!payload) {
        return;
    }
    modalManagerModel.showModalPageWithFixedFooter(payload);
};
