import { useEffect } from 'react';
import { LANDINGS_ROUTES } from '@website/shared/lib/routes';
import { getQueryParams } from '@common/utils/helpers/getQueryParams';
import axios from 'axios';
export const useMyTrackerRequest = (pathname) => {
    useEffect(() => {
        if (LANDINGS_ROUTES.includes(pathname)) {
            const params = getQueryParams();
            if ('mt_link_id' in params) {
                const reqUrl = `https://trk.mail.ru/c/${params['mt_link_id']}`;
                delete params['mt_link_id'];
                params['mt_no_redirect'] = '1';
                axios.get(reqUrl, { params });
            }
        }
    }, [pathname]);
};
