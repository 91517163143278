export const messages = {
    mobile: {
        title: 'Сделайте селфи со студенческим билетом',
        subtitle: 'Сфотографируйтесь с раскрытым документом так, чтобы ваше лицо и студенческий были хорошо видны',
        uploadButtonTitle: 'Сделать селфи со студенческим',
    },
    desktop: {
        title: 'Сделайте селфи со студенческим билетом',
        subtitle: 'Сфотографируйтесь с раскрытым документом так, чтобы ваше лицо и студенческий были хорошо видны',
        uploadButtonTitle: 'Прикрепить фото',
    },
};
