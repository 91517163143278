import { modalManagerTools } from '@common/services/modal-manager';
import tryToGetErrorsMessages from '@common/helpers/tryToGetErrorsMessages';
const { showMessageModal } = modalManagerTools.modalTemplates;
import { analyticsDictionary } from '@common/utils/Analytics';
import { intl } from '@common/shared/lib/intl';
import { messages } from './messages';
export const showSubscriptionRecoveryErrorModal = (errors) => {
    const { title, description } = tryToGetErrorsMessages(errors, {
        title: intl.formatMessage(messages.header),
        description: intl.formatMessage(messages.subheader),
    });
    showMessageModal({
        analyticShowAttribute: analyticsDictionary.modalSubscriptionRestoreError,
        mode: 'error',
        header: title,
        subheader: description,
    });
};
