import React from 'react';
const WIDTH_DEFAULT = 88;
const StudentsRejected = ({ width = WIDTH_DEFAULT }) => {
    return (<svg width={width} height={width} viewBox='0 0 88 88' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0.5 26.7643C0.5 14.1433 0.500007 7.83627 4.42759 3.92153C8.35518 0.00679657 14.6898 0 27.3521 0H45.8514C58.507 0 64.8347 3.24078e-07 68.7691 3.92153C72.7035 7.84307 72.724 14.1365 72.724 26.7643V53.3791C72.724 65.9932 72.7239 72.3003 68.7895 76.2218C64.8551 80.1434 58.5615 80.1434 45.8719 80.1434H27.3726C14.7102 80.1434 8.38247 80.1434 4.44807 76.2218C0.513664 72.3003 0.520476 66.0272 0.520476 53.3791L0.5 26.7643Z' fill='#2929B2'/>
      <path d='M0.5 26.7643C0.5 14.1433 0.500007 7.83627 4.42759 3.92153C8.35518 0.00679657 14.6898 0 27.3521 0H45.8514C58.507 0 64.8347 3.24078e-07 68.7691 3.92153C72.7035 7.84307 72.724 14.1365 72.724 26.7643V53.3791C72.724 65.9932 72.7239 72.3003 68.7895 76.2218C64.8551 80.1434 58.5615 80.1434 45.8719 80.1434H27.3726C14.7102 80.1434 8.38247 80.1434 4.44807 76.2218C0.513664 72.3003 0.520476 66.0272 0.520476 53.3791L0.5 26.7643Z' fill='#2929B2'/>
      <path d='M6.34375 28.5993C6.34375 17.725 6.34372 12.2879 9.63716 8.88966C12.9306 5.49146 18.2424 5.49146 28.8455 5.49146H44.3512C54.9612 5.49146 60.2662 5.49146 63.5597 8.88966C66.8531 12.2879 66.853 17.725 66.853 28.5993V51.5168C66.853 62.3911 66.8531 67.8282 63.5597 71.2264C60.2662 74.6246 54.9612 74.6246 44.3512 74.6246H28.8455C18.2424 74.6246 12.9374 74.6246 9.63716 71.2264C6.3369 67.8282 6.34375 62.3911 6.34375 51.5168V28.5993Z' fill='white'/>
      <path d='M37.725 51.7004H15.8574C14.7992 51.7004 13.9414 52.5555 13.9414 53.6102V53.617C13.9414 54.6718 14.7992 55.5268 15.8574 55.5268H37.725C38.7832 55.5268 39.6411 54.6718 39.6411 53.617V53.6102C39.6411 52.5555 38.7832 51.7004 37.725 51.7004Z' fill='#00EAFF'/>
      <path d='M25.8059 59.4688H15.8574C14.7992 59.4688 13.9414 60.3238 13.9414 61.3785V61.3853C13.9414 62.4401 14.7992 63.2951 15.8574 63.2951H25.8059C26.8641 63.2951 27.722 62.4401 27.722 61.3853V61.3785C27.722 60.3238 26.8641 59.4688 25.8059 59.4688Z' fill='#00EAFF'/>
      <path d='M58.6586 59.4688H33.252C32.1937 59.4688 31.3359 60.3238 31.3359 61.3785V61.3853C31.3359 62.4401 32.1937 63.2951 33.252 63.2951H58.6586C59.7168 63.2951 60.5746 62.4401 60.5746 61.3853V61.3785C60.5746 60.3238 59.7168 59.4688 58.6586 59.4688Z' fill='#00EAFF'/>
      <path d='M58.6573 17.6299H42.7833C41.7251 17.6299 40.8672 18.4849 40.8672 19.5397V19.5465C40.8672 20.6012 41.7251 21.4563 42.7833 21.4563H58.6573C59.7155 21.4563 60.5732 20.6012 60.5732 19.5465V19.5397C60.5732 18.4849 59.7155 17.6299 58.6573 17.6299Z' fill='#00EAFF'/>
      <path d='M45.0265 25.3982H42.7833C41.7251 25.3982 40.8672 26.2532 40.8672 27.308V27.3148C40.8672 28.3695 41.7251 29.2246 42.7833 29.2246H45.0265C46.0847 29.2246 46.9426 28.3695 46.9426 27.3148V27.308C46.9426 26.2532 46.0847 25.3982 45.0265 25.3982Z' fill='#00EAFF'/>
      <path d='M52.7317 33.1733H42.7833C41.7251 33.1733 40.8672 34.0284 40.8672 35.0831V35.0899C40.8672 36.1447 41.7251 36.9997 42.7833 36.9997H52.7317C53.7899 36.9997 54.6478 36.1447 54.6478 35.0899V35.0831C54.6478 34.0284 53.7899 33.1733 52.7317 33.1733Z' fill='#00EAFF'/>
      <path d='M58.6558 25.3982H51.0528C49.9946 25.3982 49.1367 26.2532 49.1367 27.308V27.3148C49.1367 28.3695 49.9946 29.2246 51.0528 29.2246H58.6558C59.714 29.2246 60.5717 28.3695 60.5717 27.3148V27.308C60.5717 26.2532 59.714 25.3982 58.6558 25.3982Z' fill='#00EAFF'/>
      <path d='M36.8644 27.4507V37.2443C36.8644 40.6426 36.8644 42.831 36.353 44.299C36.1902 44.7825 35.9357 45.2301 35.603 45.6175C35.3499 45.9107 35.0447 46.1549 34.7029 46.3379C33.4073 47.0584 31.4708 47.0584 28.2524 47.0584H22.3269C18.2698 47.0584 16.2378 47.0584 14.9764 45.6175C14.7636 45.3729 14.5846 45.1009 14.4445 44.8088C14.283 44.4817 14.1594 44.1374 14.0763 43.7825C13.7149 42.3349 13.7148 40.2891 13.7148 37.2443V27.4507C13.7148 22.8223 13.7149 20.5048 14.9764 19.0707C16.2378 17.6367 18.2698 17.6299 22.3269 17.6299H28.2524C32.3436 17.6299 34.3415 17.6299 35.603 19.0707C36.8645 20.5116 36.8644 22.8223 36.8644 27.4507Z' fill='#00EAFF'/>
      <path d='M36.8625 34.3083V37.2444C36.8625 40.6426 36.8625 42.831 36.3511 44.2991C36.1884 44.7825 35.9338 45.2302 35.6011 45.6176C35.348 45.9107 35.0428 46.1549 34.701 46.338C32.5535 46.4517 30.4009 46.4222 28.2573 46.2496C25.5299 46.0525 22.6387 45.7331 20.0749 45.4273C17.9133 45.1826 15.9836 44.9447 14.4835 44.8156C14.322 44.4886 14.1985 44.1443 14.1153 43.7893C13.7539 42.3417 13.7539 40.296 13.7539 37.2512V33.6831C14.0053 33.3652 14.2739 33.0611 14.5585 32.7723C18.377 29.4217 14.4357 28.3547 17.5519 25.9759C18.4381 25.5213 19.1796 24.8293 19.693 23.9778C20.5931 21.8437 22.5909 21.3068 25.2161 21.9389C25.5479 22.0224 25.8909 22.0522 26.2321 22.0272C29.5733 21.232 30.3643 23.4816 30.8416 24.3584C31.5235 25.5749 32.4372 25.6497 33.2146 26.207C36.3239 28.4023 35.0896 31.583 34.9942 32.1811C34.9732 32.3734 35.0068 32.5677 35.0912 32.742C35.1756 32.9162 35.3074 33.0633 35.4716 33.1665C35.985 33.4826 36.4528 33.8667 36.8625 34.3083Z' fill='#252040'/>
      <path d='M36.3509 44.2651C36.1882 44.7485 35.9337 45.1962 35.601 45.5836C35.3478 45.8767 35.0427 46.1209 34.7009 46.304C33.4053 47.0244 31.4688 47.0244 28.2504 47.0244H22.3249C18.2677 47.0244 16.2358 47.0244 14.9743 45.5836C14.7615 45.339 14.5826 45.0669 14.4425 44.7748C14.281 44.4478 14.1574 44.1035 14.0742 43.7486C15.6903 43.3 17.7223 42.8107 19.4815 42.4912C20.0338 42.3961 20.552 42.3145 21.0293 42.2534C21.5066 42.1922 21.8134 42.1718 22.1407 42.1582C22.961 42.3727 23.8108 42.453 24.6569 42.3961H25.4479C26.446 42.4931 27.4533 42.4218 28.4277 42.1854C28.6527 42.1854 28.8845 42.1854 29.1095 42.2466C29.7241 42.3197 30.3341 42.4264 30.9369 42.566C32.7806 43.0006 34.59 43.5685 36.3509 44.2651Z' fill='#CC61FA'/>
      <path d='M28.7222 41.8999C28.4015 40.4111 28.2664 38.8885 28.3199 37.3667C26.8471 37.2104 25.4356 37.7269 23.9627 37.5774L22.1557 36.8162C22.2175 38.4956 22.0268 40.1748 21.5898 41.7979C20.4034 42.1921 19.8511 45.2845 25.6197 45.3117C31.3883 45.3389 29.3631 42.1106 28.7222 41.8999Z' fill='#FCA190'/>
      <path d='M22.0625 39.2833C24.2172 40.5271 28.2812 38.7328 26.6516 38.726C25.0219 38.7192 22.1239 37.5027 22.1239 37.5027C22.1437 38.0969 22.1232 38.6919 22.0625 39.2833Z' fill='#F07875'/>
      <path d='M32.1193 30.8218C31.9373 30.6536 31.7038 30.5514 31.4564 30.5317C31.209 30.5119 30.9621 30.5758 30.7556 30.713C30.5033 28.8372 29.9101 27.1721 29.2419 26.4653C28.4304 25.5817 27.0258 24.3176 25.5052 24.3108C23.9846 24.304 22.1981 25.5885 21.414 26.4313C20.7321 27.1109 20.1389 28.81 19.8729 30.6995C19.777 30.6162 19.6654 30.5527 19.5447 30.5128C19.424 30.4728 19.2965 30.4571 19.1697 30.4666C19.0428 30.4761 18.9191 30.5105 18.8058 30.568C18.6924 30.6255 18.5916 30.7048 18.5092 30.8014C18.0319 31.3111 18.4342 32.9287 19.7911 33.52C19.8319 34.1912 19.9533 34.8552 20.1525 35.4977C20.7526 37.3667 22.3754 38.9639 25.3143 39.0454C28.2531 39.0454 29.8896 37.4075 30.4965 35.5385C30.7109 34.8777 30.8415 34.1927 30.8852 33.4996C32.1671 32.9151 32.6239 31.2568 32.1193 30.8218Z' fill='#FCA190'/>
      <path d='M23.5802 29.2314C23.9347 29.5644 23.6347 29.646 22.4141 29.6799C22.0323 29.6799 21.7322 29.7343 21.6368 29.6799C21.3504 29.3265 22.8847 28.7964 23.5802 29.2314Z' fill='#252040'/>
      <path d='M26.9479 29.2654C26.5797 29.5848 26.8797 29.6731 28.1071 29.7411C28.4957 29.7411 28.789 29.8159 28.8912 29.7411C29.1845 29.4217 27.6639 28.8508 26.9479 29.2654Z' fill='#252040'/>
      <path d='M25.2736 33.3637C24.8508 33.2889 24.5917 33.221 24.4962 33.0443C24.4008 32.8676 24.4963 32.6501 24.6054 32.4802C24.735 32.2914 24.8082 32.0699 24.8167 31.8413C24.8257 31.7911 24.8522 31.7456 24.8914 31.7128C24.9307 31.6801 24.9803 31.6621 25.0315 31.6621C25.0827 31.6621 25.1323 31.6801 25.1715 31.7128C25.2108 31.7456 25.2373 31.7911 25.2463 31.8413C25.2076 32.1298 25.1101 32.4073 24.9599 32.6569C24.9199 32.6922 24.8934 32.7402 24.8849 32.7928C24.919 32.8336 25.0281 32.8676 25.3076 32.9015C25.3377 32.9021 25.3672 32.9097 25.3937 32.9238C25.4202 32.9378 25.4431 32.9579 25.4604 32.9824C25.4776 33.0069 25.4889 33.0351 25.4932 33.0648C25.4974 33.0944 25.4946 33.1247 25.4849 33.153C25.4869 33.1812 25.4829 33.2095 25.473 33.236C25.4631 33.2624 25.4476 33.2865 25.4276 33.3065C25.4075 33.3265 25.3834 33.3419 25.3568 33.3518C25.3302 33.3617 25.3019 33.3657 25.2736 33.3637Z' fill='#F07875'/>
      <path d='M26.6105 26.7168C26.6312 27.1491 26.8159 27.5574 27.1274 27.859C27.4388 28.1607 27.8537 28.333 28.2879 28.3411C28.7226 28.3283 29.143 28.1825 29.4918 27.9236C29.8406 27.6646 30.1011 27.305 30.238 26.8935C30.3642 26.4785 30.3929 26.0402 30.322 25.6124C30.2511 25.1846 30.0823 24.7788 29.8289 24.4264C29.3188 23.7244 28.6047 23.1954 27.7833 22.9108C26.5305 22.4855 25.1712 22.4885 23.9203 22.9193C22.6694 23.3501 21.5981 24.1841 20.8759 25.2895C20.0099 26.6964 20.0577 28.1848 21.9056 28.3139C22.7437 28.3873 23.5881 28.302 24.3944 28.0625C24.7763 27.9401 26.6037 27.2469 26.6105 26.7168Z' fill='#252040'/>
      <path d='M31.8537 44.7681C26.5351 47.3575 23.4938 47.3439 18.7344 44.7137L20.0981 40.7514C20.1653 40.56 20.2723 40.385 20.4121 40.2376C20.5518 40.0903 20.7212 39.974 20.9092 39.8964C21.0971 39.8188 21.2993 39.7815 21.5027 39.7872C21.706 39.7928 21.9059 39.8412 22.0892 39.9291C24.1224 40.7977 26.4134 40.8513 28.4852 40.0786C28.8345 39.9519 29.2197 39.9662 29.5585 40.1186C29.8974 40.271 30.1631 40.5493 30.299 40.8941L31.8537 44.7681Z' fill='#CC61FA'/>
      <path d='M23.5267 34.9064C23.2676 34.4171 23.6154 34.152 24.0245 34.1113C25.4905 34.0161 26.752 33.9413 27.0861 34.2132C27.8157 34.7773 26.4043 35.8375 25.2587 35.9395C24.9051 35.9313 24.5595 35.8318 24.256 35.6507C23.9524 35.4696 23.7012 35.2132 23.5267 34.9064Z' fill='white'/>
      <path d='M22.6665 30.5159C23.0279 30.5159 23.3483 30.8557 23.3483 31.3246C23.3483 31.5049 23.2765 31.6778 23.1487 31.8052C23.0208 31.9327 22.8473 32.0043 22.6665 32.0043C22.3051 32.0383 22.0528 31.882 22.0256 31.4062C22.0008 31.2047 22.053 31.0013 22.1717 30.8363C22.2905 30.6714 22.467 30.557 22.6665 30.5159Z' fill='#252040'/>
      <path d='M27.8375 30.5499C27.4761 30.5159 27.1556 30.8625 27.1078 31.3383C27.0601 31.814 27.2851 32.0179 27.7897 32.0587C27.8751 32.0796 27.9644 32.0799 28.05 32.0595C28.1355 32.0391 28.215 31.9987 28.2817 31.9415C28.3484 31.8843 28.4004 31.8121 28.4334 31.7308C28.4665 31.6495 28.4795 31.5616 28.4716 31.4742C28.5077 31.2681 28.4616 31.0562 28.3431 30.8835C28.2247 30.7108 28.0432 30.5911 27.8375 30.5499Z' fill='#252040'/>
      <path d='M64.8479 43.4087C60.5727 43.4099 56.3832 44.604 52.7538 46.856C49.1244 49.1079 46.1999 52.3276 44.3116 56.1506C42.4233 59.9735 41.6466 64.2471 42.0692 68.4874C42.4919 72.7277 44.0972 76.7654 46.7033 80.1433C58.7861 80.1433 64.9297 80.0482 68.7686 76.2218C72.6076 72.3954 72.703 66.0272 72.703 53.379V44.7884C70.1858 43.8741 67.5271 43.4071 64.8479 43.4087Z' fill='#2929B2'/>
      <path d='M66.854 87.9999C78.2571 87.9999 87.5011 78.7861 87.5011 67.4204C87.5011 56.0546 78.2571 46.8408 66.854 46.8408C55.4509 46.8408 46.207 56.0546 46.207 67.4204C46.207 78.7861 55.4509 87.9999 66.854 87.9999Z' fill='white'/>
      <path d='M74.9672 62.8126C75.0809 62.3214 75.008 61.8057 74.7626 61.3649C74.5483 60.9407 74.2263 60.5798 73.8285 60.3183C73.4213 60.017 72.9136 59.8832 72.4102 59.9445C71.2101 60.0804 70.0168 61.1338 67.6371 63.2407L62.6048 67.6856C60.225 69.7993 59.0318 70.8527 58.7523 72.0285C58.6325 72.5186 58.7031 73.0358 58.95 73.4761C59.1679 73.8979 59.4892 74.258 59.8841 74.5228C60.2943 74.8179 60.7996 74.9511 61.3025 74.8966C62.5094 74.7607 63.7027 73.7072 66.0756 71.6003L71.1077 67.1487C73.4943 65.0418 74.6876 63.9883 74.9672 62.8126Z' fill='#FF3985'/>
      <path d='M74.9672 72.0285C75.0809 72.5196 75.008 73.0354 74.7626 73.4761C74.5467 73.8993 74.2251 74.2598 73.8285 74.5228C73.4184 74.8179 72.9131 74.951 72.4102 74.8966C71.2101 74.7606 70.0168 73.7072 67.6371 71.6003L62.6048 67.1486C60.225 65.0418 59.0318 63.9883 58.7523 62.8125C58.6325 62.3224 58.7031 61.8053 58.95 61.3649C59.1662 60.9419 59.4878 60.5815 59.8841 60.3182C60.2916 60.0176 60.7991 59.8839 61.3025 59.9444C62.5094 60.0804 63.7027 61.1338 66.0756 63.2407L71.1077 67.6856C73.4943 69.7992 74.6876 70.8527 74.9672 72.0285Z' fill='#FF3985'/>
    </svg>);
};
export default StudentsRejected;
