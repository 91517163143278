import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';
import { modifyNextSearchParams } from '@common/shared/lib/modify-next-search-params';
import { showCheckoutModal } from '@common/entities/checkout';
import { getDataPulling } from '@common/components/Root/actions';
import { commonShowSuccessfulPaymentModalParams } from '@common/entities/checkout/hooks';
import { ROUTES } from '@website/shared/lib/routes';
import { modalManagerTools } from '@common/services/modal-manager';
import { Context } from './lib/context';
import { showSuccessfulPaymentModal } from '@common/data-layer/payment-method';
const CheckoutModalDetector = ({ children }) => {
    const { query, pathname } = useRouter();
    const checkoutType = pathname === ROUTES.gift ? 'gift' : 'offer';
    const showSuccessModal = (type) => {
        const params = commonShowSuccessfulPaymentModalParams(type);
        return showSuccessfulPaymentModal(params);
    };
    const handleSuccess = ({ type }) => {
        showSuccessModal(type);
        modifyNextSearchParams({ paramsToRemove: ['paymentId'] });
        void getDataPulling({ all: true });
    };
    const handleError = () => {
        modalManagerTools.modalTemplates.showErrorModal({ checkoutType });
    };
    const defaultCallbacks = {
        handleSuccess,
        handleError,
    };
    const callbacks = useRef(defaultCallbacks);
    const reset = () => {
        callbacks.current = defaultCallbacks;
    };
    const setHandleError = (fn) => {
        callbacks.current.handleError = fn;
    };
    const setHandleSuccess = (fn) => {
        callbacks.current.handleSuccess = fn;
    };
    const showCheckout = (paymentId) => showCheckoutModal({ paymentId, type: checkoutType, ...callbacks.current });
    useEffect(() => {
        const paymentId = query?.paymentId;
        if (!paymentId || Array.isArray(paymentId)) {
            return;
        }
        void showCheckout(paymentId);
    }, [query]);
    return <Context.Provider value={{ reset, setHandleError, setHandleSuccess }}>{children}</Context.Provider>;
};
export default CheckoutModalDetector;
