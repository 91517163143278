import { intl } from '@common/shared/lib/intl';
import { messages } from './messages';
export const BUTTONS_INIT = [
    {
        elementKey: 'home',
        text: intl.formatMessage(messages.home),
    },
    {
        elementKey: 'faq',
        text: intl.formatMessage(messages.faq),
    },
    {
        elementKey: 'other_offers',
        text: intl.formatMessage(messages.otherTariffs),
    },
    {
        elementKey: 'paymethod',
        text: intl.formatMessage(messages.paymentMethod),
    },
    {
        elementKey: 'notifications',
        text: intl.formatMessage(messages.notifications),
    },
];
