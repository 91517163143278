import { createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { reset } from 'patronum';
import { createSteps } from './contants';
const gate = createGate({});
const $step = createStore('one');
const setStep = createEvent();
const steps = createSteps((step) => setStep(step));
const $stepValue = $step.map((step) => steps[step]);
sample({ clock: setStep, target: $step });
reset({ clock: gate.close, target: $step });
export const showStudentStatusReadyModal = {
    $stepValue,
    gate,
};
