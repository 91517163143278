import { analytic } from '@analytics';
const ANALYTICS_EVENT_SUBJECT_NAME = 'front_checkout_form';
export const analyticsEvent = (params) => {
    const { result, time } = params;
    void analytic.addEvent({
        subject: ANALYTICS_EVENT_SUBJECT_NAME,
        payload: {
            result,
            time,
            error_type: result === 'fail' ? params.errorType : undefined,
        },
    });
};
