import { useEffect, useMemo, useState } from 'react';
import { useUnit } from 'effector-react';
import cn from 'classnames';
import { AuthChecker } from '@common/entities/auth-state';
import { headerPrivateModel } from '@website/widgets/header/model';
import { AuthButton } from '@website/features/user';
import { MenuUserCard } from '@website/widgets/header/ui/menu-user-card';
import { Navigation } from '@website/widgets/header/ui/navigation';
import { BurgerMenu } from '@website/widgets/header/ui/burger-menu';
import styles from './styles.module.scss';
import { modalManagerTools } from '@common/services/modal-manager';
const InlineMenu = ({ linksClass }) => {
    const setStateHeaderPrivateModel = useUnit(headerPrivateModel.setState);
    const state = useUnit(headerPrivateModel.$state);
    const activeModalId = useUnit(modalManagerTools.utils.getActiveModalId());
    const [inlineMenuState, setInlineMenuState] = useState('init');
    const isOut = inlineMenuState === 'out';
    const isGoInit = inlineMenuState === 'go-init';
    const isHasActiveModal = useMemo(() => Boolean(activeModalId), [activeModalId]);
    const handleChangeOutState = () => {
        if (isHasActiveModal) {
            return;
        }
        if (window.scrollY > 50 && state === 'init') {
            setInlineMenuState('out');
            setStateHeaderPrivateModel('scroll');
            return;
        }
        if (window.scrollY <= 50 && state === 'scroll') {
            setInlineMenuState('go-init');
            setStateHeaderPrivateModel('init');
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleChangeOutState);
        return () => {
            window.removeEventListener('scroll', handleChangeOutState);
        };
    }, [handleChangeOutState]);
    return (<div className={cn(styles.inlineMenu)}>
      <div className={styles.leftBlock}>
        <Navigation className={cn(styles.navigation, isOut && styles.navigation__out, isGoInit && styles.navigation__goInit)} linksClass={linksClass}/>
      </div>
      <div className={styles.rightBlock}>
        <div className={cn(styles.userBlock, isOut && styles.userBlock__out, isGoInit && styles.userBlock__goInit)}>
          <AuthChecker authRender={<MenuUserCard />} unAuthRender={<AuthButton>Войти в личный кабинет</AuthButton>} fallback={<div className={styles.fallback}/>}/>
        </div>
        <div className={cn(styles.burgerMenu, isOut && styles.burgerMenu__out, isGoInit && styles.burgerMenu__goInit)}>
          <BurgerMenu />
        </div>
      </div>
    </div>);
};
export default InlineMenu;
