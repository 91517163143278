const tryToGetErrorsMessages = (errorOrErrors, defaultValues) => {
    let error = { title: undefined, detail: undefined };
    const defaultTitle = defaultValues?.title;
    const defaultDescription = defaultValues?.description;
    if (Array.isArray(errorOrErrors)) {
        error = errorOrErrors[0];
    }
    else if (typeof errorOrErrors === 'object') {
        error = errorOrErrors;
    }
    const { title, detail, buttons, code } = error;
    let resultTitle = undefined;
    let resultDescription = undefined;
    let resultButtons;
    if (title || detail || buttons?.length) {
        if (typeof title === 'string') {
            resultTitle = title;
        }
        if (typeof detail === 'string') {
            resultDescription = detail;
        }
        if (buttons?.length) {
            resultButtons = buttons;
        }
    }
    else {
        resultTitle = defaultTitle;
        resultDescription = defaultDescription;
        resultButtons = undefined;
    }
    return {
        title: resultTitle,
        description: resultDescription,
        buttons: resultButtons,
        code,
    };
};
export default tryToGetErrorsMessages;
