import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { StudentsUnderConsiderationIcon } from '../../icons';
import { studentAnalytics } from '../analytics';
import { Actions, Header, Subheader } from './ui';
export const showStudentStatusWaitModal = () => {
    modalManagerModel.showModal({
        analyticShowAttribute: studentAnalytics.wait.show,
        icon: <StudentsUnderConsiderationIcon />,
        header: <Header />,
        subheader: <Subheader />,
        actions: <Actions />,
    });
};
