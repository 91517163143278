import { defineMessages } from 'react-intl';
const messages = defineMessages({
    defaultTitle: {
        id: 'defaultTitle',
        defaultMessage: 'Подписка оформлена',
    },
    defaultDescription: {
        id: 'defaultDescription',
        defaultMessage: 'Подписка продлится автоматически',
    },
    coloredText: {
        id: 'coloredText',
        defaultMessage: 'Скачайте{br} приложение{br}VK Музыка,{br}это удобнее',
    },
    benefitsText: {
        id: 'benefitsText',
        defaultMessage: 'Музыка, подкасты{br}и ничего лишнего',
    },
    playlistFeatureText: {
        id: 'playlistFeatureText',
        defaultMessage: 'Ваши плейлисты уже там',
    },
    appFeatureText: {
        id: 'appFeatureText',
        defaultMessage: 'Подписка активна и в приложении',
    },
    footerButtonText: {
        id: 'footerButtonText',
        defaultMessage: 'Скачать приложение',
    },
});
export default messages;
