import { createEvent, sample } from 'effector';
import { fieldsModel } from './fields';
import { errorsFieldsStatusModel } from './errors-fields-status';
import { sendFormFx } from './api';
const clear = createEvent();
sample({
    clock: fieldsModel.setFields,
    fn: (fields) => {
        return Object.keys(fields).reduce((sum, key) => {
            const errorStatus = `${key}Status`;
            sum[errorStatus] = null;
            return sum;
        }, {});
    },
    target: errorsFieldsStatusModel.setErrors,
});
sample({ clock: clear, target: [fieldsModel.clear, errorsFieldsStatusModel.clear] });
export const formModel = {
    fields: fieldsModel,
    errors: errorsFieldsStatusModel,
    clear,
    sendFormFx,
};
