import { createMainStudentElementAnalyticString } from './constants';
const NAME_FORM_ELEMENT = 'form';
const NAME_ERROR_ELEMENT = 'banner_err';
const getMainPrefix = createMainStudentElementAnalyticString();
const BUTTON_MAIN_NAME_ELEMENT = 'button_fw';
var NAME_MODALS;
(function (NAME_MODALS) {
    NAME_MODALS["fill"] = "fill";
    NAME_MODALS["studentId"] = "add_id";
    NAME_MODALS["studentIdWithSelfie"] = "add_selfie_id";
    NAME_MODALS["recordBook"] = "add_book";
    NAME_MODALS["recordBookWithSelfie"] = "add_selfie_book";
    NAME_MODALS["result"] = "submit";
    NAME_MODALS["areYouSureYouAreStudent"] = "confirm_step_1";
    NAME_MODALS["doYouStudyIUniversity"] = "confirm_step_2";
})(NAME_MODALS || (NAME_MODALS = {}));
const createErrorString = (errorType) => errorType ? `${NAME_ERROR_ELEMENT}_${errorType}` : `${NAME_ERROR_ELEMENT}`;
const createErrorBannerString = (getBodyElementString) => (status, errorType) => `${getBodyElementString(status)}-${createErrorString(errorType)}`;
const createFormString = (status, subElementName) => `${getMainPrefix(status)}_${NAME_FORM_ELEMENT}_${subElementName}`;
const createFormCLickString = (getBodyElementString) => (status) => `${getBodyElementString(status)}-${BUTTON_MAIN_NAME_ELEMENT}`;
const createBodyElementString = (bodyName) => (status) => createFormString(status, bodyName);
const areYouSureYouAreStudentShow = (status) => `${getMainPrefix(status)}_${NAME_MODALS.areYouSureYouAreStudent}`;
const areYouSureYouAreStudentClick = createFormCLickString(areYouSureYouAreStudentShow);
const doYouStudyIUniversityShow = (status) => `${getMainPrefix(status)}_${NAME_MODALS.doYouStudyIUniversity}`;
const doYouStudyIUniversityClick = createFormCLickString(doYouStudyIUniversityShow);
const fillShow = createBodyElementString(NAME_MODALS.fill);
const fillClick = createFormCLickString(fillShow);
const studentIdShow = createBodyElementString(NAME_MODALS.studentId);
const studentIdClick = createFormCLickString(studentIdShow);
const studentIdErrorBannerShow = createErrorBannerString(studentIdShow);
const studentIdWithSelfieShow = createBodyElementString(NAME_MODALS.studentIdWithSelfie);
const studentIdWithSelfieClick = createFormCLickString(studentIdWithSelfieShow);
const studentIdWithSelfieErrorBannerShow = createErrorBannerString(studentIdWithSelfieShow);
const recordBookShow = createBodyElementString(NAME_MODALS.recordBook);
const recordBookClick = createFormCLickString(recordBookShow);
const recordBookErrorBannerShow = createErrorBannerString(recordBookShow);
const recordBookWithSelfieShow = createBodyElementString(NAME_MODALS.recordBookWithSelfie);
const recordBookWithSelfieClick = createFormCLickString(recordBookWithSelfieShow);
const recordBookWithSelfieErrorBannerShow = createErrorBannerString(recordBookWithSelfieShow);
const resultShow = createBodyElementString(NAME_MODALS.result);
const resultClick = (status) => `${resultShow(status)}-button_submit`;
const fill = {
    show: fillShow,
    click: fillClick,
};
const studentId = {
    show: studentIdShow,
    click: studentIdClick,
    errorBannerShow: studentIdErrorBannerShow,
};
const studentIdWithSelfie = {
    show: studentIdWithSelfieShow,
    click: studentIdWithSelfieClick,
    errorBannerShow: studentIdWithSelfieErrorBannerShow,
};
const recordBook = {
    show: recordBookShow,
    click: recordBookClick,
    errorBannerShow: recordBookErrorBannerShow,
};
const recordBookWithSelfie = {
    show: recordBookWithSelfieShow,
    click: recordBookWithSelfieClick,
    errorBannerShow: recordBookWithSelfieErrorBannerShow,
};
const result = {
    show: resultShow,
    click: resultClick,
};
const areYouSureYouAreStudent = {
    show: areYouSureYouAreStudentShow,
    click: areYouSureYouAreStudentClick,
};
const doYouStudyIUniversity = {
    show: doYouStudyIUniversityShow,
    click: doYouStudyIUniversityClick,
};
export const studentFormAnalytics = {
    fill,
    studentId,
    studentIdWithSelfie,
    recordBook,
    recordBookWithSelfie,
    result,
    areYouSureYouAreStudent,
    doYouStudyIUniversity,
};
