import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { RawIntlProvider } from 'react-intl';
import { useAnalyticGlobalClick, useAnalyticGlobalShow } from '@analytics';
import { attachReduxDevTools } from '@effector/redux-devtools-adapter';
import i18n from '@common/shared/lib/i18next';
import { intl } from '@common/shared/lib/intl';
import { IS_DEVELOPMENT } from '@common/consts';
import '@common/assets/scss/global.scss';
const ApplicationConfigCommon = (props) => {
    const { children } = props;
    useAnalyticGlobalClick();
    useAnalyticGlobalShow();
    useEffect(() => {
        try {
            IS_DEVELOPMENT &&
                attachReduxDevTools({
                    name: process.env.NEXT_PUBLIC_ANALYTIC_PLATFORM_SOURCE ?? 'vkmusic',
                    trace: true,
                });
        }
        catch (e) {
        }
    }, []);
    return (<I18nextProvider i18n={i18n} defaultNS={'translation'}>
      <RawIntlProvider value={intl}>{children}</RawIntlProvider>
    </I18nextProvider>);
};
export default ApplicationConfigCommon;
