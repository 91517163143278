import { createMainStudentElementAnalyticString } from '@common/entities/student/lib/analytics/constants';
export const MAIN_NAME_ELEMENT = 'main-banner_main';
const getMainPrefix = createMainStudentElementAnalyticString(MAIN_NAME_ELEMENT);
export const POSTFIX = 'banner_student_status';
const createString = (status) => {
    return `${getMainPrefix(status)}-${POSTFIX}`;
};
const createConfirmString = (status, offerId) => {
    return offerId ? `${createString(status)}_${offerId}` : createString(status);
};
export const studentStatusInfoBannerAnalytic = {
    show: (status) => createString(status),
    applyOffer: (status, offerId) => createConfirmString(status, offerId),
    applyOfferClick: (status, offerId) => `${createConfirmString(status, offerId)}-button`,
    uploadDocumentClick: `${createString('RETRY')}-button`,
};
