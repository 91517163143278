import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    header: {
        id: 'header',
        defaultMessage: 'Не удалось восстановить подписку',
    },
    subheader: {
        id: 'subheader',
        defaultMessage: 'Попробуйте еще раз позже',
    },
});
