import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DATE_FORMAT_LONG, DATE_FORMAT_SHORT } from '@common/consts';
const useDateFormat = (stringOrDate) => {
    const intl = useIntl();
    return useMemo(() => {
        let date = null;
        if (!stringOrDate) {
            return null;
        }
        const specificDate = new Date(stringOrDate.toString().replace(/-/g, '/'));
        const currentDate = new Date();
        if (specificDate.getFullYear() !== currentDate.getFullYear()) {
            date = intl.formatDate(stringOrDate, DATE_FORMAT_LONG);
            if (intl.locale === 'ru') {
                date = date.replace(/ г\.$/, '');
            }
        }
        else {
            date = intl.formatDate(stringOrDate, DATE_FORMAT_SHORT);
        }
        return date;
    }, [stringOrDate]);
};
export default useDateFormat;
