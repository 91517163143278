import { defineMessages } from 'react-intl';
const messages = defineMessages({
    paymentHistoryHeader: {
        id: 'paymentHistoryHeader',
        defaultMessage: 'История операций',
    },
    errorTitle: {
        id: 'errorTitle',
        defaultMessage: 'Не удалось загрузить {br}историю платежей',
    },
    errorButtonText: {
        id: 'errorButtonText',
        defaultMessage: 'Повторить',
    },
});
export default messages;
