import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { headerModel } from '@website/entities/header/model';
export const useLogoObserver = ({ root = null, target, callback, threshold = 1, rootMargin }) => {
    const setShowLogo = useUnit(headerModel.setShowLogo);
    useEffect(() => {
        if (!target) {
            return;
        }
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(({ isIntersecting }) => {
                setShowLogo(!isIntersecting);
                callback && callback(isIntersecting);
            });
        }, {
            root,
            threshold,
            rootMargin,
        });
        observer.observe(target);
        return () => {
            observer.disconnect();
            headerModel.$showLogo.reset();
        };
    }, [target, root, callback]);
};
