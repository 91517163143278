import api from '@common/utils/ApiCommon';
import { getDataPulling } from '@common/components/Root/actions';
import { SubscriptionsModel } from '../model';
export const cancelSubscription = async (params) => {
    const subscriptions = SubscriptionsModel.$subscriptions.getState();
    const { subscriptionId, reasons } = params;
    const paramsToSend = {
        reasons,
    };
    const subscription = subscriptions.find((subscription) => subscription.id === subscriptionId);
    if (subscription && !subscription.own) {
        paramsToSend.vk_subscription_id = subscriptionId;
    }
    const disableRecurrentResult = await api.disableSubscriptionRecurrent(paramsToSend);
    if (!disableRecurrentResult.data.successful) {
        return Promise.reject(disableRecurrentResult.data.errors);
    }
    return getDataPulling({ all: true });
};
