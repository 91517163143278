import React from 'react';
import { ButtonGroup } from '@vkontakte/vkui';
import ModalButton from '@common/components/modals/ModalButton';
import { modalManagerModel } from '@common/services/modal-manager';
import Router from 'next/router';
import { history } from '@common/shared/lib/history';
const buttonActionsMap = (button) => {
    switch (button.type) {
        case 'redirect': {
            window.open(button?.params?.url || '', '_blank');
            return;
        }
        case 'routing': {
            const navigate = (to) => (history.navigate ? history.to(to) : Router.push(to));
            navigate(button.params?.url ?? '/');
            modalManagerModel.closeModal();
            break;
        }
        case 'close':
        default: {
            modalManagerModel.closeModal();
        }
    }
};
const BackendErrorButton = (props) => {
    const { buttons } = props;
    return (<ButtonGroup stretched={true} mode={'vertical'}>
      {buttons?.map((button) => {
            return (<ModalButton key={button.title} onClick={() => buttonActionsMap(button)}>
            {button.title}
          </ModalButton>);
        })}
    </ButtonGroup>);
};
export const getProcessingBackendErrorsActions = (params) => {
    const { buttons } = params;
    if (!buttons || buttons.length === 0) {
        return undefined;
    }
    return <BackendErrorButton buttons={buttons}/>;
};
