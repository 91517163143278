import { createEffect, createStore } from 'effector';
import apiCommon from '@common/utils/ApiCommon';
import { KeywordsKeeper } from '@common/entities/offers';
export const $inactiveSubscriptionOffers = createStore([]);
export const loadOffersFx = createEffect(async () => {
    try {
        const offersKey = await KeywordsKeeper.getKeywordsByDictionaryKey('subscriptionDetails');
        const offers = await apiCommon.getOffers({ keywords: [offersKey] });
        if (offers.data.success && offers.data.payload) {
            return offers.data.payload;
        }
        return [];
    }
    catch {
        return [];
    }
});
$inactiveSubscriptionOffers.on(loadOffersFx.doneData, (_state, payload) => payload);
