import { defineMessages } from 'react-intl';
const messages = defineMessages({
    header: {
        id: 'header',
        defaultMessage: 'Отменить подписку на двоих?',
    },
    subheaderSuspendedGroupSub: {
        id: 'subheaderSuspendedGroupSub',
        defaultMessage: 'Вы и другой участник подписки не сможете пользоваться её преимуществами.',
    },
    leaveSubForTwoBtnText: {
        id: 'leaveSubForTwoBtnText',
        defaultMessage: 'Оставить подписку на двоих',
    },
    cancelSubBtnText: {
        id: 'cancelSubBtnText',
        defaultMessage: 'Отменить подписку',
    },
});
export default messages;
