import { groupSubscriptionFlowModel } from '@common/features/group-subscription-flow';
export class GroupSubscriptionStrategy {
    do(state) {
        const redirectState = state;
        if (!redirectState) {
            return;
        }
        if (redirectState.flow !== 'group_subscription')
            return;
        const { params: { offerName, correlationId }, } = redirectState;
        void groupSubscriptionFlowModel.start({ correlationId, offerName });
    }
}
