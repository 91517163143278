export const NO_INDEX_META_TAGS = [
    {
        name: 'robots',
        content: 'noindex, nofollow',
    },
    {
        name: 'googlebot',
        content: 'noindex',
    },
    {
        name: 'yandex',
        content: 'noindex',
    },
];
