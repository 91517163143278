import { showDescriptionAdditionalLoadingModal } from '@common/entities/student/lib';
import { showStudentRejectModal } from '../show-student-reject-modal';
import { showStudentFormModal } from '../show-student-form-modal';
export class RetryStrategy {
    async do(payload) {
        if (payload.statusData.status === 'RETRY' && payload.statusData.rejectInfo) {
            const params = {
                ...payload.statusData.rejectInfo.modalWidget,
                onClick: () => {
                    showStudentFormModal();
                },
            };
            showDescriptionAdditionalLoadingModal(params);
            return;
        }
        showStudentRejectModal({
            status: 'RETRY',
            tryAfterDays: payload.statusData.try_after,
            activeSubscription: payload.activeSubscription,
        });
    }
}
