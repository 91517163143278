import React, { useCallback, useEffect } from 'react';
import { Div, Headline, MiniInfoCell, Separator, Spacing } from '@vkontakte/vkui';
import { Icon20PlaceOutline, Icon20UserCircleOutline, Icon28BuildingOutline } from '@vkontakte/icons';
import { useAnalyticShowDirect } from '@analytics';
import { useIntl } from 'react-intl';
import { useUnit } from 'effector-react';
import { VkuiPlatformChooser } from '@common/shared/ui/vkui-platform-chooser';
import waitForResult from '@common/helpers/waitForResult';
import { showStudentStatusWaitModal, studentAnalytics, StudentApi, StudentModel } from '@common/entities/student';
import { ImagePreview } from '../ImagePreview';
import { NextStepButton } from '../next-step-button';
import { AcceptRules } from '../accept-rules';
import messages from './messages';
import { getApiMethod } from './lib';
import styles from './styles.module.scss';
import { footerModel, formModel, nextStepButtonModel, showStudentFormModel, uploadDocumentModel, } from '@common/entities/student/lib/show-student-form-modal/models';
import { modalManagerModel } from '@common/services/modal-manager';
import { useOffFooterModalOnSmallScreen } from '../../hooks/use-off-footer-modal-on-small-screen';
let clearWaiting = null;
const Result = () => {
    const intl = useIntl();
    const { $status: studentStatus } = useUnit({ ...StudentModel });
    const getStudentData = useUnit(StudentApi.getStudentsStatusFx);
    const { $fields: fields } = useUnit({ ...formModel.fields });
    const footerState = useUnit({ ...footerModel });
    const nextStepButtonState = useUnit({ ...nextStepButtonModel });
    const showStudentFormState = useUnit({ ...showStudentFormModel });
    const { $previewList: previewList } = useUnit({ ...uploadDocumentModel });
    const { closeModal } = useUnit({ ...modalManagerModel });
    const { firstName, lastName, cityName, establishmentName, middleName } = fields;
    const hasPreviewList = previewList.length > 0;
    const isShowFields = [firstName, lastName, cityName, establishmentName, middleName].some(Boolean);
    const fio = `${intl.formatMessage(messages.fioTitle)}: ${lastName} ${firstName} ${middleName}`.trim();
    const showSuccessModal = () => {
        showStudentStatusWaitModal();
    };
    const setError = (errors) => {
        footerState.setState({ errors });
    };
    const confirm = useCallback(async () => {
        try {
            const confirmMethod = getApiMethod(studentStatus);
            const result = await confirmMethod();
            const { data } = result;
            if (!data.successful) {
                setError(data?.errors?.map((error) => error.detail) ?? []);
                return;
            }
            await new Promise((resolve, reject) => {
                clearWaiting = waitForResult(getStudentData, {
                    params: [],
                    handleResponse: (response) => {
                        const { status } = response;
                        if (status === 'WAIT') {
                            clearWaiting?.();
                            void getStudentData();
                            closeModal();
                            showStudentFormState.clearAllModels();
                            showSuccessModal();
                            resolve({});
                            return false;
                        }
                        return true;
                    },
                    handleError: () => {
                        clearWaiting?.();
                        reject();
                        return false;
                    },
                });
                return { nextClickStatus: 'none' };
            });
        }
        catch {
            clearWaiting?.();
            setError([]);
        }
    }, []);
    useEffect(() => {
        nextStepButtonState.setState({
            click: async () => {
                await confirm();
                return { nextClickStatus: 'none' };
            },
            analytic: studentAnalytics.form.result.click(studentStatus),
        });
    }, [confirm]);
    useEffect(() => {
        footerState.setState({ showRules: true });
        return () => {
            clearWaiting?.();
            footerState.setState({ showRules: false });
        };
    }, []);
    useAnalyticShowDirect(studentAnalytics.form.result.show(studentStatus));
    useOffFooterModalOnSmallScreen();
    return (<div className={styles.result}>
      <div>
        {isShowFields && (<div>
            <Div>
              <Headline level={'2'} weight={'2'} Component='h4'>
                {intl.formatMessage(messages.headline)}
              </Headline>
            </Div>
            <MiniInfoCell before={<Icon20UserCircleOutline />} textWrap='full' mode='accent'>
              {fio}
            </MiniInfoCell>
            <MiniInfoCell before={<Icon20PlaceOutline />} textWrap='full' mode='accent'>
              {intl.formatMessage(messages.cityTitle)}: {cityName}
            </MiniInfoCell>
            <MiniInfoCell before={<Icon28BuildingOutline width={20} height={20}/>} textWrap='full' mode='accent'>
              {establishmentName}
            </MiniInfoCell>
            {hasPreviewList && (<Spacing size={16}>
                <Separator />
              </Spacing>)}
          </div>)}
        {hasPreviewList && (<div>
            <Div>
              <Headline level={'2'} weight={'2'} Component='h4'>
                {intl.formatMessage(messages.yourPhotosTitle)}
              </Headline>
            </Div>
            <Div>
              <div className={styles.photosWrap}>
                {previewList.map((preview) => (<ImagePreview key={preview} imageUrl={preview} className={styles.photo}/>))}
              </div>
            </Div>
          </div>)}
      </div>
      <VkuiPlatformChooser desktop={null} mobile={<div className={styles.footer}>
            <NextStepButton />
            <AcceptRules />
          </div>}/>
    </div>);
};
export default Result;
