import React, { useEffect } from 'react';
import { createGate, useGate, useStoreMap, useUnit } from 'effector-react';
import { callAnalyticShowDirect } from '@analytics';
import ModalPageWithFixedFooter from '../ModalPageWithFixedFooter';
import { vkUIModalManager } from '@common/services/vk-ui-modal-manager';
export const ModalManagerPageWithFixedFooter = (props) => {
    const { id, settlingHeight } = props;
    const modalContent = useStoreMap(vkUIModalManager.modalPageWithFixedFooter.$modalsPayload, (state) => state[id]);
    const closeModal = useUnit(vkUIModalManager.closeModal);
    const { analyticShowAttribute, children, gate, ...modalPageWithFixedFooterProps } = modalContent || {};
    useGate(gate ?? createGate({}));
    useEffect(() => {
        if (analyticShowAttribute) {
            callAnalyticShowDirect(analyticShowAttribute);
        }
    }, [analyticShowAttribute]);
    return (<ModalPageWithFixedFooter id={id} settlingHeight={settlingHeight} {...modalPageWithFixedFooterProps} onClose={modalPageWithFixedFooterProps?.onClose ?? closeModal}>
      {children}
    </ModalPageWithFixedFooter>);
};
