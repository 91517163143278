import React from 'react';
import classNames from 'classnames';
import useIsSmallScreen from '@common/hooks/useIsSmallScreen';
import { Group, Headline } from '@vkontakte/vkui';
import Typograf from '../Typograf';
import styles from './styles.module.scss';
const PagePlaceholder = (props) => {
    const { title, description } = props;
    const isSmallScreen = useIsSmallScreen();
    return (<Group className={classNames(styles.container, {
            [styles.container_mobile]: isSmallScreen,
        })}>
      <div className={styles.centered}>
        <Headline level='1' weight='2' className={styles.title} Component='h4'>
          <Typograf>{title}</Typograf>
        </Headline>
        <Headline level='2' className={styles.description} weight='3' Component='h4'>
          <Typograf>{description}</Typograf>
        </Headline>
      </div>
    </Group>);
};
export default PagePlaceholder;
