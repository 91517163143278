import React, { useState } from 'react';
import { createAnalyticClickAttribute } from '@analytics';
import ModalButton from '@common/components/modals/ModalButton';
import { linkPaymentMethod } from '@common/components/Root/actions';
import { CallAfterBoundCardModel } from '@common/entities/checkout';
const LinkCardButton = (props) => {
    const { buttonAnalytic, callbackAfterBoundCardModel } = props;
    const [loading, setLoading] = useState(false);
    const onClick = async () => {
        setLoading(true);
        CallAfterBoundCardModel.setCallback(callbackAfterBoundCardModel);
        try {
            await linkPaymentMethod('bound_card_before_action');
        }
        finally {
            setLoading(false);
        }
    };
    return (<ModalButton {...createAnalyticClickAttribute(buttonAnalytic)} size={'l'} stretched={true} disabled={loading} loading={loading} onClick={onClick}>
      Добавить способ оплаты
    </ModalButton>);
};
export default LinkCardButton;
