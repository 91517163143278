import apiVkBridge from '@common/utils/ApiVkBridge';
import { IS_MINIAPP } from '@common/consts';
const getOffersKeyDependsOnPlatform = async ({ base, ios, android }) => {
    if (IS_MINIAPP) {
        const platform = await apiVkBridge.getClientVersion();
        switch (platform.platform) {
            case 'ios': {
                return ios ?? base;
            }
            case 'android': {
                return android ?? base;
            }
            default: {
                return base;
            }
        }
    }
    else {
        return base;
    }
};
export default getOffersKeyDependsOnPlatform;
