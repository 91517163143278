import cn from 'classnames';
import { Button } from '@common/shared/ui';
import styles from './styles.module.scss';
const ApplyOfferButton = (props) => {
    const { className, children, ...restProps } = props;
    return (<Button type={'button'} className={cn(className, styles.applyOfferCommon)} {...restProps}>
      {children}
    </Button>);
};
export default ApplyOfferButton;
