import { useEffect } from 'react';
export const DATA_ANALYTIC_CLICK_ATTRIBUTE = 'data-analytic-click';
const ANALYTIC_CLICK_ATTRIBUTE = 'analyticClick';
const createAnalyticClickAttribute = (elementKey) => ({
    [DATA_ANALYTIC_CLICK_ATTRIBUTE]: elementKey,
});
const createUseAnalyticGlobalClick = (handleClick) => () => {
    useEffect(() => {
        const handler = (event) => {
            try {
                if (!event.target) {
                    return;
                }
                const element = event.target.closest(`[${DATA_ANALYTIC_CLICK_ATTRIBUTE}]`);
                if (element) {
                    const elementKey = element.dataset[ANALYTIC_CLICK_ATTRIBUTE];
                    if (elementKey) {
                        handleClick(elementKey);
                    }
                }
            }
            catch { }
        };
        document.addEventListener('click', handler, true);
        return () => {
            document.removeEventListener('click', handler, true);
        };
    }, []);
};
export default createUseAnalyticGlobalClick;
export { createAnalyticClickAttribute };
