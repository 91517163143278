import React from 'react';
import { useIntl } from 'react-intl';
import Typograf from '@common/components/common/Typograf';
import { messages } from '../messages';
export const Subheader = (props) => {
    const { distributorName } = props;
    const intl = useIntl();
    const namePartner = distributorName ?? intl.formatMessage(messages.namePartnerText);
    const subheader = intl.formatMessage(messages.subheaderText, { namePartner });
    return <Typograf>{subheader}</Typograf>;
};
