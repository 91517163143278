const apiUserVk = (user) => {
    return {
        userId: user.user_id,
        firstName: user.first_name,
        lastName: user.last_name,
        photo50: user.photo50,
        photo100: user.photo100,
        photo200: user.photo200,
        fullName: `${user.first_name} ${user.last_name}`,
        defaultAvatarUrl: user.photo50,
    };
};
export const mappings = {
    apiUserVk,
};
