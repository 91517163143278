import waitForResult from '@common/helpers/waitForResult';
import { MY_TRACKER_COUNTER_ID, MY_TRACKER_ENABLED, MY_TRACKER_APP, STANDALONE_MY_TRACKER_APP, IS_CLIENT, IS_MINIAPP, } from '@common/consts';
const MAX_ATTEMPTS = 30;
const INTERVAL = 100;
const logDev = (...args) => {
    console.log('%cMytracker', 'color:#fff;background-color:#ff4a1a;padding:0 2px;border-radius:2px', ...args);
};
export class MytrackerManager {
    constructor() {
        this.xMyTrackerLvid = null;
        this.vkId = null;
        this.tmr = null;
        this.init = () => {
            try {
                this.addScript();
                this.addCounter();
                let count = 0;
                const handleBadStatus = () => {
                    if (count >= MAX_ATTEMPTS) {
                        this.resolveInitPromise();
                        return false;
                    }
                    count++;
                    return true;
                };
                waitForResult(async () => {
                    const tmr = window._tmr;
                    return tmr.getClientID?.() || null;
                }, {
                    params: [],
                    handleResponse: (xMyTrackerLvid) => {
                        if (xMyTrackerLvid) {
                            this.xMyTrackerLvid = xMyTrackerLvid;
                            this.resolveInitPromise();
                            return false;
                        }
                        return handleBadStatus();
                    },
                    handleError: handleBadStatus,
                    callInterval: INTERVAL,
                });
            }
            catch {
                this.resolveInitPromise();
            }
        };
        this.initDev = () => {
            this.tmr = {
                push: (params) => {
                    logDev(params);
                },
            };
            this.resolveInitPromise();
            logDev('inited');
        };
        this.addScript = async () => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = 'https://top-fwz1.mail.ru/js/code.js';
            script.id = 'topmailru-code';
            script.onload = () => {
                this.tmr = window._tmr;
            };
            const handler = function () {
                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag?.parentNode?.insertBefore(script, firstScriptTag);
            };
            if (window.opera == '[object Opera]') {
                document.addEventListener('DOMContentLoaded', handler, false);
            }
            else {
                handler();
            }
            document.body.prepend(script);
        };
        this.addCounter = () => {
            if (MY_TRACKER_COUNTER_ID) {
                const img = document.createElement('img');
                img.src = `https://top-fwz1.mail.ru/counter?id=${MY_TRACKER_COUNTER_ID};js=na`;
                img.style.border = '0';
                img.style.position = 'absolute';
                img.style.left = '-9999px';
                img.alt = 'Top.Mail.Ru';
                const div = document.createElement('div');
                div.appendChild(img);
                const noScript = document.createElement('noScript');
                noScript.appendChild(div);
                document.body.prepend(noScript);
            }
        };
        this.isStandalone = undefined;
        this.setIsStandAlone = (isStandalone) => {
            this.isStandalone = isStandalone;
            return isStandalone;
        };
        this.getIsStandAlone = async () => {
            if (!IS_CLIENT) {
                return false;
            }
            if (typeof this.isStandalone === 'boolean') {
                return this.isStandalone;
            }
            if (!IS_MINIAPP) {
                return this.setIsStandAlone(false);
            }
            try {
                const { platform } = await (await import('@common/utils/ApiVkBridge')).default.getClientVersion();
                if (platform === 'android') {
                    const searchParams = window.location.hash.substring(1);
                    const unescapedSearchParams = unescape(searchParams);
                    const urlSearchParams = new URLSearchParams(unescapedSearchParams);
                    const isStandalone = urlSearchParams.has('standalone');
                    return this.setIsStandAlone(isStandalone);
                }
            }
            catch (error) {
                console.error(error);
            }
            return this.setIsStandAlone(false);
        };
        this.getMyTrackerApp = async () => {
            if (!IS_CLIENT) {
                return '';
            }
            const isStandalone = await this.getIsStandAlone();
            return isStandalone && STANDALONE_MY_TRACKER_APP ? STANDALONE_MY_TRACKER_APP : MY_TRACKER_APP;
        };
        this.getMyTrackerLvid = async () => {
            if (!IS_CLIENT) {
                return null;
            }
            await this.initPromise;
            return this.xMyTrackerLvid;
        };
        this.eventPageView = async () => {
            if (!this.vkId && IS_MINIAPP) {
                this.vkId = (await (await import('@common/utils/ApiVkBridge')).default.getUserInfo()).id;
            }
            await this.initPromise;
            this.tmr?.push({
                id: MY_TRACKER_COUNTER_ID,
                type: 'pageView',
                start: new Date().getTime(),
                userid: this.vkId,
            });
        };
        this.eventSubscriptionActivated = async () => {
            await this.initPromise;
            this.tmr?.push({
                id: MY_TRACKER_COUNTER_ID,
                type: 'reachGoal',
                goal: 'Subscription_activated',
            });
        };
        this.resolveInitPromise = Promise.resolve;
        this.initPromise = new Promise((resolve) => {
            this.resolveInitPromise = resolve;
        });
        if (IS_CLIENT) {
            if (MY_TRACKER_ENABLED) {
                this.init();
            }
            else {
                this.initDev();
            }
        }
    }
}
