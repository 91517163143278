import getOffersKeyDependsOnPlatform from '@common/helpers/getOffersKeyDependsOnPlatform';
import { OFFERS_KEY_WORD_MAIN, OFFERS_KEY_WORD_MAIN_ANDROID, OFFERS_KEY_WORD_MAIN_IOS, OFFERS_KEY_WORD_NEXT_ANDROID, OFFERS_KEY_WORD_NEXT_IOS, } from '@common/consts';
import { KeywordsKeeper } from '@common/entities/offers';
export const getPlatformOffersKeyWord = async (isProfileAvailable = false) => {
    const baseOffersKey = await KeywordsKeeper.getKeywordsByDictionaryKey('getPlatformOffersKeyWord');
    try {
        if (isProfileAvailable) {
            return getOffersKeyDependsOnPlatform({
                base: baseOffersKey,
                ios: OFFERS_KEY_WORD_NEXT_IOS,
                android: OFFERS_KEY_WORD_NEXT_ANDROID,
            });
        }
        return getOffersKeyDependsOnPlatform({
            base: OFFERS_KEY_WORD_MAIN,
            ios: OFFERS_KEY_WORD_MAIN_IOS,
            android: OFFERS_KEY_WORD_MAIN_ANDROID,
        });
    }
    catch {
        if (isProfileAvailable) {
            return baseOffersKey;
        }
        return OFFERS_KEY_WORD_MAIN;
    }
};
