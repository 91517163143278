import React from 'react';
import { Avatar, Spacing } from '@vkontakte/vkui';
import parser from 'html-react-parser';
import { typografIt } from '@common/components/common/Typograf';
import { intl } from '@common/shared/lib/intl';
import { modalManagerModel, modalManagerTools } from '@common/services/modal-manager';
import { analyticsDictionary, showMemberManagementConfirmModal } from './lib';
import { messages } from './messages';
const Actions = (props) => {
    const { TwoButtonTemplate } = modalManagerTools.uiModalTemplates;
    return (<TwoButtonTemplate firstButton={{
            analyticClick: analyticsDictionary.buttonConfirm,
            handler: () => {
                showMemberManagementConfirmModal(props);
            },
            children: intl.formatMessage(messages.button_confirm),
        }}/>);
};
const payloadManagerModal = (payload) => {
    return {
        analyticShowAttribute: analyticsDictionary.show,
        icon: (<Avatar size={96} src={payload.defaultAvatarUrl}/>),
        header: payload.fullName,
        subheader: parser(typografIt(intl.formatMessage(messages.description))),
        children: <Spacing size={16}/>,
        actions: <Actions {...payload}/>,
    };
};
export const showMemberManagementModal = (payload) => modalManagerModel.showModal(payloadManagerModal(payload));
