import React from 'react';
import { useUnit } from 'effector-react';
import { analyticsDictionary, createAnalyticClickAttribute } from '@analytics';
import { Text } from '@vkontakte/vkui';
import { useIntl } from 'react-intl';
import ModalButton from '@common/components/modals/ModalButton';
import { AbTestLkUntieCardModel } from '@common/features/ab-test-lk-untie-card';
import { AbTestLkUntieCardV2Model } from '@common/features/ab-test-lk-untie-card-v2/model';
import messages from './messages';
import styles from './styles.module.scss';
const CancelSubscriptionButton = (props) => {
    const { handleCancelSubscription, disabled, type } = props;
    const { cancelButtonSubscriptionType } = useUnit(AbTestLkUntieCardModel.$chosenVariant);
    const abTestNeedCustomizeSubscriptionDetail = AbTestLkUntieCardV2Model.useNeedCustomizeSubscriptionDetail();
    const generalProps = {
        ...createAnalyticClickAttribute(analyticsDictionary.modalSubscriptionManagementButtonCancelClick),
        onClick: handleCancelSubscription,
    };
    const intl = useIntl();
    const text = intl.formatMessage(messages.cancelSubBtnText);
    const showTextButton = (cancelButtonSubscriptionType === 'new' || abTestNeedCustomizeSubscriptionDetail) && type !== 'pause-recurrent';
    if (showTextButton) {
        return (<Text className={styles.label} {...generalProps}>
        {text}
      </Text>);
    }
    return (<ModalButton disabled={disabled} stretched={true} mode='secondary' {...generalProps}>
      {text}
    </ModalButton>);
};
export default CancelSubscriptionButton;
