export const RULES = `### Политика конфиденциальности Подписки VK Музыка

**Общие положения**

**1.** Настоящая Политика конфиденциальности (далее — «Политика») является официальным документом Общества с
ограниченной ответственностью «В Контакте», ОГРН 1079847035179, расположенного по адресу 191024, Санкт-Петербург, ул.
Херсонская, д. 12-14, литер А, помещение 1-Н (далее – Компания, ВКонтакте), и определяют порядок обработки и защиты
информации о физических лицах, в том числе их персональных данных, в связи с использованием Пользователями Подписки VK
Музыка (далее также «Подписка»). Действующая редакция Политики, являющейся публичным документом, доступна любому
пользователю сети Интернет при переходе по ccылке
[https://vk.com/legal/music_privacy](https://vk.com/legal/music_privacy). Компания вправе вносить изменения в настоящую
Политику. При внесении изменений в Политику Компания уведомляет об этом Пользователей путем размещения новой редакции
Правил на сайте VK.com (далее – «Сайт») по постоянному адресу
[https://vk.com/legal/music_privacy](https://vk.com/legal/music_privacy) Пользователь обязуется самостоятельно
периодически проверять настоящую Политику на предмет внесения изменений. Изменения в Политику, внесенные Компанией,
вступают в силу со дня, следующего за днем опубликования таких изменений по постоянному адресу
[https://vk.com/legal/music_privacy](https://vk.com/legal/music_privacy). Пользователь вправе отказаться от принятия
изменений и дополнений в настоящую Политику, что означает отказ Пользователя от использования Подписки. В случае
несогласия Пользователя с условиями настоящих Правил использование Подписки должно быть немедленно прекращено.

**2.** Подписка VK Музыка — опция, при которой уникальный Пользователь на определенной в Соглашении Территории в течение
30 календарных дней (Период Подписки) за фиксированную плату (Стоимость Подписки) получает доступ через Устройство к
функциям, определённым в Лицензионном соглашении
[(https://vk.com/legal/music_license)](https://vk.com/legal/music_license). Управление Подпиской осуществляется
Пользователем в зависимости от способа ее приобретения Пользователем: в рамках Сайта, на сайте Подписки
[https://music.vk.com](https://music.vk.com) (далее – «Сайт VK Музыки») или в настройках устройства, с использованием
которого была приобретена Подписка в мобильном приложении Сайта. В случае приобретения Подписки у третьего лица,
являющегося партнером ВКонтакте, управление Подпиской осуществляется в рамках ресурса, принадлежащего такому третьему
лицу и/или в настройках устройства, с использованием которого была приобретена Подписка в мобильном приложении
соответствующего ресурса. Любое использование Подписки означает согласие Пользователя с Правилами Экосистемы VK, которые
размещены в открытом доступе в сети Интернет по адресу:
[http://vk.com/vk_ecosystem_terms](http://vk.com/vk_ecosystem_terms), а также Пользовательским соглашением VK ID,
которое размещено в открытом доступе в сети Интернет по адресу: [http://id.vk.com/terms](http://id.vk.com/terms) и
Правилами защиты информации о пользователях VK ID, которые размещены в открытом доступе в сети Интернет по адресу по
адресу: [http://id.vk.com/privacy](http://id.vk.com/privacy).

**3.** В случае отсутствия в настоящей Политике положений, в полной мере определяющих (раскрывающих) условия обработки
информации о Пользователях Подписки, применяются положения Правил защиты информации о пользователях VK ID.

**Цели обработки**

**4.** Компания осуществляет обработку информации о Пользователях, в том числе их персональных данных, в целях
исполнения Лицензионного соглашения, выполнения обязательств Компании перед Пользователями в отношении использования
Подписки, в том числе для целей предоставления Пользователям функциональных возможностей Подписки, управления Подпиской,
его совершенствования, предотвращения ошибок в его работе.

**Состав информации о Пользователях**

**5.1.** Данные, получаемые Компанией при оформлении Пользователем Подписки: фамилия и имя, адрес электронной почты,
номер телефона, VK ID, информация о факте привязки банковской карты Пользователя.

**5.2.** Дополнительные данные, получаемые Компанией при доступе Пользователя к Подписке: IP-адрес хоста, тип браузера,
дата и время авторизации на Сайте (включая мобильные приложения Сайта), информация о специальных предложениях, которыми
воспользовался Пользователь, информация, содержащаяся в файлах cookies, тариф Подписки, дата активации (оформления) и
срок действия подписки.

**Обработка информации о Пользователях**

**6.** Сбор информации о Пользователе, указанной в п. 5.1. Политики, осуществляется в Сервисе, в том числе с
использованием инструмента VK Connect. Информация, указанная в п. 5.2. Политики, собирается Компанией в результате
действий Пользователя в рамках Подписки, либо получается Компанией автоматически при доступе Пользователя к Подписке.

**7.** Настоящим Пользователь дает согласие и поручает обработку Компанией указанной выше информации с использованием
средств автоматизации или без использования таких средств в целях выполнения Компанией обязательств перед Пользователем
по представлению функциональных возможностей Подписки путем сбора, систематизации, сопоставления, хранения, накопления,
использования, извлечения, уточнения, обезличивания, блокирования, уничтожения.

**8.** Поскольку Подписка предполагает возможность предоставления Пользователям специальных предложений от партнеров
Компании, представленных на Сайте VK Музыки, Пользователь соглашается и поручает Компании осуществлять передачу
партнерам Компании и получение от партнеров Компании указанной выше информации исключительно для целей и в объеме,
необходимом для исполнения Компанией своих обязательств перед Пользователем в части предоставления функциональных
возможностей Подписки и исполнения партнерами своих обязательств перед Пользователями в части реализации приобретенных
ими специальных предложений. С учетом изложенного Пользователь также дает согласие Компании на поручение партнерам
Компании для вышеобозначенной цели обработки информации о Пользователях путем сбора, получения от Компании,
сопоставления, систематизации, использования, уточнения, хранения, передачи Компании, обезличивания, блокирования и
уничтожения.

**9.** Пользователь настоящим предоставляет свое согласие на использование Компанией своих данных (номер телефона, VK
ID, адрес электронной почты) для направления сообщений на Сайте, по электронной почте, sms сообщений/звонков рекламного
характера, связанных с использованием Подписки, а также предложений пройти опрос, связанный с функциональностью
Подписки.

**10.** В случае прекращения Подписки Компания вправе продолжать хранить в рамках Подписки и на основании Лицензионного
соглашения и настоящей Политики информацию о факте оформления Подписки Пользователем и номер телефона такого
Пользователя исключительно в целях избежания недобросовестного поведения и злоупотреблений со стороны такого
Пользователя (в частности, повторного оформления Подписки с целью получения необоснованных выгод). Компания не
использует указанную информацию для определения (персонификации) физического лица.
`;
