import { createEvent, createStore } from 'effector';
import { AUTH_STATE } from './consts';
const $authLoadingState = createStore('idle');
const $authState = createStore(AUTH_STATE.IDLE);
const $isAuthorized = $authState.map((state) => state === 'authorized');
const changeAuthLoadingState = createEvent();
const authSuccess = createEvent();
const authError = createEvent();
const logout = createEvent();
$authLoadingState.on(changeAuthLoadingState, (_state, payload) => payload);
$authState.on(authSuccess, () => AUTH_STATE.AUTHORIZED);
$authState.on(authError, () => AUTH_STATE.UNAUTHORIZED);
$authState.on(logout, () => AUTH_STATE.UNAUTHORIZED);
export const AuthStateModel = {
    $authState,
    $isAuthorized,
    authSuccess,
    authError,
    logout,
    AUTH_STATE,
    $authLoadingState,
    changeAuthLoadingState,
};
