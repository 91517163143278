import { defineMessages } from 'react-intl';
const messages = defineMessages({
    headline: {
        id: 'headline',
        defaultMessage: 'Проверьте данные',
    },
    fioTitle: {
        id: 'fioTitle',
        defaultMessage: 'ФИО',
    },
    cityTitle: {
        id: 'cityTitle',
        defaultMessage: 'Город',
    },
    yourPhotosTitle: {
        id: 'yourPhotosTitle',
        defaultMessage: 'Ваши фотографии',
    },
});
export default messages;
