import store from 'store';
class Storage {
    constructor() {
        this.store = store;
        this.get = (key) => this.store.get(key);
        this.set = (key, value) => this.store.set(key, value);
        this.remove = (key) => this.store.remove(key);
        this.clearAll = () => this.store.clearAll();
        this.each = (callback) => {
            this.store.each(callback);
        };
    }
}
const storage = new Storage();
export default storage;
