import { getSubscriptionById } from '@common/entities/subscriptions';
import { showCancelSubscriptionPayloadTemplate } from '../lib/modals';
import { pauseRecurrent } from './pause-recurrent';
import { formatDate } from '@apps/common/shared/lib/intl';
export const suspendFlow = async (params) => {
    const { subscriptionId, cancelSubscription } = params;
    const subscriptionEndTime = getSubscriptionById(subscriptionId).getState()?.subscription_end_time;
    const content = {
        modal_pause_recurrent: {
            analyticsShow: 'modal_pause_recurrent',
            icon: 'ManIdeaIcon',
            header: 'Возьмите паузу на месяц',
            subheader: `Подписка будет активна до ${formatDate(subscriptionEndTime || '')}, а потом приостановится. Платёж будет отложен на месяц. Сможете отдохнуть от музыки, а потом вернуться.`,
        },
    };
    const suspendSubscriptionHandler = async () => {
        await pauseRecurrent({
            subscriptionId: subscriptionId,
        });
    };
    const showModalPauseRecurrent = () => {
        showCancelSubscriptionPayloadTemplate({
            ...content['modal_pause_recurrent'],
            firstButton: {
                analyticClick: 'modal_subscription_pause_recurrent-button_suspend',
                handler: async () => {
                    await suspendSubscriptionHandler();
                },
                mode: 'primary',
                children: 'Взять паузу',
            },
            secondButton: {
                analyticClick: 'modal_subscription_pause_recurrent-button_confirm',
                handler: async () => {
                    await cancelSubscription();
                },
                mode: 'secondary',
                children: 'Отменить подписку',
            },
        });
    };
    showModalPauseRecurrent();
};
