import apiCommon from '@common/utils/ApiCommon';
export { autoReAuthAndRecallAPI } from './interceptorsFor401Error';
export { showDDOSModal } from './interceptorsFor429Error';
export { showMaintainModal } from './interceptorsFor423Error';
const responseInterceptorsInit = (responseInterceptorsError, responseInterceptorsSuccess) => {
    responseInterceptorsError.forEach((interceptor) => {
        apiCommon.setInterceptorsResponse(null, interceptor);
    });
    responseInterceptorsSuccess.forEach((interceptor) => {
        apiCommon.setInterceptorsResponse(interceptor, null);
    });
};
export const initInterceptors = (responseInterceptorsError, responseInterceptorsSuccess = []) => {
    responseInterceptorsInit(responseInterceptorsError, responseInterceptorsSuccess);
};
