import React from 'react';
import classNames from 'classnames';
import { Icon20Cancel } from '@vkontakte/icons';
import styles from './styles.module.scss';
import { IconButton } from '@vkontakte/vkui';
const ImagePreview = (props) => {
    const { className, imageUrl, onClear } = props;
    if (!imageUrl) {
        return null;
    }
    return (<div className={classNames(styles.container, className)} style={{ backgroundImage: `url(${imageUrl})` }}>
      {onClear && (<IconButton aria-label={'Удалить выбранную картинку'} onClick={onClear} className={styles.iconButton}>
          <Icon20Cancel />
        </IconButton>)}
    </div>);
};
export default ImagePreview;
