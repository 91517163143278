import React from 'react';
import { modalManagerModel, modalManagerTools } from '@common/services/modal-manager';
import { intl } from '@common/shared/lib/intl';
import ModalButton from '@common/components/modals/ModalButton';
import { messages } from './messages';
export const showAbTestDeactivateTrialSuccessModal = (props) => {
    const { analyticShowAttribute } = props;
    modalManagerTools.modalTemplates.showMessageModal({
        analyticShowAttribute,
        mode: 'success',
        header: intl.formatMessage(messages.header),
        subheader: intl.formatMessage(messages.subheader),
        actions: (<ModalButton size='l' mode='primary' onClick={() => modalManagerModel.closeModal()}>
        {intl.formatMessage(messages.confirmButton)}
      </ModalButton>),
    });
};
