import React, { useState } from 'react';
import { ButtonGroup } from '@vkontakte/vkui';
import { useIntl } from 'react-intl';
import ModalButton from '@common/components/modals/ModalButton';
import { modalManagerModel } from '@common/services/modal-manager';
import { errorLog } from '@common/shared/lib/utils';
import { messages } from './messages';
import { createAnalyticClickAttribute } from '@analytics';
import { analytics } from '../../analytics';
const Actions = (props) => {
    const { buttonTitle, buttonAction } = props;
    const intl = useIntl();
    const [state, setState] = useState('idle');
    const isLoading = state === 'loading';
    const onLeaveSubscriptions = () => {
        modalManagerModel.closeModal();
    };
    const onAction = async () => {
        try {
            setState('loading');
            modalManagerModel.closeModal();
            await buttonAction();
        }
        catch (e) {
            errorLog(e);
        }
        finally {
            setState('idle');
        }
    };
    return (<ButtonGroup mode={'vertical'} gap={'m'} stretched={true}>
      <ModalButton size={'l'} stretched={true} onClick={onAction} loading={isLoading} disabled={isLoading} mode={'secondary'} {...createAnalyticClickAttribute(analytics.click)}>
        {buttonTitle}
      </ModalButton>
      <ModalButton size={'l'} stretched={true} onClick={onLeaveSubscriptions} disabled={isLoading}>
        {intl.formatMessage(messages.leaveSubscriptions)}
      </ModalButton>
    </ButtonGroup>);
};
export default Actions;
