import React from 'react';
import { useUnit } from 'effector-react';
import ReactMarkdown from 'react-markdown';
import { Checkbox } from '@vkontakte/vkui';
import { createAnalyticClickAttribute } from '@analytics';
import Typograf from '@common/components/common/Typograf';
import { modalManagerModel } from '@common/services/modal-manager';
import { privacyPolicyModel } from './model';
import { RULES } from './lib/rules';
import { URL_MUSIC_PRIVACY } from './lib/constants';
import styles from './styles.module.scss';
import ModalButton from '@common/components/modals/ModalButton';
const Content = () => {
    const onChangeAcceptedCheckBox = useUnit(privacyPolicyModel.onChangeAcceptedCheckBox);
    return (<div className={styles.content}>
      <div className={styles.rulesBlock}>
        <div>
          
          <ReactMarkdown children={RULES}/>
        </div>
      </div>
      <div className={styles.checkboxBlock}>
        <Checkbox onChange={onChangeAcceptedCheckBox}/>
        <div>
          Нажимая кнопку &laquo;Подтвердить&raquo;, я выражаю свое согласие с обновленной&nbsp;
          <a className={styles.checkboxBlock__link} href={URL_MUSIC_PRIVACY} target='_blank' rel='noreferrer'>
            Политикой конфиденциальности
          </a>
        </div>
      </div>
    </div>);
};
const Actions = () => {
    const isAccepted = useUnit(privacyPolicyModel.$isAccepted);
    const disabledButton = !isAccepted;
    return (<ModalButton {...createAnalyticClickAttribute('modal_privacy_policy-button_confirm')} disabled={disabledButton} onClick={privacyPolicyModel.onClickAcceptPrivacyPolicy} mode={'secondary'}>
      Подтвердить
    </ModalButton>);
};
const payloadToManagerModal = () => {
    return {
        analyticShowAttribute: 'modal_privacy_policy',
        header: <Typograf>Политика конфиденциальности</Typograf>,
        subheader: (<Typograf>
        Ознакомьтесь с&nbsp;ней ниже. Если вы&nbsp;согласны, нажмите &laquo;Подтвердить&raquo; и&nbsp;продолжайте
        пользоваться VK&nbsp;Музыкой.
      </Typograf>),
        children: <Content />,
        actions: <Actions />,
    };
};
export const showPrivacyPolicyModal = () => {
    modalManagerModel.showModal(payloadToManagerModal());
};
