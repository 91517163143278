import React, { useState } from 'react';
import SkeletonItem from '@common/components/common/SkeletonItem';
import { useCheckoutFrame } from '@common/entities/checkout/hooks';
import styles from './styles.module.scss';
const CheckoutFrameV2 = (props) => {
    const { iframeUrl, ...useCheckoutFrameParams } = props;
    const [iframeHeight, setIframeHeight] = useState(undefined);
    useCheckoutFrame({ ...useCheckoutFrameParams, setIframeHeight: (height) => setIframeHeight(height) });
    return (<div className={styles.container}>
      {iframeUrl ? (<iframe style={{ height: iframeHeight }} className={styles.iframe} src={iframeUrl} frameBorder='0'/>) : (<SkeletonItem className={styles.skeleton}/>)}
    </div>);
};
export default CheckoutFrameV2;
