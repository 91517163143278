import React from 'react';
import { Icon28CheckCircleOutline, Icon28ErrorCircleOutline } from '@vkontakte/icons';
const errorIcon = {
    before: <Icon28ErrorCircleOutline fill='var(--vkui--color_icon_negative)'/>,
};
const successIcon = {
    before: <Icon28CheckCircleOutline fill='var(--vkui--color_icon_positive)'/>,
};
export const payloadTemplates = {
    errorIcon,
    successIcon,
};
