import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { StudentsRejected } from '../../icons';
import { Actions, Header, Subheader } from './ui';
export const showStudentRejectModal = (params) => {
    modalManagerModel.showModal({
        analyticShowAttribute: '',
        icon: <StudentsRejected />,
        header: <Header />,
        subheader: <Subheader {...params}/>,
        actions: <Actions {...params}/>,
    });
};
