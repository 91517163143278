const MAP_API_ERROR_TO_ERROR_STATUS_STATE = {
    last_name: 'lastNameStatus',
    first_name: 'firstNameStatus',
    city_id: 'cityIdStatus',
    city_name: 'cityIdStatus',
    establishment_id: 'establishmentIdStatus',
    establishment_name: 'establishmentIdStatus',
    establishment_type: 'establishmentIdStatus',
};
export const mapErrorFromApiToErrorStatusInState = (errors) => {
    return errors.reduce((result, error) => {
        const mappingFieldName = error.field;
        if (MAP_API_ERROR_TO_ERROR_STATUS_STATE[mappingFieldName]) {
            const stateFiledName = MAP_API_ERROR_TO_ERROR_STATUS_STATE[mappingFieldName];
            result[stateFiledName] = error.detail;
        }
        return result;
    }, {});
};
