import Head from 'next/head';
export const Seo = ({ title, metaTags }) => {
    const headTitle = title && <title>{title}</title>;
    const headMetaTags = metaTags &&
        metaTags.map((tag) => (<meta key={tag.name || tag.property} {...tag}/>));
    return (<Head>
      {headTitle}
      {headMetaTags}
    </Head>);
};
