import React from 'react';
import { useUnit } from 'effector-react';
import { createAnalyticClickAttribute } from '@analytics';
import ModalButton from '@common/components/modals/ModalButton';
import { modalStateModel, nextStepButtonModel } from '@common/entities/student/lib/show-student-form-modal/models';
const NextStepButton = () => {
    const modalState = useUnit({ ...modalStateModel });
    const nextStepState = useUnit({ ...nextStepButtonModel });
    const isDisabled = nextStepState.$state.status === 'error';
    const isFormSending = modalState.$state.status === 'process';
    const onClick = async () => {
        try {
            modalState.setState({ status: 'process' });
            const nextClickStatus = await nextStepState.$state.click();
            if (nextClickStatus && nextClickStatus.nextClickStatus === 'none') {
                return;
            }
            modalState.increaseStep();
        }
        finally {
            modalState.setState({ status: 'idle' });
        }
    };
    return (<ModalButton {...createAnalyticClickAttribute(nextStepState.$state.analytic)} stretched={true} disabled={isDisabled || isFormSending} loading={isFormSending} onClick={onClick}>
      Продолжить
    </ModalButton>);
};
export default NextStepButton;
