import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    header: {
        id: 'header',
        defaultMessage: 'Подписка на&nbsp;VK&nbsp;Музыку приостановлена',
    },
    subheader: {
        id: 'subheader',
        defaultMessage: 'Вы&nbsp;можете слушать музыку без ограничений до&nbsp;{subscriptionEndTime} включительно. Следующее списание {newRecurrentDate}',
    },
});
