import { createEvent } from 'effector';
import { $members } from './members';
export const changeStateMember = createEvent();
$members.on(changeStateMember, (state, payload) => {
    const { items } = state;
    const changeState = (item) => item.userId === payload.userId ? { ...item, state: payload.state } : item;
    return {
        ...state,
        items: items.map(changeState),
    };
});
