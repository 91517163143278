import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    waiting: {
        id: 'waiting',
        defaultMessage: 'В ожидании',
    },
    active: {
        id: 'active',
        defaultMessage: 'Активна',
    },
    auto_renew_cancelled: {
        id: 'auto_renew_cancelled',
        defaultMessage: 'Активна, не продлевается',
    },
    suspended: {
        id: 'suspended',
        defaultMessage: 'Приостановлена',
    },
    inactive: {
        id: 'inactive',
        defaultMessage: 'Остановлена',
    },
});
