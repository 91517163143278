import { SubscriptionsModel } from '@common/entities/subscriptions';
import { setParamsStoreConcelSubscriptionFlow } from './params-store-cancel-subscription-flow';
import { cancelGroupSubscriptionFlow } from './cancel-group-subscription-flow';
import { cancelBaseFlow } from './cancel-base-subscription-flow';
const getIsGroupSubscriptionState = () => SubscriptionsModel.$activeSubscription.getState()?.features?.group?.is_group_subscription;
export const cancelFlow = (params) => {
    setParamsStoreConcelSubscriptionFlow(params);
    const { subscriptionId, isPauseAvailable } = params;
    const isGroupSubscription = getIsGroupSubscriptionState();
    if (isGroupSubscription) {
        cancelGroupSubscriptionFlow({ subscriptionId, isPauseAvailable });
        return;
    }
    cancelBaseFlow({ subscriptionId, isPauseAvailable });
};
