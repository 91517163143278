import { useEffect } from 'react';
import debounce from 'lodash/debounce';
export const DATA_ANALYTIC_SHOW_ATTRIBUTE = 'data-analytic-show';
const ANALYTIC_SHOW_ATTRIBUTE = 'analyticShow';
const createAnalyticShowAttribute = (elementKey) => ({
    [DATA_ANALYTIC_SHOW_ATTRIBUTE]: elementKey,
});
const findElements = (element) => [
    ...(element || document).querySelectorAll(`[${DATA_ANALYTIC_SHOW_ATTRIBUTE}]`),
];
const calculateElementThreshold = (element) => Math.min(window.innerHeight / element.clientHeight, 1);
const createUseAnalyticGlobalShow = (handleShow) => () => {
    useEffect(() => {
        try {
            if (!MutationObserver && !IntersectionObserver) {
                return;
            }
            const intersectionObservers = new Map();
            const observeElement = (element, handleObserve) => {
                const oldIntersectionObserver = intersectionObservers.get(element);
                const threshold = calculateElementThreshold(element);
                if (oldIntersectionObserver && oldIntersectionObserver.thresholds[0] === threshold) {
                    return;
                }
                if (oldIntersectionObserver) {
                    oldIntersectionObserver.disconnect();
                }
                const intersectionObserver = new IntersectionObserver(handleObserve, {
                    threshold: calculateElementThreshold(element),
                });
                intersectionObserver.observe(element);
                intersectionObservers.set(element, intersectionObserver);
            };
            const handleObserve = (events) => {
                events.forEach((event) => {
                    if (event.isIntersecting) {
                        const { target } = event;
                        const elementKey = target?.dataset?.[ANALYTIC_SHOW_ATTRIBUTE];
                        if (elementKey) {
                            handleShow(elementKey);
                        }
                    }
                });
            };
            const handleResize = () => {
                intersectionObservers.forEach((_intersectionObserver, element) => {
                    observeElement(element, handleObserve);
                });
            };
            const handleResizeDebounced = debounce(handleResize, 500);
            const initialElements = findElements();
            initialElements.forEach((element) => observeElement(element, handleObserve));
            window.addEventListener('resize', handleResizeDebounced);
            const handleMutation = (events) => {
                events.forEach((event) => {
                    const addedNodesArray = [...event.addedNodes];
                    const removedNodesArray = [...event.removedNodes];
                    const changedNode = event.target;
                    const changedAttrName = event.type === 'attributes' && event.attributeName;
                    const isShowAttributeChanged = changedAttrName === DATA_ANALYTIC_SHOW_ATTRIBUTE && changedNode && changedNode instanceof HTMLElement;
                    if (isShowAttributeChanged) {
                        const currentIntersectionObserver = intersectionObservers.get(changedNode);
                        if (currentIntersectionObserver) {
                            currentIntersectionObserver.disconnect();
                            intersectionObservers.delete(changedNode);
                        }
                        if (changedNode.dataset[ANALYTIC_SHOW_ATTRIBUTE]) {
                            observeElement(changedNode, handleObserve);
                        }
                    }
                    addedNodesArray.forEach((node) => {
                        try {
                            if (node instanceof HTMLElement) {
                                if (node.dataset[ANALYTIC_SHOW_ATTRIBUTE]) {
                                    observeElement(node, handleObserve);
                                }
                                const elementsToObserve = findElements(node);
                                elementsToObserve.forEach((element) => observeElement(element, handleObserve));
                            }
                        }
                        catch { }
                    });
                    removedNodesArray.forEach((removedNode) => {
                        intersectionObservers.forEach((intersectionObserver, element) => {
                            if (removedNode.contains(element)) {
                                intersectionObserver.disconnect();
                                intersectionObservers.delete(element);
                            }
                        });
                    });
                });
            };
            const mutationObserver = new MutationObserver(handleMutation);
            mutationObserver.observe(document.body, {
                childList: true,
                subtree: true,
                attributes: true,
                attributeOldValue: true,
            });
            return () => {
                intersectionObservers.forEach((observer) => observer.disconnect());
                intersectionObservers.clear();
                mutationObserver.disconnect();
                window.removeEventListener('resize', handleResizeDebounced);
            };
        }
        catch { }
    }, []);
};
export default createUseAnalyticGlobalShow;
export { createAnalyticShowAttribute };
