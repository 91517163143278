import icon90Education3XWebp from './icon-90-education@3x.webp';
import icon90Education3XPng from './icon-90-education@3x.png';
import icon90Education2XWebp from './icon-90-education@2x.webp';
import icon90Education2XPng from './icon-90-education@2x.png';
import icon90Education1XWebp from './icon-90-education@1x.webp';
import icon90Education1XPng from './icon-90-education@1x.png';
export const icon90EducationImage = {
    src: icon90Education1XPng.src,
    width: 90,
    height: 90,
    sources: [
        { width: 90, height: 90, srcSet: icon90Education3XWebp.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 90, height: 90, srcSet: icon90Education3XPng.src, media: '(-webkit-min-device-pixel-ratio: 3)' },
        { width: 90, height: 90, srcSet: icon90Education2XWebp.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 90, height: 90, srcSet: icon90Education2XPng.src, media: '(-webkit-min-device-pixel-ratio: 2)' },
        { width: 90, height: 90, srcSet: icon90Education1XWebp.src },
        { width: 90, height: 90, srcSet: icon90Education1XPng.src },
    ],
};
