import React from 'react';
import { IconButton, Input, Spacing } from '@vkontakte/vkui';
import { Icon20CopyOutline } from '@vkontakte/icons';
import parser from 'html-react-parser';
import { MINIAPP_URL } from '@common/consts';
import { typografIt } from '@common/components/common/Typograf';
import { intl } from '@common/shared/lib/intl';
import { modalManagerModel, modalManagerTools } from '@common/services/modal-manager';
import { snackbarManagerModel } from '@common/services/snackbar-manager';
import { copySubscriptionInviteLink } from '@common/features/group-subscription/lib';
import { groupSubscriptionMembersModel } from '@common/features/group-subscription/model';
import { analyticsDictionary } from './lib';
import { messages } from './messages';
const Content = (props) => {
    const { groupId } = props;
    return (<>
      <Spacing size={16}/>
      <Input after={<IconButton onClick={() => copySubscriptionInviteLink(groupId || '')}>
            <Icon20CopyOutline />
          </IconButton>} type='text' defaultValue={`${MINIAPP_URL}#joinGroup=${groupId}`} readOnly={true}/>
    </>);
};
const Actions = (props) => {
    const { userId } = props;
    const { TwoButtonTemplate } = modalManagerTools.uiModalTemplates;
    return (<TwoButtonTemplate firstButton={{
            analyticClick: analyticsDictionary.buttonConfirm,
            handler: () => {
                groupSubscriptionMembersModel.removeMember({
                    userId,
                    errorMessage: intl.formatMessage(messages.remove_member_error_message),
                });
                modalManagerModel.closeModal();
                snackbarManagerModel.addSnackSuccess({
                    children: intl.formatMessage(messages.remove_member_success_message),
                });
            },
            children: intl.formatMessage(messages.button_confirm),
        }}/>);
};
const payloadManagerModal = (payload) => {
    return {
        analyticShowAttribute: analyticsDictionary.show,
        header: parser(typografIt(intl.formatMessage(messages.title))),
        subheader: parser(typografIt(intl.formatMessage(messages.description))),
        children: <Content {...payload}/>,
        actions: <Actions {...payload}/>,
    };
};
export const showInvitationSentManagementModal = (payload) => modalManagerModel.showModal(payloadManagerModal(payload));
