import { createEffect, createEvent, sample } from 'effector';
import { studentOfferModel } from '@common/features/start-student-flow/model/student-offer';
import { StudentModel } from '@common/entities/student/model';
import { SubscriptionsModel } from '@common/entities/subscriptions/model';
import { StrategyFactory } from '@common/entities/student/lib';
import { checkToken } from './check-token';
import { initAuthorizedWay } from './init-authorized-way';
export const finishConfirmingAuthorizedWay = createEvent();
export const confirmingAuthorizedWay = createEvent();
const checkTokenFx = createEffect(async ({ hash }) => {
    const token = hash.replace(/.+=/, '');
    await checkToken(token);
});
const applyOfferFx = createEffect(async (payload) => {
    const { activeSubscription, statusData } = payload;
    if (!statusData) {
        return;
    }
    const studentOfferResponse = await studentOfferModel.getStudentOffersFx();
    const studentOffer = studentOfferResponse.success ? studentOfferResponse.payload[0] ?? null : null;
    const strategyFactory = new StrategyFactory();
    const confirmedLogic = strategyFactory.getStrategy('CONFIRMED');
    await confirmedLogic.do({ statusData, activeSubscription, studentOffer });
});
const { $startFlowState, doneData } = initAuthorizedWay({
    startEvent: confirmingAuthorizedWay,
    initStartFlowState: {
        callbackAfterFinishedFlow: () => Promise.resolve(),
        hash: '',
    },
});
sample({
    clock: doneData,
    source: {
        startFlowState: $startFlowState,
    },
    fn: (sourceData) => ({ hash: sourceData.startFlowState.hash }),
    target: checkTokenFx,
});
sample({
    clock: checkTokenFx.doneData,
    source: {
        statusData: StudentModel.$sourceData,
        activeSubscription: SubscriptionsModel.$activeSubscription,
    },
    target: applyOfferFx,
});
sample({ clock: applyOfferFx.doneData, target: finishConfirmingAuthorizedWay });
