import camera200Webp from './camera_200.webp';
import camera200Png from './camera_200.png';
export const camera200Image = {
    src: camera200Png.src,
    width: 200,
    height: 200,
    sources: [
        { width: 200, height: 200, srcSet: camera200Webp.src },
        { width: 200, height: 200, srcSet: camera200Png.src },
    ],
};
