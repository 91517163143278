import apiCommon from '@common/utils/ApiCommon';
import SubscriptionRecurrentEnableParamsSchema from '@common/utils/ApiCommon/schemas/SubscriptionRecurrentEnableParamsSchema.json';
const SubscriptionRecurrentEnableResponseSchema = () => import('@common/utils/ApiCommon/schemas/SubscriptionRecurrentEnableResponseSchema.json');
const GetSubscriptionsResponseSchema = () => import('@common/utils/ApiCommon/schemas/GetSubscriptionsResponseSchema.json');
const GetSubscriptionStatusResponseSchema = () => import('@common/utils/ApiCommon/schemas/GetSubscriptionStatusResponseSchema.json');
const getSubscriptions = (config) => apiCommon.makeGetRequest('/api/front/user/subscription/list', undefined, undefined, GetSubscriptionsResponseSchema, config);
const getSubscriptionStatus = () => apiCommon.makeGetRequest('/api/front/user/ut/check', undefined, undefined, GetSubscriptionStatusResponseSchema);
const getCurrentSubscriptionInfo = () => apiCommon.makeGetRequest('/api/front/user/subscription/info', undefined, undefined);
const enableSubscriptionRecurrent = (params) => apiCommon.makePostRequest('/api/front/user/subscription/recurrent/enable', params, params && SubscriptionRecurrentEnableParamsSchema, SubscriptionRecurrentEnableResponseSchema);
export const subscriptionsApi = {
    getSubscriptionStatus,
    getSubscriptions,
    getCurrentSubscriptionInfo,
    enableSubscriptionRecurrent,
};
