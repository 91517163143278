import React from 'react';
import { Counter } from '@vkontakte/vkui';
import { NOTIFICATIONS_ROUTE, PROFILE_ROUTE, TARIFFS_ROUTE, PAYMENT_METHOD_ROUTE, } from '@website/shared/lib/profile-routes';
import { MUSIC_HELP_MINIAPP_URL } from '@common/consts';
export const MIN_UNREAD_NOTIFICATIONS_TO_SHOW = 1;
const MINIAPP_URL = MUSIC_HELP_MINIAPP_URL;
export const onFaqClick = () => {
    window.open(MINIAPP_URL, '_blank');
};
export const buttonsSettingsCreator = ({ handler, unreadNotificationsCount }) => ({
    home: {
        conditionDisable: PROFILE_ROUTE,
        handler: handler(PROFILE_ROUTE),
    },
    faq: {
        conditionDisable: 'faq',
        handler: onFaqClick,
    },
    other_offers: {
        conditionDisable: TARIFFS_ROUTE,
        handler: handler(TARIFFS_ROUTE),
    },
    paymethod: {
        conditionDisable: PAYMENT_METHOD_ROUTE,
        handler: handler(PAYMENT_METHOD_ROUTE),
    },
    notifications: {
        conditionDisable: NOTIFICATIONS_ROUTE,
        handler: handler(NOTIFICATIONS_ROUTE),
        indicator: unreadNotificationsCount >= MIN_UNREAD_NOTIFICATIONS_TO_SHOW && (<Counter mode='primary'>{unreadNotificationsCount}</Counter>),
    },
});
