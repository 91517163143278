import React from 'react';
import { createAnalyticClickAttribute } from '@analytics';
import { ButtonGroup } from '@vkontakte/vkui';
import { useUnit } from 'effector-react';
import ModalButton from '@common/components/modals/ModalButton';
import { StudentModel } from '../../../model';
import { showStudentStatusReadyModal } from '../model';
export const Actions = () => {
    const [status] = useUnit([StudentModel.$status]);
    const [stepValue] = useUnit([showStudentStatusReadyModal.$stepValue]);
    const { buttons } = stepValue;
    const getButtonAnalytics = (button) => {
        const value = status && button.elementKey(status);
        return value ? createAnalyticClickAttribute(value) : {};
    };
    return (<ButtonGroup stretched={true}>
      {buttons.map((button, index) => (<ModalButton {...getButtonAnalytics(button)} key={index} mode={button.mode} appearance={'accent'} size={'l'} onClick={button.action}>
          {button.name}
        </ModalButton>))}
    </ButtonGroup>);
};
