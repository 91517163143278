import { useUnit } from 'effector-react';
import { Icon20ChevronRightOutline } from '@vkontakte/icons';
import { UserAvatar, UserModel } from '@website/entities/user';
import { ACCOUNT_LINK, PAY_LINK } from './lib/constants';
import Ruble from './image/ruble.svg';
import styles from './styles.module.scss';
const AccountMenu = () => {
    const { name, phone } = useUnit(UserModel.$profile) || {};
    return (<div className={styles.accountMenu}>
      <a href={ACCOUNT_LINK} className={styles.userBlock}>
        <div className={styles.userBlock__leftSide}>
          <UserAvatar height={40} width={40}/>
          <div className={styles.userBlock__info}>
            <span className={styles.userBlock__name}>{name}</span>
            {phone && <span className={styles.userBlock__phone}>{phone}</span>}
            <span className={styles.userBlock__vkId}>Управление VK ID</span>
          </div>
        </div>
        <Icon20ChevronRightOutline />
      </a>
      <div className={styles.divider}/>
      <a href={PAY_LINK} className={styles.payBlock}>
        <span className={styles.payBlock__vkPay}>VK Pay</span>
        <span className={styles.payBlock__open}>открыть</span>
        <div className={styles.payBlock__ruble}>
          <Ruble />
        </div>
      </a>
    </div>);
};
export default AccountMenu;
