import apiCommon from '@common/utils/ApiCommon';
export const getOnCardUnbindTestVariant = async () => {
    return await apiCommon
        .makeGetRequest(`api/front/user/get-deactivate-trial-on-card-unbind-test-variant`, undefined)
        .then((res) => res.data);
};
export const getOnStopRecurrentTestVariant = async () => {
    return await apiCommon
        .makeGetRequest(`api/front/user/get-deactivate-trial-on-stop-recurrent-test-variant`, undefined)
        .then((res) => res.data);
};
export const getDeactivateTrialTestVariant = async () => {
    return await apiCommon
        .makeGetRequest(`api/front/user/get-deactivate-trial-test-variant`, undefined)
        .then((res) => res.data);
};
