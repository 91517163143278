import { createEvent, createStore, sample } from 'effector';
import { spread } from 'patronum';
export const modalFactoryModel = (modelIds, defaultValue) => {
    const $activeId = createStore(null);
    const $modalsPayload = createStore(defaultValue);
    const showModal = createEvent();
    const setState = createEvent();
    const clear = createEvent();
    sample({
        clock: showModal,
        source: { activeId: $activeId, modalsPayload: $modalsPayload },
        fn: ({ activeId, modalsPayload }, newValue) => {
            const modalId1 = modelIds[0];
            const modalId2 = modelIds[1];
            switch (activeId) {
                case modalId1: {
                    return {
                        activeId: modalId2,
                        modalsPayload: {
                            ...modalsPayload,
                            [modalId2]: newValue,
                        },
                    };
                }
                case modalId2:
                default: {
                    return {
                        activeId: modalId1,
                        modalsPayload: {
                            ...modalsPayload,
                            [modalId1]: newValue,
                        },
                    };
                }
            }
        },
        target: spread({ activeId: $activeId, modalsPayload: $modalsPayload }),
    });
    sample({
        clock: setState,
        source: { activeId: $activeId, modalsPayload: $modalsPayload },
        filter: ({ activeId }) => !activeId,
        fn: ({ activeId, modalsPayload }, newValue) => {
            const activeModalId = activeId;
            const prevStateActiveIdModal = modalsPayload[activeModalId];
            return { ...modalsPayload, [activeModalId]: { ...prevStateActiveIdModal, ...newValue } };
        },
        target: $modalsPayload,
    });
    sample({ clock: $activeId, filter: (activeId) => activeId === null, fn: () => defaultValue, target: $modalsPayload });
    $activeId.on(clear, () => null);
    return {
        $activeId,
        $modalsPayload,
        showModal,
        clear,
        setState,
    };
};
