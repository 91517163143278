import { defineMessages } from 'react-intl';
const messages = defineMessages({
    header: {
        id: 'header',
        defaultMessage: 'Хотите отменить подписку на VK Музыку?',
    },
    subheader: {
        id: 'subheader',
        defaultMessage: 'Вы сможете слушать музыку без ограничений только до конца оплаченного периода. Подписка не будет продлеваться автоматически.',
    },
    confirmButtonText: {
        id: 'confirmButtonText',
        defaultMessage: 'Все равно отменить',
    },
    closeModalButtonText: {
        id: 'closeModalButtonText',
        defaultMessage: 'Останусь',
    },
    defaultTitleError: {
        id: 'defaultTitleError',
        defaultMessage: 'Упс',
    },
    defaultDetailError: {
        id: 'defaultDetailError',
        defaultMessage: 'Что то пошло не так',
    },
    titleSuccessModal: {
        id: 'titleSuccessModal',
        defaultMessage: 'Подписка на&nbsp;VK&nbsp;Музыку приостановлена',
    },
    descriptionSuccessModal: {
        id: 'descriptionSuccessModal',
        defaultMessage: 'Вы&nbsp;можете слушать музыку без ограничений до&nbsp;{subscriptionEndTimeToRender} включительно. Следующее списание {newRecurrentDateToRender}',
    },
});
export default messages;
