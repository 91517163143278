import { defineMessages } from 'react-intl';
const messages = defineMessages({
    header: {
        id: 'header',
        defaultMessage: 'Описание',
    },
    type: {
        id: 'type',
        defaultMessage: 'Тип',
    },
    status: {
        id: 'status',
        defaultMessage: 'Статус',
    },
    subscriptionStartTime: {
        id: 'subscriptionStartTime',
        defaultMessage: 'Дата активации',
    },
    cost: {
        id: 'cost',
        defaultMessage: 'Сумма платежа',
    },
    lastRecurrentPaymentTime: {
        id: 'lastRecurrentPaymentTime',
        defaultMessage: 'Последний платеж',
    },
});
export default messages;
