import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
export const preloadPayloadSearchFriendsModal = async () => {
    const header = import('./ui/header').then((result) => result.default);
    const footer = import('./ui/footer').then((result) => result.default);
    const content = import('./ui/content').then((result) => result.default);
    const [Header, Footer, Content] = await Promise.all([header, footer, content]);
    return {
        analyticShowAttribute: '',
        header: <Header />,
        children: <Content />,
        footer: <Footer />,
    };
};
export const showSearchFriendsModal = async () => {
    const payload = await preloadPayloadSearchFriendsModal();
    modalManagerModel.showModalPageWithFixedFooter(payload);
};
